import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Ticket from "../Ticket/index";
import MyOrder from "../Order/index";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     minWidth: 200,
//     marginRight: "62px",
//     boxShadow:
//       "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
//     borderRadius: "15px 15px 15px 15px",
//   },
//   MainContainer: {
//     marginLeft: "0px",
//     [theme.breakpoints.between("sm", "md")]: {
//       width: "100%",
//       marginLeft: "-15px",
//       marginRight: "0px",
//     },
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
// }));
// const classes = useStyles();

const MyDashboard = () => {
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");

  const data = [
    {
      Quantity: 20,
      Price: 100,
      TotalPrice: 2000,
    },
    {
      Quantity: 10,
      Price: 50,
      TotalPrice: 500,
    },
    {
      Quantity: 70,
      Price: 150,
      TotalPrice: 10500,
    },
  ];
  const [list, updateList] = useState(data);

  useEffect(() => {
    save();
  });

  const del = (i) => {
    const temp = [...list];
    temp.splice(i, 1);
    updateList(temp);
  };

  const save = () => {
    const total = list.reduce(function (prev, current) {
      return prev + +current.Quantity;
    }, 0);
    const price = list.reduce(function (prev, current) {
      return prev + +current.Price;
    }, 0);
    const d = total;
    const f = d * price;
    setData2(d);
    setData3(f);
  };

  return (
    <div>
      <MyOrder />
      <div style={{ marginTop: "2rem" }}></div>
      <Ticket />
    </div>
  );
};
export default MyDashboard;
