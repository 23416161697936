import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LoginImage from "../../../Assets/Images/Otp.png";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../Assets/StyleSheets/Theme";
import { Link, useLocation } from "@reach/router";
import { useParams, useHistory } from "react-router-dom";
import { MAIL } from "../../../RouteS/ApiRoutes/Mail";
import { RESENDOTP } from "../../../RouteS/ApiRoutes/Mail";
import SucessModal from "../../../Component/Modal/SucessModal";
import ErrorModal from "../../../Component/Modal/ErrorModal";
import Modal2 from "../../../Component/Modal/index2";
import OtpInput from "react-otp-input";
import Header from "../../../Component/Header/index";
import MainImage from "../../../Assets/Images/MainLogo.jpg";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "600px",
      height: 280,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "2.5rem",
    // paddingTop: "9.5rem",
    // position: "relative",
    // bottom: 0,
    position: "absolute",
    // marginBottom: "50px",
    paddingTop: "11rem",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: "23.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "9.5rem",
    },
  },
  RootImage: {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "3rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "1rem",
      marginLeft: "1rem",
    },
  },
  SubmitBtn: {
    width: "85%",
    background: ThemeColor,
    marginTop: "1rem",
    color: "white",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.down("xs")]: {
      width: "77%",
    },
  },
  textLabel: {
    textAlign: lang == "ar " ? "right" : "left",
    marginBottom: "20px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  OtpInput: {
    "&>input": {
      width: "4em !important",
      height: "4em",
      textAlign: "center",
      border: `1px solid ${ThemeColor}`,
      borderRadius: 8,
      fontSize: "16.5px",
      [theme.breakpoints.down("xs")]: {
        width: "2.5em !important",
        height: "2.5em",
        fontSize: "inherit",
      },
      "&:focus": {
        border: `1px solid ${ThemeColor}`,
        [theme.breakpoints.down("xs")]: {
          border: `1px solid ${ThemeColor}`,
        },
      },
    },
  },
  EmailImage: {
    [theme.breakpoints.between("sm", "md")]: {
      display: "none",
    },
  },
  pdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "18px",
    },
  },
  span_set: {
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
}));
const Email = () => {
  const classes = useStyles();
  const url_str = window.location.href;
  const data = url_str.split("/");
  const email = data[4].toString();
  localStorage.setItem("Email", JSON.stringify(email));
  const [otp, setOtp] = useState();
  const [resend, setResend] = useState(false);
  const signupvals = JSON.parse(localStorage.getItem("signupval"));
  const [otpmes, setOtpmes] = useState(
    `Registration Verification Otp Value:${signupvals}`
  );
  const [otpopen, setOtpOpen] = useState(true);
  const { t } = useTranslation();

  var date = new Date().toLocaleString();
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  const [status1, setStatus1] = useState({
    success1: false,
    error1: false,
  });
  const [message1, setMessage1] = useState("");

  const handleChange = (otp) => setOtp(otp);
  let history = useHistory();

  const Save = () => {
    var raw = JSON.stringify({
      Email: email,
      Otp: otp,
    });
    fetch(MAIL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.Status === true) {
            setStatus({ ...status, success: true });
            setMessage(res.Message);
          } else {
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          }
        }
      });
  };
  const ResendOtp = () => {
    setOtp("");
    const dates = new Date().getMinutes();
    var raw = JSON.stringify({
      Email: email,
      Time: dates,
    });
    fetch(RESENDOTP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          // setStatus1({ ...status1, success1: true });
          // setMessage1(res.Message);
          localStorage.setItem("signupval", JSON.stringify(res.Otp));
          setOtpmes(`Registration Verification Otp Value:${res.Otp}`);
          setOtpOpen(true);
        } else {
          setStatus1({ ...status1, error1: true });
          setMessage1(res.Message);
        }
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <div className={classes.RootImage}></div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div style={{ textAlign: "left" }}>
            <h1
              style={{ marginBottom: 0, color: "white", textAlign: "center" }}
            >
              {t("email_verification")}
            </h1>
            {/* <h1 style={{ marginBottom: 0, marginTop: 0 }}>Verification</h1> */}
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel} name="Otp">
                  {t("enter_otp")}
                </label>
                <div>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={
                      <span className={classes.span_set}>
                        &nbsp;&nbsp;&nbsp;
                      </span>
                    }
                    className={classes.OtpInput}
                  />
                </div>

                <div className={classes.pdiv}>
                  <p
                    style={{
                      fontSize: 12,
                      textAlign: "right",
                      fontWeight: 500,
                      marginBottom: "0px",
                    }}
                  >
                    {t("didnt_receive_otp")}
                  </p>
                  <Button
                    style={{
                      fontSize: 12,
                      backgroundColor: ThemeColor,
                      color: "white",
                      marginLeft: "4px",
                      marginTop: "8px",
                    }}
                    onClick={() => {
                      ResendOtp();
                    }}
                  >
                    {t("resend")}
                  </Button>
                </div>
              </div>
              <Button className={classes.SubmitBtn} onClick={Save}>
                {t("submit")}
              </Button>
            </Paper>
            {/* <img
              src={LoginImage}
              width="480"
              height="345"
              style={{ marginLeft: "8rem" }}
              className={classes.EmailImage}
            /> */}
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push("/userLogin");
          }}
        />
        <SucessModal
          open={otpopen}
          message={otpmes}
          close={() => {
            setOtpOpen(false);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
        <Modal2
          open={status1.success1}
          title={"Email For Otp"}
          message={message1}
          close={() => {
            setStatus1({ ...status1, success1: false });
          }}
        />
        <Modal2
          open={status1.error1}
          title={"Email For Otp"}
          message={message1}
          close={() => {
            setStatus1({ ...status1, error1: false });
          }}
        />
      </Container>
    </div>
  );
};
export default Email;
