import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { GETSERVICESADMIN } from "../../RouteS/ApiRoutes/Ticket";
import useStyles from "../../Page/ProtectedPage/SuperAdmin/Customer/style";
import { GETINSTALLATIONADMIN } from "../../RouteS/ApiRoutes/Installation";
import { SERVICECALENDAR } from "../../RouteS/ApiRoutes/Calendar";
const localizer = momentLocalizer(moment);
const token = JSON.parse(localStorage.getItem("token"));
const InstallationCalendar = () => {
  const [task, setTask] = useState([]);
  const [Show, setShow] = useState(false);
  useEffect(() => {
    fetch(SERVICECALENDAR, {
      method: "get",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setTask(res.data));
  });

  return (
    <div style={{ marginRight: "2rem", marginLeft: "25px" }}>
      <div
        style={{
          textAlign: "left",
          marginTop: "0rem",
          marginBottom: "2rem",
          fontSize: "22px",
          fontWeight: 600,
        }}
      >
        <span style={{ textAlign: "left", marginTop: -5 }}>
          Service Assign Calendar
        </span>
      </div>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={task}
        style={{ height: "90vh" }}
        showAllEvents={true}
      />
    </div>
  );
};

export default InstallationCalendar;

//.substr(0,10)
