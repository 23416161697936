import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import img1 from "../Frontend/image1.png";
import reg1 from "../Frontend/reg1.png";
import reg2 from "../Frontend/reg2.png";
import reg3 from "../Frontend/reg3.png";
import lay1 from "../Frontend/lay1.png";
import lay2 from "../Frontend/lay2.png";
import lay3 from "../Frontend/lay3.png";
import lay4 from "../Frontend/lay4.png";
import Footer1 from "../Frontend/Footer1";
import { makeStyles } from "@material-ui/core/styles";
import "./Page1.css";
import Header from "../Component/Header";
import { LOGIN, SIGNUP } from "../RouteS/BrowserRoute/User";
import MainImage from "../Assets/Images/MainLogo.jpg";
import Service from "../Assets/Images/service.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ThemeColor } from "../Assets/StyleSheets/Theme";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Trans, useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  Main_Div: {
    width: "100%",
    height: "100vh",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
  },
  Container: {
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    marginTop: "140px",
    position: "absolute",
    // height: "400px",
    // bottom: 0,
    // marginBottom: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      height: "475px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      height: "425px",
      // marginBottom: "80px",
    },
  },
  Text_div: {
    width: "100%",
    color: "white",
    padding: "67px 111px",
    borderRadius: "30px",
    backdropFilter: "blur(29px)",
    background:
      "linear-gradient(261.7deg, rgba(0, 0, 0, 0.49) -0.62%, rgba(0, 0, 0, 0.2107) 101.06%)",
    height: "400px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "20px",
      height: "350px",
      width: "90%",
      margin: "0px auto",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "100%",
      padding: "35px 57px",
    },
  },
  h1tag: {
    fontSize: "32px",
    fontWeight: 600,
    color: "white",
    lineHeight: "48px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  ptag: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
    textAlign: lang == "ar" ? "right" : "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1,
      // padding: "0 18px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  buttonDiv: {
    borderRadius: "20px",
    border: `1px solid ${ThemeColor}`,
    width: "150px",
    background: "white",
    color: "black",
    cursor: "pointer",
    height: "40px",
    "&:hover": {
      color: "white",
      background: ThemeColor,
      border: `1px solid white`,
    },
  },
  serviceimg: {
    height: "275px",
    width: "500px",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "170px",
    },
  },
  div_button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: "10px",
    },
  },
  button_css: {
    color: "white !important",
    backdropFilter: "blur(29px)",
    background:
      "linear-gradient(261.7deg, rgba(0, 0, 0, 0.49) -0.62%, rgba(0, 0, 0, 0.2107) 101.06%)",
    fontWeight: 400 + "!important",
    fontSize: "29px !important",
    cursor: "pointer !important",
    borderRadius: "10px !important",
    minWidth: "200px !important",
    padding: "35px 65px !important",
    // border: `2px solid ${ThemeColor} !important`,
    [theme.breakpoints.between("sm", "md")]: {
      minWidth: "180px !important",
      fontSize: "22px !important",
      padding: "15px 32px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontWeight: 300 + "!important",
      fontSize: "16px !important",
      marginBottom: "10px !important",
      padding: "15px 32px !important",
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  // const [open, setOpen] = useState(false);
  // var settings = {
  //   autoplay: true,
  //   dots: false,
  //   infinite: true,
  //   vertical: true,
  //   speed: 500,
  //   autoplaySpeed: 3000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  return (
    <>
      <div>
        <Header />
        <div className={classes.Root}></div>
        <div className={classes.Main_Div}>
          <Container className={classes.Container}>
            {/* <Slider {...settings}> */}
            <div className={classes.Text_div}>
              <h1 className={classes.h1tag}>{t("what_is_weqayah")}</h1>
              <p className={classes.ptag}>{t("firstpara_landingpage")}</p>
              <p className={classes.ptag}>{t("secondpara_landingpage")}</p>
              {/* <br/> */}
            </div>
            <div className={classes.div_button}>
              <Button
                className={classes.button_css}
                onClick={() => {
                  history.push("/program");
                }}
              >
                {t("theprogram")}
              </Button>
              <Button
                className={classes.button_css}
                onClick={() => {
                  history.push("/registrationfees");
                }}
              >
                {t("registartion_fees")}
              </Button>
              <Button
                className={classes.button_css}
                onClick={() => {
                  history.push("/requirements");
                }}
              >
                {t("requirements")}
              </Button>
            </div>

            {/* <div className={classes.Text_div}>
                <h1 className={classes.h1tag}>WEQAYAH 24/7</h1>
                <p className={classes.ptag}>
                  The system transmits all events in the control panel through
                  M2M wireless technology to the control center for rapid
                  response in the event of any kind of fire.
                </p>
                <p className={classes.ptag}>
                  The system allows employees of the Central Monitoring Station
                  (CMS) to check whether the alarm is true or false in less than
                  90 seconds.
                </p>
                <p className={classes.ptag}>
                  Then, the civil defense teams are informed of the warning
                  after verifying its validity within few seconds, and the civil
                  defense is provided with information and coordinates of the
                  site, and accordingly, the response to these incidents becomes
                  faster and more effective.
                </p>
                <button
                  className={classes.buttonDiv}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Services
                </button>
              </div> */}

            {/* <div className={classes.Text_div}>
                <h1 className={classes.h1tag}>WHAT IS REQUIRED TO REGISTER</h1>
                <p className={classes.ptag} style={{ textAlign: "left" }}>
                  The owner of the building or his representative is required to
                  complete the following:
                </p>
                <br />
                <p className={classes.ptag} style={{ textAlign: "left" }}>
                  1. Register in WEQAYAH system and pay the fees <br />
                  2. Schedule an appointment to visit the facility by  WEQAYAH
                  technical team
                  <br />
                  3. Submit a valid maintenance contract with a company 
                  licensed by Ajman Civil Defence, for the Fire Alarm System{" "}
                  <br />
                  4. Ensure the availability of Fire Alarm maintenance team 
                  when WEQAYAH technical teams visit the facility
                </p>
              </div> */}

            {/* <div className={classes.Text_div}>
                <h1 className={classes.h1tag}>REGISTRATION FEES</h1>
                <p className={classes.ptag} style={{ textAlign: "left" }}>
                  The fees for WEQAYAH System is AED 4,200. The system includes:
                </p>
                <br />
                <p className={classes.ptag} style={{ textAlign: "left" }}>
                  1. Supply, installation, testing and commissioning of the
                  alarm transmission equipment (ATE)
                  <br />
                  2. One year of free maintenance
                  <br />
                  3. Two free Assessments to determine if the property  is ready
                  to be connected to WEQAYAH Central Monitoring Station
                  <br />
                  4. Monitoring the building (24/7)
                  <br />
                </p>
                <br />
                <p className={classes.ptag} style={{ textAlign: "left" }}>
                  Notes: <br />• The fees of maintenance will be AED 1,575
                  starting the second year onwards
                  <br />• Above mentioned prices include VAT
                </p>
              </div> */}
            {/* </Slider> */}
          </Container>
        </div>
      </div>
      {/* <div>
        <Container className={classes.Container}>
          <Grid container className="registration">
            <Grid lg={4} xs={12} className="registrationgrid">
              <Box
                className="registration_card"
                onClick={() => history.push(SIGNUP)}
              >
                <div className="registrationimage">
                  <img src={reg1} alt="New Registration" />
                </div>
                <div className="registrationBox">New Registration</div>
              </Box>
            </Grid>
            <Grid lg={4} xs={12} className="registrationgrid">
              <Box className="registration_card">
                <div className="registrationimage">
                  <img src={reg2} alt="My Account" />
                </div>
                <div className="registrationBox"> My Account</div>
              </Box>
            </Grid>
            <Grid lg={4} xs={12} className="registrationgrid">
              <Box className="registration_card">
                <div className="registrationimage">
                  <img src={reg3} alt="Contact Us" />
                </div>
                <div className="registrationBox">Contact Us</div>
              </Box>
            </Grid>
          </Grid>

          <Grid container className="features_list">
            <Grid lg={6} xs={12}>
              <div className="Features">
                <h1>Alarm transmission equipment (ATE)</h1>
                <p>
                  The Ajman Government is forming a smart city initiative
                  protecting lives and properties by relaying critical Fire
                  events to the Alarm Receiving center (ARC) via an Alarm
                  transmission equipment (ATE) which is installed in each
                  building. In case of a fire or life threatening event, the ATE
                  will immediately send the Alarm to the ARC. The ARC operators
                  will verify the received alarm. Upon verification, the alarm
                  is automatically reported to the emergency services within
                  seconds providing them with situational intelligence and
                  making their response more effective to keep our cities
                  smarter and safer
                </p>
              </div>
            </Grid>
            <Grid lg={6} xs={12} xl={6} md={6} container>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay1} alt="24*7 Service" />
                  </div>
                  <div> 24/7 Service</div>
                </Box>
              </Grid>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay2} alt="Premium Services" />
                  </div>
                  <div className="feature_title">Premium Services</div>
                </Box>
              </Grid>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay3} alt="Super Fast Service" />
                  </div>
                  <div className="feature_title"> Super Fast Service</div>
                </Box>
              </Grid>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay4} alt="Best Engineers" />
                  </div>
                  <div className="feature_title">Best Engineers</div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Footer1 />
      </div> */}
      {/* {open ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Service Image</DialogTitle>
          <DialogContent>
            <img src={Service} className={classes.serviceimg} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )} */}
    </>
  );
};
export default Main;
