import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Link } from "react-router-dom";
import { LOGIN } from "../../../../RouteS/ApiRoutes/Login-SignUp";
import { useHistory } from "react-router";
import { DASHBOARDCONTROLLER } from "../../../../RouteS/BrowserRoute/ControllerRoute";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import { UPDATEADMINS, GETONEADMINS } from "../../../../RouteS/ApiRoutes/admin";

var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "100%",
      height: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "2rem",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "2rem",
  },
  LoginBtn: {
    width: "100%",
    background: ThemeColor,
    color: "white",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      width: "100%",
    },
  },
  LoginBtn2: {
    width: "100%",
    background: ThemeColor,
    color: "white",
    marginTop: "1rem",
    marginBottom: "1rem",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      width: "100%",
    },
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  loginImage: {
    [theme.breakpoints.between("sm", "md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  link: {
    fontSize: 12,
    color: ThemeColor,
    marginLeft: lang == "ar" ? "45px" : "4px",
  },
}));

const AddAdmin = () => {
  const classes = useStyles();
  const [field, setField] = useState({
    UserName: "",
    Email: "",
  });
  const [field1, setField1] = useState({
    Password: "",
  });
  const history = useHistory();
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("false");
  const [a, setA] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { t } = useTranslation();

  useEffect(() => {
    GetData();
  }, []);

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const GetData = () => {
    const c = { Id: Id };
    fetch(GETONEADMINS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(c),
    })
      .then((res) => res.json())
      .then((responseJson) => {
        setField({
          ...field,
          UserName: responseJson.Data[0].UserName,
          Email: responseJson.Data[0].Email,
        });
        setField1({ ...field1, Password: responseJson.Data[0].Password });
      });
  };

  const OnChageField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };

  const OnChagePass = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField1({ ...field1, Password: value });
      setA("");
    } else {
      setField1({ ...field1, Password: value });
      setA("At Least 6 Characters Password");
    }
  };

  const Saved = () => {
    var Data = JSON.stringify({
      Id: Id,
      UserName: field.UserName,
      Email: field.Email,
      Password: field1.Password,
    });

    fetch(UPDATEADMINS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          setStatus({ ...status, success: true });
          setMessage(res.Message);
        } else {
          setStatus({ ...status, error: true });
          setMessage(res.Message);
        }
      });
  };

  return (
    <Container maxWidth="xl" className={classes.MainContainer}>
      <div>
        <div className={classes.root}>
          <Paper elevation={3}>
            <div className={classes.FieldDiv}>
              <label className={classes.textLabel}>Admin Name</label>
              <TextField
                variant="outlined"
                size="small"
                color="secondary"
                name="UserName"
                placeholder="Enter Admin Name"
                onChange={OnChageField}
                value={field.UserName}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className={classes.FieldDiv}>
              <label className={classes.textLabel}>Admin Email</label>
              <TextField
                variant="outlined"
                size="small"
                color="secondary"
                name="Email"
                placeholder="Enter Email Address"
                value={field.Email}
                onChange={OnChageField}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className={classes.FieldDiv}>
              <label className={classes.textLabel}>{t("user_password")}</label>
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                name="Password"
                placeholder="Enter Password"
                onChange={OnChagePass}
                value={field1.Password}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <span style={{ color: "red", fontSize: "15px", textAlign: "left" }}>
              {a}
            </span>
            <div className={classes.FieldDiv}>
              <Button className={classes.LoginBtn} onClick={Saved}>
                Save
              </Button>
              <Button
                className={classes.LoginBtn2}
                onClick={() => {
                  history.push("/newadmin");
                }}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </div>
      </div>
      <SucessModal
        open={status.success}
        message={message}
        close={() => {
          return history.push("/newadmin");
        }}
      />
      <ErrorModal
        open={status.error}
        message={message}
        close={() => {
          setStatus({ ...status, error: false });
        }}
      />
    </Container>
  );
};
export default AddAdmin;
