import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import img1 from "../Frontend/image1.png";
import reg1 from "../Frontend/reg1.png";
import reg2 from "../Frontend/reg2.png";
import reg3 from "../Frontend/reg3.png";
import lay1 from "../Frontend/lay1.png";
import lay2 from "../Frontend/lay2.png";
import lay3 from "../Frontend/lay3.png";
import lay4 from "../Frontend/lay4.png";
import Footer1 from "../Frontend/Footer1";
import { makeStyles } from "@material-ui/core/styles";
import "./Page1.css";
import Header from "../Component/Header";
import { LOGIN, SIGNUP } from "../RouteS/BrowserRoute/User";
import MainImage from "../Assets/Images/MainLogo.jpg";
// import { useTheme } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";

const Token = localStorage.getItem("token");
const useStyles = makeStyles((theme) => ({
  videoheight: {
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "400px",
    },
  },
  ptag: {
    textAlign: "center",
    fontSize: "25px",
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
      fontSize: "16px",
      paddingLeft: "18px",
    },
  },
  main_div: {
    height: "100%",
    display: "flex",
    fontSize: "35px",
    fontWeight: 600,
    lineHeight: 1,
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    top: 0,
    right: 20,
    position: "absolute",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      color: "white",
      paddingTop: "100px",
    },
  },
  h1tag: {
    textAlign: "center",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      paddingLeft: "18px",
    },
  },
  imageclass: {
    width: "100%",
    backgroundImage: `url(${MainImage})`,
    height: "100vh",
    position: "absolute",
    backgroundSize: "cover",
  },
}));

export default function Page1() {
  const history = useHistory();
  const classes = useStyles();
  // const theme = useTheme();
  // const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <div>
        <Header />
        <div>
          <div className={classes.imageclass}>
            {/* <img src={MainImage} width="100%" style={{ height: "100vh" }} /> */}
          </div>
        </div>
        <Container>
          <div className={classes.main_div}>
            <h1 className={classes.h1tag}>
              Federal level Fire Safety Implementation
            </h1>
            <p className={classes.ptag}>
              The Government of Ajman, has a primary role to respond to fire and
              life safety emergencies as well as ensuring that safety solutions
              are installed and maintained in all buildings used by both the
              public and private sectors.
            </p>
            <p className={classes.ptag}>
              In 2021 Ajman established a strategic partnerships with
              specialized organizations to help support them in monitoring and
              verifying fire and life safety alarms across all types of
              establishments in the UAE.
            </p>
            <p className={classes.ptag}>
              The Intelligent Command and Control solution, covering commercial
              buildings and tower blocks, was signed with Firex to monitor and
              detect fire and smoke alarms in commercial properties.
            </p>
          </div>
        </Container>
        {/* <Footer1 /> */}
      </div>
      <div>
        <Container>
          <Grid container className="registration">
            <Grid lg={4} xs={12} className="registration_card_one">
              <Box
                className="registration_card"
                onClick={() => history.push(SIGNUP)}
              >
                <div className="registrationimage">
                  <img src={reg1} alt="New Registration" />
                </div>
                <div className="registrationBox">New Registration</div>
              </Box>
            </Grid>
            <Grid lg={4} xs={12} className="registration_card_two">
              <Box className="registration_card">
                <div className="registrationimage">
                  <img src={reg2} alt="My Account" />
                </div>
                <div className="registrationBox"> My Account</div>
              </Box>
            </Grid>
            <Grid lg={4} xs={12} className="registration_card_three">
              <Box className="registration_card">
                <div className="registrationimage">
                  <img src={reg3} alt="Contact Us" />
                </div>
                <div className="registrationBox">Contact Us</div>
              </Box>
            </Grid>
          </Grid>

          <Grid container className="features_list">
            <Grid lg={6} xs={12}>
              <div className="Features">
                <h1>Alarm transmission equipment (ATE)</h1>
                <p>
                  The Ajman Government is forming a smart city initiative
                  protecting lives and properties by relaying critical Fire
                  events to the Alarm Receiving center (ARC) via an Alarm
                  transmission equipment (ATE) which is installed in each
                  building. In case of a fire or life threatening event, the ATE
                  will immediately send the Alarm to the ARC. The ARC operators
                  will verify the received alarm. Upon verification, the alarm
                  is automatically reported to the emergency services within
                  seconds providing them with situational intelligence and
                  making their response more effective to keep our cities
                  smarter and safer
                </p>
              </div>
            </Grid>
            <Grid lg={6} xs={12} xl={6} md={6} container>
              <Grid xl={6} xs={12} md={6}>
                <div className="features_box">
                  <div className="featuresimage">
                    <img src={lay1} alt="24*7 Service" />
                  </div>
                  <h4 className="feature_title"> 24/7 Service</h4>
                </div>
              </Grid>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay2} alt="Premium Services" />
                  </div>
                  <h4 className="feature_title">Premium Services</h4>
                </Box>
              </Grid>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay3} alt="Super Fast Service" />
                  </div>
                  <h4 className="feature_title"> Super Fast Service</h4>
                </Box>
              </Grid>
              <Grid xl={6} xs={12} md={6}>
                <Box className="features_box">
                  <div className="featuresimage">
                    <img src={lay4} alt="Best Engineers" />
                  </div>
                  <h4 className="feature_title">Best Engineers</h4>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
