import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LoginImage from "../../../../Assets/Images/login.png";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Link } from "react-router-dom";
import { ALOGIN } from "../../../../RouteS/ApiRoutes/Login-SignUp";
import { useHistory } from "react-router";
import { DASHBOARD } from "../../../../RouteS/BrowserRoute/User/index";
import { DASHBOARDCONTROLLER } from "../../../../RouteS/BrowserRoute/ControllerRoute";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import Header from "../../../../Component/Header/index";
import MainImage from "../../../../Assets/Images/MainLogo.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "600px",
      height: 300,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  RootImage: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "2.5rem",
    // paddingTop: "9.5rem",
    paddingTop: "11rem",
    // position: "relative",
    // height: "100%",
    // bottom: 0,
    position: "absolute",
    // marginBottom: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: "20.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "9.5rem",
    },
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "2rem",
  },
  LoginBtn: {
    width: "85%",
    background: ThemeColor,
    marginTop: "2rem",
    color: "white",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.down("xs")]: {
      width: "77%",
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  image_div: {
    [theme.breakpoints.between("sm", "md")]: {
      display: "none",
    },
  },
}));
const EngineerLogin = () => {
  const classes = useStyles();
  const [field, setField] = useState({
    Name: "",
  });
  const [field1, setField1] = useState({
    Password: "",
  });
  const [Error, setError] = useState({
    open: false,
    Msg: "",
  });
  const history = useHistory();
  const OnChageField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");
  const [a, setA] = useState("");

  const OnChagePass = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField1({ ...field, [e.target.name]: value });
      setA("");
    } else {
      setA("At Least 6 Characters Password");
    }
  };

  const SignIn = () => {
    localStorage.setItem("MyToken", "1");

    var Data = JSON.stringify({
      Name: field.Name,
      Password: field1.Password,
    });

    fetch(ALOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.Status === true) {
            localStorage.setItem("Role", JSON.stringify("Admin"));
            localStorage.setItem("UserName", JSON.stringify(res.data.UserName));
            localStorage.setItem("token", JSON.stringify(res.token));
            localStorage.setItem("Email", JSON.stringify(res.data.Email));
            localStorage.setItem("auth", JSON.stringify("rea"));
            window.location.href = DASHBOARDCONTROLLER;
            // setStatus({ ...status, success: true });
            // setMessage(res.Message);
          } else {
            setStatus({ ...status, error: true });
            setMessage("Username or Password invalid");
          }
        }
      });
  };

  // <span style={{ color: ThemeColor }}>ARG</span>
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <div className={classes.RootImage}></div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div style={{ textAlign: "left" }}>
            <h1 style={{ textAlign: "center", color: "white" }}>Admin Login</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel}>UserName</label>
                <TextField
                  variant="outlined"
                  size="small"
                  color="secondary"
                  name="Name"
                  placeholder="Enter your User Name"
                  onChange={OnChageField}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel}>Password</label>
                <TextField
                  type="Password"
                  variant="outlined"
                  size="small"
                  name="Password"
                  placeholder="Enter your password"
                  onChange={OnChagePass}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />{" "}
                <span
                  style={{ color: "red", fontSize: "15px", textAlign: "left" }}
                >
                  {a}
                </span>
              </div>
              <Button className={classes.LoginBtn} onClick={SignIn}>
                Login
              </Button>
            </Paper>
            {/* <img
              src={LoginImage}
              width="530"
              height="374"
              style={{ marginLeft: "5rem" }}
              className={classes.image_div}
            /> */}
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return (window.location.href = DASHBOARDCONTROLLER);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </div>
  );
};
export default EngineerLogin;
