import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Component/Header/index";
import Container from "@mui/material/Container";
import MainImage from "../Assets/Images/MainLogo.jpg";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  Main_Div: {
    width: "100%",
    height: "100vh",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
  },
  MainContainer: {
    // display: "flex",
    paddingTop: "8rem",
    // position: "absolute",
    // marginTop: "280px",
    // bottom: 0,
    // position: "fixed",
    // marginBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5rem",
      marginTop: "130px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100% !important",
    },
  },
  imageclass: {
    width: "100%",
    backgroundImage: `url(${MainImage})`,
    height: "100vh",
    position: "fixed",
    backgroundSize: "cover",
    marginTop: "40px",
  },
  firstgrid: {
    background:
      "linear-gradient(261.7deg, rgba(0, 0, 0, 0.49) -0.62%, rgba(0, 0, 0, 0.2107) 101.06%)",
    backdropFilter: "blur(29px)",
    marginLeft: "180px",
    marginRight: "180px",
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    borderRadius: "10px",
    paddingRight: "20px",
    [theme.breakpoints.between("sm", "md")]: {
      margin: "0px 60px !important",
    },
    [theme.breakpoints.down("xs")]: {
      // margin: "0px 30px !important",
      margin: "0px !important",
    },
  },
  // secgrid: {
  //   background: "#ffffffeb",
  //   marginRight: "180px",
  //   marginLeft: "50px",
  //   paddingTop: "10px",
  //   paddingBottom: "20px",
  //   paddingLeft: "20px",
  //   paddingRight: "20px",
  //   borderRadius: "10px",
  //   height: "100%",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   flexDirection: "column",
  //   [theme.breakpoints.between("sm", "md")]: {
  //     margin: "0px 60px !important",
  //     marginTop: "30px !important",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     margin: "0px 30px !important",
  //     marginTop: "15px !important",
  //   },
  // },
  first_div: {
    textAlign: lang == "ar" ? "right" : "left",
    display: "flex",
    flexDirection: "column",
    color: "white",
  },
  second_div: {
    textAlign: lang == "ar" ? "right" : "left",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    color: "white",
  },
  third_div: {
    textAlign: lang == "ar" ? "right" : "left",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    color: "white",
  },
  atag: {
    color: "white",
    textDecoration: "underline",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: 1.3,
    wordBreak: "break-all",
  },
  h1tag: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  spantag: {
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: 1.3,
  },
  mapsize: {
    border: "0px",
    height: "300px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
    },
  },
  centraldiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className={classes.imageclass}></div>
      <div className={classes.Main_Div}>
        <Container maxWidth="xl" className={classes.MainContainer}>
          {/* <div className={classes.centraldiv}> */}
          <div className={classes.firstgrid}>
            <h1 className={classes.h1tag}>{t("conatct_title")}</h1>
            <div className={classes.first_div}>
              <span className={classes.spantag}>{t("contact_para1")}</span>
              <span className={classes.spantag}>600 566 566</span>
            </div>
            <div className={classes.second_div}>
              <span className={classes.spantag}>{t("contact_para2")}</span>
              <a href="mailto:info@weqayah.ae" className={classes.atag}>
                info@weqayah.ae
              </a>
            </div>
            <div className={classes.third_div}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d901.0357012088383!2d55.481687529189124!3d25.400029998987655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f59810b656b85%3A0x8742da12482fa85e!2zV2VxYXlhaCBBam1hbiAtINmI2YLYp9mK2Kkg2LnYrNmF2KfZhg!5e0!3m2!1sen!2sae!4v1651728496989!5m2!1sen!2sae"
                className={classes.mapsize}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          {/* </div> */}
        </Container>
      </div>
    </>
  );
};
export default ContactUs;
