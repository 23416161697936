import { Domain } from "../ApiDomain/index";
export const GETNOTIFICATION = `${Domain}/getListNotification`;
export const GETCUSTOMERNOTIFICATION = `${Domain}/getCustomerNotification`;
export const GETENGINEERNOTIFICATION = `${Domain}/getEngineerNotification`;

export const GETADMINSEENNOTIFICATION = `${Domain}/getAdminSeenNotification`;
export const GETENGINEERSEENNOTIFICATION = `${Domain}/getEngineerSeenNotification`;
export const GETCUSTOMERSEENNOTIFICATION = `${Domain}/getCustomerSeenNotification`;

export const UPDATEADMINNOTIFICATION = `${Domain}/updateAdminNotification`;
export const UPDATECUSTOMERNOTIFICATION = `${Domain}/updateCustomerNotification`;
export const UPDATEENGINEERNOTIFICATION = `${Domain}/updateEngineerNotification`;