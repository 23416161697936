import { Domain } from "../ApiDomain/index";

export const ADDORDER = `${Domain}/addMyOrder`;
export const UPDATEORDER = `${Domain}/updateMyOrder`;
export const UPDATEINSERTORDER = `${Domain}/updateinsertMyOrder`;
export const CHECKPREMISES = `${Domain}/checkPremises`;
export const DELETEORDER = `${Domain}/deleteMyOrder`;
export const GETREFORDER = `${Domain}/getreforder`;
export const UPDATEREFORDER = `${Domain}/updatereforder`;
export const VIEWORDER = `${Domain}/getUserOrder`;
export const VIEWONEORDER = `${Domain}/getOneOrder`;
export const GETALERNATIVE = `${Domain}/getAlternativContact`;
