import React, { useState, useEffect } from "react";
import useStyle from "./style";
import { useHistory } from "react-router";
import { Button, IconButton } from "@material-ui/core";
import MyTable from "../../../../Component/Table/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { GETENGINEERSURVEY } from "../../../../RouteS/ApiRoutes/Survey";
import {
  ENGINEERDETAILSURVEY,
  ENGINEEREDITSURVEY,
} from "../../../../RouteS/BrowserRoute/Engineer";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

function EngineerViewSurvey() {
  const token = JSON.parse(localStorage.getItem("token"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const classes = useStyle();
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [size1, setSize1] = useState(5);

  useEffect(() => {
    View();
  }, []);

  const View = () => {
    var raw = JSON.stringify({
      Id: EngineerId,
    });
    fetch(GETENGINEERSURVEY, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Id",
      accessor: "Id",
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "Ready To Install",
      accessor: "installed",
      Cell: (data) => {
        switch (data.original.installed) {
          case "No":
            return (
              <div
                style={{
                  color: "Red",
                }}
              >
                {data.original.installed}
              </div>
            );
          case "Yes":
            return (
              <div
                style={{
                  color: "green",
                }}
              >
                {data.original.installed}
              </div>
            );
        }
      },
    },
    {
      Header: "Ates No",
      accessor: "atessr",
    },
    {
      Header: "Owner Name",
      accessor: "OwnerName",
    },
    {
      Header: "View",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <IconButton
            style={{ padding: 0 }}
            onClick={() =>
              history.push(ENGINEERDETAILSURVEY + "/" + data.original.Id)
            }
          >
            <RemoveRedEyeIcon style={{ fontSize: 20, color: "#3AAFA9" }} />
          </IconButton>
        );
      },
    },
    {
      Header: "Edit",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              if (data.original.installed === "No") {
                history.push(ENGINEEREDITSURVEY + "/" + data.original.Id);
              }
            }}
          >
            <EditIcon style={{ fontSize: 20, color: "#000000" }} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Fire Survey Report</h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
}
export default EngineerViewSurvey;
