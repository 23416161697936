export const ATEREPORTS = "/atesreport";
export const ATEREPORTSVIEW = "/atesreportview/:id";

export const ADDDAMAGEATES = "/AddDamageATEs";

export const SERVICES = "/services";
export const TASK = "/task";
export const ENGINEERLOGIN = "/engineer";
export const FIRESURVEYREPORT = "/firesurveyreport";
export const VIEWSURVEY = "/ViewSurvey";

export const ENGINEERDAMAGE = "/damageengineer";

export const ENGINEERDAMAGEHISTORY = "/damageengineerhistory";

export const ENGINEERNOTIFICATION = "/engineernotification";

export const ENGINEERVIEWSURVEY = "/EngineerViewSurvey";
export const ENGINEERDETAILSURVEY = "/EngineerDetailsurvey";
export const ENGINEEREDITSURVEY = "/EngineerEditsurvey";
