import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { VIEWTICKET } from "../../../../RouteS/BrowserRoute/User";
import { ADDTICKET, VIEWTIME } from "../../../../RouteS/ApiRoutes/Ticket";
import { GETISSUE } from "../../../../RouteS/ApiRoutes/Issue";
import { LISTPREMISES } from "../../../../RouteS/ApiRoutes/Premises";
import SucessModal from "../../../../Component/Modal/SucessModal";
import { GETTIME, VIEWALLTIME } from "../../../../RouteS/ApiRoutes/Time";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { GETUSERATES } from "../../../../RouteS/ApiRoutes/Customer";
import { useTranslation } from "react-i18next";

var lang = JSON.parse(localStorage.getItem("lang"));
const token = JSON.parse(localStorage.getItem("token"));
const Id = JSON.parse(localStorage.getItem("UserId"));
const fname = JSON.parse(localStorage.getItem("FirstName"));
const lname = JSON.parse(localStorage.getItem("LastName"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      padding: 25,
      borderRadius: 20,
      boxShadow: "none",
      border: "1px solid " + ThemeColor + "",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "12px",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "25px",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "25px",
    },
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: 204,
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: "1rem",
      marginLeft: "1rem",
    },
    "&>.css-2b097c-container": {
      textAlign: "left",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  SaveBtn: {
    width: "35%",
    background: ThemeColor,
    marginTop: "1rem",
    color: "white",
    height: "7%",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "35% !important",
    },
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  MainLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginLeft: "3rem",
  },
}));

const AddTicket = () => {
  const classes = useStyles();
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [Databui, setDataBui] = useState([]);
  const [Dataser, setDataSer] = useState([]);
  const [Open, setOpen] = useState(false);
  const [Msg, setMsg] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { t } = useTranslation();

  const [Floor, setFloor] = useState([
    {
      id: "1",
      name: "1st",
    },
    {
      id: "2",
      name: "2nd",
    },
  ]);
  const [issue, setIssue] = useState([]);
  const [Time, setTime] = useState([]);
  const [field, setField] = useState({
    Id: "",
    BusinessName: "",
    Floor: "",
    SerialNo: "",
    Issue: "",
    time: "",
  });
  useEffect(() => {
    GetIssue();
    print1();
    print();
  }, []);

  const UserId = JSON.parse(localStorage.getItem("UserId"));
  const print1 = () => {
    var raw = JSON.stringify({
      UserId: UserId,
    });
    fetch(GETUSERATES, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
        const resData = responseJson.Data.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.BusinessName === value.BusinessName)
        );
        setDataBui(resData);
      });
  };

  const GetBulidinList = () => {
    const raw = JSON.stringify({ UserId: Id });
    fetch(LISTPREMISES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => setData(res.Data));
  };

  const onChangeBusiness = (e) => {
    setField({ ...field, BusinessName: e.label, Id: e.value });
    const resData = Data.filter(
      (item) => item.BusinessName.indexOf(e.label) !== -1
    );
    setDataSer(resData);
  };

  const onChangeSerialNo = (e) => {
    if (field.BusinessName !== "") {
      setField({ ...field, SerialNo: e.label });
    }
  };
  const onChangeIssue = (e) => {
    if (field.BusinessName !== "" && field.SerialNo !== "") {
      setField({ ...field, Issue: e.label });
    }
  };
  const GetIssue = () => {
    fetch(GETISSUE, {
      method: "GET",

      headers: {
        // Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setIssue(res.data));
  };

  const print = () => {
    fetch(VIEWALLTIME, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setTime(responseJson.data);
      });
  };

  const options = [];
  for (var i = 0; i < Time.length; i++) {
    options.push({ value: Time[i].Time, label: Time[i].Time });
  }
  const Save = () => {
    if (field.Id !== "" && field.SerialNo !== "" && field.Issue !== "") {
      const raw = JSON.stringify({
        Id: field.Id,
        SerialNo: field.SerialNo,
        Issue: field.Issue,
        StartDate: "",
        EndDate: "",
        Time: "",
        AssignTo: "",
        Status: "pending",
        UserId: Id,
      });
      console.log(raw);
      fetch(ADDTICKET, {
        method: "POST",
        headers: {
          // Authorization: "Bearer " + token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: raw,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            setOpen(true);
            setMsg("Sucessfull Add Ticket");
          }
        });
    }
  };

  const [date, setDate] = useState("");
  const onChangeField = (e) => {
    setDate(e.target.value);
    const Data = JSON.stringify({ Date: e.target.value });
    fetch(GETTIME, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setTime(responseJson.Data);
      });
  };

  return (
    <div maxwidth="xl" className={classes.MainContainer}>
      <div>
        <div
          style={{
            textAlign: lang == "ar" ? "right" : "left",
            marginTop: "-2rem",
          }}
        >
          <h1>{t("add_new_ticket")}</h1>
        </div>
        <div className={classes.root}>
          <Paper elevation={3}>
            <div>
              <div className={classes.mainDiv}>
                <div className={classes.FieldDiv} style={{ textAlign: "left" }}>
                  <label className={classes.textLabel}>
                    {t("bussines_name")}
                  </label>
                  <Select
                    onChange={onChangeBusiness}
                    options={Databui.map((st) => ({
                      value: st.OrderId,
                      label: st.BusinessName,
                    }))}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>
                    {t("enter_ates_serial_no")}
                  </label>
                  <Select
                    onChange={onChangeSerialNo}
                    options={
                      field.BusinessName != ""
                        ? Dataser.map((st) => ({
                            value: st.Id,
                            label: st.SerialNo,
                            disabled: false,
                          }))
                        : Data.map((st) => ({
                            value: st.Id,
                            label: st.SerialNo,
                            disabled: true,
                          }))
                    }
                    isOptionDisabled={(option) => option.disabled}
                  />
                </div>
                <div className={classes.FieldDiv} style={{ textAlign: "left" }}>
                  <label className={classes.textLabel}>
                    {" "}
                    {t("select_issue")}
                  </label>
                  <Select
                    onChange={onChangeIssue}
                    options={
                      field.BusinessName != "" && field.SerialNo != ""
                        ? issue.map((st) => ({
                            value: st.Id,
                            label: st.IsssueName,
                            disabled: false,
                          }))
                        : issue.map((st) => ({
                            value: st.Id,
                            label: st.IsssueName,
                            disabled: true,
                          }))
                    }
                    isOptionDisabled={(option) => option.disabled}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.SaveBtn}
                onClick={Save}
                style={{
                  width: "11%",
                  marginRight: lang == "ar" ? "0rem" : "3rem",
                  marginLeft: lang == "ar" ? "3rem" : "0rem",
                }}
              >
                {t("save")}
              </Button>
              <Button
                className={classes.SaveBtn}
                onClick={history.goBack}
                style={{ width: "11%" }}
              >
                {t("cancel")}
              </Button>
            </div>
          </Paper>
        </div>
      </div>
      <SucessModal
        open={Open}
        message={Msg}
        close={() => {
          history.push(VIEWTICKET);
        }}
      />
    </div>
  );
};
export default AddTicket;
