import React, { useState, useEffect } from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useHistory } from "react-router-dom";
import HomeSvg from "../../Assets/SVG/Home";
import Home from "../../Assets/Mysvg/home.svg";
import "./sidebar.css";
import BuildingSvg from "../../Assets/SVG/Building";
import Ticket from "../../Assets/SVG/Ticket";
import MyOrder from "../../Assets/SVG/Order";
import LogoutSvg from "../../Assets/SVG/Logout";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HistoryIcon from "@mui/icons-material/History";
import WarningIcon from "@mui/icons-material/Warning";

import {
  VIEWPREMISES,
  VIEWORDER,
  VIEWTICKET,
  USERVIEWSURVEY,
  CUSTOMERNOTIFICATION,
} from "../../RouteS/BrowserRoute/User";
import {
  RoleEngineer,
  RoleUser,
  SuperAdmin,
} from "../../ConstVariable/RoleVariable";
import ConfirmationDilog from "../../Component/Modal/ConfirmationModal";
import {
  ENGINEERDAMAGE,
  SERVICES,
  TASK,
  ENGINEERDAMAGEHISTORY,
  ENGINEERNOTIFICATION,
  ENGINEERVIEWSURVEY,
} from "../../RouteS/BrowserRoute/Engineer";
import { DASHBOARDCONTROLLER } from "../../RouteS/BrowserRoute/ControllerRoute";
import PeopleIcon from "@material-ui/icons/People";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import EventIcon from "@material-ui/icons/Event";
import WidgetsIcon from "@material-ui/icons/Widgets";
import {
  CUSTOMERREQUEST,
  DAMAGEREQUEST,
  MYCUSTOMER,
  DAMAGEHISTORY,
  TIMESLOTS,
  VIEWPRICE,
  VIEWSTAFF,
  NOTIFICATION,
  ADMINVIEWSURVEY,
  ADMIN
} from "../../RouteS/BrowserRoute/Admin";
import { ls } from "../../SecureLs/Ls";
import { useTranslation } from "react-i18next";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const [open, setOPen] = useState(true);
  const [landata, setLandata] = useState("");
  const [colorS, setcolorS] = useState();
  const [Open2, setOpen2] = React.useState({
    admin: false,
    user: false,
    engineer: false,
  });
  const role = JSON.parse(localStorage.getItem("Role"));
  const EIDno = JSON.parse(localStorage.getItem("EIDno"));
  const UserName = JSON.parse(localStorage.getItem("UserName"));
  const Email = JSON.parse(localStorage.getItem("Email"));
  const Langu = JSON.parse(localStorage.getItem("lang"));
  const history = useHistory();
  const openPage = (URL) => {
    history.push(URL);
  };
  useEffect(() => {
    setLandata(Langu);
  }, []);

  const Logout = () => {
    localStorage.clear();
    window.location.href = "/";
    localStorage.setItem("lang", JSON.stringify(landata));
  };
  const LogoutEngineer = () => {
    localStorage.clear();
    window.location.href = "/engineer";
  };
  const LogoutAdmin = () => {
    localStorage.clear();
    window.location.href = "/admin";
  };
  return (
    <div /*style={{marginTop:'2.4rem',height:'100%'}}*/>
      <ProSidebar collapsed={props.open} style={{ position: "fixed" }}>
        {(() => {
          switch (role) {
            case RoleUser:
              return (
                <>
                  {props.open == false ? (
                    <Menu>
                      <div
                        style={{
                          textAlign: "left",
                          color: "white",
                          marginLeft: "32px",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: 18,
                            marginInline: 0,
                            color: "white",
                          }}
                        >
                          {EIDno}
                        </h2>
                        <span style={{ fontSize: 12, textAlign: "left" }}>
                          {Email}
                        </span>
                      </div>
                    </Menu>
                  ) : null}
                  <Menu>
                    <MenuItem
                      icon={<HomeSvg />}
                      onClick={() => openPage(DASHBOARDCONTROLLER)}
                    >
                      {t("dashboard")}
                    </MenuItem>
                    <MenuItem
                      icon={<AssignmentIcon style={{ color: "white" }} />}
                      onClick={() => openPage(USERVIEWSURVEY)}
                    >
                      {t("fire_survey_report")}
                    </MenuItem>
                    {/* <MenuItem
                      icon={<BuildingSvg />}
                      onClick={() => openPage(VIEWBUILDING)}
                    >
                     Active Premises
                    </MenuItem> */}
                    <MenuItem
                      icon={<MyOrder />}
                      onClick={() => openPage(VIEWORDER)}
                    >
                      {t("premises_in_process")}
                    </MenuItem>
                    <MenuItem
                      icon={<Ticket />}
                      onClick={() => openPage(VIEWTICKET)}
                    >
                      {t("my_tickets")}
                    </MenuItem>
                    {/* <MenuItem
                      icon={<NotificationsIcon style={{ color: "white" }} />}
                      onClick={() => openPage(CUSTOMERNOTIFICATION)}
                    >
                      Notification
                    </MenuItem> */}
                    <MenuItem
                      icon={<LogoutSvg />}
                      onClick={() => setOpen2({ ...Open2, user: true })}
                    >
                      {t("logout")}
                    </MenuItem>
                  </Menu>
                </>
              );
            case RoleEngineer:
              return (
                <>
                  {props.open == false ? (
                    <Menu>
                      <div
                        style={{
                          textAlign: "left",
                          color: "white",
                          marginLeft: "32px",
                        }}
                      >
                        <h2 style={{ textAlign: "left", color: "white" }}>
                          {UserName}
                        </h2>
                        <span>{Email}</span>
                        {/* <h2 style={{ marginBottom: "0.1rem" }}>Mr.Robin</h2>
                        <span>Assistant Technician</span>
                        <br />
                        <span>robin123@gmail.com</span> */}
                      </div>
                    </Menu>
                  ) : null}
                  <Menu>
                    <MenuItem
                      icon={<HomeSvg />}
                      onClick={() => openPage(DASHBOARDCONTROLLER)}
                    >
                      Dashboard
                    </MenuItem>
                    <MenuItem
                      icon={<AssignmentIcon style={{ color: "white" }} />}
                      onClick={() => openPage(ENGINEERVIEWSURVEY)}
                    >
                      Fire Survey Report
                    </MenuItem>
                    <MenuItem
                      icon={<BuildingSvg />}
                      onClick={() => openPage(TASK)}
                    >
                      My Tasks
                    </MenuItem>
                    <MenuItem
                      icon={<WarningIcon style={{ color: "white" }} />}
                      onClick={() => openPage(ENGINEERDAMAGE)}
                    >
                      Damage
                    </MenuItem>
                    <MenuItem
                      icon={<HistoryIcon style={{ color: "white" }} />}
                      onClick={() => openPage(ENGINEERDAMAGEHISTORY)}
                    >
                      Damage History
                    </MenuItem>
                    <MenuItem
                      icon={<Ticket />}
                      onClick={() => openPage(SERVICES)}
                    >
                      My Services
                    </MenuItem>
                    {/* <MenuItem
                      icon={<NotificationsIcon style={{ color: "white" }}/>}
                      onClick={() => openPage(ENGINEERNOTIFICATION)}
                    >
                      Notification
                    </MenuItem> */}
                    <SubMenu
                      title="Calendar"
                      icon={<EventIcon style={{ color: "white" }} />}
                    >
                      <MenuItem
                        icon={<EventIcon style={{ color: "white" }} />}
                        onClick={() =>
                          openPage("/InstallationCalendarEngineer")
                        }
                      >
                        Installation
                      </MenuItem>
                      <MenuItem
                        icon={<EventIcon style={{ color: "white" }} />}
                        onClick={() => openPage("/ServiceCalendarEngineer")}
                      >
                        Service
                      </MenuItem>
                    </SubMenu>
                    <MenuItem
                      icon={<LogoutSvg />}
                      onClick={() => setOpen2({ ...Open2, engineer: true })}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              );
            case SuperAdmin:
              return (
                <>
                  {props.open == false ? (
                    <Menu>
                      <div
                        style={{
                          textAlign: "left",
                          color: "white",
                          marginLeft: "32px",
                        }}
                      >
                        <h2 style={{ textAlign: "left", color: "white" }}>
                          {UserName}
                        </h2>
                        <span>{Email}</span>
                      </div>
                    </Menu>
                  ) : null}
                  <Menu>
                    <MenuItem
                      icon={<HomeSvg />}
                      onClick={() => openPage(DASHBOARDCONTROLLER)}
                    >
                      Dashboard
                    </MenuItem>
                    {/* <MenuItem
                      icon={<HomeSvg />}
                      onClick={() => openPage(ADMIN)}
                    >
                      Admin
                    </MenuItem> */}
                    <MenuItem
                      icon={<AssignmentIcon style={{ color: "white" }} />}
                      onClick={() => openPage(ADMINVIEWSURVEY)}
                    >
                      Fire Survey Report
                    </MenuItem>
                    <MenuItem
                      icon={<PeopleIcon style={{ color: "white" }} />}
                      onClick={() => openPage(MYCUSTOMER)}
                    >
                      My Customer
                    </MenuItem>
                    <MenuItem
                      icon={<PeopleIcon style={{ color: "white" }} />}
                      onClick={() => openPage(VIEWSTAFF)}
                    >
                      Team
                    </MenuItem>
                    <MenuItem
                      icon={<MoreTimeIcon style={{ color: "white" }} />}
                      onClick={() => openPage(TIMESLOTS)}
                    >
                      Time Solts
                    </MenuItem>
                    <MenuItem
                      icon={<RecordVoiceOverIcon style={{ color: "white" }} />}
                      onClick={() => openPage(CUSTOMERREQUEST)}
                    >
                      Customer’s Requests
                    </MenuItem>
                    <MenuItem
                      icon={<AttachMoneyIcon style={{ color: "white" }} />}
                      onClick={() => openPage(VIEWPRICE)}
                    >
                      Price
                    </MenuItem>
                    <MenuItem
                      icon={<WarningIcon style={{ color: "white" }} />}
                      onClick={() => openPage(DAMAGEREQUEST)}
                    >
                      Damage Request
                    </MenuItem>
                    <MenuItem
                      icon={<HistoryIcon style={{ color: "white" }} />}
                      onClick={() => openPage(DAMAGEHISTORY)}
                    >
                      Damage History
                    </MenuItem>
                    {/* <MenuItem
                      icon={<NotificationsIcon style={{ color: "white" }} />}
                      onClick={() => openPage(NOTIFICATION)}
                    >
                     Notifications
                    </MenuItem> */}
                    <SubMenu
                      title="Calendar"
                      icon={<EventIcon style={{ color: "white" }} />}
                    >
                      <MenuItem
                        icon={<EventIcon style={{ color: "white" }} />}
                        onClick={() => openPage("/InstallationCalendar")}
                      >
                        Installation
                      </MenuItem>
                      <MenuItem
                        icon={<EventIcon style={{ color: "white" }} />}
                        onClick={() => openPage("/ServiceCalendar")}
                      >
                        Service
                      </MenuItem>
                    </SubMenu>
                    <MenuItem
                      icon={<LogoutSvg />}
                      onClick={() => setOpen2({ ...Open2, admin: true })}
                    >
                      Log Out
                    </MenuItem>
                  </Menu>
                </>
              );
          }
        })()}
      </ProSidebar>
      <ConfirmationDilog
        open={Open2.user}
        close={() => {
          return setOpen2({ ...Open2, user: false });
        }}
        ok={() => Logout()}
      />
      <ConfirmationDilog
        open={Open2.engineer}
        close={() => {
          return setOpen2({ ...Open2, engineer: false });
        }}
        ok={() => LogoutEngineer()}
      />
      <ConfirmationDilog
        open={Open2.admin}
        close={() => {
          return setOpen2({ ...Open2, admin: false });
        }}
        ok={() => LogoutAdmin()}
      />
    </div>
  );
};

export default Sidebar;
