import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import { ThemeColor } from "../../../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../../../Component/Table/index";
import useStyle from "./style";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { LISTENGINEERSERVICES } from "../../../../../../RouteS/ApiRoutes/Services";
import { useHistory } from "react-router-dom";
import MyModal from "../../../../../../Component/Modal/index";
const token = JSON.parse(localStorage.getItem("token"));

const ServiceReq = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyle();
  const [Data, setData] = useState([]);

  const url_str = window.location.href;
  const data = url_str.split("/");
  const EngineerId = data[4].toString();
  const history = useHistory();
  const [open2, setOpen2] = useState(false);
  const [holdmessage, setHoldMessage] = useState();
  useEffect(() => {
    getServices();
  }, []);

  const getServices = () => {
    const BodyData = JSON.stringify({
      Id: EngineerId,
    });
    fetch(LISTENGINEERSERVICES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Ticket Number",
      accessor: "TicketNo",
    },
    {
      Header: "Customer Name",
      accessor: "CustomerName",
    },

    {
      Header: "SerialNo",
      accessor: "SerialNo",
    },
    {
      Header: "Requested On",
      accessor: "RequestOn",
    },

    {
      Header: "Status",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Hold") {
          return row[filter.id] === "Hold";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Hold">Hold</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 3,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Hold":
            return (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      background: "red",
                      width: "75px",
                      height: "20px",
                      margin: "0px auto",
                      marginTop: "15px",
                      borderRadius: 13,
                      color: "white",
                      padding: 3,
                      marginLeft: "75px",
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    {data.original.Status}
                  </div>
                  <div>
                    <IconButton
                      onClick={() => {
                        setOpen2(true),
                          setHoldMessage(data.original.HoldMessage);
                      }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
    {
      Header: "Action",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={() => {
              return (
                history.push(`/servicedetails/${data.original.Id}`),
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                )
              );
            }}
          >
            View Details
          </Button>
        );
      },
    },
  ];
  return (
    <div className={classes.MainContainer}>
      <MyTable data={Data} columns={columns} />
      <MyModal
        open={open2}
        close={() => setOpen2(false)}
        title="Reason For Hold Task"
        class="ReshedulModal"
        MyClose={() => setOpen2(false)}
      >
        <div className={classes.ModalField}>
          <div>
            <label>{holdmessage}</label>
          </div>
        </div>
        <Button
          className={classes.AssignBtn}
          onClick={() => {
            setOpen2(false);
          }}
        >
          Close
        </Button>
      </MyModal>
    </div>
  );
};
export default ServiceReq;
