import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";
import Logo from "../../Assets/Images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    background: ThemeColor,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    height: "20%",
    bottom: 0,
    position: "absolute",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection:'column'
  },
  btnLink: {
    color: "#000000",
  },
  Icons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}
