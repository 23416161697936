import { Button, TextField, IconButton } from "@material-ui/core";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../../Component/Table/index";
import {
  GETSERVICESADMIN,
  TICKETRANGE,
} from "../../../../../RouteS/ApiRoutes/Ticket";
import MyModal from "../../../../../Component/Modal/index";
import useStyle from "./style";
import { GETENGINEER } from "../../../../../RouteS/ApiRoutes/MyEngineer";
import { VIEWONEORDER } from "../../../../../RouteS/ApiRoutes/Order";
import { ls } from "../../../../../SecureLs/Ls";
import { ASSIGNSERVICES } from "../../../../../RouteS/ApiRoutes/Services";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useHistory } from "react-router-dom";

const token = JSON.parse(localStorage.getItem("token"));
const ServiceReq = () => {
  const history = useHistory();
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [Data, setData] = useState([]);
  const [EngineerList, setEngineerList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [time, setTime] = useState();
  const [endDate, setEndDate] = useState();
  const [userId, setUserId] = useState();
  const [username, setUserName] = useState();
  const [serialno, setSerialNo] = useState();
  const [businessname, setBusinessName] = useState();
  const [address, setAddress] = useState();
  const [RangeDate, setRangeDate] = useState([]);
  const [new1, setNew1] = useState();
  const [new2, setNew2] = useState();
  const [assign, setAssign] = useState("");
  const [reassign, setReassign] = useState("");
  const [open2, setOpen2] = useState(false);
  const [holdmessage, setHoldMessage] = useState();
  const [EngineerVariable, setVariable] = useState({
    EngineerId: "",
    AssginTo: "",
  });
  const [serviceId, setServiceId] = useState();
  const [Effect, setEffect] = useState(false);
  useEffect(() => {
    getServices();
    getEngineer();
  }, [Effect ? Data : null]);
  const getServices = () => {
    fetch(GETSERVICESADMIN, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setData(res.data));
  };
  const GetDatranges = (id) => {
    fetch(TICKETRANGE, {
      method: "POST",
      headers: {
        // Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ Id: id }),
    })
      .then((res) => res.json())
      .then((res) => setRangeDate(res.Data));
  };
  const handleCallback = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };
  const getEngineer = () => {
    fetch(GETENGINEER, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setEngineerList(res.Data));
  };
  const onChangeEngineer = (e) => {
    setVariable({
      ...EngineerVariable,
      EngineerId: e.value,
      AssginTo: e.label,
    });
  };
  const AssignEngineer = () => {
    setEffect(true);
    const Data = JSON.stringify({
      Id: serviceId,
      UserId: userId,
      EngineerId: EngineerVariable.EngineerId,
      AssignTo: EngineerVariable.AssginTo,
      SheduleOn: startDate,
      Time: time,
      Name: username,
      SerialNo: serialno,
      BusinessName: businessname,
      Address: address,
      Assign: assign,
      Reassign: reassign,
    });
    console.log(Data);
    fetch(ASSIGNSERVICES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          setEffect(false);
          setOpen(false);
        }
      });
  };
  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Ticket Number",
      accessor: "TicketNo",
    },
    {
      Header: "Serial No",
      accessor: "SerialNo",
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "Schedule On",
      id: "SheduleOn",
      Cell: (data) => {
        if (data.original.SheduleOn != "") {
          return data.original.SheduleOn;
        } else {
          return (
            <div style={{ fontSize: 12, fontWeight: 600 }}>Not Schedule</div>
          );
        }
      },
    },

    {
      Header: "Status",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Hold") {
          return row[filter.id] === "Hold";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Hold">Hold</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Hold":
            return (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      background: "red",
                      width: "100px",
                      height: "20px",
                      margin: "0px auto",
                      marginTop: "15px",
                      borderRadius: 13,
                      color: "white",
                      padding: 3,
                      marginLeft: "40px",
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    {data.original.Status}
                  </div>
                  <div>
                    <IconButton
                      onClick={() => {
                        setOpen2(true),
                          setHoldMessage(data.original.HoldMessage);
                      }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            );
        }
      },
    },

    {
      Header: "Assign to",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.Status === "Hold") {
          return (
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => {
                return (
                  setServiceId(data.original.TicketNo),
                  setOpen(true),
                  GetDatranges(data.original.Id),
                  setNew1(data.original.StartDate),
                  setNew2(data.original.EndDate),
                  setUserId(data.original.UserId),
                  setTime(data.original.Time),
                  setUserName(data.original.CustomerName),
                  setSerialNo(data.original.SerialNo),
                  setBusinessName(data.original.BusinessName),
                  setAddress(data.original.Address),
                  setReassign("ReAssign")
                );
              }}
            >
              Re-Assign
            </Button>
          );
        } else {
          if (data.original.AssignTo == "") {
            return (
              <Button
                style={{
                  color: "white",
                  background: ThemeColor,
                  borderRadius: 10,
                }}
                onClick={() => {
                  return (
                    setServiceId(data.original.TicketNo),
                    setOpen(true),
                    GetDatranges(data.original.Id),
                    setNew1(data.original.StartDate),
                    setNew2(data.original.EndDate),
                    setUserId(data.original.UserId),
                    setTime(data.original.Time),
                    setUserName(data.original.CustomerName),
                    setSerialNo(data.original.SerialNo),
                    setBusinessName(data.original.BusinessName),
                    setAddress(data.original.Address),
                    setAssign("Assign")
                  );
                }}
              >
                Assign
              </Button>
            );
          } else {
            return <p>{data.original.AssignTo}</p>;
          }
        }
      },
    },
    {
      Header: "Action",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={() => {
              return (
                history.push(`/servicedetails/${data.original.Id}`),
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                )
              );
            }}
          >
            View Details
          </Button>
        );
      },
    },
    // {
    //   Header: " ",
    //   filterable: false,
    //   id: "icon",
    //   Cell: () => {
    //     return (
    //       <Button
    //         style={{
    //           color: "white",
    //           background: ThemeColor,
    //           borderRadius: 10,
    //         }}
    //       >
    //         View Detail
    //       </Button>
    //     );
    //   },
    // },
  ];
  return (
    <div className={classes.MainContainer}>
      <MyTable data={Data} columns={columns} />
      <MyModal
        open={open2}
        close={() => setOpen2(false)}
        title="Reason For Hold Task"
        class="ReshedulModal"
        MyClose={() => setOpen2(false)}
      >
        <div className={classes.ModalField}>
          <div>
            <label>{holdmessage}</label>
          </div>
        </div>
        <Button
          className={classes.AssignBtn}
          onClick={() => {
            setOpen2(false);
          }}
        >
          Close
        </Button>
      </MyModal>
      <MyModal
        open={open}
        close={() => setOpen(false)}
        title="Defective ATE Report"
        class="ReshedulModal"
        MyClose={() => {
          return setOpen(false);
        }}
      >
        <div>
          <div className={classes.ModalField}>
            <label>Select Date</label>
            {RangeDate.length > 0 ? (
              <DateRangePicker
                initialSettings={{
                  // startDate: new1,
                  // endDate: new2,
                  minDate: moment(moment(), "DD/MM/YYYY"),
                  // maxDate: new2,
                  dateLimit: { days: 9 },
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                  opens: "right",
                  singleDatePicker: true,
                }}
                onCallback={handleCallback}
              >
                <input
                  style={{
                    height: 40,
                    borderRadius: 5,
                    border: "0.5px solid " + ThemeColor + "",
                  }}
                />
                {/* <button
             style={{
               width: "100%",
               border: 0,
               background: "#B5944E",
               color: "white",
               borderRadius: 8,
               height: 38,
             }}
           >
             {RangeDate[0].StartDate} To {RangeDate[0].EndDate}
           </button> */}
              </DateRangePicker>
            ) : null}
          </div>
          <div className={classes.ModalField2}>
            <label>Select Engineer</label>
            <Select
              placeholder="Select Engineer"
              options={EngineerList.map((eng) => ({
                value: eng.Id,
                label: eng.FirstName + " " + eng.LastName,
              }))}
              onChange={onChangeEngineer}
            />
          </div>
        </div>

        <Button className={classes.AssignBtn} onClick={AssignEngineer}>
          Assign Task
        </Button>
      </MyModal>
    </div>
  );
};
export default ServiceReq;
