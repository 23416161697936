import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { GETSERVICESADMIN } from "../../RouteS/ApiRoutes/Ticket";
import { makeStyles } from "@material-ui/core/styles";
import {
  INSTALLATIONENGINEERCALENDAR,
  SERVICECALENDAR,
} from "../../RouteS/ApiRoutes/Calendar";
const localizer = momentLocalizer(moment);
const token = JSON.parse(localStorage.getItem("token"));

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginRight: "2rem",
    marginLeft: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      width: "90%",
      marginLeft: "40px",
    },
  },
}));

const InstallationCalendarEngineer = () => {
  const classes = useStyles();

  const [task, setTask] = useState([]);

  const [Show, setShow] = useState(false);

  useEffect(() => {
    var Data = JSON.parse(localStorage.getItem("EngineerId"));
    var obj = JSON.stringify({ EngineerId: Data });
    fetch(INSTALLATIONENGINEERCALENDAR, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: obj,
    })
      .then((res) => res.json())
      .then((res) => setTask(res.data));
  }, []);

  return (
    <div className={classes.MainContainer}>
      <div
        style={{
          textAlign: "left",
          marginTop: "0rem",
          marginBottom: "2rem",
          fontSize: "22px",
          fontWeight: 600,
        }}
      >
        <span style={{ textAlign: "left", marginTop: -5 }}>
          Installation Assign Calendar{" "}
        </span>
      </div>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={task}
        style={{ height: "90vh" }}
        showAllEvents={true}
      />
    </div>
  );
};

export default InstallationCalendarEngineer;

//.substr(0,10)
