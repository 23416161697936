import * as React from "react"

function LogoutSvg(props) {
  return (
    <svg
      width={21}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 .808A1.057 1.057 0 01.758.036C.875.01.995-.002 1.115 0h12.113c.687 0 1.106.39 1.113 1.059v3.279a.97.97 0 01-.013.112h-1.986v-.225-1.658a.5.5 0 00-.53-.564H4.053c-.029 0-.058 0-.133.012.096.053.15.086.209.115 1.349.694 2.696 1.391 4.051 2.074a1.178 1.178 0 01.712 1.161v8.297h2.822c.453 0 .626-.184.636-.645V9.216h1.99c0 .067.011.125.011.184v5.156c0 .551-.265.944-.727 1.07-.142.033-.288.05-.434.047H8.873v3.697c0 .568-.355.78-.86.52L.76 16.13a1.302 1.302 0 01-.76-.847V.808z"
        fill="#fff"
      />
      <path
        d="M16.876 7.869h-5.49c-.474 0-.848-.165-1.055-.608a1.082 1.082 0 011.011-1.577H16.926c-.068-.075-.112-.125-.16-.173l-1.253-1.253a1.059 1.059 0 01-.19-1.318 1.015 1.015 0 011.461-.234c.07.052.136.11.197.172 1.045 1.044 2.09 2.088 3.132 3.132.403.403.474.869.209 1.301-.059.091-.129.174-.209.247a1355.76 1355.76 0 01-3.151 3.153c-.418.418-.95.487-1.374.186a1.008 1.008 0 01-.174-1.5c.312-.363.67-.685 1.01-1.025.154-.154.312-.307.467-.46l-.015-.043z"
        fill="#fff"
      />
    </svg>
  )
}

export default LogoutSvg
