import * as React from "react"

function MyOrder(props) {
  return (
    <svg
      width={20}
      height={23}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.852 22.933c-.171-.139-.358-.263-.512-.418A514.584 514.584 0 01.31 19.5c-.414-.413-.413-.754.002-1.17 1.254-1.257 2.514-2.508 3.764-3.771.72-.728 1.582-1.083 2.608-1.079 2.245.01 4.49.003 6.735.004.445 0 .722.258.679.64a.942.942 0 01-.109.313c-.216.425-.445.845-.659 1.27-.161.322-.408.468-.77.467a485.9 485.9 0 00-4.386 0c-.504 0-.827.403-.698.857a.653.653 0 00.63.481c1.56.005 3.119.02 4.677-.009.788-.015 1.355-.448 1.719-1.143.557-1.063 1.105-2.13 1.672-3.188.228-.426.454-.862.752-1.238.6-.756 1.425-1.108 2.384-1.132.548-.013.856.518.596 1.026-.402.79-.822 1.572-1.235 2.357-.595 1.135-1.18 2.276-1.787 3.405-.922 1.717-2.37 2.591-4.315 2.609-1.768.016-3.536.005-5.304 0a.472.472 0 00-.37.154c-.72.726-1.444 1.447-2.172 2.165-.157.153-.341.278-.513.417l-.358-.001z"
        fill="#fff"
      />
      <path
        d="M10.342 0c.322.129.676.208.957.396.578.386.838.97.842 1.666v.627H14.079c.484.003.746.261.747.748.003 1.807.003 3.613 0 5.42v2.485c0 .548-.248.797-.788.797h-7.81c-.54 0-.8-.255-.8-.791-.003-2.62-.003-5.24 0-7.86 0-.542.258-.8.793-.8h1.873c.016-.305.023-.6.05-.893A1.992 1.992 0 019.77.039.972.972 0 009.895 0h.447zm.453 2.678c0-.249.008-.473-.002-.696a.657.657 0 00-.606-.635.646.646 0 00-.705.548c-.033.254-.006.516-.006.783h1.32z"
        fill="#fff"
      />
    </svg>
  )
}

export default MyOrder
