import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import img1 from "../Frontend/image1.png";
import reg1 from "../Frontend/reg1.png";
import reg2 from "../Frontend/reg2.png";
import reg3 from "../Frontend/reg3.png";
import lay1 from "../Frontend/lay1.png";
import lay2 from "../Frontend/lay2.png";
import lay3 from "../Frontend/lay3.png";
import lay4 from "../Frontend/lay4.png";
import Footer1 from "./Footer1";
import { makeStyles } from "@material-ui/core/styles";
import "./Page1.css";
import Header from "../Component/Header";
import { LOGIN, SIGNUP } from "../RouteS/BrowserRoute/User";
import MainImage from "../Assets/Images/MainLogo.jpg";
// import Service from "../Assets/Images/service.png";
import Service from "../Assets/ProtectedLogos/services.png";
import { ThemeColor } from "../Assets/StyleSheets/Theme";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  Main_Div: {
    width: "100%",
    height: "100vh",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingBottom: "50px",
  },
  Container2: {
    padding: "0px !important",
    position: "absolute",
    marginTop: "0px",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "-30px",
      height: "475px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      height: "425px",
    },
  },
  Container: {
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    // marginTop: "40px",
    // height: "400px",
    // bottom: 0,
    position: "absolute",
    // marginBottom: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      height: "475px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      height: "425px",
    },
  },
  Text_div: {
    width: "100%",
    color: "white",
    padding: "14px",
    textAlign: lang == "ar" ? "right" : "left",
    // paddingTop: "40px",
    // paddingBottom: "40px",
    // paddingLeft: "100px",
    // paddingRight: "100px",
    borderRadius: "15px",
    backdropFilter: "blur(29px)",
    marginLeft: lang == "ar" ? "0px" : "80px",
    marginRight: lang == "ar" ? "80px" : "0px",
    width: "589px",
    background:
      "linear-gradient(261.7deg, rgba(0, 0, 0, 0.49) -0.62%, rgba(0, 0, 0, 0.2107) 101.06%)",
    height: "350px",
    [theme.breakpoints.down("xs")]: {
      height: "277px",
      width: "90%",
      margin: "0px auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "100%",
      padding: "33px",
      width: "94%",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  h1tag: {
    fontSize: "40px",
    fontWeight: 600,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: "30px",
    },
  },
  ptag: {
    fontSize: "17px",
    fontWeight: 600,
    lineHeight: 1.3,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1,
      // padding: "0 18px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  buttonDiv: {
    borderRadius: "20px",
    border: `1px solid ${ThemeColor}`,
    width: "150px",
    background: "white",
    color: "black",
    cursor: "pointer",
    height: "40px",
    "&:hover": {
      color: "white",
      background: ThemeColor,
      border: `1px solid white`,
    },
  },
  serviceimg: {
    height: "350px",
    // width: "500px",
    background: "white",
    padding: "10px",
    objectFit: "contain",
    borderRadius: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      height: "170px",
    },
  },
  grid_css: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginBottom: "10px !important",
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Header />
        <div className={classes.Root}></div>
        <div className={classes.Main_Div}>
          <Container className={classes.Container2} maxWidth="xl">
            <Grid container>
              <Grid item lg={6} md={12} xs={12} className={classes.grid_css}>
                <div className={classes.Text_div}>
                  <h1 className={classes.h1tag}>{t("weqayah24_7")}</h1>
                  <p className={classes.ptag}>{t("program_para1")}</p>
                  <p className={classes.ptag}>{t("program_para2")}</p>
                  <p className={classes.ptag}>{t("program_para3")}</p>
                </div>
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <div>
                  <img src={Service} className={classes.serviceimg} />
                </div>
              </Grid>
            </Grid>
          </Container>
          {/* <Container className={classes.Container}>
            <Grid container>
              <Grid item xs={12} lg={6} md={12}>
                <div className={classes.Text_div}>
                  <h1 className={classes.h1tag}>WEQAYAH 24/7</h1>
                  <p className={classes.ptag}>
                    The system transmits all events in the control panel through
                    M2M wireless technology to the control center for rapid
                    response in the event of any kind of fire.
                  </p>
                  <p className={classes.ptag}>
                    The system allows employees of the Central Monitoring
                    Station (CMS) to check whether the alarm is true or false in
                    less than 90 seconds.
                  </p>
                  <p className={classes.ptag}>
                    Then, the civil defense teams are informed of the warning
                    after verifying its validity within few seconds, and the
                    civil defense is provided with information and coordinates
                    of the site, and accordingly, the response to these
                    incidents becomes faster and more effective.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} md={12}>
                {" "}
                <img src={Service} className={classes.serviceimg} />
              </Grid>
            </Grid>
          </Container> */}
        </div>
      </div>
    </>
  );
};
export default Main;
