import { Button, Divider, IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ADDTIME, EDITTIME2 } from "../../../../RouteS/BrowserRoute/Admin";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { VIEWALLTIME } from "../../../../RouteS/ApiRoutes/Time";
import { DELETETIME } from "../../../../RouteS/ApiRoutes/Time";
import SucessModal from "../../../../Component/Modal/SucessModal";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  Titlediv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
}));

const TimeSlots = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const classes = useStyles();

  const [Data, setData] = useState([]);
  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Time Slot",
      accessor: "Time",
    },
    {
      Header: "Status",
      id: "status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "true":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "false":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
    {
      Headers: "",
      id: "Action",
      filterable: false,
      Cell: (data) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => history.push(EDITTIME2 + "/" + data.original.Id)}
            >
              <EditIcon style={{ fontSize: 20, color: "#000000" }} />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ background: "black", height: 15, width: 2 }}
            />
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                save(data.original.Id);
                view();
              }}
            >
              <DeleteIcon style={{ fontSize: 20, color: "#717171" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    view();
  }, []);

  const [a, setA] = useState(false);
  const view = () => {
    fetch(VIEWALLTIME, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.data);
      });
  };

  const save = (Id) => {
    const c = { Id: Id };
    fetch(DELETETIME, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(c),
    })
      .then((res) => res.json())
      .then((responseJson) => {
        if (responseJson.Status === true) {
          setStatus({ ...status, success: true });
          setMessage(responseJson.Message);
        } else {
          setStatus({ ...status, error: true });
          setMessage(responseJson.Message);
        }
      });
    view();
  };
  return (
    <div className={classes.MainContainer}>
      <div className={classes.Titlediv}>
        <h1 style={{ textAlign: "left" }}>Time Slots</h1>
        <Button
          style={{ color: ThemeColor, fontSize: 16, fontWeight: 600 }}
          onClick={() => history.push(ADDTIME)}
        >
          Add Time +
        </Button>
      </div>
      <MyTable data={Data} columns={columns} />
      <SucessModal
        open={status.success}
        message={message}
        close={() => {
          setStatus({ ...status, success: false });
        }}
      />
    </div>
  );
};
export default TimeSlots;
