import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../Assets/ProtectedLogos/Weqaya.png";
import NewLogo from "../../Assets/ProtectedLogos/NewLogo.png";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileHeader from "../../Frontend/MobileFile";
import { useTranslation } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  root: {
    // flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    // background: "#ffffff87",
    zIndex: "11",
    position: "fixed",
    width: "100%",
    // padding: "0px 30px",
  },
  root2: {
    // flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    background: "white",
    zIndex: "11",
    position: "fixed",
    width: "100%",
    // padding: "0px 30px",
  },
  appbar: {
    background: "transparent",
    boxShadow: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  LogoDiv: {
    // width: "20%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnLink: {
    color: "#000000",
  },
  Icons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  first_div: {
    display: "flex",
    alignItems: "center",
    paddingTop: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  sec_div: {
    display: "flex",
    alignItems: "center",
    width: "40%",
    justifyContent: "space-around",
  },
  sec_span_div: {
    color: "black",
    fontWeight: 400,
    fontSize: "23px",
    cursor: "pointer",
    flexShrink: 0,
    marginRight: "10px",
  },
  third_div: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const MyHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [scroll_active, setScroll_Active] = React.useState();
  const [lan, setLan] = useState("");
  const [checked, setChecked] = useState(false);

  const { t, i18n } = useTranslation();
  const url_str = window.location.href;
  const data = url_str.split("/");
  const email = data[3].toString();

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("lang"));
    if (lang != "") {
      setLan(lang);
      // if (lang == "ar") {
      //   setChecked(true);
      // }
      i18n.changeLanguage(JSON.parse(localStorage.getItem("lang")));
    }
  }, []);

  const handleChange = (e) => {
    setLan(e.target.value);
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", JSON.stringify(e.target.value));
    window.location.href = "";
  };

  const changebg = () => {
    if (window.scrollY >= 20) {
      setScroll_Active(true);
    } else {
      setScroll_Active(false);
    }
  };
  window.addEventListener("scroll", changebg);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleCheck = (e) => {
    setChecked(e.target.checked);
    if ((e.target.checked && lan == "en") || lan == null) {
      i18n.changeLanguage("ar");
      localStorage.setItem("lang", JSON.stringify("ar"));
      window.location.href = "";
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", JSON.stringify("en"));
      window.location.href = "";
    }
  };
  return (
    <>
      {scroll_active ? (
        Mobile ? (
          <MobileHeader />
        ) : Ipad ? (
          <MobileHeader />
        ) : email == "admin" || email == "engineer" ? (
          <div className={classes.root2}>
            <div className={classes.MainContainer}>
              <div className={classes.first_div}>
                <img
                  src={Logo}
                  width="110"
                  style={{ marginRight: "1rem" }}
                  onClick={() => history.push("/")}
                />
              </div>
              <div className={classes.sec_div}>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Home
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/userLogin");
                  }}
                >
                  Login/Registration
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/program");
                  }}
                >
                  The Program
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/media");
                  }}
                >
                  Media
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/contactus");
                  }}
                >
                  Contact Us
                </div>
                {/* <select value={lan} onChange={handleChange}>
                <option value="en">English</option>
                <option value="ar">Arabic</option>
              </select> */}
              </div>
              <div className={classes.third_div}>
                <img
                  src={NewLogo}
                  width="400"
                  style={{ marginRight: "1rem" }}
                  onClick={() => history.push("/")}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.root2}>
            <div className={classes.MainContainer}>
              <div className={classes.first_div}>
                <img
                  src={Logo}
                  width="110"
                  style={{ marginRight: "1rem" }}
                  onClick={() => history.push("/")}
                />
              </div>
              <div className={classes.sec_div}>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  {t("home")}
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/userLogin");
                  }}
                >
                  {t("loginregistration")}
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/program");
                  }}
                >
                  {t("theprogram")}
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/media");
                  }}
                >
                  {t("media")}
                </div>
                <div
                  className={classes.sec_span_div}
                  onClick={() => {
                    history.push("/contactus");
                  }}
                >
                  {t("contactus")}
                </div>
                {/* <select value={lan} onChange={handleChange}>
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select> */}
              </div>
              <div>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={checked}
                      onChange={handleCheck}
                      name="checkedB"
                    />
                  }
                  label={lan == "ar" ? "English" : "عربي"}
                />
              </div>
              <div className={classes.third_div}>
                <img
                  src={NewLogo}
                  width="400"
                  style={{ marginRight: "1rem" }}
                  onClick={() => history.push("/")}
                />
              </div>
            </div>
          </div>
        )
      ) : Mobile ? (
        <MobileHeader />
      ) : Ipad ? (
        <MobileHeader />
      ) : email == "admin" || email == "engineer" ? (
        <div className={classes.root2}>
          <div className={classes.MainContainer}>
            <div className={classes.first_div}>
              <img
                src={Logo}
                width="110"
                style={{ marginRight: "1rem" }}
                onClick={() => history.push("/")}
              />
            </div>
            <div className={classes.sec_div}>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/");
                }}
              >
                Home
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/userLogin");
                }}
              >
                Login/Registration
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/program");
                }}
              >
                The Program
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/media");
                }}
              >
                Media
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/contactus");
                }}
              >
                Contact Us
              </div>
              {/* <select value={lan} onChange={handleChange}>
              <option value="en">English</option>
              <option value="ar">Arabic</option>
            </select> */}
            </div>
            <div className={classes.third_div}>
              <img
                src={NewLogo}
                width="400"
                style={{ marginRight: "1rem" }}
                onClick={() => history.push("/")}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root2}>
          <div className={classes.MainContainer}>
            <div className={classes.first_div}>
              <img
                src={Logo}
                width="110"
                style={{ marginRight: "1rem" }}
                onClick={() => history.push("/")}
              />
            </div>
            <div className={classes.sec_div}>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/");
                }}
              >
                {t("home")}
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/userLogin");
                }}
              >
                {t("loginregistration")}
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/program");
                }}
              >
                {t("theprogram")}
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/media");
                }}
              >
                {t("media")}
              </div>
              <div
                className={classes.sec_span_div}
                onClick={() => {
                  history.push("/contactus");
                }}
              >
                {t("contactus")}
              </div>
              {/* <select value={lan} onChange={handleChange}>
                <option value="en">English</option>
                <option value="ar">Arabic</option>
              </select> */}
            </div>
            <div>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={checked}
                    onChange={handleCheck}
                    name="checkedB"
                  />
                }
                label={lan == "ar" ? "English" : "عربي"}
              />
            </div>
            <div className={classes.third_div}>
              <img
                src={NewLogo}
                width="400"
                style={{ marginRight: "1rem" }}
                onClick={() => history.push("/")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MyHeader;
