import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import {
  Avatar,
  IconButton,
  Button,
  Container,
  InputAdornment,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  UPDATEUSER,
  GETONEUSER,
  RESETPASSWORD,
} from "../../../../RouteS/ApiRoutes/Profile";
import { DASHBOARDCONTROLLER } from "../../../../RouteS/BrowserRoute/ControllerRoute/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../Engineer/Profile/profile.css";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      height: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down("sm", "md")]: {
        width: "100%",
        height: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginLeft: "25px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      "&>.MuiFormControl-root": {
        width: "100% !important",
      },
      width: "100% !important",
    },
  },
  RegisterBtn: {
    width: "20%",
    background: ThemeColor,
    marginTop: "1rem",
    marginBottom: "2rem",
    fontSize: 17,
    marginLeft: "1rem",
    marginRight: "1rem",
    fontWeight: 600,
    color: "white",
    height: "7%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "35%",
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "71%",
      marginBottom: "1rem",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
    width: "100%",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  mainDiv_pass: {
    display: "flex",
    alignItems: "flex-start",
    // flexFlow: "wrap",
    flexDirection: "column",
    marginBottom: "1.5rem",
  },
  MainLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginLeft: lang == "ar" ? "0rem" : "3rem",
    marginRight: lang == "ar" ? "3rem" : "0rem",
  },
  profileImageDiv: {
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&>span": {
      fontWeight: 600,
      marginTop: "0.5rem",
    },
  },
  ProfileBtn: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 30,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  ProfileBtn2: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 0,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  passDiv: {
    display: "flex",
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const Profile = () => {
  const UserId = JSON.parse(localStorage.getItem("UserId"));
  const token = JSON.parse(localStorage.getItem("token"));

  const history = useHistory();
  const classes = useStyles();
  const imageRef = useRef();
  const { t } = useTranslation();

  const [field, setField] = useState({
    Id: "",
    FirstName: "",
    LastName: "",
    UserName: "",
    EIDno: "",
    Email: "",
    ContactNo: "",
    // AltContactNo: "",
    // FirstName2: "",
    // LastName2: "",
    // ContactNo2: "",
    Password: "",
  });
  const [message, setMessage] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [Image, setImage] = useState("");
  const [file, setFile] = useState();
  const [profileImg, setProfileImage] = useState(true);
  const [Images, setImages] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onChangeFile = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    setProfileImage(true);
  };

  const onChangeField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };

  useEffect(() => {
    view();
  }, []);

  const view = () => {
    const Id = JSON.stringify({ Id: UserId });
    fetch(GETONEUSER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Id,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
        setImages(responseJson.Data[0].Image);
      });
  };

  const savechanges = () => {
    if (emailerror == "") {
      const data = new FormData();
      data.append("Id", UserId);
      data.append("FirstName", field.FirstName);
      data.append("LastName", field.LastName);
      data.append("UserName", field.UserName);
      data.append("EIDno", field.EIDno);
      data.append("Email", field.Email);
      data.append("ContactNo", field.ContactNo);
      // data.append("AltContactNo", field.AltContactNo);
      // data.append("FirstName2", field.FirstName2);
      // data.append("LastName2", field.LastName2);
      // data.append("ContactNo2", field.ContactNo2);
      if (newpassword !== "") {
        data.append("Password", newpassword);
      } else {
        data.append("Password", field.Password);
      }
      data.append("ConfirmPassword", confirmpassword);
      data.append("file", file);
      fetch(UPDATEUSER, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status === true) {
            setStatus({ ...status, success: true });
            setMessage("Data Successfully Updated");
          } else {
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          }
        });
    }
  };

  // reset();
  // const reset = () => {
  //   const Data = JSON.stringify({
  //     Id: UserId,
  //     CurrentPassword: field.Password,
  //     NewPassword: newpassword,
  //     NewConfirmPassword: confirmpassword,
  //   });
  //   fetch(RESETPASSWORD, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: Data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.Status === true) {
  //         setStatus({ ...status, success: true });
  //         setMessage("Data Successfully Updated");
  //       } else {
  //         setStatus({ ...status, error: true });
  //         setMessage(res.Message);
  //       }
  //     });
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // width: "98%",
          alignItems: "center",
          marginLeft: "30px",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            marginTop: "0px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
            marginBottom: "20px",
          }}
        />
      </div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div
            style={{
              textAlign: lang == "ar" ? "right" : "left",
              marginTop: "-2rem",
            }}
          >
            <h1>{t("my_profile")}</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.profileImageDiv}>
                {profileImg ? (
                  <>
                    <IconButton
                      className={classes.ProfileBtn2}
                      onClick={() => imageRef.current.click()}
                    >
                      <Avatar
                        src={Image !== "" ? Image : Images}
                        style={{ height: 100, width: 100 }}
                      />
                    </IconButton>
                    <span>{t("registration_image")}</span>
                  </>
                ) : (
                  <>
                    {" "}
                    <IconButton
                      className={classes.ProfileBtn}
                      onClick={() => imageRef.current.click()}
                    >
                      <PhotoCameraIcon
                        style={{ color: "black", fontSize: 32 }}
                      />
                    </IconButton>
                    <span>{t("registration_image")}</span>
                  </>
                )}
                <input
                  type="file"
                  ref={imageRef}
                  style={{ display: "none" }}
                  onChange={onChangeFile}
                />
              </div>
              <div>
                <h3 className={classes.MainLabel}>{t("personal_info")}</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("first_name")}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="FirstName"
                      onChange={onChangeField}
                      value={field.FirstName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      className={classes.widthclass}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("last_name")}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="LastName"
                      onChange={onChangeField}
                      value={field.LastName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("user_name")}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="UserName"
                      onChange={onChangeField}
                      value={field.UserName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("eid_number")}
                    </label>
                    <InputMask
                      mask="999-9999-9999999-9"
                      disabled={false}
                      maskChar=" "
                      onChange={onChangeField}
                      value={field.EIDno}
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          size="small"
                          name="EIDno"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      )}
                    </InputMask>
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("email_address")}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Email"
                      onChange={
                        // onChangeField
                        (e) => {
                          const re =
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                          if (re.test(e.target.value)) {
                            setField({ ...field, Email: e.target.value });
                            setEmailError("");
                          } else {
                            setField({ ...field, Email: e.target.value });
                            setEmailError("Please Enter Valid Email");
                          }
                        }
                      }
                      value={field.Email}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {emailerror == "" ? (
                      ""
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {emailerror}
                      </div>
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("mobile_number")}
                    </label>
                    <ReactPhoneInput
                      country={"ae"}
                      value={field.ContactNo}
                      onChange={(e) => {
                        setField({ ...field, ContactNo: e });
                      }}
                    />
                    {/* <TextField
                      variant="outlined"
                      size="small"
                      name="ContactNo"
                      onChange={onChangeField}
                      value={field.ContactNo}
                      style={{ width: "222px" }}
                      inputProps={{ maxLength: 12 }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Alternative Contact Number
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="AltContactNo"
                      onChange={onChangeField}
                      value={field.AltContactNo}
                      style={{ width: "222px" }}
                      inputProps={{ maxLength: 12 }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    />
                  </div> */}
                </div>
                {/* <div>
                  <h3 className={classes.MainLabel}>
                    Alternate Contact Information
                  </h3>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>First Name</label>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="FirstName2"
                        onChange={onChangeField}
                        value={field.FirstName2}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Last Name</label>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="LastName2"
                        onChange={onChangeField}
                        value={field.LastName2}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Contact Number
                      </label>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="ContactNo2"
                        onChange={onChangeField}
                        style={{ width: "222px" }}
                        inputProps={{ maxLength: 12 }}
                        value={field.ContactNo2}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                <div>
                  <h3 className={classes.MainLabel}>{t("security_info")}</h3>
                  <div className={classes.mainDiv_pass}>
                    <div>
                      <div className={classes.FieldDiv}>
                        <label className={classes.textLabel}>
                          {t("old_password")}
                        </label>
                        <TextField
                          type={showPassword1 ? "text" : "password"}
                          variant="outlined"
                          size="small"
                          name="Password"
                          value={field.Password}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword1}
                                  onMouseDown={handleMouseDownPassword1}
                                >
                                  {showPassword1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.passDiv}>
                      <div className={classes.FieldDiv}>
                        <label className={classes.textLabel}>
                          {t("new_password")}
                        </label>
                        <TextField
                          variant="outlined"
                          size="small"
                          name="NewPassword"
                          type={showPassword ? "text" : "password"}
                          value={newpassword}
                          onChange={(e) => {
                            setNewpassword(e.target.value);
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className={classes.FieldDiv}>
                        <label className={classes.textLabel}>
                          {t("confirm_pass")}
                        </label>
                        <TextField
                          type={showConfirmPassword ? "text" : "password"}
                          variant="outlined"
                          size="small"
                          name="ConfirmPassword"
                          value={confirmpassword}
                          onChange={(e) => {
                            setConfirmpassword(e.target.value);
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className={classes.RegisterBtn}
                onClick={() => {
                  savechanges();
                }}
              >
                {t("save_changes")}
              </Button>
              <Button
                className={classes.RegisterBtn}
                onClick={() => history.push(DASHBOARDCONTROLLER)}
              >
                {t("cancel")}
              </Button>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(DASHBOARDCONTROLLER);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </>
  );
};
export default Profile;
