import React, { useEffect, useState } from "react";
import MyTable from "../../../../Component/Table/index";
import { GETORDERDAMAGEHISTORY } from "../../../../RouteS/ApiRoutes/Ate";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "20px",
    },
  },
}));

const DamageEngineerHistory = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const classes = useStyles();

  const [Data, setData] = useState([]);

  useEffect(() => {
    getDamageHistory();
    getDamageNewHistory();
  }, []);

  const getDamageHistory = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(GETORDERDAMAGEHISTORY, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
      });
  };

  const getDamageNewHistory = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(GETORDERDAMAGEHISTORY, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    { Header: "Order Id", accessor: "OrderId" },
    {
      Header: "Damage Id",
      accessor: "DamageId",
    },
    {
      Header: "Old Engineer Name",
      accessor: "OldAssign",
    },
    {
      Header: "Engineer Name",
      accessor: "NewAssign",
    },
    {
      Header: "Serial No",
      accessor: "SerialNo",
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Damage ATEs Detail</h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default DamageEngineerHistory;
