import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import { VIEWTIME } from "../../../../RouteS/BrowserRoute/Admin/index";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import { UPDATETIME, VIEWONETIME } from "../../../../RouteS/ApiRoutes/Time";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";

const token = JSON.parse(localStorage.getItem("token"));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    marginLeft: "25px",
    // display: "flex",
    // justifyContent: "center",
    // flexDirection: "row",
    // marginTop: "2.5rem",
    // alignItems: "center",
    // width: "100%",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    alignItems: "center",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
      marginLeft: "0rem",
    },
  },
  SaveBtn: {
    width: "15%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    marginBottom: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      width: "32%",
      marginRight: "10px !important",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  SaveBtn2: {
    width: "10%",
    background: ThemeColor,
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "50%",
    alignItems: "center",
    borderRadius: "10px",
    marginTop: "1.5rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 12,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    marginBottom: 0,
    marginTop: "2rem",
  },
  DateTextField: {
    width: "223px",
  },
}));

const AddPrice = () => {
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");
  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const [time, setTime] = useState("");

  let handleChange = (e) => {
    setTime(e.target.value);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    var Data = JSON.stringify({
      Time: time,
      Id: Id,
    });
    console.log("data is :" + Data);
    fetch(UPDATETIME, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.Status === true) {
            setStatus({ ...status, success: true });
            setMessage(res.Message);
          } else {
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          }
        }
      });
  };

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWONETIME + "Id=" + Id + "", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setTime(responseJson.Data[0].Time);
      });
  };
  return (
    <>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "left",
              marginTop: "0rem",
            }}
          >
            <h1>Update Time</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Enter TimeSlote</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    style={{ width: "70%" }}
                    name="Time"
                    value={time}
                    onChange={handleChange}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button className={classes.SaveBtn} onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className={classes.SaveBtn}
                    onClick={() => history.push(VIEWTIME)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(VIEWTIME);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </>
  );
};

export default AddPrice;
