import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LoginImage from "../../../Assets/Images/login.png";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../Assets/StyleSheets/Theme";
import { Link } from "react-router-dom";
import { LOGIN } from "../../../RouteS/ApiRoutes/Login-SignUp";
import { useHistory } from "react-router";
import { ls } from "../../../SecureLs";
import { DASHBOARD } from "../../../RouteS/BrowserRoute/User/index";
import { DASHBOARDCONTROLLER } from "../../../RouteS/BrowserRoute/ControllerRoute";
import SucessModal from "../../../Component/Modal/SucessModal";
import ErrorModal from "../../../Component/Modal/ErrorModal";
import InputMask from "react-input-mask";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Header from "../../../Component/Header/index";
import MainImage from "../../../Assets/Images/MainLogo.jpg";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "600px",
      height: 340,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "350px",
      },
    },
  },
  RootImage: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "2.5rem",
    paddingTop: "11rem",
    // position: "relative",
    // height: "100%",
    // bottom: 0,
    position: "absolute",
    // marginBottom: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: "20.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "9.5rem",
    },
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "2rem",
  },
  LoginBtn: {
    width: "85%",
    background: ThemeColor,
    // marginTop: "2rem",
    color: "white",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      width: "77%",
    },
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  loginImage: {
    [theme.breakpoints.between("sm", "md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  link: {
    fontSize: 12,
    color: ThemeColor,
    marginLeft: lang == "ar" ? "45px" : "4px",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [Error, setError] = useState({
    open: false,
    Msg: "",
  });
  const [field, setField] = useState({
    Name: "",
  });
  const [field1, setField1] = useState({
    Password: "",
  });
  const history = useHistory();
  const OnChageField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };
  // var Email=JSON.parse(localStorage.getItem('Email'));
  const [email, setEmail] = useState();

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("false");
  const [a, setA] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { t } = useTranslation();

  const OnChagePass = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField1({ ...field, [e.target.name]: value });
      setA("");
    } else {
      setA("At Least 6 Characters Password");
    }
  };
  const SignIn = () => {
    var Data = JSON.stringify({
      Name: field.Name,
      Password: field1.Password,
    });

    fetch(LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.Status === true) {
            localStorage.setItem("MyToken", "1");
            localStorage.setItem("Role", JSON.stringify("User"));
            localStorage.setItem("token", JSON.stringify(res.token));
            localStorage.setItem("UserId", JSON.stringify(res.UserId));
            localStorage.setItem("Email", JSON.stringify(res.Email));
            localStorage.setItem("FirstName", JSON.stringify(res.FirstName));
            localStorage.setItem("LastName", JSON.stringify(res.LastName));
            localStorage.setItem("UserName", JSON.stringify(res.UserName));
            localStorage.setItem("EIDno", JSON.stringify(res.EIDno));
            localStorage.setItem("ContactNo", JSON.stringify(res.ContactNo));
            localStorage.setItem("auth", JSON.stringify("reu"));
            window.location.href = DASHBOARDCONTROLLER;
            // setStatus({ ...status, success: true });
            // setMessage(res.Message);
          } else if (res.User === "Inactive") {
            setEmail(res.Email);
            setActive("true");
            localStorage.setItem("signupval", JSON.stringify(res.Otp));
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          } else {
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          }
        }
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <div className={classes.RootImage}></div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div className={classes.title}>
            <h1 style={{ color: "white", textAlign: "center" }}>
              {t("user_login_title")}
            </h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel}>{t("user_name")}</label>
                <TextField
                  variant="outlined"
                  size="small"
                  color="secondary"
                  name="Name"
                  placeholder="EID Number/User Name/Email Address"
                  onChange={OnChageField}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel}>
                  {t("user_password")}
                </label>
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  size="small"
                  name="Password"
                  placeholder="Enter your password"
                  onChange={OnChagePass}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />{" "}
                <span
                  style={{ color: "red", fontSize: "15px", textAlign: "left" }}
                >
                  {a}
                </span>
                <br />
                <Link
                  to="/requestOtp"
                  style={{
                    textAlign: lang == "ar" ? "left" : "right",
                    color: ThemeColor,
                    marginBottom: "10px",
                  }}
                >
                  {t("user_forgotpass")}
                </Link>
              </div>
              <Button className={classes.LoginBtn} onClick={SignIn}>
                {t("user_login")}
              </Button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginRight: "3rem",
                  marginTop: "2px",
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    textAlign: "right",
                    fontWeight: 500,
                    marginBottom: "0rem",
                  }}
                >
                  {t("user_dont_account")}
                </p>
                <Link to="/signup" className={classes.link}>
                  {t("user_signup")}
                </Link>
              </div>
            </Paper>
            {/* <img
              src={LoginImage}
              width="530"
              height="374"
              style={{ marginLeft: "5rem" }}
              className={classes.loginImage}
            /> */}
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return (window.location.href = DASHBOARDCONTROLLER);
          }}
        />
        {active === "true" ? (
          <ErrorModal
            open={status.error}
            message={message}
            close={() => {
              return history.push(`/email/${email}`);
            }}
          />
        ) : (
          <ErrorModal
            open={status.error}
            message={message}
            close={() => {
              setStatus({ ...status, error: false });
            }}
          />
        )}
      </Container>
    </div>
  );
};
export default Login;
