import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Button, Divider, Paper } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  GETONESURVEY,
  GETONELIFESYSTEM,
  GETONECONTACTSURVEY,
} from "../../../../RouteS/ApiRoutes/Survey";
import { PDFExport } from "@progress/kendo-react-pdf";
import logo from "./logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "99%",
      height: 140,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      background: "rgba(170, 158, 41, 0.09)",
    },
  },
  MainContainer: {
    marginLeft: "25px",
  },
  div1: {
    display: "flex",
    width: 300,
    justifyContent: "space-between",
    margin: "0px auto",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: 35,
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: 35,
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  div2: {
    display: "flex",
    width: 300,
    justifyContent: "space-between",
    margin: "0px auto",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "1rem",
      "&>span": {
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
      },
    },
  },
  div3: {
    display: "flex",

    width: 300,
    justifyContent: "space-between",
    margin: "0px auto",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: 35,
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: 35,
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  DividerVeritical: {
    height: 110,
    width: 2.8,
    background: ThemeColor,
    borderRadius: 10,
  },
}));

const ViewSurvey = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const pdfExportComponent = React.useRef(null);

  const [field, setField] = useState([]);
  const [field2, setField2] = useState([]);
  const [field3, setField3] = useState([]);

  useEffect(() => {
    View();
    ContactView();
    LifesafetyView();
  }, []);

  const View = () => {
    var raw = JSON.stringify({
      Id: Id,
    });
    fetch(GETONESURVEY, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
      });
  };

  const ContactView = () => {
    var raw = JSON.stringify({
      Id: Id,
    });
    fetch(GETONECONTACTSURVEY, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField2(responseJson.Data);
      });
  };

  const LifesafetyView = () => {
    var raw = JSON.stringify({
      Id: Id,
    });
    fetch(GETONELIFESYSTEM, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField3(responseJson.Data);
      });
  };

  return (
    <>
      <div className={classes.MainContainer}>
        <div className={classes.root}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "98%",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              onClick={history.goBack}
              style={{
                paddingLeft: "10px",
                width: "40px",
                height: "40px",
                fontSize: 15,
                backgroundColor: ThemeColor,
                color: "white",
              }}
            />
          </div>
          <div className="example-config">
            <Button
              style={{
                backgroundColor: ThemeColor,
                color: "white",
              }}
              onClick={() => {
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
              Export PDF
            </Button>
          </div>
          <PDFExport paperSize="A2" margin="2cm" ref={pdfExportComponent}>
            <div
              style={{
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: ThemeColor,
                    height: "70px",
                    marginRight: "0px",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={logo}
                    height="100%"
                    width="80px"
                    style={{
                      marginLeft: "20px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "15px",
                      marginTop: "12px",
                      marginBottom: "5px",
                      marginLeft: "20px",
                      color: "white",
                    }}
                  >
                    Abu Dhabi Civil Defence
                    <br /> General Directorate
                  </span>
                  <span
                    style={{
                      color: "white",
                      fontSize: "25px",
                      marginTop: "16px",
                      marginLeft: "530px",
                    }}
                  >
                    <b>Fire Survey Report</b>
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        Team Details
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <tr>
                        <td>
                          Team Member 1 :
                          {" " + field.FirstName + " " + field.LastName}
                        </td>
                        <td>
                          Team Member 2 :
                          {" " + field.FirstName2 + " " + field.LastName2}
                        </td>
                      </tr>
                      <tr>
                        <td>ID Number : {field.IdNumber1}</td>
                        <td>ID Number : {field.IdNumber2}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        Premises Details
                      </div>
                    </div>
                    <table
                      style={{
                        width: "90%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <tr>
                        <td>Business Name:{field.BusinessName}</td>
                        <td>Premises Name:{field.PremisesName}</td>
                        <td>Type Of Premises: {field.TypeOfPremises}</td>
                      </tr>
                      <tr>
                        <td>Premises Status : {field.PremisesStatus}</td>
                        <td>Type Of Client : {field.TypeOfClient}</td>
                        <td>
                          Developer / Real Estate Agencies:
                          {field.DeveloperRealEstateAgencies}
                        </td>
                      </tr>
                      <tr>
                        <td>Number Of Floors : {field.NumberOfFloors}</td>
                        <td>
                          Height Of The Premises : {field.HeightOfThePremises}
                        </td>
                        <td>Number Of Blocks: {field.NumberOfBlocks}</td>
                      </tr>
                      <tr>
                        <td>Premises Up Area : {field.BuildUpAera}</td>
                        <td>Municipality No : {field.MunicipalityNo}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        Premises Location
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <tr>
                        <td>Emirates : {field.Emirates}</td>
                        <td>Address : {field.Address}</td>
                        <td>Districts : {field.Districts}</td>
                      </tr>
                      <tr>
                        <td>Makani No : {field.MakaniNo}</td>
                        {/* <td>
                          3Words :
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={field.ThreeWord}
                          >
                            {field.ThreeWord}
                          </a>
                        </td> */}
                        <td>Location : {field.Location1}</td>
                        <td>Main Road : {field.MainRoad}</td>
                      </tr>
                      <tr>
                        <td>Landmark : {field.Landmark}</td>
                        <td>Plot No : {field.PlotNo}</td>
                        <td>LAT : {field.LAT}</td>
                      </tr>
                      <tr>
                        <td>LON : {field.LON}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        Contact Details
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <tr>
                        <td>Owner Name : {field.OwnerName}</td>
                        <td>Designation : {field.Designation1}</td>
                        <td>Mobile Number : {field.TelephoneNumber1}</td>
                      </tr>
                      <tr>
                        <td>
                          Alternative Contact Number : {field.MobileNumber1}
                        </td>
                        <td>FAX : {field.Fax1}</td>
                        <td>Email : {field.Email1}</td>
                      </tr>
                      <tr>
                        <td>Responsible For : {field.ResponsibleFor1}</td>
                      </tr>
                    </table>
                    <hr style={{ width: "95%" }}></hr>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      {(() => {
                        let myArray = [];
                        for (let i = 0; i < field2.length; i++) {
                          myArray.push(
                            <>
                              <tr>
                                <td>
                                  Contact Person :{field2[i].ContactPerson}
                                </td>
                                <td>Designation :{field2[i].Designation}</td>
                                <td>
                                  Telephone Number :{field2[i].TelephoneNumber}
                                </td>
                              </tr>

                              <tr>
                                <td>Mobile Number :{field2[i].MobileNumber}</td>
                                <td>FAX : {field2[i].Fax}</td>
                                <td>Email : {field2[i].Email}</td>
                              </tr>

                              <tr>
                                <td>
                                  Responsible For :{field2[i].ResponsibleFor}
                                </td>
                              </tr>
                            </>
                          );
                        }
                        return myArray;
                      })()}
                    </table>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        Life Safety Systems
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <tr>
                        <td>
                          Life safety system : {field.SelectLifeSafetySystem}
                        </td>
                        <td>Qty : {field.Qty}</td>
                        <td>Mobile Number : {field.MobileNo1}</td>
                      </tr>
                      <tr>
                        <td>Location : {field.Location2}</td>
                        <td>Make : {field.Make}</td>
                        <td>Model : {field.Model1}</td>
                      </tr>
                      <tr>
                        <td>AMC /Supplier Name : {field.AMCSupplierName}</td>
                        <td>Expiry Date : {field.ExpiryDate}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        BMS System
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <tr>
                        <td>Vender : {field.Vendor}</td>
                        <td>Model : {field.Model2}</td>
                        <td>Version : {field.Version}</td>
                      </tr>
                      <tr>
                        <td>Contact Person: {field.ContactPerson2}</td>
                        <td>Mobile Number : {field.MobileNo2}</td>
                        <td>Remarks : {field.Remarks}</td>
                      </tr>
                      <tr>
                        <td>BMS Protocol : {field.SelectBMSProtocol}</td>
                        <td>Network Port : {field.NetworkPort}</td>
                        <td>Other Info : {field.OtherInfo2}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingBottom: "1rem",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      marginRight: "20px",
                      marginLeft: "10px",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "250px",
                        height: "40px",
                        fontFamily: "Poppins",
                        backgroundColor: ThemeColor,
                        color: "white",
                        fontSize: "24px",
                        marginTop: "10px",
                        marginRight: "0px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "3px",
                        }}
                      >
                        Visible Observations
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        textAlign: "left",
                        marginLeft: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      {(() => {
                        let myArray = [];
                        for (let i = 0; i < field3.length; i++) {
                          myArray.push(
                            <>
                              <tr>
                                <td>
                                  Life safety system :
                                  {field3[i].SelectLifeSafetySystem}
                                </td>
                                <td>Status :{field3[i].SelectStatus}</td>
                              </tr>
                            </>
                          );
                        }
                        return myArray;
                      })()}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </PDFExport>
        </div>
      </div>
    </>
  );
};

export default ViewSurvey;
