import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { VIEWALLPRICE } from "../../RouteS/ApiRoutes/Price";
import LoginImage from "../../Assets/Images/login.png";
import {
  Button,
  Container,
  TextField,
  IconButton,
  Avatar,
} from "@material-ui/core";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";
import { InputAdornment } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useHistory } from "react-router";
import { DASHBOARDCONTROLLER } from "../../RouteS/BrowserRoute/ControllerRoute";
import Sucessmodal from "../../Component/Modal/SucessModal";
import ErrorModal from "../../Component/Modal/ErrorModal";
import {
  ADDORDER,
  UPDATEORDER,
  GETREFORDER,
  UPDATEREFORDER,
  DELETEORDER,
  UPDATEINSERTORDER,
  CHECKPREMISES,
} from "../../RouteS/ApiRoutes/Order";
import { GETINSTALLATIONADMIN } from "../../RouteS/ApiRoutes/Installation";
import { addDays } from "date-fns";
import { VIEWALLTIME } from "../../RouteS/ApiRoutes/Time";
import DatePicker from "react-datepicker";
import MyModal from "../../Component/Modal/index";
import "./date1.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Select, MenuItem } from "@material-ui/core";
import "bootstrap-daterangepicker/daterangepicker.css";
import { red } from "@material-ui/core/colors";
import { SENDREMINDER } from "../../RouteS/ApiRoutes/Reminder";
import validator from "validator";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import SelectSearch, { fuzzySearch } from "react-select-search";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Domain } from "../../RouteS/ApiDomain/index";
import { Update } from "@material-ui/icons";
import PulseLoader from "react-spinners/PulseLoader";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@material-ui/icons/Info";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const token = JSON.parse(localStorage.getItem("token"));
const UserId = JSON.parse(localStorage.getItem("UserId"));
const email = JSON.parse(localStorage.getItem("Email"));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 640,
      height: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      paddingBottom: "20px",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        paddingBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "96%",
        paddingBottom: "0px",
      },
    },
  },
  root2: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 460,
      height: 300,
      borderRadius: 10,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      background: "#FAFAFF",
      overflow: "auto",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 460,
      // height: 275,
      borderRadius: 10,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      background: "#FAFAFF",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  ModalField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&> label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  AssignBtn: {
    display: "flex",
    alignItems: "right",
    background: ThemeColor,
    color: "white",
    width: "10%",
    fontWeight: 600,
    marginTop: "1rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  AddContactDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "3.5rem",
  },
  ContactSpan: {
    fontWeight: 700,
    color: ThemeColor,
    "&:hover": {
      cursor: "pointer",
    },
  },
  lableSpan: {
    textAlign: lang == "ar" ? "right" : "left",
    color: ThemeColor,
    marginLeft: lang == "ar" ? 0 : 5,
    marginRight: lang == "ar" ? 5 : 0,
  },
  lableSpan1: {
    textAlign: lang == "ar" ? "right" : "left",
    color: ThemeColor,
    marginLeft: lang == "ar" ? 0 : 5,
    marginRight: lang == "ar" ? 5 : 0,
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
    },
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "69%",
    },
  },
  Addfieldsbutton: {
    color: ThemeColor,
    fontSize: 16,
    fontWeight: 600,
    marginRight: "2rem",
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  notchedOutline2: {
    borderColor: "#e1e1e1 !important",
  },
  textFieldClass: {
    "&>.MuiOutlinedInput-root": {
      height: 27,
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    // marginBottom: "1.5rem",
  },
  mainDiv2: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  MainLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginLeft: lang == "ar" ? "0rem" : "3rem",
    marginRight: lang == "ar" ? "3rem" : "0rem",
    marginTop: "1rem",
  },
  MainLabel2: {
    textAlign: "Right",
    marginRight: "3rem",
  },
  AddBtnDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    "&>button": {
      background: ThemeColor,
      color: "white",
      width: 130,
      marginRight: "1.5rem",
      "&:hover": {
        background: ThemeColor,
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.5rem",
      marginLeft: "1.4rem",
      justifyContent: "space-between",
    },
  },
  IconButton: {
    border: "1px solid " + ThemeColor + "",
    height: 35,
    width: 35,
    marginLeft: "0.5rem",
  },
  IconButton2: {
    border: "1px solid #e1e1e1",
    height: 10,
    width: 10,
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  ATEtext: {
    display: "flex",
    alignItems: "center",
  },
  ATEtext2: {
    display: "flex",
    alignItems: "center",
    width: 120,
    background: "#e1e1e1",
    marginBottom: "0.5rem",
  },
  checkOut: {
    display: "flex",
    justifyContent: "space-between",
    padding: 25,
    borderBottom: "1px dashed",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: 30,
        fontWeight: 500,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "&>span": {
        height: 30,
        fontWeight: 500,
      },
    },
  },

  checkOut2: {
    display: "flex",
    justifyContent: "space-between",
    padding: 25,

    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: 30,
        fontWeight: 500,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "&>span": {
        height: 30,
        fontWeight: 500,
      },
    },
  },
  CheckoutBtn: {
    background: ThemeColor + "!important",
    color: "white  !important",
    height: 40,
    width: "97%",
    [theme.breakpoints.between("sm", "md")]: {
      marginBottom: "2rem",
      width: "98%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  Titlewithbutton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  AddPremises: {
    padding: 20,
    "& > .MuiPaper-elevation3": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 10,
      "&>div:nth-child(1)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "&>span:nth-child(1)": {
          fontSize: 18,
          fontWeight: 500,
        },
        "&>span:nth-child(2)": {
          fontSize: 16,
          fontWeight: 500,
        },
        "&>p": {
          fontSize: "10px !important",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
      "&>div:nth-child(2)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "&>button": {
          background: "#E1E1E1",
          color: "#363636",
          marginBottom: "7px",
          width: 120,
          fontSize: 11,
          fontWeight: 600,
        },
      },
    },
  },
  "MuiIconButton-root": {
    padding: "0px !important",
  },
}));
const Fname = JSON.parse(localStorage.getItem("FirstName"));
const Lname = JSON.parse(localStorage.getItem("LastName"));
const Conatctno = JSON.parse(localStorage.getItem("ContactNo"));

const Premises = () => {
  const { t } = useTranslation();
  const [field, setField] = useState({
    businessName: "",
    premisesName: "",
    floornumber: "",
    roomnumber: "",
    Emirate: "Ajman",
    address: "",
    districts: "",
    makaniNo: "",
    threewords: "",
    plotnumber: "",
    ATE: 0,
    startdate: "",
    enddate: "",
    time: "",
  });
  const [field1, setField1] = useState({
    premisesNameErr: "",
    businessNameErr: "",
    addressErr: "",
    districtsErr: "",
    makaniNoErr: "",
    PlotNumberErr: "",
    ATEErr: "",
    dateErr: "",
    timeErr: "",
  });

  const [Values, setValues] = useState(false);
  const [OrderData, setOrder] = useState([]);
  const [ContactRow, setContactRaw] = useState([]);
  const [contectShow, SetContactShow] = useState(false);
  const [open, setOPen] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [messages, setMessages] = useState("");
  const [Data, setData] = useState([]);
  const [open2, setOPen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [ContactField, setContactField] = useState({
    FirstName: "",
    LastName: "",
    FullName: "",
    Email: "",
    Contact: "",
    EmeContact1: "",
    EmeContact2: "",
    EmeContact3: "",
    ContactName1: "",
    ContactName2: "",
    ContactName3: "",
  });
  const [ContactFieldErr, setContactFieldErr] = useState({
    FirstNameErr: "",
    FullNameErr: "",
    LastNameErr: "",
    EmailErr: "",
    ContactErr: "",
    EmeContact1Err: "",
    EmeContact2Err: "",
    EmeContact3Err: "",
    ContactName1Err: "",
    ContactName2Err: "",
    ContactName3Err: "",
  });
  const [msg, setMessage] = useState("");
  const [msg2, setMessage2] = useState("");
  const [ViewData, SetView] = useState(false);
  const [allOrder, SetAllOrder] = useState([]);
  const [ViewData2, SetView2] = useState(false);
  const [Time, setTime] = useState([]);
  const [indexvalue, setIndexvalue] = useState(0);
  const [ContectSend, setSendData] = useState([]);
  const [Emirate, setEmirate] = useState("");
  const history = useHistory();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [addcontact, setAddcontact] = useState("none");
  const [addcontact2, setAddcontact2] = useState("none");
  const [contact, setContact] = useState([]);
  const [displays, setDisplays] = useState([]);
  const classes = useStyles();
  const [ddd, setddd] = useState([]);

  const [encrypted, setEncrypted] = useState("");
  const [random, setRandom] = useState([]);
  const [pmerchant, setPMerchant] = useState("");
  const [preturn, setPReturn] = useState(
    // "https://dharmik.gopirupareliya.xyz/payment"
    `${Domain}/payment`
  );

  useEffect(() => {
    if (ViewData) {
      return OrderData;
    }
    return () => SetView(false);
  });
  useEffect(() => {
    if (contectShow) {
      return ContactRow;
    }
    return () => SetContactShow(false);
  });

  const url_str = window.location.href;
  const data = url_str.split("/");
  const urldata = url_str.split("/");

  useEffect(() => {
    fetch(VIEWALLPRICE, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
    fetch(GETINSTALLATIONADMIN, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // allOrder.push(res.Data);
        SetAllOrder(res.Data);
      });
  }, []);

  const RefOrder = () => {
    const refnodata = data[4].toString();
    if (refnodata) {
      const dataref = JSON.stringify({ OrderId: refnodata });
      fetch(GETREFORDER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: dataref,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setOrder(responseJson.Data);
        });
    }
  };

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (data.length > 4) {
      RefOrder();
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  // console.log(OrderData);

  const OnchangeFullName = (e) => {
    setContactField({ ...ContactField, FullName: e.target.value });
  };
  const OnchangeLastName = (e) => {
    setContactField({ ...ContactField, LastName: e.target.value });
  };
  const OnchangeEmail = (e) => {
    setContactField({ ...ContactField, Email: e.target.value });
  };
  const OnchangeContact = (e) => {
    setContactField({ ...ContactField, Contact: e });
  };
  const OnchangeEmeContact1 = (e) => {
    setContactField({ ...ContactField, EmeContact1: e });
  };
  const OnchangeEmeContact2 = (e) => {
    setContactField({ ...ContactField, EmeContact2: e });
  };
  const OnchangeEmeContact3 = (e) => {
    setContactField({ ...ContactField, EmeContact3: e });
  };
  const OnchangeContactName1 = (e) => {
    setContactField({ ...ContactField, ContactName1: e.target.value });
  };
  const OnchangeContactName2 = (e) => {
    setContactField({ ...ContactField, ContactName2: e.target.value });
  };
  const OnchangeContactName3 = (e) => {
    setContactField({ ...ContactField, ContactName3: e.target.value });
  };

  const [value, setValue] = useState();

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const [emailerror, setEmailError] = useState("");
  const [ec1, setEC1] = useState(false);
  const [ec2, setEC2] = useState(false);
  const [ec3, setEC3] = useState(false);

  const AddOrder = () => {
    const cuerrent = moment().format();
    const duedate = moment(cuerrent).add(1, "year");
    const insertDate = moment(duedate).format("DD/MM/YYYY");
    const ddd = JSON.parse(localStorage.getItem(field.businessName));
    if (
      field.premisesName !== "" &&
      field.businessName !== "" &&
      field.plotnumber !== "" &&
      field.address !== "" &&
      field.districts !== "" &&
      field.Emirate !== "" &&
      field.ATE !== 0 &&
      startDate !== "" &&
      endDate !== "" &&
      field.time !== "" &&
      ContactField.FullName !== "" &&
      ContactField.Email !== "" &&
      Object.keys(ContactField.Contact).length > 3 &&
      Object.keys(ContactField.EmeContact1).length > 3 &&
      Object.keys(ContactField.EmeContact2).length > 3 &&
      Object.keys(ContactField.EmeContact3).length > 3 &&
      ContactField.ContactName1 !== "" &&
      ContactField.ContactName2 !== "" &&
      ContactField.ContactName3 !== "" &&
      emailerror == ""
    ) {
      if (
        ContactField.EmeContact1 != ContactField.EmeContact2 &&
        ContactField.EmeContact2 != ContactField.EmeContact3 &&
        ContactField.EmeContact1 != ContactField.EmeContact3
      ) {
        setErr();
        OrderData.push({
          UserId: UserId,
          Name: Fname + " " + Lname,
          BusinessName: field.businessName,
          PremisesName: field.premisesName,
          MakaniNo: field.makaniNo,
          PlotNumber: field.plotnumber,
          ThreeWord: field.threewords,
          Emirate: field.Emirate,
          LandMark: "test",
          Product: "ATE",
          Address: field.address,
          Districts: field.districts,
          ATEs: field.ATE,
          Price: field.ATE * Data[0].ATEPrice,
          SheduleOn: "",
          StartDate: startDate,
          EndDate: endDate,
          DueDate: insertDate,
          Time: field.time,
          FloorNumber: field.floornumber,
          RoomNumber: field.roomnumber,
          FullName: ContactField.FullName,
          // LastName: ContactField.LastName,
          Email: ContactField.Email,
          Contact: ContactField.Contact,
          EmeContact1: ContactField.EmeContact1,
          EmeContact2: ContactField.EmeContact2,
          EmeContact3: ContactField.EmeContact3,
          ContactName1: ContactField.ContactName1,
          ContactName2: ContactField.ContactName2,
          ContactName3: ContactField.ContactName3,
        });
        if (data.length > 4) {
          displays.push(1);
        }
        setField({ ...field, time: "" });
        setStartDate("");
        setEndDate("");
        SetView(true);
        setField({
          ...field,
          premisesName: "",
          businessName: "",
          address: "",
          districts: "",
          makaniNo: "",
          Emirate: "Ajman",
          threewords: "",
          plotnumber: "",
          ATE: 0,
          date: "",
          roomnumber: "",
          floornumber: "",
          time: "",
        });
        setContactField({
          ...ContactField,
          FirstName: "",
          FullName: "",
          // LastName: "",
          Email: "",
          Contact: "971",
          EmeContact1: "971",
          EmeContact2: "971",
          EmeContact3: "971",
          ContactName1: "",
          ContactName2: "",
          ContactName3: "",
        });
        setContactFieldErr({
          ...ContactFieldErr,
          FullNameErr: "",
          // LastNameErr: "",
          EmailErr: "",
          ContactErr: "",
          EmeContact1Err: "",
          EmeContact2Err: "",
          EmeContact3Err: "",
          ContactName1Err: "",
          ContactName2Err: "",
          ContactName3Err: "",
        });
        setErrorMessage(null);
        setSelectedDate("");
        setField1({
          ...field1,
          premisesNameErr: "",
          businessNameErr: "",
          addressErr: "",
          districtsErr: "",
          makaniNoErr: "",
          PlotNumberErr: "",
          ATEErr: "",
          dateErr: "",
          timeErr: "",
        });
        setOpen4(false);
        setMessages("");
        setEC1(false);
        setEC2(false);
        setEC3(false);
      } else {
        if (
          ContactField.EmeContact1 == ContactField.EmeContact2 &&
          ContactField.EmeContact2 == ContactField.EmeContact3 &&
          ContactField.EmeContact1 == ContactField.EmeContact3
        ) {
          setEC1(true);
          setEC2(true);
          setEC3(true);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact2Err: "Emergency Contact2 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        } else if (ContactField.EmeContact1 == ContactField.EmeContact2) {
          setEC1(true);
          setEC2(true);
          setEC3(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact2Err: "Emergency Contact2 Was Required!!",
          });
        } else if (ContactField.EmeContact2 == ContactField.EmeContact3) {
          setEC2(true);
          setEC3(true);
          setEC1(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact2Err: "Emergency Contact2 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        } else {
          setEC1(true);
          setEC3(true);
          setEC2(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        }
      }
    } else {
      // setOpen4(true);
      // setMessages("Please Fill All The Details.");
      setField1({
        ...field1,
        premisesNameErr: "PremisesName Was Required!!",
        businessNameErr: "BusinessName Was Required!!",
        addressErr: "Address Was Required!!",
        districtsErr: "Districts Was Required!!",
        // makaniNoErr: "MakaniNo Must Be 10 Digits!!",
        PlotNumberErr: "PlotNumber Was Required!!",
        ATEErr: "FirePanel Was Required!!",
        dateErr: "Date Was Required!!",
        timeErr: "Time Was Required!!",
      });
      setContactFieldErr({
        ...ContactFieldErr,
        FullNameErr: "FullName Was Required!!",
        // LastNameErr: "LastName Was Required!!",
        EmailErr: "Email Was Required!!",
        ContactErr: "Contact Was Required!!",
        EmeContact1Err: "Emergency Contact1 Was Required!!",
        EmeContact2Err: "Emergency Contact2 Was Required!!",
        EmeContact3Err: "Emergency Contact3 Was Required!!",
        ContactName1Err: "ContactName1 Was Required!!",
        ContactName2Err: "ContactName2 Was Required!!",
        ContactName3Err: "ContactName3 Was Required!!",
      });
    }
  };

  // const [valuePrice, setValuePrice] = useState([]);
  // const pricesorder = () => {
  //   for (var i = 0; i < OrderData.length; i++) {
  //     valuePrice.push(OrderData[0].ATEs * Data[0].ATEPrice);
  //   }
  // };

  // console.log("value: ", valuePrice.length > 0 ? valuePrice : "");

  const RemoveData = (item) => {
    SetView(true);
    if (item !== -1) {
      OrderData.splice(item, 1);
    }
  };

  const [indexs, setIndexs] = useState("");
  const [editedData, setEditedData] = useState("");
  const UpdateData = (item, i) => {
    SetView(true);
    setUpdateOpens(true);
    setEditedData(item);
    // console.log(item.Id);
    if (data.length > 4) {
      setIds(item.Id);
      // console.log(item.Id);
    }
    setIndexs(i);
    setField({
      ...field,
      businessName: item.BusinessName,
      premisesName: item.PremisesName,
      makaniNo: item.MakaniNo,
      threewords: item.ThreeWord,
      Emirate: item.Emirate,
      plotnumber: item.PlotNumber,
      address: item.Address,
      districts: item.Districts,
      ATE: item.ATEs,
      insertDate: item.DueDate,
      time: item.Time,
      floornumber: item.FloorNumber,
      roomnumber: item.RoomNumber,
    });
    setStartDate(item.StartDate);
    setEndDate(item.EndDate);
    setContactField({
      ...ContactField,
      FullName: item.FullName,
      // LastName: item.LastName,
      Email: item.Email,
      Contact: item.Contact,
      EmeContact1: item.EmeContact1,
      EmeContact2: item.EmeContact2,
      EmeContact3: item.EmeContact3,
      ContactName1: item.ContactName1,
      ContactName2: item.ContactName2,
      ContactName3: item.ContactName3,
    });
  };

  const UpdateOrderData = () => {
    const cuerrent = moment().format();
    const duedate = moment(cuerrent).add(1, "year");
    const insertDate = moment(duedate).format("DD/MM/YYYY");
    const ddd = JSON.parse(localStorage.getItem(field.businessName));
    if (
      field.premisesName !== "" &&
      field.businessName !== "" &&
      field.plotnumber !== "" &&
      field.address !== "" &&
      field.districts !== "" &&
      field.Emirate !== "" &&
      field.ATE !== 0 &&
      startDate !== "" &&
      endDate !== "" &&
      field.time !== "" &&
      ContactField.FullName !== "" &&
      // ContactField.LastName !== "" &&
      ContactField.Email !== "" &&
      Object.keys(ContactField.Contact).length > 3 &&
      Object.keys(ContactField.EmeContact1).length > 3 &&
      Object.keys(ContactField.EmeContact2).length > 3 &&
      Object.keys(ContactField.EmeContact3).length > 3 &&
      ContactField.ContactName1 !== "" &&
      ContactField.ContactName2 !== "" &&
      ContactField.ContactName3 !== "" &&
      emailerror == ""
    ) {
      if (
        ContactField.EmeContact1 != ContactField.EmeContact2 &&
        ContactField.EmeContact2 != ContactField.EmeContact3 &&
        ContactField.EmeContact1 != ContactField.EmeContact3
      ) {
        setErr();
        OrderData[OrderData.indexOf(editedData)] = {
          UserId: UserId,
          Name: Fname + " " + Lname,
          BusinessName: field.businessName,
          PremisesName: field.premisesName,
          MakaniNo: field.makaniNo,
          PlotNumber: field.plotnumber,
          ThreeWord: field.threewords,
          Emirate: field.Emirate,
          LandMark: "test",
          Product: "ATE",
          Address: field.address,
          Districts: field.districts,
          ATEs: field.ATE,
          Price: field.ATE * Data[0].ATEPrice,
          SheduleOn: "",
          StartDate: startDate,
          EndDate: endDate,
          DueDate: insertDate,
          Time: field.time,
          FloorNumber: field.floornumber,
          RoomNumber: field.roomnumber,
          FullName: ContactField.FullName,
          // LastName: ContactField.LastName,
          Email: ContactField.Email,
          Contact: ContactField.Contact,
          EmeContact1: ContactField.EmeContact1,
          EmeContact2: ContactField.EmeContact2,
          EmeContact3: ContactField.EmeContact3,
          ContactName1: ContactField.ContactName1,
          ContactName2: ContactField.ContactName2,
          ContactName3: ContactField.ContactName3,
        };
        setUpdateOpens(false);
        setField({ ...field, time: "" });
        setStartDate("");
        setEndDate("");
        SetView(true);
        setField({
          ...field,
          premisesName: "",
          businessName: "",
          address: "",
          districts: "",
          makaniNo: "",
          Emirate: "Ajman",
          threewords: "",
          plotnumber: "",
          ATE: 0,
          date: "",
          roomnumber: "",
          floornumber: "",
          time: "",
        });
        setContactField({
          ...ContactField,
          FullName: "",
          // LastName: "",
          Email: "",
          Contact: "971",
          EmeContact1: "971",
          EmeContact2: "971",
          EmeContact3: "971",
          ContactName1: "",
          ContactName2: "",
          ContactName3: "",
        });
        setContactFieldErr({
          ...ContactFieldErr,
          FullNameErr: "",
          // LastNameErr: "",
          EmailErr: "",
          ContactErr: "",
          EmeContact1Err: "",
          EmeContact2Err: "",
          EmeContact3Err: "",
          ContactName1Err: "",
          ContactName2Err: "",
          ContactName3Err: "",
        });
        setErrorMessage(null);
        setSelectedDate("");
        setField1({
          ...field1,
          premisesNameErr: "",
          businessNameErr: "",
          addressErr: "",
          districtsErr: "",
          makaniNoErr: "",
          PlotNumberErr: "",
          ATEErr: "",
          dateErr: "",
          timeErr: "",
        });
        setOpen4(false);
        setMessages("");
        setEC1(false);
        setEC2(false);
        setEC3(false);
      } else {
        if (
          ContactField.EmeContact1 == ContactField.EmeContact2 &&
          ContactField.EmeContact2 == ContactField.EmeContact3 &&
          ContactField.EmeContact1 == ContactField.EmeContact3
        ) {
          setEC1(true);
          setEC2(true);
          setEC3(true);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact2Err: "Emergency Contact2 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        } else if (ContactField.EmeContact1 == ContactField.EmeContact2) {
          setEC1(true);
          setEC2(true);
          setEC3(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact2Err: "Emergency Contact2 Was Required!!",
          });
        } else if (ContactField.EmeContact2 == ContactField.EmeContact3) {
          setEC2(true);
          setEC3(true);
          setEC1(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact2Err: "Emergency Contact2 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        } else {
          setEC1(true);
          setEC3(true);
          setEC2(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        }
      }
    } else {
      // setOpen4(true);
      // setMessages("Please Fill All The Details.");
      setField1({
        ...field1,
        premisesNameErr: "PremisesName Was Required!!",
        businessNameErr: "BusinessName Was Required!!",
        addressErr: "Address Was Required!!",
        districtsErr: "Districts Was Required!!",
        // makaniNoErr: "MakaniNo Must Be 10 Digits!!",
        PlotNumberErr: "PlotNumber Was Required!!",
        ATEErr: "FirePanel Was Required!!",
        dateErr: "Date Was Required!!",
        timeErr: "Time Was Required!!",
      });
      setContactFieldErr({
        ...ContactFieldErr,
        FullNameErr: "FullName Was Required!!",
        // LastNameErr: "LastName Was Required!!",
        EmailErr: "Email Was Required!!",
        ContactErr: "Contact Was Required!!",
        EmeContact1Err: "Emergency Contact1 Was Required!!",
        EmeContact2Err: "Emergency Contact2 Was Required!!",
        EmeContact3Err: "Emergency Contact3 Was Required!!",
        ContactName1Err: "ContactName1 Was Required!!",
        ContactName2Err: "ContactName2 Was Required!!",
        ContactName3Err: "ContactName3 Was Required!!",
      });
    }
  };

  const handleCallback = (start, end) => {
    setStartDate(moment(start).format("DD/MM/YYYY"));
    setEndDate(moment(end).format("DD/MM/YYYY"));
  };

  const handleTotal = (e) => {
    console.log(e.target.value);
  };

  const SaveOrder = (e) => {
    if (OrderData.length === 0) {
      setOPen2(true);
      setMessage2("Please Fill All Field!!");
    } else {
      for (var i = 0; i < OrderData.length; i++) {
        localStorage.removeItem(OrderData[i].BusinessName);
      }
      console.log("aa", OrderData);
      if (true === e) {
        setOpen3(false);
        // const prices = document.getElementById("prices").value;
        // const taxs = document.getElementById("taxs").value;
        // const deliveryfees = document.getElementById("deliveryfees").value;
        // const installationfees =
        //   document.getElementById("installationfees").value;
        // const total = document.getElementById("total").value;
        var ReminderData = JSON.stringify({
          name: Fname + " " + Lname,
          email: email,
        });
        const priceing = OrderData.reduce((sum, a) => sum + a.Price, 0);
        var order = JSON.stringify({
          Order: OrderData,
          Email: email,
          Price: priceing,
          Tax: 0,
          DeliveryFee: 0,
          InstallationFee: 0,
          Total: priceing,
          Name: Fname + " " + Lname,
          userId: UserId,
          RefNo: refs,
        });
        // console.log(order);
        fetch(ADDORDER, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token + "",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: order,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.Status == true) {
              random.splice(0, random.length);
              setOPen(true);
              setMessage("Request Placed.");
            }
          });
        // fetch(SENDREMINDER, {
        //   method: "POST",
        //   headers: {
        //     Authorization: "Bearer " + token + "",
        //     "Content-Type": "application/json",
        //     "Access-Control-Allow-Origin": "*",
        //   },
        //   body: ReminderData,
        // })
        //   .then((res) => res.json())
        //   .then((res) => console.log(res));
      } else {
        setOpen3(true);
        encryptdata();
        // setTimeout(() => {
        // }, 1000);
      }
    }
  };

  const UpdateOrder = (e) => {
    if (true == e) {
      setOpen3(false);
      random.splice(0, random.length);
      setOPen(true);
      setMessage("Request Placed.");
    } else {
      setOpen3(true);
      Updateref();
      encryptdata();
      // setTimeout(() => {
      // }, 1000);
    }
  };

  const Updateref = () => {
    const dataref = JSON.stringify({
      oldRefNo: OrderData[0].RefNo,
      RefNo: random[0],
    });
    fetch(UPDATEREFORDER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: dataref,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      });
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const OnchangePremisesName = (e) => {
    setField({ ...field, premisesName: e.target.value });
    // var order = JSON.stringify({
    //   PremisesName: e.target.value,
    // });
    // fetch(CHECKPREMISES, {
    //   method: "POST",
    //   headers: {
    //     Authorization: "Bearer " + token + "",
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: order,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.status == true) {
    //       setMessages(res.data);
    //       setOpen4(true);
    //       setValues(true);
    //     } else {
    //       setValues(false);
    //     }
    //   });
  };
  const OnchangeBusinessgName = (e) => {
    setField({ ...field, businessName: e.target.value });
  };
  const OnchangeFloorNumber = (e) => {
    setField({ ...field, floornumber: e.target.value });
  };
  const OnchangeRoomNumber = (e) => {
    setField({ ...field, roomnumber: e.target.value });
  };
  const OnchangeAdress = (e) => {
    setField({ ...field, address: e.target.value });
  };

  const [Ids, setIds] = useState("");

  const updateOrderdb = () => {
    const cuerrent = moment().format();
    const duedate = moment(cuerrent).add(1, "year");
    const insertDate = moment(duedate).format("DD/MM/YYYY");
    const ddd = JSON.parse(localStorage.getItem(field.businessName));
    if (
      field.premisesName !== "" &&
      field.businessName !== "" &&
      field.plotnumber !== "" &&
      field.address !== "" &&
      field.districts !== "" &&
      field.Emirate !== "" &&
      field.ATE !== 0 &&
      startDate !== "" &&
      endDate !== "" &&
      field.time !== "" &&
      ContactField.FullName !== "" &&
      // ContactField.LastName !== "" &&
      ContactField.Email !== "" &&
      Object.keys(ContactField.Contact).length > 3 &&
      Object.keys(ContactField.EmeContact1).length > 3 &&
      Object.keys(ContactField.EmeContact2).length > 3 &&
      Object.keys(ContactField.EmeContact3).length > 3 &&
      ContactField.ContactName1 !== "" &&
      ContactField.ContactName2 !== "" &&
      ContactField.ContactName3 !== "" &&
      emailerror == ""
    ) {
      if (
        ContactField.EmeContact1 != ContactField.EmeContact2 &&
        ContactField.EmeContact2 != ContactField.EmeContact3 &&
        ContactField.EmeContact1 != ContactField.EmeContact3
      ) {
        setErr();
        const OrderDatas = [
          {
            UserId: UserId,
            Name: Fname + " " + Lname,
            BusinessName: field.businessName,
            PremisesName: field.premisesName,
            MakaniNo: field.makaniNo,
            PlotNumber: field.plotnumber,
            ThreeWord: field.threewords,
            Emirate: field.Emirate,
            LandMark: "test",
            Product: "ATE",
            Address: field.address,
            Districts: field.districts,
            ATEs: field.ATE,
            Price: field.ATE * Data[0].ATEPrice,
            SheduleOn: "",
            StartDate: startDate,
            EndDate: endDate,
            DueDate: insertDate,
            Time: field.time,
            FloorNumber: field.floornumber,
            RoomNumber: field.roomnumber,
            FullName: ContactField.FullName,
            // LastName: ContactField.LastName,
            Email: ContactField.Email,
            Contact: ContactField.Contact,
            EmeContact1: ContactField.EmeContact1,
            EmeContact2: ContactField.EmeContact2,
            EmeContact3: ContactField.EmeContact3,
            ContactName1: ContactField.ContactName1,
            ContactName2: ContactField.ContactName2,
            ContactName3: ContactField.ContactName3,
          },
        ];

        var order = JSON.stringify({
          Order: OrderDatas,
          Email: email,
          Price: field.ATE * Data[0].ATEPrice,
          Total: field.ATE * Data[0].ATEPrice,
          userId: UserId,
          Id: Ids,
        });

        fetch(UPDATEORDER, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token + "",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: order,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.Status == true) {
              RefOrder();
            }
          });
        setUpdateOpens(false);
        setField({ ...field, time: "" });
        setStartDate("");
        setEndDate("");
        SetView(true);
        setField({
          ...field,
          premisesName: "",
          businessName: "",
          address: "",
          districts: "",
          makaniNo: "",
          plotnumber: "",
          Emirate: "Ajman",
          threewords: "",
          ATE: 0,
          date: "",
          roomnumber: "",
          floornumber: "",
          time: "",
        });
        setContactField({
          ...ContactField,
          FullName: "",
          // LastName: "",
          Email: "",
          Contact: "971",
          EmeContact1: "971",
          EmeContact2: "971",
          EmeContact3: "971",
          ContactName1: "",
          ContactName2: "",
          ContactName3: "",
        });
        setContactFieldErr({
          ...ContactFieldErr,
          FullNameErr: "",
          // LastNameErr: "",
          EmailErr: "",
          ContactErr: "",
          EmeContact1Err: "",
          EmeContact2Err: "",
          EmeContact3Err: "",
          ContactName1Err: "",
          ContactName2Err: "",
          ContactName3Err: "",
        });
        setErrorMessage(null);
        setSelectedDate("");
        setField1({
          ...field1,
          premisesNameErr: "",
          businessNameErr: "",
          addressErr: "",
          districtsErr: "",
          makaniNoErr: "",
          PlotNumberErr: "",
          ATEErr: "",
          dateErr: "",
          timeErr: "",
        });
        setOpen4(false);
        setMessages("");
        setEC1(false);
        setEC2(false);
        setEC3(false);
      } else {
        if (
          ContactField.EmeContact1 == ContactField.EmeContact2 &&
          ContactField.EmeContact2 == ContactField.EmeContact3 &&
          ContactField.EmeContact1 == ContactField.EmeContact3
        ) {
          setEC1(true);
          setEC2(true);
          setEC3(true);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact2Err: "Emergency Contact2 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        } else if (ContactField.EmeContact1 == ContactField.EmeContact2) {
          setEC1(true);
          setEC2(true);
          setEC3(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact2Err: "Emergency Contact2 Was Required!!",
          });
        } else if (ContactField.EmeContact2 == ContactField.EmeContact3) {
          setEC2(true);
          setEC3(true);
          setEC1(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact2Err: "Emergency Contact2 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        } else {
          setEC1(true);
          setEC3(true);
          setEC2(false);
          setContactFieldErr({
            ...ContactFieldErr,
            EmeContact1Err: "Emergency Contact1 Was Required!!",
            EmeContact3Err: "Emergency Contact3 Was Required!!",
          });
        }
      }
    } else {
      // setOpen4(true);
      // setMessages("Please Fill All The Details.");
      setField1({
        ...field1,
        premisesNameErr: "PremisesName Was Required!!",
        businessNameErr: "BusinessName Was Required!!",
        addressErr: "Address Was Required!!",
        districtsErr: "Districts Was Required!!",
        // makaniNoErr: "MakaniNo Must Be 10 Digits!!",
        PlotNumberErr: "PlotNumber Was Required!!",
        ATEErr: "FirePanel Was Required!!",
        dateErr: "Date Was Required!!",
        timeErr: "Time Was Required!!",
      });
      setContactFieldErr({
        ...ContactFieldErr,
        FullNameErr: "FullName Was Required!!",
        // LastNameErr: "LastName Was Required!!",
        EmailErr: "Email Was Required!!",
        ContactErr: "Contact Was Required!!",
        EmeContact1Err: "Emergency Contact1 Was Required!!",
        EmeContact2Err: "Emergency Contact2 Was Required!!",
        EmeContact3Err: "Emergency Contact3 Was Required!!",
        ContactName1Err: "ContactName1 Was Required!!",
        ContactName2Err: "ContactName2 Was Required!!",
        ContactName3Err: "ContactName3 Was Required!!",
      });
    }
  };

  // console.log("OrderData: ", OrderData);
  // console.log("display data:", displays);

  const UpdateInsertOrder = (e) => {
    if (true == e) {
      setOpen3(false);
      const refnodata = data[4].toString();
      const priceing = OrderData.reduce((sum, a) => sum + a.Price, 0);
      var order = JSON.stringify({
        Order: OrderData,
        Email: email,
        Price: priceing,
        Tax: 0,
        DeliveryFee: 0,
        InstallationFee: 0,
        Total: priceing,
        Name: Fname + " " + Lname,
        userId: UserId,
        RefNo: random[0],
        OrderId: refnodata,
      });
      fetch(UPDATEINSERTORDER, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: order,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status == true) {
            random.splice(0, random.length);
            setOPen(true);
            setMessage("Request Placed.");
          }
        });
    } else {
      setOpen3(true);
      Updateref();
      encryptdata();
      // setTimeout(() => {
      // }, 1000);
    }
  };
  const [err, setErr] = useState();
  const onChangeATE = (e) => {
    setField({ ...field, ATE: e.target.value });
  };

  const OnchangeThreeWords = (e) => {
    const abc = e.target.value.substr(2);
    setField({
      ...field,
      threewords: "https://what3words.com" + abc,
    });
  };
  const OnchangeTime = (e) => {
    setField({ ...field, time: e.target.value });
  };
  const OnchangeDistricts = (e) => {
    setField({ ...field, districts: e });
  };

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWALLTIME, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setTime(responseJson.data);
      });
  };

  const RemoveOrder = (e, index) => {
    var order = JSON.stringify({
      Id: e,
    });

    fetch(DELETEORDER, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: order,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          // RefOrder();
          SetView(true);
          if (index !== -1) {
            OrderData.splice(index, 1);
          }
          if (OrderData.length == 0) {
            console.log(OrderData);
            history.push(DASHBOARDCONTROLLER);
          }
        }
      });
  };
  // const options = [];
  // for (var i = 0; i < Time.length; i++) {
  //   options.push({ value: Time[i].Time, label: Time[i].Time });
  // }
  // console.log(options);
  // const option1 = [
  //   { value: "Business in Charge", label: "Business in Charge",name:"position"},
  //   { value: "FM Company", label: "FM Company",name:"position" },
  //   { value: "PRO", label: "PRO",name:"position"},
  //   { value: "Representative", label: "Representative",name:"position" },
  //   { value: "Security", label: "Security",name:"position" },
  // ];

  const mapp = () => {
    window.open("https://what3words.com/toddler.geologist.animated", "_blank");
  };

  const renderOptions = () => {
    return Time.map((dt, i) => {
      return (
        <MenuItem label={dt.Time} value={dt.Time} key={i} name="position">
          {dt.Time}
        </MenuItem>
      );
    });
  };

  const [valuesearch, setValueSearch] = useState("");
  const [updateopens, setUpdateOpens] = useState(false);
  const [probut, setProbut] = useState(false);
  const [okbut, setOkbut] = useState(false);

  const optionssearch = [
    {
      name: "Al Awsat (middle-east)",
      value: "Al Awsat (middle-east)",
    },
    {
      name: "Al Aliyah",
      value: "Al Aliyah",
    },
    {
      name: "Al Hamaidiyah 1",
      value: "Al Hamaidiyah 1",
    },
    {
      name: "Al Hamaidiyah 2",
      value: "Al Hamaidiyah 2",
    },
    {
      name: "Al Jurf 1",
      value: "Al Jurf 1",
    },
    {
      name: "Al Jurf 2",
      value: "Al Jurf 2",
    },
    {
      name: "Al Raqayib 1",
      value: "Al Raqayib 1",
    },
    {
      name: "Al Janubi (southern)",
      value: "Al Janubi (southern)",
    },
    {
      name: "Ajman Industrial Area 1",
      value: "Ajman Industrial Area 1",
    },
    {
      name: "Ajman Industrial Area 2",
      value: "Ajman Industrial Area 2",
    },
    {
      name: "Al Muwaihat 1",
      value: "Al Muwaihat 1",
    },
    {
      name: "Al Muwaihat 2",
      value: "Al Muwaihat 2",
    },
    {
      name: "Al Muwaihat 3",
      value: "Al Muwaihat 3",
    },
    {
      name: "Al Rawdha 1",
      value: "Al Rawdha 1",
    },
    {
      name: "Al Rawdha 2",
      value: "Al Rawdha 2",
    },
    {
      name: "Al Rawdha 3",
      value: "Al Rawdha 3",
    },
    {
      name: "Al Talah 1",
      value: "Al Talah 1",
    },
    {
      name: "Al Talah 2",
      value: "Al Talah 2",
    },
    {
      name: "Mohd. Bin Zayed City 1",
      value: "Mohd. Bin Zayed City 1",
    },
    {
      name: "Mohd. Bin Zayed City 2",
      value: "Mohd. Bin Zayed City 2",
    },
    {
      name: "Al Manamah",
      value: "Al Manamah",
    },
    {
      name: "Al Manamah 1",
      value: "Al Manamah 1",
    },
    {
      name: "Al Manamah 2",
      value: "Al Manamah 2",
    },
    {
      name: "Al Manamah 3",
      value: "Al Manamah 3",
    },
    {
      name: "Al Manamah 4",
      value: "Al Manamah 4",
    },
    {
      name: "Al Manamah 5",
      value: "Al Manamah 5",
    },
    {
      name: "Al Manamah 6",
      value: "Al Manamah 6",
    },
    {
      name: "Al Manamah 7",
      value: "Al Manamah 7",
    },
    {
      name: "Al Manamah 8",
      value: "Al Manamah 8",
    },
    {
      name: "Al Manamah 9",
      value: "Al Manamah 9",
    },
    {
      name: "Al Manamah 10",
      value: "Al Manamah 10",
    },
    {
      name: "Al Manamah 11",
      value: "Al Manamah 11",
    },
    {
      name: "Al Manamah 12",
      value: "Al Manamah 12",
    },
    {
      name: "Al Manamah 13",
      value: "Al Manamah 13",
    },
    {
      name: "Al Manamah 14",
      value: "Al Manamah 14",
    },
    {
      name: "Al Manamah 15",
      value: "Al Manamah 15",
    },
    {
      name: "Al Manamah 16",
      value: "Al Manamah 16",
    },
    {
      name: "Al Manamah 17",
      value: "Al Manamah 17",
    },
    {
      name: "Ash Shamali (northern)",
      value: "Ash Shamali (northern)",
    },
    {
      name: "Al Bahyah",
      value: "Al Bahyah",
    },
    {
      name: "Al Jurf Industrial 1",
      value: "Al Jurf Industrial 1",
    },
    {
      name: "Al Jurf Industrial 2",
      value: "Al Jurf Industrial 2",
    },
    {
      name: "Al Jurf Industrial 3",
      value: "Al Jurf Industrial 3",
    },
    {
      name: "Ash Sharqi (eastern)",
      value: "Ash Sharqi (eastern)",
    },
    {
      name: "Al Amrah",
      value: "Al Amrah",
    },
    {
      name: "Al Hiliw 1",
      value: "Al Hiliw 1",
    },
    {
      name: "Al Hiliw 2",
      value: "Al Hiliw 2",
    },
    {
      name: "Al Yasmin",
      value: "Al Yasmin",
    },
    {
      name: "Al Zahyah",
      value: "Al Zahyah",
    },
    {
      name: "Az Zura",
      value: "Az Zura",
    },
    {
      name: "Markaz Al Madinat (city center)",
      value: "Markaz Al Madinat (city center)",
    },
    {
      name: "An Nakhil 1",
      value: "An Nakhil 1",
    },
    {
      name: "An Nakhil 2",
      value: "An Nakhil 2",
    },
    {
      name: "An Nu’aimiyah 1",
      value: "An Nu’aimiyah 1",
    },
    {
      name: "An Nu’aimiyah 2",
      value: "An Nu’aimiyah 2",
    },
    {
      name: "An Nu’aimiyah 3",
      value: "An Nu’aimiyah 3",
    },
    {
      name: "Ar Rashidiyah 1",
      value: "Ar Rashidiyah 1",
    },
    {
      name: "Ar Rashidiyah 2",
      value: "Ar Rashidiyah 2",
    },
    {
      name: "Ar Rashidiyah 3",
      value: "Ar Rashidiyah 3",
    },
    {
      name: "Ar Rumailah 1",
      value: "Ar Rumailah 1",
    },
    {
      name: "Ar Rumailah 2",
      value: "Ar Rumailah 2",
    },
    {
      name: "Ar Rumailah 3",
      value: "Ar Rumailah 3",
    },
    {
      name: "As Safia",
      value: "As Safia",
    },
    {
      name: "Liwarah 1",
      value: "Liwarah 1",
    },
    {
      name: "Liwarah 2",
      value: "Liwarah 2",
    },
    {
      name: "Mushairif",
      value: "Mushairif",
    },
    {
      name: "Masfut",
      value: "Masfut",
    },
    {
      name: "Masfut 1",
      value: "Masfut 1",
    },
    {
      name: "Masfut 2",
      value: "Masfut 2",
    },
    {
      name: "Masfut 3",
      value: "Masfut 3",
    },
    {
      name: "Masfut 4",
      value: "Masfut 4",
    },
    {
      name: "Masfut 5",
      value: "Masfut 5",
    },
    {
      name: "Masfut 6",
      value: "Masfut 6",
    },
    {
      name: "Masfut 7",
      value: "Masfut 7",
    },
    {
      name: "Masfut 8",
      value: "Masfut 8",
    },
    {
      name: "Masfut 9",
      value: "Masfut 9",
    },
    {
      name: "Masfut 10",
      value: "Masfut 10",
    },
    {
      name: "Masfut 11",
      value: "Masfut 11",
    },
    {
      name: "Masfut 12",
      value: "Masfut 12",
    },
    {
      name: "Masfut 13",
      value: "Masfut 13",
    },
    {
      name: "Masfut 14",
      value: "Masfut 14",
    },
    {
      name: "Masfut 15",
      value: "Masfut 15",
    },
  ];

  const handleClicks = () => {
    setEncrypted("");
    // random.push(makeid());
  };

  const [refs, setrefs] = useState("");
  const encryptdata = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append(
      "price",
      // OrderData.reduce((sum, a) => sum + a.Price, 0)
      0
    );
    // urlencoded.append("dbprice", Data[0].ATEPrice);
    urlencoded.append(
      "ates",
      OrderData.reduce((sum, a) => sum + JSON.parse(a.ATEs), 0)
    );
    urlencoded.append("email", email);
    urlencoded.append("contact", Conatctno);
    urlencoded.append("name", Fname + " " + Lname);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`${Domain}/encrypt`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result != false) {
          setEncrypted(result.data);
          setPMerchant(result.merchant);
          setrefs(result.refno);
        }
      })
      .catch((error) => console.log("error", error));
  };

  function makeid() {
    // if (allOrder.length > 0) {
    //   // return JSON.parse(allOrder[0].RefNo) + 1;
    //   const maxss = allOrder.reduce(function (prev, current) {
    //     return prev.RefNo > current.RefNo ? prev : current;
    //   });
    //   console.log(maxss);
    //   return JSON.parse(maxss.RefNo) + 1;
    // } else {
    //   return 11051;
    // }
    // var result = "";
    // var characters =
    //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    // var charactersLength = characters.length;
    // for (var i = 0; i < length; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    // return result;
  }
  const imageRef = useRef();
  return loader && urldata.length > 4 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <span
        style={{
          fontSize: "20px",
        }}
      >
        Loading...
      </span>
      <PulseLoader color={"#003e6a"} />
    </div>
  ) : (
    <Container
      maxWidth="xl"
      className={classes.MainContainer}
      style={{
        marginTop:
          data.length > 4
            ? OrderData.length > 0
              ? OrderData[0].ErrorMsg == "SUCCESS"
                ? "0rem"
                : OrderData[0].ErrorMsg != "SUCCESS" &&
                  OrderData[0].ErrorMsg != ""
                ? "0rem"
                : "2.5rem"
              : ""
            : "2.5rem",
      }}
    >
      <div
        style={{
          display:
            data.length > 4
              ? OrderData.length > 0
                ? OrderData[0].ErrorMsg == "SUCCESS"
                  ? "none"
                  : OrderData[0].ErrorMsg != "SUCCESS" &&
                    OrderData[0].ErrorMsg != ""
                  ? "none"
                  : ""
                : ""
              : "",
        }}
      >
        <h2 className={classes.lableSpan}>{t("premises_detail")}</h2>
        <div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                <h3 className={classes.MainLabel}>{t("premises_info")}</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("bussines_name")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Business Name"
                      value={field.businessName}
                      onChange={OnchangeBusinessgName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.businessName === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.businessNameErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("premises_name")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Premises Name"
                      value={field.premisesName}
                      onChange={OnchangePremisesName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.premisesName === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.premisesNameErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("floor_number")}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="FloorNumber"
                      value={field.floornumber}
                      onChange={OnchangeFloorNumber}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("room_number")}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="RoomNumber"
                      value={field.roomnumber}
                      onChange={OnchangeRoomNumber}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("emirate")} <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      name="Emirate"
                      style={{ userSelect: "none" }}
                      value={field.Emirate}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {/* <button
                      style={{
                        userSelect: "none",
                        borderRadius: "5px",
                        border: "1px solid",
                        height: "40px",
                        background: "white",
                        textAlign: "left",
                      }}
                      disabled="true"
                      className={classes.notchedOutline}
                    >
                      {field.Emirate}
                    </button> */}
                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{
                        height: "40px",
                        border: "1px solid",
                        borderColor: ThemeColor,
                        borderRadius: "5px",
                      }}
                      onChange={OnchangeEmirate}
                    >
                      <MenuItem value="Abu Dhabi">Abu Dhabi</MenuItem>
                      <MenuItem value="Dubai">Dubai</MenuItem>
                      <MenuItem value="Sharjah">Sharjah</MenuItem>
                      <MenuItem value="Ajman">Ajman</MenuItem>
                      <MenuItem value="Umm Al Quwain">Umm Al Quwain</MenuItem>
                      <MenuItem value="Fujairah">Fujairah</MenuItem>
                      <MenuItem value="Ras Al Khaimah">Ras Al Khaimah</MenuItem>
                    </Select> */}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("districts")} <span style={{ color: "red" }}>*</span>
                    </label>
                    <SelectSearch
                      options={optionssearch}
                      value={field.districts}
                      onChange={OnchangeDistricts}
                      search
                      filterOptions={fuzzySearch}
                      placeholder="Search City"
                      emptyMessage="Not found"
                    />
                    {field.districts === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.districtsErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Districts</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Districts"
                      value={field.districts}
                      onChange={OnchangeDistricts}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.districts === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.districtsErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("address")} <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Address"
                      value={field.address}
                      onChange={OnchangeAdress}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.address === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.addressErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        {t("makani_no")} &nbsp; &nbsp;
                        <Tippy
                          theme="light"
                          placement="top"
                          content={t("makani_descr")}
                        >
                          <HelpIcon
                            style={{
                              color: ThemeColor,
                              fontSize: "20px",
                            }}
                          />
                        </Tippy>
                      </div>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="MakaniNo"
                      value={field.makaniNo}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setField({ ...field, makaniNo: e.target.value });
                        if (e.target.value.length < 10) {
                          setField1({
                            ...field1,
                            makaniNoErr: "MakaniNo Must Be 10 Digits!!",
                          });
                        }
                      }}
                      inputProps={{ maxLength: 10, minLength: 10 }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.makaniNo.length !== 10 ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.makaniNoErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      3Words(optional)
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="ThreeWords"
                      value={field.threewords}
                      onChange={OnchangeThreeWords}
                      // onChange={validate}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                          <div>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={mapp}
                                style={{ padding: "0px", marginLeft: "10px" }}
                              >
                                <TravelExploreIcon />
                              </IconButton>
                            </InputAdornment>
                          </div>
                        ),
                      }}
                      style={{ width: "223px" }}
                    />
                  </div> */}
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("qty_of_fire_panel")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Contact"
                      value={field.ATE}
                      // onChange={onChangeATE}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setField({ ...field, ATE: e.target.value });
                      }}
                      inputProps={{ maxLength: 4, minLength: 1 }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.ATE === 0 ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.ATEErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("plot_number")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="plotnumber"
                      value={field.plotnumber}
                      // onChange={onChangeATE}
                      onChange={(e) => {
                        setField({ ...field, plotnumber: e.target.value });
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {field.plotnumber == "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.PlotNumberErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Owner FullName
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="FullName"
                      onChange={OnchangeFullName}
                      // onChange={(e) => {
                      //   const re = /^[A-Za-z]+$/;
                      //   if (e.target.value === "" || re.test(e.target.value))
                      //     setContactField({
                      //       ...ContactField,
                      //       FullName: e.target.value,
                      //     });
                      // }}
                      value={ContactField.FullName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {ContactField.FullName === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.FullNameErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("owner_lastname")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="LastName"
                      // onChange={OnchangeLastName}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setContactField({
                            ...ContactField,
                            LastName: e.target.value,
                          });
                      }}
                      value={ContactField.LastName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {ContactField.LastName === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.LastNameErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("owner_email")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Address"
                      // onChange={OnchangeEmail}
                      onChange={(e) => {
                        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                        if (re.test(e.target.value)) {
                          setContactField({
                            ...ContactField,
                            Email: e.target.value,
                          });
                          setEmailError("");
                        } else {
                          setContactField({
                            ...ContactField,
                            Email: e.target.value,
                          });
                          setEmailError("Please Enter Valid Email");
                        }
                      }}
                      value={ContactField.Email}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {ContactField.Email === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.EmailErr}
                      </span>
                    ) : (
                      ""
                    )}
                    {emailerror == "" ? (
                      ""
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {emailerror}
                      </div>
                    )}
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("owner_contact_number")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactPhoneInput
                      country={"ae"}
                      onChange={OnchangeContact}
                      value={ContactField.Contact}
                    />
                    {Object.keys(ContactField.Contact).length < 4 ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.ContactErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={classes.mainDiv2}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("contact_name1")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="ContactName3"
                      // onChange={OnchangeContactName1}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setContactField({
                            ...ContactField,
                            ContactName1: e.target.value,
                          });
                      }}
                      value={ContactField.ContactName1}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {ContactField.ContactName1 === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.ContactName1Err}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("emergency_number1")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactPhoneInput
                      country={"ae"}
                      onChange={OnchangeEmeContact1}
                      value={ContactField.EmeContact1}
                    />
                    {Object.keys(ContactField.EmeContact1).length < 4 ? (
                      <span
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        {ContactFieldErr.EmeContact1Err}
                      </span>
                    ) : (
                      ""
                    )}
                    {ec1 ? (
                      <span
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        {ContactFieldErr.EmeContact1Err}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("contact_name2")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="ContactName3"
                      // onChange={OnchangeContactName2}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setContactField({
                            ...ContactField,
                            ContactName2: e.target.value,
                          });
                      }}
                      value={ContactField.ContactName2}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {ContactField.ContactName2 === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.ContactName2Err}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("emergency_number2")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactPhoneInput
                      country={"ae"}
                      onChange={OnchangeEmeContact2}
                      value={ContactField.EmeContact2}
                    />
                    {Object.keys(ContactField.EmeContact2).length < 4 ? (
                      <span
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        {ContactFieldErr.EmeContact2Err}
                      </span>
                    ) : (
                      ""
                    )}
                    {ec2 ? (
                      <span
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        {ContactFieldErr.EmeContact2Err}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("contact_name3")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="ContactName3"
                      value={ContactField.ContactName3}
                      // onChange={OnchangeContactName3}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setContactField({
                            ...ContactField,
                            ContactName3: e.target.value,
                          });
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {ContactField.ContactName3 === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {ContactFieldErr.ContactName3Err}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("emergency_number3")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactPhoneInput
                      country={"ae"}
                      onChange={OnchangeEmeContact3}
                      value={ContactField.EmeContact3}
                    />
                    {Object.keys(ContactField.EmeContact3).length < 4 ? (
                      <span
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        {ContactFieldErr.EmeContact3Err}
                      </span>
                    ) : (
                      ""
                    )}
                    {ec3 ? (
                      <span
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "13px",
                        }}
                      >
                        {ContactFieldErr.EmeContact3Err}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                <h3 className={classes.MainLabel}>
                  {t("survey_schedule_info")}
                </h3>
                <div className={classes.mainDiv2}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("select_date_range")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <DateRangePicker
                      initialSettings={{
                        // minDate: moment().format("DD/MM/YYYY"),
                        minDate: moment(moment(), "DD/MM/YYYY").add(10, "days"),
                        maxDate: moment(moment(), "DD/MM/YYYY").add(39, "days"),
                        dateLimit: { days: 9 },
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                      }}
                      onCallback={handleCallback}
                    >
                      <input
                        style={{
                          height: 30,
                          borderRadius: 8,
                          border: "0.5px solid grey",
                        }}
                      />
                    </DateRangePicker>
                    {startDate === "" && endDate === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.dateErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.FieldDiv} style={{ width: "250px" }}>
                    <label className={classes.textLabel}>
                      {t("select_time")} <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <Select options={options} onChange={OnchangeTime} /> */}
                    <Select
                      name="position"
                      value={field.time}
                      onChange={OnchangeTime}
                    >
                      {renderOptions()}
                    </Select>
                    {field.time === "" ? (
                      <span style={{ color: "red", textAlign: "left" }}>
                        {field1.timeErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.AddBtnDiv}>
                {OrderData.length > 0 ? (
                  <Button
                    onClick={() => {
                      if (updateopens) {
                        if (data.length > 4 && Ids != undefined) {
                          setValueSearch("");
                          updateOrderdb();
                        } else {
                          UpdateOrderData();
                          setValueSearch("");
                        }
                      } else {
                        AddOrder();
                      }
                    }}
                  >
                    {t("add_premises_survey")}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (updateopens) {
                        UpdateOrderData();
                        setValueSearch("");
                      } else {
                        AddOrder();
                      }
                    }}
                  >
                    {t("save")}
                  </Button>
                )}
                <Button
                  // onClick={history.goBack}
                  onClick={() => {
                    history.push(DASHBOARDCONTROLLER);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop:
            data.length > 4
              ? OrderData.length > 0
                ? OrderData[0].ErrorMsg == "SUCCESS"
                  ? "2.5rem"
                  : OrderData[0].ErrorMsg != "SUCCESS" &&
                    OrderData[0].ErrorMsg != ""
                  ? "2.5rem"
                  : ""
                : ""
              : "0rem",
        }}
      >
        <h2 className={classes.lableSpan1}>{t("order_summury")}</h2>
        <div className={classes.root2}>
          <Paper elevation={3}>
            {OrderData.length > 0 ? (
              OrderData.map((data, index) => {
                return valuesearch == data.Email ? (
                  <div className={classes.AddPremises} key={index}>
                    <Paper
                      elevation={3}
                      style={{
                        background: "#f0f0f0fa",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "17px",
                        }}
                      >
                        <span>{data.BusinessName}</span>
                        {Data.length > 0 ? (
                          <span>
                            {t("ate_price")} : {data.ATEs * Data[0].ATEPrice}
                          </span>
                        ) : null}

                        <p style={{ textAlign: "left" }}>
                          {t("scheduled_on")} : {data.Time}
                          <br />
                          {t("date_range")} :{" "}
                          {data.StartDate + " " + data.EndDate}
                        </p>
                        <p style={{ textAlign: "left" }}>
                          {data.ErrorMsg != undefined &&
                          data.ErrorMsg != "" &&
                          data.ErrorMsg != "SUCCESS" ? (
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              {t("payment_failed")} &nbsp;{data.ErrorMsg}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                      {/* <div>
                        <Button
                          onClick={() => {
                            UpdateData(data);
                          }}
                        >
                          Edit
                        </Button>
                      </div> */}
                      {urldata.length > 4 ? (
                        OrderData.length > 0 ? (
                          OrderData[0].ErrorMsg == "SUCCESS" ? (
                            <div
                              style={{
                                background: "#008000bd",
                                color: "white",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              {t("order_placed")}
                            </div>
                          ) : OrderData[0].ErrorMsg != "SUCCESS" &&
                            OrderData[0].ErrorMsg != "" ? (
                            <div
                              style={{
                                background: "red",
                                color: "white",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              {t("order_failed")}
                            </div>
                          ) : (
                            <div>
                              <Button
                                onClick={() => {
                                  UpdateData(data, index);
                                  setValueSearch(data.Email);
                                }}
                              >
                                {t("edit")}
                              </Button>
                              <Button
                                onClick={() => {
                                  if (urldata.length > 4) {
                                    if (data.Id != undefined) {
                                      RemoveOrder(data.Id, index);
                                    } else {
                                      RemoveData(index);
                                    }
                                  } else {
                                    RemoveData(index);
                                  }
                                }}
                              >
                                {t("remove")}
                              </Button>
                            </div>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <div>
                          <Button
                            onClick={() => {
                              UpdateData(data, index);
                              setValueSearch(data.Email);
                            }}
                          >
                            {t("edit")}
                          </Button>
                          <Button
                            onClick={() => {
                              if (urldata.length > 4) {
                                if (data.Id != undefined) {
                                  RemoveOrder(data.Id);
                                } else {
                                  RemoveData(index);
                                }
                              } else {
                                RemoveData(index);
                              }
                            }}
                          >
                            {t("remove")}
                          </Button>
                        </div>
                      )}
                    </Paper>
                  </div>
                ) : (
                  <div className={classes.AddPremises} key={index}>
                    <Paper elevation={3}>
                      <div
                        style={{
                          marginTop: "17px",
                        }}
                      >
                        <span>{data.BusinessName}</span>
                        {Data.length > 0 ? (
                          <span>
                            {t("ate_price")} : {data.ATEs * Data[0].ATEPrice}
                          </span>
                        ) : null}

                        <p style={{ textAlign: "left" }}>
                          {t("scheduled_on")} : {data.Time}
                          <br />
                          {t("date_range")} :{" "}
                          {data.StartDate + " " + data.EndDate}
                        </p>
                        <p style={{ textAlign: "left" }}>
                          {data.ErrorMsg != undefined &&
                          data.ErrorMsg != "" &&
                          data.ErrorMsg != "SUCCESS" ? (
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              {t("payment_failed")} &nbsp;{data.ErrorMsg}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                      {/* <div>
                        <Button
                          onClick={() => {
                            UpdateData(data);
                          }}
                        >
                          Edit
                        </Button>
                      </div> */}
                      {urldata.length > 4 ? (
                        OrderData.length > 0 ? (
                          OrderData[0].ErrorMsg == "SUCCESS" ? (
                            <div
                              style={{
                                background: "#32CD32",
                                color: "white",
                                padding: "5px",
                                borderRadius: "10px",
                              }}
                            >
                              {t("order_placed")}
                            </div>
                          ) : OrderData[0].ErrorMsg != "SUCCESS" &&
                            OrderData[0].ErrorMsg != "" ? (
                            <div
                              style={{
                                background: "red",
                                color: "white",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              {t("order_failed")}
                            </div>
                          ) : (
                            <div>
                              <Button
                                onClick={() => {
                                  UpdateData(data);
                                  setValueSearch(data.Email);
                                }}
                              >
                                {t("edit")}
                              </Button>
                              <Button
                                onClick={() => {
                                  if (urldata.length > 4) {
                                    if (data.Id != undefined) {
                                      RemoveOrder(data.Id);
                                    } else {
                                      RemoveData(index);
                                    }
                                  } else {
                                    RemoveData(index);
                                  }
                                }}
                              >
                                {t("remove")}
                              </Button>
                            </div>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <div>
                          <Button
                            onClick={() => {
                              UpdateData(data);
                              setValueSearch(data.Email);
                            }}
                          >
                            {t("edit")}
                          </Button>
                          <Button
                            onClick={() => {
                              if (urldata.length > 4) {
                                if (data.Id != undefined) {
                                  RemoveOrder(data.Id);
                                } else {
                                  RemoveData(index);
                                }
                              } else {
                                RemoveData(index);
                              }
                            }}
                          >
                            {t("remove")}
                          </Button>
                        </div>
                      )}
                    </Paper>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 275,
                }}
              >
                <h2 style={{ color: "#80808063" }}>{t("no_order_found")}</h2>
              </div>
            )}
            {}
          </Paper>
        </div>
        <div className={classes.root3}>
          <Paper elevation={3}>
            <div className={classes.checkOut}>
              <div>
                <span>{t("qty")}</span>
                {/* <span>Total Amount</span>
                <span>Tax</span>
                <span>Delivery Fee</span>
                <span>Installation Fee</span> */}
              </div>
              <div>
                <span>
                  {OrderData.reduce((sum, a) => sum + JSON.parse(a.ATEs), 0)}{" "}
                  {t("ate_firepanel")}
                </span>
                {/* {Data.length > 0 ? (
                  <>
                    <span style={{ display: "none" }}>
                      ${" "}
                      {OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].ATEPrice}
                    </span>
                    <input
                      style={{ display: "none" }}
                      id="prices"
                      value={
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].ATEPrice
                      }
                    />
                    <span style={{ display: "none" }}>
                      ${" "}
                      {OrderData.reduce((a, v) => a + v.ATEs, 0) * Data[0].Tax}
                    </span>
                    <input
                      style={{ display: "none" }}
                      id="taxs"
                      value={
                        OrderData.reduce((a, v) => a + v.ATEs, 0) * Data[0].Tax
                      }
                    />
                    <span style={{ display: "none" }}>
                      ${" "}
                      {OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].DeliveryFee}
                    </span>
                    <input
                      style={{ display: "none" }}
                      id="deliveryfees"
                      value={
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].DeliveryFee
                      }
                    />
                    <span style={{ display: "none" }}>
                      ${" "}
                      {OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].InstallationFee}
                    </span>
                    <input
                      style={{ display: "none" }}
                      id="installationfees"
                      value={
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].InstallationFee
                      }
                    />
                  </>
                ) : (
                  <>
                    <span style={{ display: "none" }}>$ 00.00</span>
                    <span style={{ display: "none" }}>$ 00.00</span>
                    <span style={{ display: "none" }}>$ 00.00</span>
                    <span style={{ display: "none" }}>$ 00.00</span>
                  </>
                )} */}
              </div>
            </div>
            <div className={classes.checkOut2}>
              <div>
                <span>{t("payable_ammount")}</span>
              </div>
              <div>
                {Data.length > 0 && loader == false ? (
                  <>
                    <span>
                      AED &nbsp;
                      {/* {OrderData.reduce((a, v) => a + v.ATEs, 0) *
                        Data[0].ATEPrice +
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].Tax +
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].DeliveryFee +
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].InstallationFee} */}
                      {OrderData.reduce((sum, a) => sum + a.Price, 0)}
                    </span>
                    {/* <input
                      style={{ display: "none" }}
                      id="total"
                      value={
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].ATEPrice +
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].Tax +
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].DeliveryFee +
                        OrderData.reduce((a, v) => a + v.ATEs, 0) *
                          Data[0].InstallationFee
                      }
                    /> */}
                  </>
                ) : (
                  <span>AED 0.00</span>
                )}
              </div>
            </div>
          </Paper>
        </div>
        <form
          method="POST"
          action="https://www.ajmanpay.gov.ae/ajmanpay/IntegrationPaymentPage?"
          style={{
            display: "none",
          }}
        >
          payOrder:
          <input name="payOrder" defaultValue={encrypted} />
          <br />
          P_Merchant: <input name="P_Merchant" defaultValue={pmerchant} />
          <br />
          P_Return_URL:
          <input name="P_Return_URL" defaultValue={preturn} />
          <br />
          <button type="submit" ref={imageRef}>
            Pay
          </button>
        </form>
        <Button
          className={classes.CheckoutBtn}
          disabled={probut ? true : false}
          onClick={() => {
            setProbut(true);
            handleClicks();
            if (data.length > 4) {
              if (OrderData.length > 0) {
                if (OrderData[0].ErrorMsg == "SUCCESS") {
                  history.push(DASHBOARDCONTROLLER);
                } else if (
                  OrderData[0].ErrorMsg != "SUCCESS" &&
                  OrderData[0].ErrorMsg != ""
                ) {
                  history.push(DASHBOARDCONTROLLER);
                } else {
                  if (displays.length > 0) {
                    UpdateInsertOrder();
                  } else {
                    UpdateOrder();
                  }
                }
              } else {
                SaveOrder();
              }
            } else {
              SaveOrder();
            }
          }}
        >
          {data.length > 4
            ? OrderData.length > 0
              ? OrderData[0].ErrorMsg == "SUCCESS"
                ? t("return_to_home_page")
                : OrderData[0].ErrorMsg != "SUCCESS" &&
                  OrderData[0].ErrorMsg != ""
                ? t("return_to_home_page")
                : t("proceed_to_payment")
              : t("proceed_to_checkout")
            : t("proceed_to_checkout")}
        </Button>
      </div>
      <Sucessmodal
        open={open}
        message={msg}
        close={() => {
          // history.push(DASHBOARDCONTROLLER);
          if (encrypted !== "") {
            imageRef.current.click();
          }
        }}
      />
      <ErrorModal
        open={open2}
        message={msg2}
        close={() => {
          setOPen2(false);
          setProbut(false);
        }}
      />
      <ErrorModal
        open={open4}
        message={messages}
        close={() => {
          if (Values) {
            setValues(false);
            history.push(DASHBOARDCONTROLLER);
          } else {
            setOpen4(false);
            setProbut(false);
          }
        }}
      />
      <MyModal
        open={open3}
        close={() => {
          setOpen3(false);
          setProbut(false);
        }}
        title={t("are_u_sure_model")}
        class="ReshedulModal"
        MyClose={() => {
          setOpen3(false);
          setProbut(false);
        }}
      >
        <div
          style={{
            color: "black",
            fontWeight: 600,
            display: "flex",
            paddingBottom: "2rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t("yes_confirm")}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            color: "black",
            display: "flex",
            alignItems: "right",
            justifyContent: "flex-end",
          }}
        >
          <Button
            // disabled={encrypted != "" ? false : true}
            disabled={encrypted != "" ? (okbut ? true : false) : true}
            style={{
              marginRight: "0px",
              backgroundColor: ThemeColor,
              color: "white",
              opacity: encrypted != "" ? (okbut ? 0.7 : 1) : 0.7,
            }}
            onClick={() => {
              if (value === "yes") {
                if (data.length > 4) {
                  if (displays.length > 0) {
                    if (encrypted !== "") {
                      UpdateInsertOrder(true);
                    }
                  } else {
                    if (encrypted !== "") {
                      UpdateOrder(true);
                    }
                  }
                } else {
                  if (encrypted !== "") {
                    SaveOrder(true);
                  }
                }
                setOkbut(true);
              } else {
                alert("please check the radio button");
              }
            }}
          >
            {t("ok")}
          </Button>
          <Button
            disabled={encrypted != "" ? false : true}
            style={{
              marginRight: lang == "ar" ? "15px" : "0px",
              backgroundColor: ThemeColor,
              color: "white",
              marginLeft: lang == "ar" ? "0px" : "15px",
              opacity: encrypted != "" ? 1 : 0.7,
            }}
            onClick={() => {
              setOpen3(false);
              setProbut(false);
              setValue("");
              setOkbut(false);
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </MyModal>
    </Container>
  );
};
export default Premises;
