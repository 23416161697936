import React from "react";
import Profile from "../../Page/ProtectedPage/User/Profile/index";
import EngineerProfile from "../../Page/ProtectedPage/Engineer/Profile";
import AdminProfile from "../../Page/ProtectedPage/SuperAdmin/Profile";
import {RoleUser,RoleEngineer,SuperAdmin} from '../../ConstVariable/RoleVariable'
const ProfileController = () => {
  const Role = JSON.parse(localStorage.getItem('Role'));
  switch (Role) {
    case RoleUser:
      return <Profile />;
    case RoleEngineer:
      return <EngineerProfile />;
    case SuperAdmin:
      return <AdminProfile />;
    default:
      return <h1>Role Not define</h1>;
  }
};
export default ProfileController;
