import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import { useHistory } from "react-router-dom";
import MyModal from "../../../../Component/Modal/index";
import TextField from "@material-ui/core/TextField";
import { ATEREPORTS } from "../../../../RouteS/BrowserRoute/Engineer/index";
import { LISTDAMAGE, UPDATEDAMAGE } from "../../../../RouteS/ApiRoutes/Damage";
import Dropdown, {
  DropdownToggle,
  DropdownMenu,
  DropdownMenuWrapper,
  MenuItem,
  DropdownButton,
  Icon,
} from "@trendmicro/react-dropdown";
import useStyle from "./style";
import PuffLoader from "react-spinners/PuffLoader";
import Select from "react-select";
import { GETENGINEER } from "../../../../RouteS/ApiRoutes/MyEngineer";
import { Schedule } from "@material-ui/icons";

const DamageRequest = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const classes = useStyle();
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [engineerId, setEngineerId] = useState();
  const [damageId, setDamageId] = useState();
  let [color, setColor] = useState("#ffffff");
  let [loading, setLoading] = useState(true);
  const [EngineerList, setEngineerList] = useState([]);
  const [EngineerVariable, setVariable] = useState({
    EngineerId: "",
    AssginTo: "",
  });
  const [field, setField] = useState({
    OldEngineerId: "",
    DamageId: "",
    OrderId: "",
    SerialNo: "",
    OldAssign: "",
  });
  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, []);
  useEffect(() => {
    getDamage();
    getEngineer();
    AssignEngineer();
  }, []);

  const onChangeEngineer = (e) => {
    setVariable({
      ...EngineerVariable,
      EngineerId: e.value,
      AssginTo: e.label,
    });
  };

  const AssignEngineer = () => {
    const Data = JSON.stringify({
      Id: damageId,
      Reschedule: date,
      EngineerId: EngineerVariable.EngineerId,
      EngineerName: EngineerVariable.AssginTo,
      OrderId: field.OrderId,
      OldEngineerId: field.OldEngineerId,
      DamageId: field.DamageId,
      SerialNo: field.SerialNo,
      OldAssign: field.OldAssign,
    });
    console.log(Data);
    fetch(UPDATEDAMAGE, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          getDamage();
        }
      });
  };

  const getEngineer = () => {
    fetch(GETENGINEER, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setEngineerList(res.Data));
  };

  const getDamage = () => {
    fetch(LISTDAMAGE)
      .then((res) => res.json())
      .then((res) => {
        return setData(res.data);
        console.log(res.data);
      });
  };
  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Issue Name",
      accessor: "IssueName",
    },
    {
      Header: "Engineer Name",
      accessor: "EngineerName",
    },
    {
      Header: "Description",
      accessor: "Description",
    },
    {
      Header: "Serial No",
      accessor: "SerialNo",
    },
    {
      Header: "Location",
      accessor: "Location",
    },
    {
      Header: "Action",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.Reassign !== "") {
          return data.original.EngineerName;
        } else {
          return (
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => {
                return (
                  setField({
                    ...field,
                    DamageId: data.original.DamageId,
                    OldEngineerId: data.original.EngineerId,
                    OrderId: data.original.OrderId,
                    SerialNo: data.original.SerialNo,
                    OldAssign: data.original.EngineerName,
                  }),
                  setDamageId(data.original.DamageId),
                  setOpen(true)
                );
              }}
            >
              Re-Assign
            </Button>
          );
        }
      },
    },
  ];

  const [date, setDate] = useState();
  const onChangeDate = (e) => {
    setDate(e.target.value);
  };
  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Damage Request ATEs</h1>
      <MyTable data={Data} columns={columns} />
      <MyModal
        open={open}
        close={() => setOpen(false)}
        title="Damage Ates Re-Assign"
        class="ReshedulModal"
        MyClose={() => setOpen(false)}
      >
        <div>
          <div className={classes.ModalField}>
            <div>
              <label style={{ color: "grey", fontSize: 12, fontWeight: 500 }}>
                Reschedule Date
              </label>
            </div>
            <TextField
              type="date"
              className={classes.DateTextField}
              variant="outlined"
              size="small"
              name="RescheduleDate"
              onChange={onChangeDate}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
          <div className={classes.ModalField2}>
            <label>Select Engineer</label>
            <Select
              placeholder="Select Engineer"
              options={EngineerList.map((eng) => ({
                value: eng.Id,
                label: eng.FirstName,
              }))}
              onChange={onChangeEngineer}
            />
          </div>
        </div>
        <Button
          className={classes.AssignBtn}
          onClick={() => {
            AssignEngineer();
            setOpen(false);
          }}
        >
          Assign Task
        </Button>
      </MyModal>
    </div>
  );
};
export default DamageRequest;
