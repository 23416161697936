import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileMenu from "./MobileMenu";
import Logo from "../Assets/ProtectedLogos/Image.png";
import NewLogo from "../Assets/ProtectedLogos/NewLogo.png";
import "../App.css";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import { Trans, useTranslation } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    boxShadow: "none",
    width: "100%",
    marginTop: "-1px",
    zIndex: 11,
    padding: "10px 0px",
    flexDirection: "row",
  },
  toolRoot: {
    width: "100%",
  },
  MenuIcon: {
    color: "black",
    marginLeft: "20px",
  },
  IpadRoot: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    paddingTop: "5px",
  },
  MobileRoot: {
    display: "flex",
    width: "100%",
  },
  DrawerRoot: {
    width: "100% !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const MobileHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const [lan, setLan] = useState("");
  const [checked, setChecked] = useState(false);
  const { t, i18n } = useTranslation();
  const url_str = window.location.href;
  const data = url_str.split("/");
  const email = data[3].toString();

  useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("lang"));
    if (lang != "") {
      setLan(lang);
      // if (lang == "ar") {
      //   setChecked(true);
      // }
      i18n.changeLanguage(JSON.parse(localStorage.getItem("lang")));
    }
  }, []);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleCheck = (e) => {
    setChecked(e.target.checked);
    if ((e.target.checked && lan == "en") || lan == null) {
      i18n.changeLanguage("ar");
      localStorage.setItem("lang", JSON.stringify("ar"));
      window.location.href = "";
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", JSON.stringify("en"));
      window.location.href = "";
    }
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const GotoHome = () => {
    history.push("/");
  };
  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolRoot}>
          {Ipad ? (
            <div className={classes.IpadRoot}>
              <img src={Logo} alt="img" width={100} onClick={GotoHome} />
            </div>
          ) : (
            <div className={classes.MobileRoot}>
              <img src={Logo} alt="img" width={100} onClick={GotoHome} />
            </div>
          )}
          <div>
            <img
              src={NewLogo}
              style={{
                marginRight: Ipad ? "1rem" : "0px",
                width: Ipad ? "300px" : "200px",
              }}
              onClick={() => history.push("/")}
            />
          </div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={showDrawer}
          >
            <MenuIcon className={classes.MenuIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={Logo}
                alt="img"
                width={100}
                onClick={() => history.push("/")}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={checked}
                    onChange={handleCheck}
                    name="checkedB"
                  />
                }
                label={lan == "ar" ? "English" : "عربي"}
              />
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        }
        closable={false}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <div style={{ padding: "0px" }}>
          <MobileMenu />
        </div>
      </Drawer>
    </>
  );
};

export default MobileHeader;
