import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Component/Header/index";
import Container from "@mui/material/Container";
import MainImage from "../Assets/Images/MainLogo.jpg";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Domain } from "../RouteS/ApiDomain/index";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  Main_Div: {
    width: "100%",
    height: "100%",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
  },
  MainContainer: {
    paddingTop: "8rem",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5rem",
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100% !important",
    },
  },
  imageclass: {
    width: "100%",
    backgroundImage: `url(${MainImage})`,
    height: "100vh",
    position: "fixed",
    backgroundSize: "cover",
    marginTop: "40px",
  },
  videoset: {
    width: "892px",
    height: "500px",
    [theme.breakpoints.between("sm", "md")]: {
      width: "692px",
      height: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "340px",
      height: "200px",
    },
  },
}));

const Media = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className={classes.imageclass}></div>
      <div className={classes.Main_Div}>
        <Container maxWidth="xl" className={classes.MainContainer}>
          <div className={classes.firstdiv}>
            {lang == "ar" ? (
              <video className={classes.videoset} controls>
                <source
                  src={`${Domain}/video/MAIN1AR_v02.mp4`}
                  type="video/mp4"
                />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video className={classes.videoset} controls>
                <source
                  src={`${Domain}/video/MAIN1EN_v02.mp4`}
                  type="video/mp4"
                />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
export default Media;
