import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { InputAdornment } from "@material-ui/core";
import { UPDATEFIRESURVEY } from "../../../../RouteS/ApiRoutes/Installation";
import {
  GETONESURVEY,
  GETONELIFESYSTEM,
  GETONECONTACTSURVEY,
} from "../../../../RouteS/ApiRoutes/Survey";
import { GETONEUSER } from "../../../../RouteS/ApiRoutes/Profile";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Profile/profile.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      height: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginLeft: "25px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  FieldDiv2: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "223px",
    alignItems: "Left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  FieldDiv9: {
    display: "flex",
    flexDirection: "row",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "223px",
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  RegisterBtn: {
    width: "35%",
    background: ThemeColor,
    marginTop: "1rem",
    color: "white",
    height: "7%",
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    // alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1rem",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    fontSize: "20px",
    margin: "0px",
  },
  MainLabel1: {
    textAlign: "left",
    marginLeft: "3rem",
    marginTop: "2rem",
    fontSize: "20px",
    marginBottom: "0px",
  },
  Titlewithbutton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Addfieldsbutton: {
    color: ThemeColor,
    fontSize: 16,
    fontWeight: 600,
    marginRight: "2rem",
  },
  AddBtnDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    marginBottom: "1.5rem",
    "&>button": {
      background: ThemeColor,
      color: "white",
      width: 130,
      marginRight: "1.5rem",
      "&:hover": {
        background: ThemeColor,
      },
    },
  },
  mainTitle: {
    textAlign: "left",
    marginTop: "-5rem",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "-1rem",
    },
  },
}));

const EditSurvey = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const FirstName = JSON.parse(localStorage.getItem("FirstName"));
  const LastName = JSON.parse(localStorage.getItem("LastName"));
  const UserId = JSON.parse(localStorage.getItem("UserId"));
  const ATEs = JSON.parse(localStorage.getItem("ate"));
  const OrderId = JSON.parse(localStorage.getItem("OrderId"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const IdNumber1 = JSON.parse(localStorage.getItem("IdNumber"));

  //   const token = JSON.parse(localStorage.getItem("token"));
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const pdfExportComponent = React.useRef(null);

  const [field, setField] = useState([]);
  const [field2, setField2] = useState([]);
  const [field3, setField3] = useState([]);

  useEffect(() => {
    View();
    ContactView();
    LifesafetyView();
  }, []);

  const View = () => {
    var raw = JSON.stringify({
      Id: Id,
    });
    fetch(GETONESURVEY, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
      });
  };

  const ContactView = () => {
    var raw = JSON.stringify({
      Id: Id,
    });
    fetch(GETONECONTACTSURVEY, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField2(responseJson.Data);
      });
  };

  const LifesafetyView = () => {
    var raw = JSON.stringify({
      Id: Id,
    });
    fetch(GETONELIFESYSTEM, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField3(responseJson.Data);
      });
  };
  const [open3, setOpen3] = useState(false);
  const [install, setInstall] = useState("");
  const options7 = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  let handleChangeIn = (e) => {
    setInstall(e.value);
  };

  const addfiresurvey = (e) => {
    if (true === e) {
      var Data = JSON.stringify({
        Id: Id,
        installed: install,
      });
      localStorage.setItem("Data", Data);
      fetch(UPDATEFIRESURVEY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: Data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (install === "Yes") {
            history.push("/dashboardcontroller");
          } else {
            history.push("/EngineerViewSurvey");
          }
        });
    } else {
      setOpen3(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginLeft: "30px",
        }}
      >
        <Button>
          <ArrowBackIosIcon
            onClick={history.goBack}
            style={{
              paddingLeft: "10px",
              width: "40px",
              height: "40px",
              fontSize: 15,
              backgroundColor: ThemeColor,
              color: "white",
            }}
          />
        </Button>
      </div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div className={classes.mainTitle}>
            <h1>Fire Survey</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                <h3
                  className={classes.MainLabel1}
                  style={{ marginTop: "2rem" }}
                >
                  ATEs Serial No
                </h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Enter ATEs Serial No
                      </label>
                      <Select placeholder={field.atessr} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h3 className={classes.MainLabel1}>Team Details</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>First Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="FirstName"
                        value={FirstName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Last Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LastName"
                        value={LastName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>ID Number</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="IdNumber1"
                        value={IdNumber1}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>First Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="FirstName2"
                        value={field.FirstName2}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Last Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LastName2"
                        value={field.LastName2}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>ID Number</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="IdNumber2"
                        value={field.IdNumber2}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h3 className={classes.MainLabel}>Premises Details</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Business Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BusinessName"
                        value={field.BusinessName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Premises Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesName"
                        value={field.PremisesName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Type Of Premises
                      </label>
                      <Select
                        isSearchable={Boolean}
                        placeholder={field.TypeOfPremises}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Premises Status
                      </label>
                      <Select placeholder={field.PremisesStatus} />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Type Of Client
                      </label>
                      <Select placeholder={field.TypeOfClient} />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Developer / Real Estate Agencies
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="DeveloperRealEstateAgencies"
                        value={field.DeveloperRealEstateAgencies}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Number of Floors
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="NumberOfFloors"
                        value={field.NumberOfFloors}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Height of the Premises
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="HeightOfThePremises"
                        value={field.HeightOfThePremises}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Number of Blocks
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="NumberOfBlocks"
                        value={field.NumberOfBlocks}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Premises Category
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesCategory"
                        value={field.PremisesCategory}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Other Info.</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesOtherInfo"
                        value={field.PremisesOtherInfo}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* Location Div */}
                <h3 className={classes.MainLabel}>Premises Locatoin</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Emirate</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        value={field.Emirate}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Address</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Address"
                        value={field.Address}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Districts</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Districts"
                        value={field.Districts}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Makani No</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="MakaniNo"
                        value={field.MakaniNo}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Three words</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Threeword"
                        value={field.ThreeWord}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    {/* <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Location</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesLocation"
                        value={field.PremisesLocation}
                        
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div> */}
                    {/* <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Main Road</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="MainRoad"
                        value={field.MainRoad}
                        
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div> */}
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Landmark</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Landmark"
                        value={field.Landmark}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Plot No</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PlotNo"
                        value={field8.PlotNumber}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>LAT</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LAT"
                        value={field.LAT}
                        inputProps={{ maxlength: 3, minlength: 3 }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>LON</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LON"
                        value={field.LON}
                        inputProps={{ maxlength: 3, minlength: 3 }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* Owner Div */}
                <div className={classes.Titlewithbutton}>
                  <h3 className={classes.MainLabel}>Contact Details</h3>
                </div>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Owner Name</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerName"
                      value={field.OwnerName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Mobile Number</label>
                    <ReactPhoneInput
                      country={"ae"}
                      value={field.OwnerMobileNumber}
                    />
                    {/* <TextField
                      style={{ width: "223px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerMobileNumber"
                      value={field.OwnerMobileNumber}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Alternative Contact Number
                    </label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerAlternativeContactNumber"
                      style={{ width: "220px" }}
                      inputProps={{ maxLength: 12 }}
                      value={field.OwnerAlternativeContactNumber}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    />
                  </div> */}
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Fax</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerFax"
                      value={field.OwnerFax}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Email</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerEmail"
                      value={field.OwnerEmail}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Responsible For</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerResponsibleFor"
                      value={field.OwnerResponsibleFor}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                </div>
                {(() => {
                  let myArray = [];
                  for (let i = 0; i < field2.length; i++) {
                    myArray.push(
                      <>
                        <div className={classes.mainDiv}>
                          <div className={classes.FieldDiv}>
                            <label className={classes.textLabel}>
                              Contact Person
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              name="ContactPerson"
                              value={field2[i].ContactPerson}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                            />
                          </div>
                          <div className={classes.FieldDiv}>
                            <label className={classes.textLabel}>
                              Telephone Number
                            </label>
                            <ReactPhoneInput
                              country={"ae"}
                              value={field2[i].TelephoneNumber}
                            />
                            {/* <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              name="TelephoneNumber"
                              style={{ width: "220px" }}
                              inputProps={{ maxLength: 12 }}
                              value={field2[i].TelephoneNumber}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +
                                  </InputAdornment>
                                ),
                              }}
                            /> */}
                          </div>
                          <div className={classes.FieldDiv}>
                            <label className={classes.textLabel}>
                              Mobile Number
                            </label>
                            <ReactPhoneInput
                              country={"ae"}
                              value={field2[i].MobileNumber}
                            />
                            {/* <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              name="MobileNumber"
                              style={{ width: "225px" }}
                              inputProps={{ maxLength: 12 }}
                              value={field2[i].MobileNumber}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +
                                  </InputAdornment>
                                ),
                              }}
                            /> */}
                          </div>
                          <div className={classes.FieldDiv}>
                            <label className={classes.textLabel}>Fax</label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              name="Fax"
                              value={field2[i].Fax}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                            />
                          </div>
                          <div className={classes.FieldDiv}>
                            <label className={classes.textLabel}>Email</label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              name="Email"
                              value={field2[i].Email}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                            />
                          </div>
                          <div className={classes.FieldDiv}>
                            <label className={classes.textLabel}>
                              Responsible For
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              name="ResponsibleFor"
                              value={field2[i].ResponsibleFor}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  }
                  return myArray;
                })()}
                <div className={classes.Titlewithbutton}>
                  <h3 className={classes.MainLabel}>Life Safety Systems</h3>
                </div>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Select Life Safety System
                      </label>
                      <Select placeholder={field.SelectLifeSafetySystem1} />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Qty</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Qty"
                        value={field.Qty}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Location</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LifeSafetyLocation"
                        value={field.LifeSafetyLocation}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Make</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Make"
                        value={field.Make}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Model</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LifeSafetyModel"
                        value={field.LifeSafetyModel}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        AMC /Supplier Name
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="AMCSupplierName"
                        value={field.AMCSupplierName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Contact Number
                      </label>
                      <ReactPhoneInput
                        country={"ae"}
                        value={field.LifeSafetyMobileNumber}
                      />
                      {/* <TextField
                        style={{ width: "223px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LifeSafetyMobileNumber"
                        inputProps={{ maxLength: 12 }}
                        value={field.LifeSafetyMobileNumber}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                          ),
                        }}
                      /> */}
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Expiry Date</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="ExpiryDate"
                        value={field.ExpiryDate}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h3 className={classes.MainLabel}>BMS System</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Vendor</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Vendor"
                        value={field.Vendor}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Model</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSModel"
                        value={field.BMSModel}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Version</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Version"
                        value={field.Version}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Contact Person
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSContactPerson"
                        value={field.BMSContactPerson}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Mobile No</label>
                      <ReactPhoneInput
                        country={"ae"}
                        value={field.BMSMobileNo}
                      />
                      {/* <TextField
                        style={{ width: "223px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSMobileNo"
                        inputProps={{ maxLength: 12 }}
                        value={field.BMSMobileNo}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                          ),
                        }}
                      /> */}
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Remarks</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Remarks"
                        value={field.Remarks}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Select BMS Protocol
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="SelectBMSProtocol"
                        value={field.SelectBMSProtocol}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Network Port</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="NetworkPort"
                        value={field.NetworkPort}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Other Info.</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSOtherInfo"
                        value={field.BMSOtherInfo}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.MainLabel}>
                  <div className={classes.Titlewithbutton}>
                    <div>
                      <h3>Life Safety Systems Snags List</h3>
                      <h3>(Visible Observations only)</h3>
                    </div>
                  </div>
                </div>
                <div>
                  {(() => {
                    let myArray = [];
                    for (let i = 0; i < field3.length; i++) {
                      myArray.push(
                        <>
                          <div className={classes.mainDiv}>
                            <div className={classes.FieldDiv2}>
                              <label className={classes.textLabel}>
                                Select Life Safety System
                              </label>
                              <Select
                                placeholder={field3[i].SelectLifeSafetySystem}
                              />
                            </div>
                            <div className={classes.FieldDiv2}>
                              <label className={classes.textLabel}>
                                Select Status
                              </label>
                              <Select placeholder={field3[i].SelectStatus} />
                            </div>
                          </div>
                        </>
                      );
                    }
                    return myArray;
                  })()}
                </div>

                <h3 className={classes.MainLabel}>ATEs Install On Survey</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv2}>
                    <label className={classes.textLabel}>
                      Ready To Install
                    </label>
                    <Select
                      options={options7}
                      onChange={(e) => {
                        handleChangeIn(e);
                      }}
                      placeholder={field.installed}
                    />
                  </div>
                </div>
                <div className={classes.AddBtnDiv}>
                  <Button onClick={history.goBack}>Cancel</Button>
                  <Button
                    onClick={() => {
                      if (install === "") {
                        addfiresurvey();
                      } else {
                        addfiresurvey(true);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </Container>
    </>
  );
};
export default EditSurvey;
