import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  Titlediv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  h1tag: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  AddPremises: {
    color: ThemeColor,
    fontSize: 16,
    fontWeight: 600,
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));
export default useStyles;
