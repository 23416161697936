import useStyle from "./style";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import MyModal from "../../../../Component/Modal/index";
import MyTable from "../../../../Component/Table/index";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Button, Hidden, IconButton } from "@material-ui/core";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import {
  LISTENGINEERINSTALLATION,
  UPDATESTATUS,
  GETENGPENDING,
  GETENGCOMPLETE,
  GETENGHOLD,
} from "../../../../RouteS/ApiRoutes/Installation";

const IntstallationTask = () => {
  const Id = JSON.parse(localStorage.getItem("Id"));
  const token = JSON.parse(localStorage.getItem("token"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));

  const classes = useStyle();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [Data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [obj, setObj] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [text, setText] = useState("");
  const [userId, setUserId] = useState("");
  const [uname, setUserName] = useState("");
  const [ates, setATEs] = useState("");
  const [ename, setEname] = useState("");
  const [pendingstatus, setPendingstatus] = useState([]);
  const [completestatus, setCompletestatus] = useState([]);
  const [holdstatus, setHoldstatus] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [holdmessage, setHoldMessage] = useState("");
  const [Complete, setComplete] = useState(true);

  useEffect(() => {
    getInstallation();
    getPending();
    gethold();
    getcomplete();
  }, []);

  const getPending = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(GETENGPENDING, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setPendingstatus(res.Data);
        localStorage.setItem("Pending", JSON.stringify(res.Data.length));
      });
  };

  const gethold = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(GETENGHOLD, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setHoldstatus(res.Data);
        localStorage.setItem("Hold", JSON.stringify(res.Data.length));
      });
  };

  const getcomplete = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(GETENGCOMPLETE, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setCompletestatus(res.Data);
        localStorage.setItem("Complete", JSON.stringify(res.Data.length));
      });
  };

  const getInstallation = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(LISTENGINEERINSTALLATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.Data);
        for (var i = 0; i < res.Data.length; i++) {
          if (res.Data[i].ATEs == res.Data[i].InstalledATEs) {
            setComplete(false);
          }
        }
        localStorage.setItem("Total", JSON.stringify(res.Data.length));
      });
  };

  const updatestatus = (id) => {
    const BodyData = JSON.stringify({
      Id: id,
      Status: status,
      HoldMessage: text,
      UserId: userId,
      Ates: ates,
      Ename: ename,
      Uname: uname,
    });
    fetch(UPDATESTATUS, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setObj(true);
        handleClickOpen();
        getInstallation();
      });
  };

  const handleClickOpen = () => {
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen1(false);
  };
  const handlestatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value === "Hold") {
      setOpen(true);
    }
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      width: 50,
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "ATEs",
      accessor: "ATEs",
      width: 50,
    },
    {
      Header: "Installed ATEs",
      accessor: "InstalledATEs",
      width: 50,
    },
    {
      Header: "Damage ATEs",
      accessor: "DamageATEs",
      width: 50,
    },
    {
      Header: "Payment Invoice",
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.receiptUrl != "" ? (
              <a
                href={data.original.receiptUrl}
                style={{
                  color: "blue",
                }}
                rel="noreferrer"
                target="_blank"
              >
                {data.original.receiptUrl == "" ? "" : "View Invoice"}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: "NOC",
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.noc != "" ? (
              <a
                href={data.original.noc}
                style={{
                  color: "blue",
                }}
                rel="noreferrer"
                target="_blank"
              >
                {data.original.receiptUrl == "" ? "" : "View Noc"}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: "Install Date",
      accessor: "SheduleOn",
    },
    {
      Header: "Time Slot",
      accessor: "Time",
    },
    {
      Header: "Status",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 3,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
    {
      Header: "To Do",
      filterable: false,
      accessor: "Status",
      Cell: (data) => {
        if (
          data.original.ATEs <=
          data.original.InstalledATEs + data.original.DamageATEs
        ) {
          if (data.original.Status === "Complete") {
            return (
              <select onChange={handlestatus}>
                <option selected value="Complete" disabled={Complete}>
                  Complete
                </option>
              </select>
            );
          } else if (data.original.Status === "In Progress") {
            return (
              <select onChange={handlestatus}>
                <option selected value="In Progress">
                  In Progress
                </option>
                <option value="Complete">Complete</option>
              </select>
            );
          } else {
            return (
              <select onChange={handlestatus}>
                <option value="" selected disabled>
                  Select Status
                </option>
                <option value="In Progress">In Progress</option>
                <option value="Complete" disabled={Complete}>
                  Complete
                </option>
              </select>
            );
          }
        } else {
          if (data.original.Status === "In Progress") {
            return (
              <select onChange={handlestatus}>
                <option selected value="In Progress">
                  In Progress
                </option>
              </select>
            );
          } else {
            return (
              <select onChange={handlestatus}>
                <option value="" selected disabled>
                  Select Status
                </option>
                <option value="In Progress">In Progress</option>
              </select>
            );
          }
        }
      },
    },
    {
      Header: "Update",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={(e) => {
              updatestatus(data.original.Id);
              setUserId(data.original.UserId);
              setATEs(data.original.ATEs);
              setEname(data.original.AssignTo);
              setUserName(data.original.Name);
            }}
          >
            Update
          </Button>
        );
      },
    },
    {
      Header: "ATEs Report",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={() => {
              if (data.original.InstalledATEs >= data.original.ATEs) {
                history.push(`/atesreportview/${data.original.Id}`);
              } else {
                history.push(`/atesreport`);
                localStorage.setItem("ate", JSON.stringify(data.original.ATEs));
                localStorage.setItem(
                  "OrderId",
                  JSON.stringify(data.original.Id)
                );
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                );
                localStorage.setItem(
                  "BusinessName",
                  JSON.stringify(data.original.BusinessName)
                );
                localStorage.setItem(
                  "dueDate",
                  JSON.stringify(data.original.DueDate)
                );
              }
            }}
          >
            Submit
          </Button>
        );
      },
    },
    {
      Header: "View",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={(e) => {
              history.push(`/atesreportview/${data.original.Id}`);
            }}
          >
            View Ates
          </Button>
        );
      },
    },
    {
      Header: "Survey",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={(e) => {
              if (data.original.ATEs === data.original.ATEsSurvey) {
                history.push(`/EngineerViewSurvey`);
              } else {
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                );
                localStorage.setItem("ate", JSON.stringify(data.original.ATEs));
                localStorage.setItem(
                  "OrderId",
                  JSON.stringify(data.original.Id)
                );
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                );
                localStorage.setItem(
                  "BusinessName",
                  JSON.stringify(data.original.BusinessName)
                );
                localStorage.setItem(
                  "dueDate",
                  JSON.stringify(data.original.DueDate)
                );
                history.push(`/firesurveyreport`);
              }
            }}
          >
            <AssignmentIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left", marginTop: "1rem" }}>
        Installation Task
      </h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default IntstallationTask;
