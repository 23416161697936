import { Button, Divider, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import ViewATEs from "./ATEs";
import { useHistory } from "react-router";
import {
  GETALERNATIVE,
  VIEWONEORDER,
} from "../../../../RouteS/ApiRoutes/Order";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "25px",
    "& > .MuiPaper-elevation3": {
      width: "99%",
      height: "100%",
      borderRadius: 20,
      display: "flex",
      alignItems: "center",
      background: "white",
      marginBottom: "10px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  ArrowDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
  ArrowIcon: {
    paddingLeft: "10px",
    width: "40px !important",
    height: "40px !important",
    fontSize: 15,
    backgroundColor: ThemeColor,
    color: "white",
  },
  TitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
    marginTop: "15px",
  },
  div1: {
    display: "flex",
    width: "100%",
    marginLeft: "2rem",
    paddingTop: "10px",
    paddingBottom: "20px",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  div2: {
    marginTop: "2rem",
  },
  DividerVeritical: {
    height: 110,
    width: 2.8,
    background: ThemeColor,
    borderRadius: 10,
  },
}));

const ViewProduct = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [Data, setData] = useState([]);
  const [ContactData, setContactData] = useState([]);
  const url_str = window.location.href;
  const data = url_str.split("/");
  const OrderId = data[4].toString();

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  useEffect(() => {
    GetOneOrder();
    GetContact();
  }, []);

  const GetContact = () => {
    const raw = JSON.stringify({ Id: OrderId });
    fetch(GETALERNATIVE, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => setContactData(res.Data));
  };

  const GetOneOrder = () => {
    const raw = JSON.stringify({ Id: OrderId });
    fetch(VIEWONEORDER, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => setData(res.Data[0]));
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.ArrowDiv}>
          <ArrowBackIosIcon
            onClick={history.goBack}
            className={classes.ArrowIcon}
          />
        </div>
        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>Premise Details</h1>
          <Button
            style={{ color: ThemeColor, fontSize: 16, fontWeight: 600 }}
            onClick={() => history.push("/premises")}
          >
            Add Premise +
          </Button>
        </div>
        {tablet ? (
          <Paper elevation={3}>
            <div className={classes.div1}>
              <div>
                <span>{t("product_name")}&nbsp;</span>
                <span>{t("total_ates")}&nbsp;</span>
                <span>{t("makani_no")}&nbsp;</span>
                <span>{t("time")}&nbsp;</span>
                <span>{t("contact_name1")}&nbsp;</span>
                <span>{t("emergency_number1")}&nbsp;</span>
                <span>{t("payment_invoice")}&nbsp;</span>
                <span>{t("bussines_name")}&nbsp;</span>
                <span>{t("assignto")}&nbsp;</span>
                <span>Owner FullName&nbsp;</span>
                <span>{t("payment_status")}&nbsp;</span>
              </div>
              <div>
                <span>:&nbsp;&nbsp;{Data.Product}</span>
                <span>:&nbsp;&nbsp;{Data.ATEs}</span>
                <span>:&nbsp;&nbsp;{Data.MakaniNo}</span>
                <span>:&nbsp;&nbsp;{Data.Time}</span>
                <span>:&nbsp;&nbsp;{Data.ContactName1}</span>
                <span>:&nbsp;&nbsp;{Data.EmeContact1}</span>
                <span>
                  :&nbsp;&nbsp;{" "}
                  {Data.receiptUrl == "" ? (
                    ""
                  ) : (
                    <a target="_blank" rel="noreferrer" href={Data.receiptUrl}>
                      View Invoice
                    </a>
                  )}
                </span>
                <span>:&nbsp;&nbsp;{Data.BusinessName}</span>
                <span>:&nbsp;&nbsp;{Data.AssignTo}</span>
                <span>:&nbsp;&nbsp;{Data.FullName}</span>
                <span>:&nbsp;&nbsp;{Data.payStatus}</span>
              </div>
            </div>

            <div className={classes.div1}>
              <div>
                <span>{t("address")}&nbsp;</span>
                <span>{t("landMark")}&nbsp;</span>
                <span>{t("shedule_on")}&nbsp;</span>
                <span>{t("ate_status")}&nbsp;</span>
                <span>{t("owner_email")}&nbsp;</span>
                <span>{t("contact_name2")}&nbsp;</span>
                <span>{t("emergency_number2")}&nbsp;</span>
                <span>{t("noc")}&nbsp;</span>
                <span>{t("owner_contact")}&nbsp;</span>
                <span>{t("contact_name3")}&nbsp;</span>
                <span>{t("emergency_number3")}&nbsp;</span>
                <span>{t("plot_number")}&nbsp;</span>
              </div>
              <div>
                <span>:&nbsp;&nbsp;{Data.Address}</span>
                <span>:&nbsp;&nbsp;{Data.LandMark}</span>
                <span>:&nbsp;&nbsp;{Data.SheduleOn}</span>
                <span>:&nbsp;&nbsp;{Data.Status}</span>
                <span>:&nbsp;&nbsp;{Data.Email}</span>
                <span>:&nbsp;&nbsp;{Data.ContactName2}</span>
                <span>:&nbsp;&nbsp;{Data.EmeContact2}</span>
                <span>
                  :&nbsp;&nbsp;{" "}
                  {Data.noc == "" ? (
                    ""
                  ) : (
                    <a target="_blank" rel="noreferrer" href={Data.noc}>
                      View NOC
                    </a>
                  )}
                </span>
                <span>:&nbsp;&nbsp;{Data.Contact}</span>
                <span>:&nbsp;&nbsp;{Data.ContactName3}</span>
                <span>:&nbsp;&nbsp;{Data.EmeContact3}</span>
                <span>:&nbsp;&nbsp;{Data.PlotNumber}</span>
              </div>
            </div>
          </Paper>
        ) : (
          <Paper elevation={3}>
            <div className={classes.div1}>
              <div>
                <span>{t("product_name")}&nbsp;</span>
                <span>{t("total_ates")}&nbsp;</span>
                <span>{t("makani_no")}&nbsp;</span>
                <span>{t("time")}&nbsp;</span>
                <span>{t("contact_name1")}&nbsp;</span>
                <span>{t("emergency_number1")}&nbsp;</span>
                <span>{t("payment_invoice")}&nbsp;</span>
              </div>
              <div>
                <span>:&nbsp;&nbsp;{Data.Product}</span>
                <span>:&nbsp;&nbsp;{Data.ATEs}</span>
                <span>:&nbsp;&nbsp;{Data.MakaniNo}</span>
                <span>:&nbsp;&nbsp;{Data.Time}</span>
                <span>:&nbsp;&nbsp;{Data.ContactName1}</span>
                <span>:&nbsp;&nbsp;{Data.EmeContact1}</span>
                <span>
                  :&nbsp;&nbsp;{" "}
                  {Data.receiptUrl == "" ? (
                    ""
                  ) : (
                    <a target="_blank" rel="noreferrer" href={Data.receiptUrl}>
                      View Invoice
                    </a>
                  )}
                </span>
              </div>
            </div>

            <div className={classes.div1}>
              <div>
                <span>{t("address")}&nbsp;</span>
                <span>{t("landMark")}&nbsp;</span>
                <span>{t("shedule_on")}&nbsp;</span>
                <span>{t("ate_status")}&nbsp;</span>
                <span>{t("owner_email")}&nbsp;</span>
                <span>{t("contact_name2")}&nbsp;</span>
                <span>{t("emergency_number2")}&nbsp;</span>
                <span>{t("noc")}&nbsp;</span>
              </div>
              <div>
                <span>:&nbsp;&nbsp;{Data.Address}</span>
                <span>:&nbsp;&nbsp;{Data.LandMark}</span>
                <span>:&nbsp;&nbsp;{Data.SheduleOn}</span>
                <span>:&nbsp;&nbsp;{Data.Status}</span>
                <span>:&nbsp;&nbsp;{Data.Email}</span>
                <span>:&nbsp;&nbsp;{Data.ContactName2}</span>
                <span>:&nbsp;&nbsp;{Data.EmeContact2}</span>
                <span>
                  :&nbsp;&nbsp;{" "}
                  {Data.noc == "" ? (
                    ""
                  ) : (
                    <a target="_blank" rel="noreferrer" href={Data.noc}>
                      View NOC
                    </a>
                  )}
                </span>
              </div>
            </div>

            <div className={classes.div1}>
              <div>
                <span>{t("bussines_name")}&nbsp;</span>
                <span>{t("assignto")}&nbsp;</span>
                <span>Owner FullName&nbsp;</span>
                <span>{t("owner_contact")}&nbsp;</span>
                <span>{t("contact_name3")}&nbsp;</span>
                <span>{t("emergency_number3")}&nbsp;</span>
                <span>{t("payment_status")}&nbsp;</span>
                <span>{t("plot_number")}&nbsp;</span>
              </div>
              <div>
                <span>:&nbsp;&nbsp;{Data.BusinessName}</span>
                <span>:&nbsp;&nbsp;{Data.AssignTo}</span>
                <span>:&nbsp;&nbsp;{Data.FullName}</span>
                <span>:&nbsp;&nbsp;{Data.Contact}</span>
                <span>:&nbsp;&nbsp;{Data.ContactName3}</span>
                <span>:&nbsp;&nbsp;{Data.EmeContact3}</span>
                <span>:&nbsp;&nbsp;{Data.payStatus}</span>
                <span>:&nbsp;&nbsp;{Data.PlotNumber}</span>
              </div>
            </div>
          </Paper>
        )}
        {/* <h1 style={{ textAlign: "left" }}>Additional Contact</h1>
        {ContactData.length === 0 ? (
          <>
            <div style={{ fontSize: "20px" }}>No Additional Contact Found</div>
          </>
        ) : (
          ContactData.map((Data, index) => {
            return (
              <Paper elevation={3} key={index}>
                <div className={classes.div1}>
                  <div>
                    <span>Full Name&nbsp;</span>
                    <span>Contact No&nbsp;</span>
                  </div>
                  <div>
                    <span>:&nbsp;&nbsp;{Data.FullName}</span>
                    <span>:&nbsp;&nbsp;{Data.ContactNo}</span>
                  </div>
                </div>
                <div className={classes.div1}>
                  <div>
                    <span>Email&nbsp;</span>
                    <span>Position&nbsp;</span>
                  </div>
                  <div>
                    <span>:&nbsp;&nbsp;{Data.Email}</span>
                    <span>:&nbsp;&nbsp;{Data.Position}</span>
                  </div>
                </div>
              </Paper>
            );
          })
        )} */}
      </div>
      <div>
        <ViewATEs />
      </div>
    </div>
  );
};
export default ViewProduct;
