import { Avatar, Button, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { MenuOpen } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { Link, Redirect, Route, useHistory } from "react-router-dom";
import Logo from "../../Assets/ProtectedLogos/Weqaya.png";
import Header from "../../Component/SideBar/Sidebar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";
import { PROFILE } from "../BrowserRoute/User";
import {
  RoleEngineer,
  RoleUser,
  SuperAdmin,
} from "../../ConstVariable/RoleVariable";
// import randomColor from "randomcolor";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { PROFILECONTROLLER } from "../BrowserRoute/ControllerRoute";
import { NOTIFICATION } from "../BrowserRoute/Admin";
import { ENGINEERNOTIFICATION } from "../BrowserRoute/Engineer";
import { CUSTOMERNOTIFICATION } from "../BrowserRoute/User";
import {
  GETNOTIFICATION,
  GETCUSTOMERNOTIFICATION,
  GETENGINEERNOTIFICATION,
  UPDATEADMINNOTIFICATION,
  UPDATECUSTOMERNOTIFICATION,
  UPDATEENGINEERNOTIFICATION,
  GETADMINSEENNOTIFICATION,
  GETENGINEERSEENNOTIFICATION,
  GETCUSTOMERSEENNOTIFICATION,
} from "../ApiRoutes/Notification";
import img from "../../Assets/ProfilePic/1.jpg";
import img2 from "../../Assets/ProfilePic/2.jpg";
import { ls } from "../../SecureLs/Ls";
import ConfirmationDilog from "../../Component/Modal/ConfirmationModal";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { VIEWONESTAFF } from "../ApiRoutes/Staff";
import { GETONEUSER } from "../ApiRoutes/Profile";
import { useTranslation } from "react-i18next";
const drawerWidth = 200;
var lang = JSON.parse(localStorage.getItem("lang"));
const auths = JSON.parse(localStorage.getItem("auth"));

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      // width: drawerWidth,
      // marginRight: 60,
      width:
        auths == "rea"
          ? drawerWidth
          : auths == "ree"
          ? drawerWidth
          : lang == "ar"
          ? 0
          : drawerWidth,
      marginRight:
        auths == "rea" ? 60 : auths == "ree" ? 60 : lang == "ar" ? 0 : 60,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      // marginRight: 50,
      // width: theme.spacing(7) + 1,
      marginRight:
        auths == "rea" ? 50 : auths == "ree" ? 50 : lang == "ar" ? 0 : 50,
      width:
        auths == "rea"
          ? theme.spacing(7) + 1
          : auths == "ree"
          ? theme.spacing(7) + 1
          : lang == "ar"
          ? 0
          : theme.spacing(7) + 1,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("xs")]: {
        marginRight: lang == "ar" ? 0 : 10,
      },
      // overflowX: "hidden",
    },
    hederLink: {
      width: "15%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>a": {
        marginRight: lang == "ar" ? "0rem" : "2rem",
        marginLeft: lang == "ar" ? "2rem" : "0rem",
        color: "black",
        fontWeight: 600,
        textDecoration: "auto",
        fontSize: 16,
        "&:hover": {
          color: "#B5944E",
        },
      },
    },
    MainLogoImage: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      marginTop: "40px",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      width: "93%",
      // marginRight: lang == "ar" ? "80px" : "0",
      [theme.breakpoints.between("sm", "md")]: {
        width: "65%",
      },
    },
  })
);

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const Token = JSON.parse(localStorage.getItem("token"));
  const role = JSON.parse(localStorage.getItem("Role"));
  const Eidno = JSON.parse(localStorage.getItem("EIDno"));
  const UserName = JSON.parse(localStorage.getItem("UserName"));
  const userId = JSON.parse(localStorage.getItem("UserId"));
  const engineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [open3, setOpen3] = React.useState(true);
  const [admin, setAdmin] = useState(true);
  const [customer, setCustomer] = useState(true);
  const [engineer, setEngineer] = useState(true);
  const [Open2, setOpen2] = React.useState({
    admin: false,
    user: false,
    engineer: false,
  });
  const [landata, setLandata] = useState("");
  const Langu = JSON.parse(localStorage.getItem("lang"));

  useEffect(() => {
    setLandata(Langu);
    if (role === "Admin") {
      getAllNotification();
      getAdminSeenNotification();
    } else if (role === "Engineer") {
      getEngineerNotification();
      getEngineerSeenNotification();
    } else {
      getCustomerNotification();
      getCustomerSeenNotification();
    }
  }, []);

  const [field, setField] = useState([]);

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWONESTAFF + "Id=" + engineerId + "", {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
      });
  };

  const [field2, setField2] = useState([]);

  useEffect(() => {
    view();
  }, []);

  const view = () => {
    const Id = JSON.stringify({ Id: userId });
    fetch(GETONEUSER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      body: Id,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField2(responseJson.Data[0]);
        // setImages(responseJson.Data[0].Image);
      });
  };

  var randomColor = Math.floor(Math.random() * 16777215).toString(16);
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [insertData, setInsertData] = useState([]);
  const [insertData2, setInsertData2] = useState([]);
  const [insertData3, setInsertData3] = useState([]);
  const [insertData4, setInsertData4] = useState([]);
  const [insertData5, setInsertData5] = useState([]);
  const [insertData6, setInsertData6] = useState([]);
  var update = [];
  var update2 = [];
  var update3 = [];

  const getAllNotification = () => {
    fetch(GETNOTIFICATION, {
      headers: {
        Authorization: "Bearer " + Token + "",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setInsertData(res.data);
      });
  };

  const getCustomerNotification = () => {
    const raw = JSON.stringify({
      UserId: userId,
    });
    fetch(GETCUSTOMERNOTIFICATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setInsertData2(res.Data);
        }
      });
  };
  const getEngineerNotification = () => {
    const raw = JSON.stringify({
      EngineerId: engineerId,
    });
    fetch(GETENGINEERNOTIFICATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setInsertData3(res.Data);
        }
      });
  };

  const getAdminSeenNotification = () => {
    fetch(GETADMINSEENNOTIFICATION, {
      headers: {
        Authorization: "Bearer " + Token + "",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setInsertData4(res.data);
      });
  };

  const getEngineerSeenNotification = () => {
    const raw = JSON.stringify({
      EngineerId: engineerId,
    });
    fetch(GETENGINEERSEENNOTIFICATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setInsertData5(res.data);
        }
      });
  };
  const getCustomerSeenNotification = () => {
    const raw = JSON.stringify({
      UserId: userId,
    });
    fetch(GETCUSTOMERSEENNOTIFICATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setInsertData6(res.data);
        }
      });
  };

  for (var k = 0; k < insertData.length; k++) {
    update.push({
      Id: insertData[k].Id,
    });
  }
  for (var k = 0; k < insertData2.length; k++) {
    update2.push({
      UserId: insertData2[k].UserId,
    });
  }
  for (var k = 0; k < insertData3.length; k++) {
    update3.push({
      EngineerId: insertData3[k].EngineerId,
    });
  }
  const [display, setDisplay] = useState(true);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setDisplay(false);
    if (role === "Admin" && admin) {
      const raw = JSON.stringify({
        AdminSeen: "Seen",
        Update: update,
      });
      fetch(UPDATEADMINNOTIFICATION, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: raw,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status === true) {
            setAdmin(false);
          }
        });
    }
    if (role === "Engineer" && engineer) {
      const raw = JSON.stringify({
        EngineerSeen: "Seen",
        Update: update3,
      });
      fetch(UPDATEENGINEERNOTIFICATION, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: raw,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status === true) {
            setEngineer(false);
          }
        });
    }
    if (role === "User" && customer) {
      const raw = JSON.stringify({
        CustomerSeen: "Seen",
        Update: update2,
      });
      fetch(UPDATECUSTOMERNOTIFICATION, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: raw,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status === true) {
            setCustomer(false);
          }
        });
    }
  };

  const history = useHistory();
  const theme = useTheme();

  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const ProfileOpen = () => {
    setAnchorEl(null);
    history.push(PROFILECONTROLLER);
  };
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
    localStorage.setItem("lang", JSON.stringify(landata));
    setAnchorEl(null);
  };
  const LogoutEngineer = () => {
    localStorage.clear();
    window.location.href = "/engineer";

    setAnchorEl(null);
  };

  const LogoutAdmin = () => {
    localStorage.clear();
    window.location.href = "/admin";
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    if (Mobile) {
      setOpen3(!open3);
    }
    setOpen(!open);
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        if (role) {
          return (
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                position="fixed"
                style={{ background: "white" }}
                className={
                  (clsx(classes.appBar, {
                    [classes.appBarShift]: Mobile ? open3 : open,
                  }),
                  "theme-background")
                }
              >
                <Toolbar>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                      >
                        {open ? (
                          <MenuIcon style={{ color: "black" }} />
                        ) : (
                          <MenuOpen style={{ color: "black" }} />
                        )}
                      </IconButton>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Logo}
                          width="80"
                          className={classes.MainLogoImage}
                          style={{
                            marginLetf: "0.5rem",
                            marginRight: "0.5rem",
                          }}
                          onClick={() => history.push("/")}
                        />
                      </div>
                    </div>

                    {(() => {
                      switch (role) {
                        case RoleUser:
                          return (
                            <div
                              className={classes.hederLink}
                              style={{
                                width: "25%",
                                justifyContent: lang == "ar" ? "left" : "right",
                              }}
                            >
                              <>
                                <Avatar
                                  src={field2.Image}
                                  style={{
                                    marginLeft: lang == "ar" ? "18px" : "0px",
                                    marginRight: lang == "ar" ? "18px" : "0px",
                                    height: 35,
                                    width: 35,
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <Link
                                  to="#"
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "right",
                                    color: ThemeColor,
                                  }}
                                >
                                  {UserName}
                                  <ExpandMoreIcon />
                                </Link>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  style={{ marginTop: "30px" }}
                                  onClose={handleClose}
                                >
                                  <MenuItem onClick={ProfileOpen}>
                                    {t("my_profile")}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      setOpen2({ ...Open2, user: true })
                                    }
                                  >
                                    {t("logout")}
                                  </MenuItem>
                                </Menu>
                              </>
                            </div>
                          );
                        case RoleEngineer:
                          return (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                src={field.Image}
                                style={{
                                  marginLeft: "18px",
                                  height: 35,
                                  width: 35,
                                  marginRight: "0.5rem",
                                }}
                              />
                              <Link
                                to="#"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  color: ThemeColor,
                                  textDecoration: "none",
                                  fontSize: 18,
                                  fontWeight: 600,
                                }}
                              >
                                {UserName}
                                <ExpandMoreIcon />
                              </Link>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                style={{ marginTop: "30px" }}
                                onClose={handleClose}
                              >
                                <MenuItem onClick={ProfileOpen}>
                                  My Profile
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    setOpen2({ ...Open2, engineer: true })
                                  }
                                >
                                  Logout
                                </MenuItem>
                              </Menu>
                            </div>
                          );
                        case SuperAdmin:
                          return (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                style={{
                                  marginLeft: "18px",
                                  height: 35,
                                  width: 35,
                                  marginRight: "0.5rem",
                                }}
                              />
                              <Link
                                to="#"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  color: ThemeColor,
                                  textDecoration: "none",
                                  fontSize: 18,
                                  fontWeight: 600,
                                }}
                              >
                                {UserName}
                                <ExpandMoreIcon />
                              </Link>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                style={{ marginTop: "30px" }}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() =>
                                    setOpen2({ ...Open2, admin: true })
                                  }
                                >
                                  Logout
                                </MenuItem>
                              </Menu>
                            </div>
                          );
                      }
                    })()}
                  </div>
                </Toolbar>
              </AppBar>
              <div
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: Mobile ? !open3 : !open,
                  [classes.drawerClose]: Mobile ? open3 : open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: Mobile ? !open3 : !open,
                    [classes.drawerClose]: Mobile ? open : open,
                  }),
                }}
              >
                {open3 && Mobile ? (
                  <Header open={open3} />
                ) : (
                  <Header open={open3} />
                )}

                {Mobile ? (
                  open3 ? (
                    <Header open={open3} />
                  ) : (
                    <Header open={open3} />
                  )
                ) : open ? (
                  <Header open={open} />
                ) : (
                  <Header open={open} />
                )}
                {/* {open ? <Header open={open} /> : <Header open={open} />} */}
              </div>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Component />
                <ConfirmationDilog
                  open={Open2.user}
                  close={() => {
                    return (
                      setOpen2({ ...Open2, user: false }), setAnchorEl(null)
                    );
                  }}
                  ok={() => logout()}
                />
                <ConfirmationDilog
                  open={Open2.engineer}
                  close={() => {
                    return (
                      setOpen2({ ...Open2, engineer: false }), setAnchorEl(null)
                    );
                  }}
                  ok={() => LogoutEngineer()}
                />
                <ConfirmationDilog
                  open={Open2.admin}
                  close={() => {
                    return (
                      setOpen2({ ...Open2, admin: false }), setAnchorEl(null)
                    );
                  }}
                  ok={() => LogoutAdmin()}
                />
              </main>
            </div>
          );
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
