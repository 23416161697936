import React, { useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./Page1.css";
import Header from "../Component/Header";
import MainImage from "../Assets/Images/MainLogo.jpg";
import { ThemeColor } from "../Assets/StyleSheets/Theme";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  Main_Div: {
    width: "100%",
    height: "100vh",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "100px",
    },
  },
  Container: {
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    position: "absolute",
    // height: "400px",
    // marginTop: "100px",
    marginTop: "5px",
    // bottom: 0,
    // marginBottom: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      height: "475px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      height: "425px",
    },
  },
  Text_div: {
    width: "100%",
    color: "white",
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: "100px",
    paddingRight: "100px",
    borderRadius: "30px",
    background:
      "linear-gradient(261.7deg, rgba(0, 0, 0, 0.49) -0.62%, rgba(0, 0, 0, 0.2107) 101.06%)",
    backdropFilter: "blur(29px)",
    height: "400px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "20px",
      height: "350px",
      paddingLeft: "40px",
      paddingRight: "40px",
      width: "90%",
      margin: "0px auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "475px",
    },
  },
  h1tag: {
    fontSize: "40px",
    fontWeight: 600,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  ptag: {
    fontSize: "17px",
    fontWeight: 600,
    lineHeight: 1.3,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1,
      // padding: "0 18px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  buttonDiv: {
    borderRadius: "20px",
    border: `1px solid ${ThemeColor}`,
    width: "150px",
    background: "white",
    color: "black",
    cursor: "pointer",
    height: "40px",
    "&:hover": {
      color: "white",
      background: ThemeColor,
      border: `1px solid white`,
    },
  },
  serviceimg: {
    height: "275px",
    width: "500px",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "170px",
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Header />
        <div className={classes.Root}></div>
        <div className={classes.Main_Div}>
          <Container className={classes.Container}>
            <div className={classes.Text_div}>
              <h1 className={classes.h1tag}>{t("what_is_register")}</h1>
              <p
                className={classes.ptag}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              >
                {t("require_para1")}
              </p>
              <br />
              <p
                className={classes.ptag}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              >
                {t("require_para2")} <br />
                {t("require_para3")}
                <br />
                {t("require_para4")} <br />
                {t("require_para5")}
              </p>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default Main;
