import { Button, Divider, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import { useHistory, useParams } from "react-router";
import { GETONEUSER } from "../../../../../RouteS/ApiRoutes/Customer";
import { VIEWONEORDER } from "../../../../../RouteS/ApiRoutes/Order";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "60px",
    marginRight: "50px",
  },
  root: {
    "& > .MuiPaper-elevation3": {
      width: "100%",
      height: "100%",
      borderRadius: 20,
      display: "flex",
      alignItems: "center",
      background: "white",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  ArrowDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
  ArrowIcon: {
    paddingLeft: "10px",
    width: "40px !important",
    height: "40px !important",
    fontSize: 15,
    backgroundColor: ThemeColor,
    color: "white",
  },
  TitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
    marginTop: "20px",
  },
  custdiv: {
    display: "flex",
    width: "100%",
    marginLeft: "2rem",
    paddingTop: "10px",
    paddingBottom: "20px",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  div11: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&>span": {
      height: "100%",
      marginTop: "10px",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  div21: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&>span": {
      height: "100%",
      marginTop: "10px",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  div22: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&>span": {
      height: "90%",
      width: "10% !important",
      marginTop: "10px",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  div12: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&>span": {
      height: "100%",
      marginTop: "10px",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  div01: {
    display: "flex",
    width: "100%",
    marginLeft: "2rem",
  },
  div02: {
    display: "flex",
    width: "100%",
    paddingBottom: "10px",
    marginLeft: "2rem",
  },
  div1: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "15px 0px",
  },
  div2: {
    display: "flex",
    width: "100%",
    margin: "15px 0px",
  },
  div3: {
    display: "flex",
    width: "100%",
    margin: "15px 0px",
  },
  DividerVeritical: {
    height: 110,
    width: 2.8,
    background: ThemeColor,
    borderRadius: 10,
  },
}));

const ViewDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("token"));
  const userid = JSON.parse(localStorage.getItem("UserId"));

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    EIDno: "",
    Email: "",
    ContactNo: "",
    FirstName2: "",
    LastName2: "",
    ContactNo2: "",
    PurchasedATEs: "",
  });
  const [field1, setField1] = useState({
    Name: "",
    BusinessName: "",
    MakaniNo: "",
    Address: "",
    ATEs: "",
    StartDate: "",
    EndDate: "",
    Time: "",
    SheduleOn: "",
    EngineerId: "",
    AssignTo: "",
    Status: "",
    HoldMessage: "",
  });

  useEffect(() => {
    print1();
    print2();
  }, []);

  const print1 = () => {
    var raw = JSON.stringify({
      Id: userid,
    });
    fetch(GETONEUSER, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
      });
  };
  const print2 = () => {
    var raw = JSON.stringify({
      Id: id,
    });
    fetch(VIEWONEORDER, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField1(responseJson.Data[0]);
      });
  };

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <div className={classes.MainContainer}>
      <div className={classes.root}>
        <div className={classes.ArrowDiv}>
          <ArrowBackIosIcon
            onClick={history.goBack}
            className={classes.ArrowIcon}
          />
        </div>
        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>Customer Details</h1>
        </div>
        <Paper elevation={3}>
          <div className={classes.custdiv}>
            <div>
              <span>Customer Name </span>
              <span>EID Number </span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field.FirstName + " " + field.LastName}</span>
              <span>:&nbsp;&nbsp;{field.EIDno}</span>
            </div>
          </div>
          <div className={classes.custdiv}>
            <div>
              <span>Email </span>
              <span>Contact No</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field.Email}</span>
              <span>:&nbsp;&nbsp;{field.ContactNo}</span>
            </div>
          </div>
          {/* <div className={classes.custdiv}>
            <div>
              <span>Alternate</span>
              <span>Contact No</span>
            </div>
            <div>
              <span>
                :&nbsp;&nbsp;{field.FirstName2 + " " + field.LastName2}
              </span>
              <span>:&nbsp;&nbsp;{field.ContactNo2}</span>
            </div>
          </div> */}
        </Paper>

        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>Installation Details</h1>
        </div>
        {tablet ? (
          <Paper elevation={3}>
            <div className={classes.div1}>
              <div className={classes.div01}>
                <div className={classes.div11}>
                  <span>Customer Name</span>
                  <span>Business Name</span>
                  <span>Makani No</span>
                  <span>Plot Number</span>
                  <span>Owner FullName</span>
                  <span>Payment Invoice</span>
                  <span>Contact Name1</span>
                  <span>Emergency Number1</span>
                  <span>AssignTo</span>
                  <span>Time</span>
                  <span>ATE Status</span>
                  <span>Owner Email</span>
                  <span>Payment Status</span>
                </div>
                <div className={classes.div12}>
                  <span>:&nbsp;&nbsp;{field1.Name}</span>
                  <span>:&nbsp;&nbsp;{field1.BusinessName}</span>
                  <span>:&nbsp;&nbsp;{field1.MakaniNo}</span>
                  <span>:&nbsp;&nbsp;{field1.PlotNumber}</span>
                  <span>:&nbsp;&nbsp;{field1.FullName}</span>
                  <span>
                    :&nbsp;&nbsp;{" "}
                    {field1.receiptUrl == "" ? (
                      ""
                    ) : (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={field1.receiptUrl}
                      >
                        View Invoice
                      </a>
                    )}
                  </span>
                  <span>:&nbsp;&nbsp;{field1.ContactName1}</span>
                  <span>:&nbsp;&nbsp;{field1.EmeContact1}</span>
                  <span>:&nbsp;&nbsp;{field1.AssignTo}</span>
                  <span>:&nbsp;&nbsp;{field1.Time}</span>
                  <span>:&nbsp;&nbsp;{field1.Status}</span>
                  <span>:&nbsp;&nbsp;{field1.Email}</span>
                  <span>:&nbsp;&nbsp;{field1.payStatus}</span>
                </div>
              </div>
              {/* <div className={classes.div02}>
              <div className={classes.div21}>
                <span>3Words</span>
              </div>
              <div className={classes.div22}>
                <span>
                  :&nbsp;&nbsp;
                  <a target="_blank" rel="noreferrer" href={field1.ThreeWord}>
                    {field1.ThreeWord}
                  </a>
                </span>
              </div>
            </div> */}
            </div>
            <div className={classes.div2}>
              <div className={classes.div11}>
                <span>Address</span>
                <span>ATEs</span>
                <span>SheduleOn</span>
                <span>NOC</span>
                <span>Contact Name2</span>
                <span>Emergency Number2</span>
                <span>Owner Contact</span>
                <span>Contact Name3</span>
                <span>Emergency Number3</span>
              </div>
              <div className={classes.div12}>
                <span>:&nbsp;&nbsp;{field1.Address}</span>
                <span>:&nbsp;&nbsp;{field1.ATEs}</span>
                <span>:&nbsp;&nbsp;{field1.SheduleOn}</span>
                <span>
                  :&nbsp;&nbsp;{" "}
                  {field1.noc == "" ? (
                    ""
                  ) : (
                    <a target="_blank" rel="noreferrer" href={field1.noc}>
                      View NOC
                    </a>
                  )}
                </span>
                <span>:&nbsp;&nbsp;{field1.ContactName2}</span>
                <span>:&nbsp;&nbsp;{field1.EmeContact2}</span>
                <span>:&nbsp;&nbsp;{field1.Contact}</span>
                <span>:&nbsp;&nbsp;{field1.ContactName3}</span>
                <span>:&nbsp;&nbsp;{field1.EmeContact3}</span>
              </div>
            </div>
          </Paper>
        ) : (
          <Paper elevation={3}>
            <div className={classes.div1}>
              <div className={classes.div01}>
                <div className={classes.div11}>
                  <span>Customer Name</span>
                  <span>Business Name</span>
                  <span>Makani No</span>
                  <span>Owner FullName</span>
                  <span>Payment Invoice</span>
                  <span>Contact Name1</span>
                  <span>Emergency Number1</span>
                  <span>Payment Status</span>
                </div>
                <div className={classes.div12}>
                  <span>:&nbsp;&nbsp;{field1.Name}</span>
                  <span>:&nbsp;&nbsp;{field1.BusinessName}</span>
                  <span>:&nbsp;&nbsp;{field1.MakaniNo}</span>
                  <span>:&nbsp;&nbsp;{field1.FullName}</span>
                  <span>
                    :&nbsp;&nbsp;{" "}
                    {field1.receiptUrl == "" ? (
                      ""
                    ) : (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={field1.receiptUrl}
                      >
                        View Invoice
                      </a>
                    )}
                  </span>
                  <span>:&nbsp;&nbsp;{field1.ContactName1}</span>
                  <span>:&nbsp;&nbsp;{field1.EmeContact1}</span>
                  <span>:&nbsp;&nbsp;{field1.payStatus}</span>
                </div>
              </div>
              {/* <div className={classes.div02}>
              <div className={classes.div21}>
                <span>3Words</span>
              </div>
              <div className={classes.div22}>
                <span>
                  :&nbsp;&nbsp;
                  <a target="_blank" rel="noreferrer" href={field1.ThreeWord}>
                    {field1.ThreeWord}
                  </a>
                </span>
              </div>
            </div> */}
            </div>
            <div className={classes.div2}>
              <div className={classes.div11}>
                <span>Address</span>
                <span>ATEs</span>
                <span>SheduleOn</span>
                <span>NOC</span>
                <span>Contact Name2</span>
                <span>Emergency Number2</span>
                <span>Plot Number</span>
              </div>
              <div className={classes.div12}>
                <span>:&nbsp;&nbsp;{field1.Address}</span>
                <span>:&nbsp;&nbsp;{field1.ATEs}</span>
                <span>:&nbsp;&nbsp;{field1.SheduleOn}</span>
                <span>
                  :&nbsp;&nbsp;{" "}
                  {field1.noc == "" ? (
                    ""
                  ) : (
                    <a target="_blank" rel="noreferrer" href={field1.noc}>
                      View NOC
                    </a>
                  )}
                </span>
                <span>:&nbsp;&nbsp;{field1.ContactName2}</span>
                <span>:&nbsp;&nbsp;{field1.EmeContact2}</span>
                <span>:&nbsp;&nbsp;{field1.PlotNumber}</span>
              </div>
            </div>
            <div className={classes.div3}>
              <div className={classes.div11}>
                <span>AssignTo</span>
                <span>Time</span>
                <span>ATE Status</span>
                <span>Owner Email</span>
                <span>Owner Contact</span>
                <span>Contact Name3</span>
                <span>Emergency Number3</span>
              </div>
              <div className={classes.div12}>
                <span>:&nbsp;&nbsp;{field1.AssignTo}</span>
                <span>:&nbsp;&nbsp;{field1.Time}</span>
                <span>:&nbsp;&nbsp;{field1.Status}</span>
                <span>:&nbsp;&nbsp;{field1.Email}</span>
                <span>:&nbsp;&nbsp;{field1.Contact}</span>
                <span>:&nbsp;&nbsp;{field1.ContactName3}</span>
                <span>:&nbsp;&nbsp;{field1.EmeContact3}</span>
              </div>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
};
export default ViewDetails;
