import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "./style";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MyModal from "../../../../Component/Modal/index";
import { GETISSUE } from "../../../../RouteS/ApiRoutes/Issue";
import { ADDDAMAGEISSUE } from "../../../../RouteS/ApiRoutes/Issue";
import {
  ATEREPORTS,
  FIRESURVEYREPORT,
  TASK,
} from "../../../../RouteS/BrowserRoute/Engineer";
import {
  ADDATES,
  GETONEORDER,
  GETONEORDERATES,
  GETONEORDERDAMAGEATES,
  GETONEORDERHISTORY,
} from "../../../../RouteS/ApiRoutes/Ate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import { ConstructionOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

const AddAtes = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const engineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const userId = JSON.parse(localStorage.getItem("UserId"));
  const orderid = JSON.parse(localStorage.getItem("OrderId"));
  const firstname = JSON.parse(localStorage.getItem("FirstName"));
  const ated = JSON.parse(localStorage.getItem("ate"));
  const BusinessName = JSON.parse(localStorage.getItem("BusinessName"));
  const lastname = JSON.parse(localStorage.getItem("LastName"));
  const UserName = JSON.parse(localStorage.getItem("UserName"));
  const DueDate = JSON.parse(localStorage.getItem("dueDate"));

  const classes = useStyles();
  const [Survey, setSurvey] = useState(false);
  const [open, setOpen] = useState(false);
  const [ateData, setAteData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [issue, setIssue] = useState([]);
  const [NewData, setNewData] = useState([]);
  const [issueName, setIssueName] = useState([]);
  const [description, setDescription] = useState([]);
  const [view, setView] = useState(0);
  const [view1, setView1] = useState(true);
  const [serialno, setSerialNo] = useState();
  const [floor, setFloor] = useState();
  const history = useHistory();
  useEffect(() => {
    GetIssue();
  }, []);
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  const [field, setField] = useState({
    SerialNo: "",
    Floor: "",
  });
  const [ErrorPos, setErrorPos] = useState();
  const [Error, setError] = useState(false);
  const [field1, setField1] = useState({
    Status: "",
  });
  const [insertData2, setInsertData2] = useState([]);
  const [insertData3, setInsertData3] = useState([]);

  const onChangeIssue = (e) => {
    setIssueName(e.label);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const GetIssue = () => {
    fetch(GETISSUE, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setIssue(res.data));
  };
  const options = [
    { value: "Installed", label: "Installed" },
    { value: "Defective ATE", label: "Defective ATE" },
  ];

  const onChangeStatus = (e, i) => {
    setField1({ ...field1, Status: e.value });
    if (e.value === "Defective ATE") {
      setOpen(true);
    }
  };
  const onChangeSerialNumber = (e, j) => {
    setField({ ...field, SerialNo: e.target.value });
  };
  const onChangeLocation = (e, j) => {
    setField({ ...field, Floor: e.target.value });
  };

  useEffect(() => {
    getOneOrderAtes();
  }, []);
  const [insertData, setInsertData] = useState([]);

  const getOneOrderAtes = () => {
    const BodyData = JSON.stringify({
      OrderId: orderid,
      EngineerId: engineerId,
    });
    fetch(GETONEORDERATES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setInsertData(res.data);
        // console.log(res.data);
      });
  };

  // const Save = (i) => {
  //   if (
  //     orderid !== "" &&
  //     BusinessName !== "" &&
  //     field.SerialNo !== "" &&
  //     field.Floor !== "" &&
  //     field1.Status !== ""
  //   ) {
  //     if (field1.Status === "Installed") {
  //       setView(i);
  //       const BodyData = JSON.stringify({
  //         OrderId: orderid,
  //         BusinessName: BusinessName,
  //         InstallBy: UserName,
  //         ates: [
  //           {
  //             SerialNumber: field.SerialNo,
  //             Location: field.Floor,
  //             Status: field1.Status,
  //           },
  //         ],
  //         EngineerId: engineerId,
  //         UserId: userId,
  //       });
  //       fetch(ADDATES, {
  //         method: "POST",
  //         headers: {
  //           Authorization: "Bearer " + token + "",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //         body: BodyData,
  //       })
  //         .then((res) => res.json())
  //         .then((res) => {
  //           if (res.Status === true) {
  //             ateData.splice(0, 1);
  //             setField({ ...field, SerialNo: "", Floor: "" });
  //             setStatus({ ...status, success: true });
  //             setMessage(res.Message);
  //           }
  //         });
  //     } else {
  //       setView(i);
  //       const raw = JSON.stringify({
  //         EngineerId: engineerId,
  //         IssueName: issueName,
  //         Description: description,
  //         SerialNo: field.SerialNo,
  //         Location: field.Floor,
  //         OrderId: orderid,
  //         EngineerName: UserName,
  //       });
  //       fetch(ADDDAMAGEISSUE, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //         body: raw,
  //       })
  //         .then((res) => res.json())
  //         .then((res) => {
  //           if (res.Status === true) {
  //             setStatus({ ...status, success: true });
  //             setMessage(res.Message);
  //           }
  //         });
  //     }
  //   } else {
  //     setStatus({ ...status, error: true });
  //     setMessage("Please Fill The Above Details..");
  //     setView1(false);
  //   }
  // };
  const addDamage = () => {
    const raw = JSON.stringify({
      EngineerId: engineerId,
      UserId: userId,
      BusinessName: BusinessName,
      OrderId: orderid,
      IssueName: issueName,
      Description: description,
      SerialNo: field.SerialNo,
      Location: field.Floor,
      EngineerName: UserName,
      DueDate: DueDate,
    });
    fetch(ADDDAMAGEISSUE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setStatus({ ...status, success: true });
          setMessage(res.Message);
          setOpen(false);
        }
      });
  };

  const Submit = () => {
    if (
      orderid !== "" &&
      BusinessName !== "" &&
      field.SerialNo !== "" &&
      field.Floor !== "" &&
      field1.Status !== ""
    ) {
      setSurvey(true);
      const BodyData = JSON.stringify({
        OrderId: orderid,
        BusinessName: BusinessName,
        InstallBy: UserName,
        DueDate: DueDate,
        ates: [
          {
            SerialNumber: field.SerialNo,
            Location: field.Floor,
            Status: field1.Status,
          },
        ],
        EngineerId: engineerId,
        UserId: userId,
      });
      fetch(ADDATES, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: BodyData,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status === true) {
            ateData.splice(0, 1);
            setField({ ...field, SerialNo: "", Floor: "" });
            setStatus({ ...status, success: true });
            setMessage(res.Message);
          } else {
            setSurvey(false);
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          }
        });
    } else {
      setStatus({ ...status, error: true });
      setMessage("Please Fill The All Details");
      setView1(false);
    }
    // console.log(NewData);
  };

  const saved = insertData2.length;
  const [field8, setField8] = useState([]);

  useEffect(() => {
    view8();
  }, []);

  const view8 = () => {
    const Id = JSON.stringify({ Id: orderid });
    fetch(GETONEORDER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Id,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField8(responseJson.Data[0]);
        // console.log(responseJson.Data[0]);
      });
  };

  // field8.ATEsSurvey
  const [atessr, setAtesr] = useState("");
  const OnChangeAtesr = (e) => {
    setAtesr(e.value);
  };
  const options6 = [];
  for (var i = field8.InstalledATEs + field8.DamageATEs; i < ated; i++) {
    options6.push({
      value: i + 1,
      label: "ATEs No : " + (i + 1),
    });
  }

  return (
    <Container maxWidth="xl" className={classes.MainContainer}>
      <divMainLabel
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
            marginBottom: "20px",
          }}
        />
      </divMainLabel>
      <div style={{ marginTop: "20px" }}>
        <div style={{ textAlign: "left", marginTop: "-2rem" }}>
          <h1>Submit Today’s Task </h1>
        </div>
        <div className={classes.root}>
          <Paper elevation={3}>
            <div>
              <h3 className={classes.MainLabel}>ATEs Information</h3>
              <div className={classes.mainDiv}>
                <div className={classes.FieldDiv3}>
                  <label className={classes.textLabel}>
                    Enter ATEs Serial No
                  </label>
                  <div className="select-container">
                    <Select options={options6} onChange={OnChangeAtesr} />
                  </div>
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>ATE Serial Number</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="SerialNumber"
                    onChange={(e) => {
                      onChangeSerialNumber(e);
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>ATE Location</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="Location"
                    onChange={(e) => {
                      onChangeLocation(e);
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv2}>
                  <label className={classes.textLabel}>
                    Installation Status
                  </label>
                  <div
                    className="select-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <Select
                      options={options}
                      onChange={(e) => {
                        onChangeStatus(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {Survey ? (
              <div></div>
            ) : ated == saved ? (
              <>
                <div>
                  <Button
                    className={classes.SubmitBtn}
                    onClick={() => history.push(`/atesreportview/${orderid}`)}
                  >
                    View Ates
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Button className={classes.SubmitBtn} onClick={Submit}>
                  Submit
                </Button>
                {/* <Button className={classes.SubmitBtn} onClick={Submit}>
                  Cancel
                </Button> */}
              </div>
            )}
          </Paper>
        </div>
      </div>
      <MyModal
        open={open}
        close={() => setOpen(false)}
        title="Defective ATE Report"
        class="ReshedulModal"
        MyClose={() => setOpen(false)}
      >
        <div>
          <div className={classes.ModalField2}>
            <label>Status of ATE</label>
            <Select
              onChange={onChangeIssue}
              options={issue.map((st) => ({
                value: st.Id,
                label: st.IsssueName,
              }))}
            />
          </div>
        </div>
        <div className={classes.ModalField}>
          <label>Describe Problem</label>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            name="Description"
            onChange={onChangeDescription}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            fullWidth
            minRows={5}
            multiline
          />
        </div>
        <Button className={classes.ReasonBtn} onClick={addDamage}>
          {/* setOpen(false); */}
          Submit Reason
        </Button>
      </MyModal>
      <SucessModal
        open={status.success}
        message={message}
        close={() => {
          // setStatus({ ...status, success: false });
          history.push("/dashboardcontroller");
          // window.location.href="/atesreport";
        }}
      />
      <ErrorModal
        open={status.error}
        message={message}
        close={() => {
          setStatus({ ...status, error: false });
        }}
      />
    </Container>
  );
};
export default AddAtes;
