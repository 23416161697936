import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  RoleEngineer,
  RoleUser,
  SuperAdmin,
} from "../../ConstVariable/RoleVariable";
import EngineerDashboard from "../../Page/ProtectedPage/Engineer/Dashboard";
import AdminDashboard from "../../Page/ProtectedPage/SuperAdmin/Dashboard";
import MyDashboard from "../../Page/ProtectedPage/User/Dashboard";
import {ls} from '../../SecureLs';

const MyDashboardController = () => {
  const Role = JSON.parse(localStorage.getItem('Role'));
  switch (Role) {
    case "User":
      return <MyDashboard />;
    case RoleEngineer:
      return <EngineerDashboard />;
    case SuperAdmin:
      return <AdminDashboard />;
    default:
      return <h1>Role Not define</h1>;
  }
};
export default MyDashboardController;
