import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import jsPDF from "jspdf";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import MyModal from "../../../../Component/Modal/index";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { InputAdornment } from "@material-ui/core";
import {
  ADDFIRESURVEY,
  GETONEORDER,
} from "../../../../RouteS/ApiRoutes/Installation";
import { GETONEUSER } from "../../../../RouteS/ApiRoutes/Profile";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Profile/profile.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      height: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginLeft: "25px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  FieldDiv2: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "223px",
    alignItems: "Left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  FieldDiv9: {
    display: "flex",
    flexDirection: "row",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "223px",
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  RegisterBtn: {
    width: "35%",
    background: ThemeColor,
    marginTop: "1rem",
    color: "white",
    height: "7%",
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    // alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1rem",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    fontSize: "20px",
    margin: "0px",
  },
  MainLabel1: {
    textAlign: "left",
    marginLeft: "3rem",
    fontSize: "20px",
    marginBottom: "0px",
    marginTop: "2rem",
  },
  Titlewithbutton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Addfieldsbutton: {
    color: ThemeColor,
    fontSize: 16,
    fontWeight: 600,
    marginRight: "2rem",
  },
  AddBtnDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    marginBottom: "1.5rem",
    "&>button": {
      background: ThemeColor,
      color: "white",
      width: 130,
      marginRight: "1.5rem",
      "&:hover": {
        background: ThemeColor,
      },
    },
  },
  mainTitle: {
    textAlign: "left",
    marginTop: "-5rem",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "-1rem",
    },
  },
}));

const FireSurveyReport = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const FirstName = JSON.parse(localStorage.getItem("FirstName"));
  const LastName = JSON.parse(localStorage.getItem("LastName"));
  const UserId = JSON.parse(localStorage.getItem("UserId"));
  const ATEs = JSON.parse(localStorage.getItem("ate"));
  const OrderId = JSON.parse(localStorage.getItem("OrderId"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const IdNumber1 = JSON.parse(localStorage.getItem("IdNumber"));

  const classes = useStyles();
  let history = useHistory();

  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    IdNumber1: "",
    FirstName2: "",
    LastName2: "",
    IdNumber2: "",
    BusinessName: "",
    PremisesName: "",
    DeveloperRealEstateAgencies: "",
    NumberOfFloors: "",
    HeightOfThePremises: "",
    NumberOfBlocks: "",
    PremisesCategory: "",
    PremisesOtherInfo: "",
    Emirate: "",
    Address: "",
    Districts: "",
    MakaniNo: "",
    ThreeWord: "",
    PremisesLocation: "",
    MainRoad: "",
    Landmark: "",
    PlotNo: "",
    LAT: "",
    LON: "",
    OwnerName: "",
    OwnerDesignation: "",
    OwnerMobileNumber: "",
    OwnerAlternativeContactNumber: "",
    OwnerFax: "",
    OwnerEmail: "",
    OwnerResponsibleFor: "",
    Qty: "",
    LifeSafetyMobileNumber: "",
    LifeSafetyLocation: "",
    Make: "",
    LifeSafetyModel: "",
    AMCSupplierName: "",
    ExpiryDate: "",
    Vendor: "",
    BMSModel: "",
    Version: "",
    BMSContactPerson: "",
    BMSMobileNo: "",
    Remarks: "",
    SelectBMSProtocol: "",
    NetworkPort: "",
    BMSOtherInfo: "",
  });

  const OnChangeField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };

  const [TypeOfPremises, setTypeOfPremises] = useState("");
  const OnChangeTypeOfPremises = (e) => {
    setTypeOfPremises(e.value);
  };

  const [PremisesStatus, setPremisesStatus] = useState("");
  const OnChangePremisesStatus = (e) => {
    setPremisesStatus(e.value);
  };

  const [TypeOfClient, setTypeOfClient] = useState("");
  const OnChangeTypeOfClient = (e) => {
    setTypeOfClient(e.value);
  };

  const [SelectLifeSafetySystem1, setSelectLifeSafetySystem1] = useState("");
  const OnChangeSelectLifeSafetySystem1 = (e) => {
    setSelectLifeSafetySystem1(e.value);
  };

  const [field8, setField8] = useState([]);
  const [field9, setField9] = useState([]);

  useEffect(() => {
    view();
    view1();
  }, []);

  const view = () => {
    const Raw = JSON.stringify({ Id: UserId });

    fetch(GETONEUSER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField9(responseJson.Data[0]);
      });
  };

  const view1 = () => {
    const Id = JSON.stringify({ Id: OrderId });
    fetch(GETONEORDER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Id,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField8(responseJson.Data[0]);
      });
  };

  const [atessr, setAtesr] = useState("");
  const options6 = [];
  for (var i = field8.ATEsSurvey; i < ATEs; i++) {
    options6.push({
      value: i + 1,
      label: "ATEs No : " + (i + 1),
    });
  }
  const OnChangeAtesr = (e) => {
    setAtesr(e.value);
  };

  const options1 = [
    { value: "Assembly", label: "Assembly" },
    {
      value: "Airport terminal premisess",
      label: "Airport terminal premisess",
    },
    { value: "Animal Housing", label: "Animal Housing" },
    { value: "Business", label: "Business" },
    { value: "Bars", label: "Bars" },
    { value: "Cinemas", label: "Cinemas" },
    { value: "Casinos", label: "Casinos" },
    { value: "Clinics", label: "Clinics" },
    { value: "Commercial Villa", label: "Commercial Villa" },
    { value: "Community halls", label: "Community halls" },
    { value: "Day care", label: "Day care" },
    {
      value: "Detention and Correctional",
      label: "Detention and Correctional",
    },
    { value: "Educational", label: "Educational" },
    { value: "Exhibition halls", label: "Exhibition halls" },
    { value: "Fitness centers", label: "Fitness centers" },
    { value: "Hotel", label: "Hotel" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Hospitals", label: "Hospitals" },
    {
      value: "Industrial/Factories/ Workshops",
      label: "Industrial/Factories/ Workshops",
    },
    { value: "Indoor Stadiums", label: "Indoor Stadiums" },
    { value: "Residential", label: "Residential" },
    { value: "Private Villa", label: "Private Villa" },
    { value: "Mercantile", label: "Mercantile" },
    { value: "Malls", label: "Malls" },
    { value: "Motels", label: "Motels" },
    {
      value: "Motor Fuel Dispensing Facilities (Gas/Petrol Stations)",
      label: "Motor Fuel Dispensing Facilities (Gas/Petrol Stations)",
    },
    { value: "Parking", label: "Parking" },
    { value: "Robotic Storage", label: "Robotic Storage" },
    { value: "Residential", label: "Residential" },
    { value: "Storage/ Warehouse", label: "Storage/ Warehouse" },
  ];
  const options2 = [
    { value: "Active", label: "Active" },
    { value: "Empty", label: "Empty" },
  ];
  const options3 = [
    { value: "Educational", label: "Educational" },
    { value: "Commercial", label: "Commercial" },
    { value: "Sports", label: "Sports" },
    { value: "Industrial", label: "Industrial" },
    { value: "Residential", label: "Residential" },
    { value: "Government", label: "Government" },
    { value: "Shopping Mall", label: "Shopping Mall" },
    { value: "WareHouse", label: "WareHouse" },
    { value: "Factory", label: "Factory" },
    { value: "Labour Accommodation", label: "Labour Accommodation" },
  ];
  const options4 = [
    { value: "Fire Alarm", label: "Fire Alarm" },
    { value: "Fire Fighting", label: "Fire Fighting" },
    { value: "Central Battery", label: "Central Battery" },
    { value: "Emergency Lighting", label: "Emergency Lighting" },
  ];
  const options5 = [
    { value: "System not Powered On", label: "System not Powered On" },
    { value: "No Outputs Available", label: "No Outputs Available" },
    { value: "System in fault condition", label: "System in fault condition" },
    { value: "System in Alarm State", label: "System in Alarm State" },
    { value: "No Power For ATE", label: "No Power For ATE" },
  ];
  const options7 = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const [open3, setOpen3] = useState(false);

  const [value, setValue] = useState();
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const [formValues, setFormValues] = useState([
    {
      ContactPerson: "",
      TelephoneNumber: "",
      MobileNumber: "",
      Fax: "",
      Email: "",
      ResponsibleFor: "",
    },
  ]);

  let handleChange01 = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let handleChange02 = (i, e) => {
    console.log(e);
    let newFormValues = [...formValues];
    newFormValues[i]["TelephoneNumber"] = e;
    setFormValues(newFormValues);
  };

  let handleChange03 = (i, e) => {
    console.log(e);
    let newFormValues = [...formValues];
    newFormValues[i]["MobileNumber"] = e;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        ContactPerson: "",
        TelephoneNumber: "",
        MobileNumber: "",
        Fax: "",
        Email: "",
        ResponsibleFor: "",
      },
    ]);
  };

  const [formValues11, setFormValues11] = useState([
    { SelectLifeSafetySystem: "" },
  ]);
  const [formValues12, setFormValues12] = useState([{ SelectStatus: "" }]);
  const [install, setInstall] = useState("");

  let handleChange12 = (i, e) => {
    let newFormValues = [...formValues12];
    newFormValues[i] = { SelectStatus: e.value };
    setFormValues12(newFormValues);
  };

  let handleChangeIn = (e) => {
    setInstall(e.value);
  };

  let handleChange11 = (i, e) => {
    let newFormValues = [...formValues11];
    newFormValues[i] = { SelectLifeSafetySystem: e.value };
    setFormValues11(newFormValues);
  };

  let addFormFields1 = () => {
    setFormValues11([...formValues11, { SelectLifeSafetySystem: "" }]);
    setFormValues12([...formValues12, { SelectStatus: "" }]);
  };

  const [fieldErr, setFieldErr] = useState({
    TypeOfPremisesErr: "",
    AtessrErr: "",
    PremisesStatusErr: "",
    TypeOfClientErr: "",
    DeveloperRealEstateAgenciesErr: "",
    ReadyToInstallErr: "",
  });
  const addfiresurvey = (e) => {
    var _ = require("lodash");
    var formValues2 = [];
    for (var i = 0; i < formValues11.length; i++) {
      formValues2.push(_.merge(formValues11[i], formValues12[i]));
    }
    if (
      atessr !== "" &&
      TypeOfPremises !== "" &&
      PremisesStatus !== "" &&
      TypeOfClient !== "" &&
      field.DeveloperRealEstateAgencies !== ""
    ) {
      if (true === e) {
        var Data = JSON.stringify({
          atessr: atessr,
          installed: install,
          OrderId: OrderId,
          EngineerId: EngineerId,
          UserId: UserId,
          FirstName: FirstName,
          LastName: LastName,
          IdNumber1: IdNumber1,
          FirstName2: field.FirstName2,
          LastName2: field.LastName2,
          IdNumber2: field.IdNumber2,
          BusinessName: field8.BusinessName,
          PremisesName: field8.PremisesName,
          TypeOfPremises: TypeOfPremises,
          PremisesStatus: PremisesStatus,
          TypeOfClient: TypeOfClient,
          DeveloperRealEstateAgencies: field.DeveloperRealEstateAgencies,
          NumberOfFloors: field.NumberOfFloors,
          HeightOfThePremises: field.HeightOfThePremises,
          NumberOfBlocks: field.NumberOfBlocks,
          PremisesCategory: field.PremisesCategory,
          PremisesOtherInfo: field.PremisesOtherInfo,
          Emirate: field8.Emirate,
          Address: field8.Address,
          Districts: field8.Districts,
          MakaniNo: field8.MakaniNo,
          ThreeWord: field8.ThreeWord,
          PremisesLocation: "",
          MainRoad: "",
          Landmark: field.Landmark,
          PlotNo: field8.PlotNumber,
          LAT: field.LAT,
          LON: field.LON,
          OwnerName: field8.Name,
          OwnerDesignation: "",
          OwnerMobileNumber: field9.ContactNo,
          OwnerAlternativeContactNumber: field9.AltContactNo,
          OwnerFax: field.OwnerFax,
          OwnerEmail: field9.Email,
          OwnerResponsibleFor: field.OwnerResponsibleFor,
          SelectLifeSafetySystem1: SelectLifeSafetySystem1,
          Qty: field.Qty,
          LifeSafetyMobileNumber: field.LifeSafetyMobileNumber,
          LifeSafetyLocation: field.LifeSafetyLocation,
          Make: field.Make,
          LifeSafetyModel: field.LifeSafetyModel,
          AMCSupplierName: field.AMCSupplierName,
          ExpiryDate: field.ExpiryDate,
          Vendor: field.Vendor,
          BMSModel: field.BMSModel,
          Version: field.Version,
          BMSContactPerson: field.BMSContactPerson,
          BMSMobileNo: field.BMSMobileNo,
          Remarks: field.Remarks,
          SelectBMSProtocol: field.SelectBMSProtocol,
          NetworkPort: field.NetworkPort,
          BMSOtherInfo: field.BMSOtherInfo,
          formValues: formValues,
          formValues2: formValues2,
        });
        localStorage.setItem("Data", Data);
        fetch(ADDFIRESURVEY, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: Data,
        })
          .then((res) => res.json())
          .then((res) => {
            if (install === "Yes") {
              history.push("/dashboardcontroller");
            } else {
              history.push("/dashboardcontroller");
            }
          });
      } else {
        setOpen3(true);
      }
    } else {
      setFieldErr({
        ...fieldErr,
        TypeOfPremisesErr: "This Field is Required",
        AtessrErr: "This Field is Required",
        PremisesStatusErr: "This Field is Required",
        TypeOfClientErr: "This Field is Required",
        DeveloperRealEstateAgenciesErr: "This Field is Required",
        ReadyToInstallErr: "This Field is Required",
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginLeft: "30px",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
          }}
        />
      </div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div className={classes.mainTitle}>
            <h1>Fire Survey</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                <h3 className={classes.MainLabel1}>ATEs Serial No</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Enter ATEs Serial No
                      </label>
                      <Select options={options6} onChange={OnChangeAtesr} />
                      {atessr === "" ? (
                        <div style={{ color: "red" }}>{fieldErr.AtessrErr}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h3 className={classes.MainLabel1}>Team Details</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>First Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="FirstName"
                        value={FirstName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Last Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LastName"
                        value={LastName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>ID Number</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="IdNumber1"
                        value={IdNumber1}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>First Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="FirstName2"
                        value={field.FirstName2}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Last Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LastName2"
                        value={field.LastName2}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>ID Number</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="IdNumber2"
                        value={field.IdNumber2}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h3 className={classes.MainLabel}>Premises Details</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Business Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BusinessName"
                        value={field8.BusinessName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Premises Name</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesName"
                        value={field8.PremisesName}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Type Of Premises
                      </label>
                      <Select
                        options={options1}
                        isSearchable={Boolean}
                        onChange={OnChangeTypeOfPremises}
                      />
                      {TypeOfPremises === "" ? (
                        <div style={{ color: "red" }}>
                          {fieldErr.TypeOfPremisesErr}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Premises Status
                      </label>
                      <Select
                        options={options2}
                        onChange={OnChangePremisesStatus}
                      />
                      {PremisesStatus === "" ? (
                        <div style={{ color: "red" }}>
                          {fieldErr.PremisesStatusErr}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Type Of Client
                      </label>
                      <Select
                        options={options3}
                        onChange={OnChangeTypeOfClient}
                      />
                      {TypeOfClient === "" ? (
                        <div style={{ color: "red" }}>
                          {fieldErr.TypeOfClientErr}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Developer / Real Estate Agencies
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="DeveloperRealEstateAgencies"
                        value={field.DeveloperRealEstateAgencies}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                      {field.DeveloperRealEstateAgencies === "" ? (
                        <div style={{ color: "red" }}>
                          {fieldErr.DeveloperRealEstateAgenciesErr}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Number of Floors
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="NumberOfFloors"
                        value={field.NumberOfFloors}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Height of the Premises
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="HeightOfThePremises"
                        value={field.HeightOfThePremises}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Number of Blocks
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="NumberOfBlocks"
                        value={field.NumberOfBlocks}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Premises Category
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesCategory"
                        value={field.PremisesCategory}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Other Info.</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesOtherInfo"
                        value={field.PremisesOtherInfo}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* Location Div */}
                <h3 className={classes.MainLabel}>Premises Locatoin</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Emirate</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        value={field8.Emirate}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                      {/* <Select options={options6} onChange={OnChangeEmirates} /> */}
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Address</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Address"
                        value={field8.Address}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Districts</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Districts"
                        value={field8.Districts}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Makani No</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="MakaniNo"
                        value={field8.MakaniNo}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    {/* <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>3Words</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Threeword"
                        value={field8.ThreeWord}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div> */}
                    {/* <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Location</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PremisesLocation"
                        value={field.PremisesLocation}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div> */}
                    {/* <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Main Road</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="MainRoad"
                        value={field.MainRoad}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div> */}
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Landmark</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Landmark"
                        value={field.Landmark}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Plot No</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="PlotNo"
                        value={field8.PlotNumber}
                        // onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Latitude</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LAT"
                        value={field.LAT}
                        onChange={OnChangeField}
                        inputProps={{ maxlength: 3, minlength: 3 }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Longitude</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LON"
                        value={field.LON}
                        onChange={OnChangeField}
                        inputProps={{ maxlength: 3, minlength: 3 }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* Owner Div */}
                <div className={classes.Titlewithbutton}>
                  <h3 className={classes.MainLabel}>Contact Details</h3>
                  <Button
                    className={classes.Addfieldsbutton}
                    onClick={() => addFormFields()}
                  >
                    Add Contact +
                  </Button>
                </div>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Owner Name</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerName"
                      value={field8.Name}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Designation</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerDesignation"
                      value={field.OwnerDesignation}
                      onChange={OnChangeField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div> */}
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Mobile Number</label>
                    <ReactPhoneInput
                      country={"ae"}
                      value={field9.ContactNo}
                      onChange={(e) => {
                        setField({ ...field, ContactNo: e });
                      }}
                    />
                    {/* <TextField
                      style={{ width: "223px" }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerMobileNumber"
                      value={field9.ContactNo}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Alternative Contact Number
                    </label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerAlternativeContactNumber"
                      style={{ width: "220px" }}
                      inputProps={{ maxLength: 12 }}
                      value={field9.AltContactNo}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    />
                  </div> */}
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Fax</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerFax"
                      value={field.OwnerFax}
                      onChange={OnChangeField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Email</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerEmail"
                      value={field9.Email}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Responsible For</label>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      name="OwnerResponsibleFor"
                      value={field.OwnerResponsibleFor}
                      onChange={OnChangeField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                </div>
                {/* ContactDiv */}
                <div>
                  {formValues.map((element, index) => (
                    <>
                      <div className={classes.mainDiv}>
                        <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>
                            Contact Person
                          </label>
                          <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="ContactPerson"
                            value={element.ContactPerson || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                          />
                        </div>
                        {/* <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>
                            Designation
                          </label>
                          <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="Designation"
                            value={element.Designation || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                          />
                        </div> */}
                        <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>
                            Telephone Number
                          </label>
                          <ReactPhoneInput
                            country={"ae"}
                            value={element.TelephoneNumber || ""}
                            onChange={(e) => handleChange02(index, e)}
                            name="TelephoneNumber"
                          />
                          {/* <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="TelephoneNumber"
                            style={{ width: "220px" }}
                            inputProps={{ maxLength: 12 }}
                            value={element.TelephoneNumber || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  +
                                </InputAdornment>
                              ),
                            }}
                          /> */}
                        </div>
                        <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>
                            Mobile Number
                          </label>
                          <ReactPhoneInput
                            country={"ae"}
                            value={element.MobileNumber || ""}
                            onChange={(e) => handleChange03(index, e)}
                            name="MobileNumber"
                          />
                          {/* <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="MobileNumber"
                            style={{ width: "225px" }}
                            inputProps={{ maxLength: 12 }}
                            value={element.MobileNumber || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  +
                                </InputAdornment>
                              ),
                            }}
                          /> */}
                        </div>
                        <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>Fax</label>
                          <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="Fax"
                            value={element.Fax || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                          />
                        </div>
                        <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>Email</label>
                          <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="Email"
                            value={element.Email || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                          />
                        </div>
                        <div className={classes.FieldDiv}>
                          <label className={classes.textLabel}>
                            Responsible For
                          </label>
                          <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            name="ResponsibleFor"
                            value={element.ResponsibleFor || ""}
                            onChange={(e) => handleChange01(index, e)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                {/* system div */}
                <div className={classes.Titlewithbutton}>
                  <h3 className={classes.MainLabel}>Life Safety Systems</h3>
                </div>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Select Life Safety System
                      </label>
                      <Select
                        options={options4}
                        onChange={OnChangeSelectLifeSafetySystem1}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Qty</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Qty"
                        value={field.Qty}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Location</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LifeSafetyLocation"
                        value={field.LifeSafetyLocation}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Make</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Make"
                        value={field.Make}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Model</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LifeSafetyModel"
                        value={field.LifeSafetyModel}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        AMC /Supplier Name
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="AMCSupplierName"
                        value={field.AMCSupplierName}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Contact Number
                      </label>
                      <ReactPhoneInput
                        country={"ae"}
                        value={field.LifeSafetyMobileNumber}
                        onChange={(e) => {
                          setField({ ...field, LifeSafetyMobileNumber: e });
                        }}
                      />
                      {/* <TextField
                        style={{ width: "223px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        name="LifeSafetyMobileNumber"
                        inputProps={{ maxLength: 12 }}
                        value={field.LifeSafetyMobileNumber}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                          ),
                        }}
                      /> */}
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Expiry Date</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="ExpiryDate"
                        value={field.ExpiryDate}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* bms div */}
                <h3 className={classes.MainLabel}>BMS System</h3>
                <div>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Vendor</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Vendor"
                        value={field.Vendor}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Model</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSModel"
                        value={field.BMSModel}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Version</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Version"
                        value={field.Version}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        Contact Person
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSContactPerson"
                        value={field.BMSContactPerson}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Mobile No</label>
                      <ReactPhoneInput
                        country={"ae"}
                        value={field.BMSMobileNo}
                        onChange={(e) => {
                          setField({ ...field, BMSMobileNo: e });
                        }}
                      />
                      {/* <TextField
                        style={{ width: "223px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSMobileNo"
                        inputProps={{ maxLength: 12 }}
                        value={field.BMSMobileNo}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                          ),
                        }}
                      /> */}
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Remarks</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="Remarks"
                        value={field.Remarks}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Select BMS Protocol
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="SelectBMSProtocol"
                        value={field.SelectBMSProtocol}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Network Port</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="NetworkPort"
                        value={field.NetworkPort}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Other Info.</label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        name="BMSOtherInfo"
                        value={field.BMSOtherInfo}
                        onChange={OnChangeField}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.MainLabel}>
                  <div className={classes.Titlewithbutton}>
                    <div>
                      <h3>Life Safety Systems Snags List</h3>
                      <h3>(Visible Observations only)</h3>
                    </div>
                    <Button
                      className={classes.Addfieldsbutton}
                      onClick={() => addFormFields1()}
                    >
                      Add Life Safety Systems +
                    </Button>
                  </div>
                </div>
                <div>
                  {formValues11.map((element, index) => (
                    <>
                      <div className={classes.mainDiv}>
                        <div className={classes.FieldDiv2} key={index}>
                          <label className={classes.textLabel}>
                            Select Life Safety System
                          </label>
                          <Select
                            options={options4}
                            onChange={(e) => handleChange11(index, e)}
                          />
                        </div>
                        <div className={classes.FieldDiv2} key={index}>
                          <label className={classes.textLabel}>
                            Select Status
                          </label>
                          <Select
                            options={options5}
                            onChange={(e) => handleChange12(index, e)}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                </div>

                <h3 className={classes.MainLabel}>ATEs Install On Survey</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv2}>
                    <label className={classes.textLabel}>
                      Ready To Install
                    </label>
                    <Select
                      options={options7}
                      onChange={(e) => {
                        handleChangeIn(e);
                      }}
                    />
                    {install === "" ? (
                      <div style={{ color: "red" }}>
                        {fieldErr.ReadyToInstallErr}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className={classes.AddBtnDiv}>
                  <Button onClick={history.goBack}>Cancel</Button>
                  <Button
                    onClick={() => {
                      if (install === "") {
                        addfiresurvey();
                      } else {
                        addfiresurvey(true);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
        {/* <MyModal
          open={open3}
          close={() => setOpen3(false)}
          title="Ready To Install ATEs For Survey Details?"
          class="ReshedulModal"
          MyClose={() => setOpen3(false)}
        >
          <div
            style={{
              color: "black",
              fontWeight: 600,
              display: "flex",
              paddingBottom: "2rem",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              color: "black",
              display: "flex",
              alignItems: "right",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                marginRight: "0px",
                backgroundColor: ThemeColor,
                color: "white",
              }}
              onClick={() => {
                if (value === "yes") {
                  addfiresurvey(true);
                } else {
                  setOpen3(false);
                }
              }}
            >
              Ok
            </Button>
            <Button
              style={{
                marginRight: "0px",
                backgroundColor: ThemeColor,
                color: "white",
                marginLeft: "15px",
              }}
              onClick={() => {
                setOpen3(false);
                setValue("");
              }}
            >
              Cancel
            </Button>
          </div>
        </MyModal> */}
      </Container>
    </>
  );
};
export default FireSurveyReport;
