import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "20px",
    },
  },
}));
export default useStyles;
