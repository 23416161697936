import { Button, Divider, IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import { ADDTICKET } from "../../../../RouteS/BrowserRoute/User";
import { useHistory } from "react-router-dom";
import {
  ADDSTAFF,
  DETAILSTAFF,
  EDITSTAFF2,
  VIEWSTAFF,
} from "../../../../RouteS/BrowserRoute/Admin";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { VIEWALLSTAFF } from "../../../../RouteS/ApiRoutes/Staff";
import { DELETESTAFF } from "../../../../RouteS/ApiRoutes/Staff";
import SucessModal from "../../../../Component/Modal/SucessModal";

const useStyles = makeStyles(() => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  Titlediv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
}));

const token = JSON.parse(localStorage.getItem("token"));
const MyStaff = () => {
  const history = useHistory();
  const classes = useStyles();
  const [Data, setData] = useState([]);
  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Name",
      id: "Name",
      Cell: (row) => {
        return row.original.FirstName + " " + row.original.LastName;
      },
    },
    {
      Header: "Branch",
      accessor: "Branch",
    },
    {
      Header: "User Name",
      accessor: "UserName",
    },
    {
      Header: "Email",
      accessor: "Email",
    },
    {
      Header: "Contact No",
      // accessor: "ContactNo",
      id: "Contact No",
      Cell: (row) => {
        return "+"+row.original.ContactNo;
      },
    },
    {
      Headers: "",
      id: "Action",
      filterable: false,
      Cell: (data) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => history.push(DETAILSTAFF + "/" + data.original.Id)}
            >
              <RemoveRedEyeIcon style={{ fontSize: 20, color: "#3AAFA9" }} />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ background: "black", height: 15, width: 2 }}
            />
            <IconButton
              style={{ padding: 0 }}
              onClick={() => history.push(EDITSTAFF2 + "/" + data.original.Id)}
            >
              <EditIcon style={{ fontSize: 20, color: "#000000" }} />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ background: "black", height: 15, width: 2 }}
            />
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                save(data.original.Id);
                view();
              }}
            >
              <DeleteIcon style={{ fontSize: 20, color: "#717171" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    view();
  }, []);

  const [a, setA] = useState(false);
  const view = () => {
    fetch(VIEWALLSTAFF, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
        localStorage.setItem(
          "engineers",
          JSON.stringify(responseJson.Data.length)
        );
      });
  };

  const save = (Id) => {
    const c = { Id: Id };
    fetch(DELETESTAFF, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(c),
    })
      .then((res) => res.json())
      .then((responseJson) => {
        if (responseJson.Status === true) {
          setStatus({ ...status, success: true });
          setMessage(responseJson.Message);
        } else {
          setStatus({ ...status, error: true });
          setMessage(responseJson.Message);
        }
      });
    view();
  };
  return (
    <div className={classes.MainContainer}>
      <div className={classes.Titlediv}>
        <h1 style={{ textAlign: "left" }}>Staff Members</h1>
        <Button
          style={{ color: ThemeColor, fontSize: 16, fontWeight: 600 }}
          onClick={() => history.push(ADDSTAFF)}
        >
          Add Staff +
        </Button>
      </div>
      <MyTable data={Data} columns={columns} />
      <SucessModal
        open={status.success}
        message={message}
        close={() => {
          setStatus({ ...status, success: false });
        }}
      />
    </div>
  );
};
export default MyStaff;
