import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Component/Header";
import Login from "./Page/User/Login/Login";
import Request from "./Page/User/ForgotPassword/Request";
import ForgotPass from "./Page/User/ForgotPassword/ForgotPass";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Component/Footer";
import SignUp from "./Page/User/SignUp";
import Email from "./Page/User/Email/index";
import {
  PREMISES,
  PREMISESRETURN,
  PREMISESREPAY,
  LOGIN,
  SIGNUP,
  DASHBOARD,
  VIEWPREMISES,
  VIEWTICKET,
  VIEWORDER,
  PRODUCT,
  ADDTICKET,
  PROFILE,
  REQUEST,
  EMAIL2,
  FORGOTPASS,
  USERVIEWSURVEY,
  MAINPAGE,
  CONATCTUS,
  PROGRAM,
  REGISTRATIONFEES,
  REQUIREMENTS,
  MEDIA,
  USERDETAILSURVEY,
  CUSTOMERNOTIFICATION,
} from "./RouteS/BrowserRoute/User";
import Premises from "./Page/Premises";
import Repaypremises from "./Page/Premises/Repayment";
import ProtectedRoute from "./RouteS/Protected/Protected";
import Dashboard from "./Page/ProtectedPage/User/Dashboard";
import MyDashboard from "./Page/ProtectedPage/User/Dashboard";
import MyPremises from "./Page/ProtectedPage/User/Premises";
import MyTicket from "./Page/ProtectedPage/User/Ticket";
import CustomerNotification from "./Page/ProtectedPage/User/Notification";
import MyOrder1 from "./Page/ProtectedPage/User/Order";
import ViewProduct from "./Page/ProtectedPage/User/ViewProduct";
import AddTicket from "./Page/ProtectedPage/User/Ticket/AddTicket";
import Userviewsurvey from "./Page/ProtectedPage/User/FireSurvey/index";
import UserDetailSurvey from "./Page/ProtectedPage/User/FireSurvey/UserDetailSurvey";
import Profile from "./Page/ProtectedPage/User/Profile";
import {
  DASHBOARDCONTROLLER,
  PROFILECONTROLLER,
} from "./RouteS/BrowserRoute/ControllerRoute";
import MyDashboardController from "./Controller/Dashboard/index";
import {
  ATEREPORTS,
  ATEREPORTSVIEW,
  ADDDAMAGEATES,
  ENGINEERDAMAGE,
  ENGINEERDAMAGEHISTORY,
  ENGINEERLOGIN,
  SERVICES,
  FIRESURVEYREPORT,
  VIEWSURVEY,
  TASK,
  ENGINEERNOTIFICATION,
  ENGINEERVIEWSURVEY,
  ENGINEERDETAILSURVEY,
  ENGINEEREDITSURVEY,
} from "./RouteS/BrowserRoute/Engineer";
import EngineerLogin from "./Page/ProtectedPage/Engineer/Login/Login";
import ViewSurvey from "./Page/ProtectedPage/Engineer/ViewSurvey";
import ATEsReport from "./Page/ProtectedPage/Engineer/ATEsReport";
import EngineerNotification from "./Page/ProtectedPage/Engineer/Notification";
import ViewAtes from "./Page/ProtectedPage/Engineer/ATEsReport/ViewAtes";
import ServiceTask from "./Page/ProtectedPage/Engineer/ServiceTask";
import IntstallationTask from "./Page/ProtectedPage/Engineer/InstallationTask";
import ProfileController from "./Controller/Profile";
import DamageEngineer from "./Page/ProtectedPage/Engineer/DamageEngineer";
import DamageEngineerHistory from "./Page/ProtectedPage/Engineer/DamageEngineerHistory";
import EngineerViewSurvey from "./Page/ProtectedPage/Engineer/EngineerViewSurvey";
import EngView from "./Page/ProtectedPage/Engineer/EngineerViewSurvey/EngViewSurvey";
import {
  ADDSTAFF,
  ADMINLOGIN,
  ADMIN,
  ADDADMIN,
  EDITADMIN,
  CUSTOMERREQUEST,
  DEATAILCUSTOMER,
  DETAILSTAFF,
  EDITSTAFF,
  MYCUSTOMER,
  RESHEDULEREQ,
  TIMESLOTS,
  NOTIFICATION,
  VIEWSTAFF,
  ADDTIME,
  EDITTIME,
  INSTALLATIONDETAILS,
  SERVICEDETAILS,
  DAMAGEREQUEST,
  DAMAGEHISTORY, 
  ADDPRICE,
  VIEWPRICE,
  EDITPRICE,
  ADMINVIEWSURVEY,
  DETAILSURVEY,
} from "./RouteS/BrowserRoute/Admin";
import AdminLogin from "./Page/ProtectedPage/SuperAdmin/Login/Login";
import SetAdmin from "./Page/ProtectedPage/SuperAdmin/Admin";
import AddAdmin from "./Page/ProtectedPage/SuperAdmin/Admin/AddAdmin";
import UpdateAdmin from "./Page/ProtectedPage/SuperAdmin/Admin/UpdateAdmin";
import MyCustomer from "./Page/ProtectedPage/SuperAdmin/Customer";
import AdminViewSurvey from "./Page/ProtectedPage/SuperAdmin/AdminViewSurvey";
import Detailsurvey from "./Page/ProtectedPage/SuperAdmin/AdminViewSurvey/Detailsurvey";
import DetailCustomer from "./Page/ProtectedPage/SuperAdmin/Customer/DetailCustomer";
import MyStaff from "./Page/ProtectedPage/SuperAdmin/Staff";
import AddStaff from "./Page/ProtectedPage/SuperAdmin/Staff/AddStaff";
import EditStaff from "./Page/ProtectedPage/SuperAdmin/Staff/EditStaff";
import DetailStaff from "./Page/ProtectedPage/SuperAdmin/Staff/DetailStaff";
import CustomerReq from "./Page/ProtectedPage/SuperAdmin/ReqCustomer";
import InstallationViewDetails from "./Page/ProtectedPage/SuperAdmin/Request/Installation/ViewDetails";
import ServiceViewDetails from "./Page/ProtectedPage/SuperAdmin/Request/Service/ViewDetails";
import TimeSlots from "./Page/ProtectedPage/SuperAdmin/TimeSlots";
import AddTime from "./Page/ProtectedPage/SuperAdmin/TimeSlots/AddTime";
import EditTime from "./Page/ProtectedPage/SuperAdmin/TimeSlots/EditTime";
import RescheduleReq from "./Page/ProtectedPage/SuperAdmin/ResheduleReq";
import Page1 from "./Frontend/Page1";
import Main from "./Frontend/Main";
import Calendar1 from "./Component/Calendar/Calendar1";
import ServiceCalendar from "./Component/Calendar/ServiceCalendar";
import InstallationCalendar from "./Component/Calendar/InstallationCalendar";
import InstallationCalendarEngineer from "./Component/Calendar/InstallationCalendarEngineer";
import ServiceCalendarEngineer from "./Component/Calendar/ServiceCalendarEngineer";
import FireSurveyReport from "./Page/ProtectedPage/Engineer/SurveyForm";
import EditSurvey from "./Page/ProtectedPage/Engineer/SurveyForm/EditSurveyForm";
import DamageRequest from "./Page/ProtectedPage/SuperAdmin/DamageRequest";
import DamageHistory from "./Page/ProtectedPage/SuperAdmin/DamageHistory";
import Notification from "./Page/ProtectedPage/SuperAdmin/Notification";
import MyPrice from "./Page/ProtectedPage/SuperAdmin/Price";
import AddPrice1 from "./Page/ProtectedPage/SuperAdmin/Price/AddPrice1";
import EditPrice from "./Page/ProtectedPage/SuperAdmin/Price/EditPrice";
import AddAtesReport from "./Page/ProtectedPage/Engineer/ATEsReport/AddATES";
import AddDamageATEs from "./Page/ProtectedPage/Engineer/DamageEngineer/AddDamageATEs";

import ContactUs from "./Frontend/ContactUs";
import Program from "./Frontend/Program";
import Registrationfees from "./Frontend/Registrationfees";
import Requirements from "./Frontend/Requirements";
import Media from "./Frontend/Media";

function App() {
  return (
    <div className="App">
      <Router>
        {/* <Header /> */}
        {/* Controller Switch */}
        <Switch>
          <ProtectedRoute
            path={DASHBOARDCONTROLLER}
            component={MyDashboardController}
          />
          <ProtectedRoute
            path={PROFILECONTROLLER}
            component={ProfileController}
          />
        </Switch>

        {/* User Switch */}
        <Switch>
          <Route path={CONATCTUS} component={ContactUs} exact />
          <Route path={PROGRAM} component={Program} exact />
          <Route path={REGISTRATIONFEES} component={Registrationfees} exact />
          <Route path={REQUIREMENTS} component={Requirements} exact />
          <Route path={MEDIA} component={Media} exact />

          <Route path={MAINPAGE} component={Main} exact />
          <Route path={LOGIN} component={Login} exact />
          <Route path={SIGNUP} component={SignUp} exact />
          <Route path={EMAIL2} component={Email} exact />
          <Route path={PREMISES} component={Premises} exact />
          <Route path={PREMISESRETURN} component={Premises} exact />
          <Route path={PREMISESREPAY} component={Repaypremises} exact />
          <Route path={REQUEST} component={Request} exact />
          <Route path={FORGOTPASS} component={ForgotPass} exact />
          <ProtectedRoute path={VIEWPREMISES} component={MyPremises} />
          <ProtectedRoute path={VIEWTICKET} component={MyTicket} />
          {/* <ProtectedRoute path={CUSTOMERNOTIFICATION} component={CustomerNotification} /> */}
          <ProtectedRoute path={VIEWORDER} component={MyOrder1} />
          <ProtectedRoute path={PRODUCT} component={ViewProduct} />
          <ProtectedRoute path={ADDTICKET} component={AddTicket} />
          <ProtectedRoute path={USERVIEWSURVEY} component={Userviewsurvey} />
          <ProtectedRoute
            path={USERDETAILSURVEY}
            component={UserDetailSurvey}
          />
        </Switch>

        {/* Engineer Switch */}
        <Switch>
          <Route path={ENGINEERLOGIN} component={EngineerLogin} />
          <ProtectedRoute path={ATEREPORTS} component={AddAtesReport} />
          <ProtectedRoute path={ATEREPORTSVIEW} component={ViewAtes} />
          <ProtectedRoute path={SERVICES} component={ServiceTask} />
          {/* <ProtectedRoute path={ENGINEERNOTIFICATION} component={EngineerNotification} /> */}
          <ProtectedRoute path={TASK} component={IntstallationTask} />
          <ProtectedRoute path={ENGINEERDAMAGE} component={DamageEngineer} />
          <ProtectedRoute path={ADDDAMAGEATES} component={AddDamageATEs} />
          <ProtectedRoute
            path={ENGINEERDAMAGEHISTORY}
            component={DamageEngineerHistory}
          />
          <ProtectedRoute
            path={FIRESURVEYREPORT}
            component={FireSurveyReport}
          />
          <ProtectedRoute
            path={ENGINEERVIEWSURVEY}
            component={EngineerViewSurvey}
          />
          <ProtectedRoute path={ENGINEERDETAILSURVEY} component={EngView} />
          <ProtectedRoute path={ENGINEEREDITSURVEY} component={EditSurvey} />
          <ProtectedRoute path={VIEWSURVEY} component={ViewSurvey} />
        </Switch>

        {/* Super Admin Switch */}
        <Switch>
          <Route path={ADMINLOGIN} component={AdminLogin} />
          {/* <ProtectedRoute path={ADMIN} component={SetAdmin} />
          <ProtectedRoute path={ADDADMIN} component={AddAdmin} />
          <ProtectedRoute path={EDITADMIN} component={UpdateAdmin} /> */}
          <ProtectedRoute path={DEATAILCUSTOMER} component={DetailCustomer} />
          <ProtectedRoute path={VIEWSTAFF} component={MyStaff} />
          <ProtectedRoute path={ADDSTAFF} component={AddStaff} />
          <ProtectedRoute path={EDITSTAFF} component={EditStaff} />
          <ProtectedRoute path={DETAILSTAFF} component={DetailStaff} />
          <ProtectedRoute path={CUSTOMERREQUEST} component={CustomerReq} />
          <ProtectedRoute path={MYCUSTOMER} component={MyCustomer} />
          <ProtectedRoute path={ADMINVIEWSURVEY} component={AdminViewSurvey} />
          <ProtectedRoute path={DETAILSURVEY} component={Detailsurvey} />
          <ProtectedRoute
            path={INSTALLATIONDETAILS}
            component={InstallationViewDetails}
          />
          <ProtectedRoute
            path={SERVICEDETAILS}
            component={ServiceViewDetails}
          />
          <ProtectedRoute path={DAMAGEREQUEST} component={DamageRequest} />
          <ProtectedRoute path={DAMAGEHISTORY} component={DamageHistory} />
          {/* <ProtectedRoute path={NOTIFICATION} component={Notification} /> */}
          <ProtectedRoute path={TIMESLOTS} component={TimeSlots} />
          <ProtectedRoute path={ADDTIME} component={AddTime} />
          <ProtectedRoute path={EDITTIME} component={EditTime} />
          <ProtectedRoute path={RESHEDULEREQ} component={RescheduleReq} />
          <ProtectedRoute path={VIEWPRICE} component={MyPrice} />
          <ProtectedRoute path={ADDPRICE} component={AddPrice1} />
          <ProtectedRoute path={EDITPRICE} component={EditPrice} />
        </Switch>

        <Switch>
          <ProtectedRoute path="/Calendar" component={Calendar1} />
          <ProtectedRoute path="/ServiceCalendar" component={ServiceCalendar} />
          <ProtectedRoute
            path="/ServiceCalendarEngineer"
            component={ServiceCalendarEngineer}
          />
          <ProtectedRoute
            path="/InstallationCalendar"
            component={InstallationCalendar}
          />
          <ProtectedRoute
            path="/InstallationCalendarEngineer"
            component={InstallationCalendarEngineer}
          />
        </Switch>
      </Router>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
