import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import InstallationReq from "../../Request/Installation";
import ServiceReq from "../../Request/Service";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  MainRoot: {
    marginRight: 0,
    "&>.insertTab .MuiBox-root": {
      paddingLeft: 0,
      width: "100%",
      paddingRight: 0,
    },
  },
  root: {
    borderBottom: "0px !important",
    marginLeft: "25px",
    "&>.MuiTabs-root": {
      width: 255,
      border: "1px solid " + ThemeColor + "",
      borderRadius: 10,
      minHeight: 0,
      height: 40,
      alignItems: "center",
      "&> .MuiTabs-scroller .MuiTabs-flexContainer button": {
        width: "50%",
        fontWeight: 600,
        color: "black",
      },
      "&> .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected": {
        background: ThemeColor,
        color: "white",
        fontWeight: 600,
      },
    },
  },
}));
const MyTab = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className={classes.MainRoot}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        className={classes.root}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Installations" {...a11yProps(0)} />
          <Tab label="Services" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="insertTab">
        <InstallationReq />
      </TabPanel>
      <TabPanel value={value} index={1} className="insertTab">
        <ServiceReq />
      </TabPanel>
    </Box>
  );
};
export default MyTab;
