import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import { DEATAILCUSTOMER2 } from "../../../../RouteS/BrowserRoute/Admin";
import useStyle from "./style";
import { GETALLUSER } from "../../../../RouteS/ApiRoutes/Customer";

const ServiceReq = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyle();
  const history = useHistory();
  const [Data, setData] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    view();
  }, []);

  const view = () => {
    fetch(GETALLUSER, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
        localStorage.setItem(
          "Customer",
          JSON.stringify(responseJson.Data.length)
        );
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Customer Name",
      id: "Name",
      Cell: (row) => {
        return row.original.FirstName + " " + row.original.LastName;
      },
    },
    {
      Header: "EID Number",
      accessor: "EIDno",
    },
    {
      Header: "Contact Number",
      accessor: "ContactNo",
      id: "icon",
      Cell: (data) => {
        return "+" + data.original.ContactNo;
      },
    },
    {
      Header: "Email Address",
      accessor: "Email",
    },
    {
      Header: " ",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={() =>
              history.push(DEATAILCUSTOMER2 + "/" + data.original.Id)
            }
          >
            View Detail
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <div>
        <h1 style={{ textAlign: "left" }}>My Customer</h1>
      </div>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default ServiceReq;
