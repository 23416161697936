import moment from "moment";
import useStyle from "./style";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MyModal from "../../../../../Component/Modal/index";
import MyTable from "../../../../../Component/Table/index";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Button, IconButton, TextField } from "@material-ui/core";
import { VIEWALLTIME } from "../../../../../RouteS/ApiRoutes/Time";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import { VIEWONEORDER } from "../../../../../RouteS/ApiRoutes/Order";
import { GETENGINEER } from "../../../../../RouteS/ApiRoutes/MyEngineer";
import {
  ASSIGNINSTLLATION,
  GETINSTALLATIONADMIN,
  GETPENDING,
  GETINPROGRESS,
  GETCOMPLETE,
} from "../../../../../RouteS/ApiRoutes/Installation";
import { element } from "prop-types";
import { Domain } from "../../../../../RouteS/ApiDomain";

const InstallationReq = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [EngineerList, setEngineerList] = useState([]);
  const [Data, setData] = useState([]);
  const [userId, setUserId] = useState();
  const [businessName, setBusinessName] = useState();
  const [ates, setAtes] = useState();
  const [address, setAddress] = useState();
  const [name, setName] = useState();
  const [serviceId, setServiceId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [Effect, setEffect] = useState(false);
  const [RangeDate, setRangeDate] = useState([]);
  const [RangeEffect, setRangeEffect] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [assign, setAssign] = useState("");
  const [reassign, setReassign] = useState("");
  const [new1, setNew1] = useState();
  const [new2, setNew2] = useState();
  const [time, setTime] = useState();
  const [Abc, setAbc] = useState([]);
  const [sta1, setSta1] = useState([]);
  const [holdmessage, setHoldMessage] = useState();
  const [sta2, setSta2] = useState([]);
  const [sta3, setSta3] = useState([]);
  const [size1, setSize1] = useState(20);
  const [EngineerVariable, setVariable] = useState({
    EngineerId: "",
    AssginTo: "",
  });

  useEffect(() => {
    getServices();
    getEngineer();
    getPending();
    getInprogress();
    getComplete();
  }, [Effect ? Data : null, RangeEffect ? RangeDate : null]);

  const getPending = () => {
    fetch(GETPENDING, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSta1(res.Data);
        localStorage.setItem("Pending", JSON.stringify(res.Data.length));
      });
  };

  const getInprogress = () => {
    fetch(GETINPROGRESS, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSta2(res.Data);
        localStorage.setItem("InProgress", JSON.stringify(res.Data.length));
      });
  };

  const getComplete = () => {
    fetch(GETCOMPLETE, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSta3(res.Data);
        localStorage.setItem("Complete", JSON.stringify(res.Data.length));
      });
  };

  const [sta4, setSta4] = useState([]);

  const getServices = () => {
    fetch(GETINSTALLATIONADMIN, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.Data);
        const filteredUsers = res.Data.filter((user) => {
          return user.payStatus == "Complete";
        });
        for (var i = 0; i < res.Data.length; i++) {
          if (res.Data[i].Status == "Pending" && res.Data[i].payStatus == "Complete") {
            sta4.push(res.Data[i]);
          }
        }
        const sumWithInitial = filteredUsers.reduce(function (acc, obj) { return acc + obj.ATEs; }, 0);
        // console.log(sumWithInitial);
        localStorage.setItem("Inorder", JSON.stringify(sta4.length));
        localStorage.setItem("Orders", JSON.stringify(res.Data.length));
        localStorage.setItem("Paid", JSON.stringify(filteredUsers.length));
        localStorage.setItem("TotalPaidATEs", JSON.stringify(sumWithInitial));
      });
  };

  const getEngineer = () => {
    fetch(GETENGINEER, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => setEngineerList(res.Data));
  };

  const GetDatranges = (id) => {
    setRangeEffect(true);
    fetch(VIEWONEORDER, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ Id: id }),
    })
      .then((res) => res.json())
      .then((res) => {
        setRangeDate(res.Data);
        setRangeEffect(false);
      });
  };

  const handleCallback = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };

  const onChangeEngineer = (e) => {
    setVariable({
      ...EngineerVariable,
      EngineerId: e.value,
      AssginTo: e.label,
      Time: e.label,
    });
  };

  const onChangeTime1 = (e) => {
    setTime(e.value);
  };

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWALLTIME, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setAbc(responseJson.data);
      });
  };

  const options = [];
  for (var i = 0; i < Abc.length; i++) {
    options.push({ value: Abc[i].Time, label: Abc[i].Time });
  }

  const AssignEngineer = () => {
    setEffect(true);
    const Data = JSON.stringify({
      Id: serviceId,
      EngineerId: EngineerVariable.EngineerId,
      SheduleOn: startDate,
      AssignTo: EngineerVariable.AssginTo,
      UserId: userId,
      ATEs: ates,
      Address: address,
      BusinessName: businessName,
      Name: name,
      Time: time,
      Assign: assign,
      Reassign: reassign,
    });
    fetch(ASSIGNINSTLLATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          setEffect(false);
          setOpen(false);
        }
      });
  };

  const Generatepdf = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("UserId", e.UserId);
    urlencoded.append("noc_num", e.noc_num);
    urlencoded.append("receiptno", e.ReceiptNo);
    urlencoded.append("refno", e.RefNo);
    urlencoded.append("plotno", e.PlotNumber);
    urlencoded.append("date", moment(e.PurchaseOn).format("DD/MM/YYYY"));
    urlencoded.append("fullname", e.FullName);
    urlencoded.append("premisesname", e.PremisesName);
    urlencoded.append("makanino", e.MakaniNo);
    urlencoded.append("pdfs", moment(e.PurchaseOn).format("DD-MM-YYYY"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`${Domain}/generatepdf`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == true) {
          getServices();
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };
  const columns = [
    {
      Header: "#",
      id: "row",
      width: 80,
      filterable: false,
      desc: true,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "Customer",
      accessor: "Name",
    },
    {
      Header: "ATEs",
      accessor: "ATEs",
    },
    {
      Header: "Payment Invoice",
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.receiptUrl != "" ? (
              <a
                href={data.original.receiptUrl}
                style={{
                  color: "blue",
                }}
                rel="noreferrer"
                target="_blank"
              >
                {data.original.receiptUrl == "" ? "" : "View Invoice"}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: "NOC",
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.payStatus == "Complete" ? (
              data.original.noc == "" ? (
                <Button style={{ backgroundColor: ThemeColor, color: "white" }} onClick={() => { Generatepdf(data.original) }}>Generate Noc</Button>
              ) : (
                <a
                  href={data.original.noc}
                  style={{
                    color: "blue",
                  }}
                  rel="noreferrer"
                  target="_blank"
                >
                  {data.original.receiptUrl == "" ? "" : "View Noc"}
                </a>
              )
            ) : data.original.noc != "" ? (
              <a
                href={data.original.noc}
                style={{
                  color: "blue",
                }}
                rel="noreferrer"
                target="_blank"
              >
                {data.original.receiptUrl == "" ? "" : "View Noc"}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: "Payment Status",
      accessor: "payStatus",
    },
    {
      Header: "Requested On",
      id: "RequestOn",
      Cell: (data) => {
        return moment(data.original.PurchaseOn).format("DD/MM/YYYY hh:mm:ss a");
      },
    },
    {
      Header: "Payment Date",
      accessor: "PaymentDate",
    },
    {
      Header: "Status",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
    {
      Header: "Assign to",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.AssignTo == "") {
          return (
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => {
                return (
                  setUserId(data.original.UserId),
                  setAtes(data.original.ATEs),
                  setAddress(data.original.Address),
                  setName(data.original.Name),
                  setBusinessName(data.original.BusinessName),
                  setTime(data.original.Time),
                  setServiceId(data.original.Id),
                  setOpen(true),
                  GetDatranges(data.original.Id),
                  setNew1(data.original.StartDate),
                  setNew2(data.original.EndDate),
                  setAssign("Assign")
                );
              }}
            >
              Assign
            </Button>
          );
        } else {
          return <div>{data.original.AssignTo}</div>;
        }
      },
    },
    {
      Header: "Action",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={() => {
              return (
                history.push(`/installationdetails/${data.original.Id}`),
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                )
              );
            }}
          >
            View Details
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <MyTable data={Data} columns={columns} defaultPageSize={size1} />
      <MyModal
        open={open2}
        close={() => setOpen2(false)}
        title="Reason For Hold Task"
        class="ReshedulModal"
        MyClose={() => setOpen2(false)}
      >
        <div className={classes.ModalField}>
          <div>
            <label>{holdmessage}</label>
          </div>
        </div>
        <Button
          className={classes.AssignBtn}
          onClick={() => {
            setOpen2(false);
          }}
        >
          Close
        </Button>
      </MyModal>
      <MyModal
        open={open}
        close={() => setOpen(false)}
        title="Defective ATE Report"
        MyClose={() => setOpen(false)}
        class={classes.modal1}
      >
        <div>
          <div className={classes.ModalField}>
            <label>Select Date</label>

            {RangeDate.length > 0 ? (
              <DateRangePicker
                initialSettings={{
                  startDate: new1,
                  endDate: new2,
                  minDate: new1,
                  maxDate: new2,
                  dateLimit: { days: 9 },
                  locale: {
                    format: "DD/MM/YYYY",
                  },

                  singleDatePicker: true,
                }}
                onCallback={handleCallback}
              >
                <input
                  style={{
                    height: 40,
                    borderRadius: 5,
                    border: "0.5px solid " + ThemeColor + "",
                  }}
                />
              </DateRangePicker>
            ) : null}
          </div>
          <div className={classes.ModalField2}>
            <label>Select Engineer</label>
            <Select
              placeholder="Select Engineer"
              options={EngineerList.map((eng) => ({
                value: eng.Id,
                label: eng.FirstName + " " + eng.LastName,
              }))}
              onChange={onChangeEngineer}
            />
          </div>
          <div className={classes.ModalField2}>
            <label>Select Time</label>
            <Select
              placeholder="Select Time"
              options={options}
              onChange={onChangeTime1}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <Button className={classes.AssignBtn} onClick={AssignEngineer}>
          Assign Task
        </Button>
      </MyModal>
    </div>
  );
};
export default InstallationReq;
