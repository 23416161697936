import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import { ADDTICKET } from "../../../../RouteS/BrowserRoute/User";
import { useHistory } from "react-router-dom";
import {
  CLOSETICKET,
  GETONEUSERTICKET,
} from "../../../../RouteS/ApiRoutes/Ticket";
import moment from "moment";
import Sucessmodal from "../../../../Component/Modal/SucessModal";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  Titlediv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  AddBuildingBtn: {
    color: ThemeColor,
    fontSize: 16,
    fontWeight: 600,
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0rem",
      fontSize: 12,
    },
  },
  h1tag: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
}));

const MyTicket = () => {
  const UserId = JSON.parse(localStorage.getItem("UserId"));
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [Data, setData] = useState([]);
  const [Effect, setEffect] = useState(true);
  const [open, setOpen] = useState(false);
  const [msg, setMessage] = useState("");
  const [size1, setSize1] = useState(5);

  useEffect(() => {
    const raw1 = JSON.stringify({
      UserId: UserId,
    });

    fetch(GETONEUSERTICKET, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw1,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.Data);
        setEffect(false);
      });
  }, [Effect ? Data : null]);
  const CloseTicket = (id) => {
    setEffect(true);
    const raw = JSON.stringify({
      Id: id,
    });
    fetch(CLOSETICKET, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          setEffect(false);
          setMessage(res.Message);
          setOpen(true);
        }
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: `${t("bussines_name")}`,
      accessor: "BusinessName",
    },
    {
      Header: `${t("ticket_number")}`,
      accessor: "TicketNo",
    },
    {
      Header: `${t("serial_no")}`,
      accessor: "SerialNo",
    },
    {
      Header: `${t("logged_date")}`,
      id: "AddOn",
      Cell: (data) => {
        return moment(data.original.RequestOn).format("YYYY/MM/DD hh:mm:ss ");
      },
    },
    {
      Header: `${t("address")}`,
      accessor: "Address",
    },
    {
      Header: `${t("request_on")}`,
      id: "AddOn",
      Cell: (data) => {
        return moment(data.original.RequestOn).format("YYYY/MM/DD hh:mm:ss ");
      },
    },

    {
      Header: `${t("problem")}`,
      accessor: "Issue",
    },
    {
      Header: `${t("close_on")}`,
      id: "CloseOn",
      Cell: (Data) => {
        if (
          moment(Data.original.CloseOn).format("YYYY/MM/DD") === "Invalid date"
        ) {
          return "none";
        } else {
          return moment(Data.original.CloseOn).format("YYYY/MM/DD hh:mm:ss");
        }
      },
    },
    {
      Header: `${t("ticket_number")}`,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Hold") {
          return row[filter.id] === "Hold";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Hold">Hold</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Hold":
            return (
              <div
                style={{
                  background: "red",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <div className={classes.Titlediv}>
        <h1 className={classes.h1tag}>Service Tickets</h1>
        <Button
          className={classes.AddBuildingBtn}
          onClick={() => history.push(ADDTICKET)}
        >
          {t("request_new_ticket")}
        </Button>
      </div>
      <MyTable data={Data} columns={columns} defaultPageSize={size1} />
      <Sucessmodal open={open} close={() => setOpen(false)} message={msg} />
    </div>
  );
};
export default MyTicket;
