import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MyCard from "../Component/Card/index";
import MyTab from "../Component/Tab/index";
import { GETACTIVEENG } from "../../../../RouteS/ApiRoutes/Installation";
import { GETALLUSER } from "../../../../RouteS/ApiRoutes/Customer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "25px",
    [theme.breakpoints.between("sm", "md")]: {
      margin: "0px auto",
      flexDirection: "column",
      width: "100%",
      marginLeft: "15px",
    },
  },
}));

const AdminDashboard = () => {
  const classes = useStyles();
  const token = JSON.parse(localStorage.getItem("token"));
  var Customer = JSON.parse(localStorage.getItem("Customer"));
  var Orders = JSON.parse(localStorage.getItem("Orders"));
  var InOrder = JSON.parse(localStorage.getItem("Inorder"));
  var Paid = JSON.parse(localStorage.getItem("Paid"));
  var totalpaidate = JSON.parse(localStorage.getItem("TotalPaidATEs"));
  var Pending = JSON.parse(localStorage.getItem("Pending"));
  var InProgress = JSON.parse(localStorage.getItem("InProgress"));
  var Complete = JSON.parse(localStorage.getItem("Complete"));

  const [sta2, setSta2] = useState([]);
  const [Data, setData] = useState([]);

  useEffect(() => {
    view();
    // getactiveeng();
  }, []);

  // const getactiveeng = () => {
  //   fetch(GETACTIVEENG, {
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer " + token + "",
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setSta2(res.Data.length);
  //     });
  // };

  const view = () => {
    fetch(GETALLUSER, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
        localStorage.setItem(
          "Customer",
          JSON.stringify(responseJson.Data.length)
        );
      });
  };

  return (
    <div>
      <div className={classes.root}>
        <MyCard title="Total Premises" value={Orders} />
        <MyCard title="Total Paid Premises" value={Paid} />
        <MyCard title="Total Paid ATE" value={totalpaidate} />
        <MyCard title="Premises Not Visited" value={InOrder} />
        <MyCard title="Premises Visited" value={InProgress} />
        <MyCard title="Number of Installed ATE" value={Complete} />
        {/* <MyCard title="Active engineers" value={sta2} /> */}
      </div>
      <div>
        <h1
          style={{ textAlign: "left", marginLeft: "25px", marginTop: "1rem" }}
        >
          Recent Requests From Customers
        </h1>
        <MyTab />
      </div>
    </div>
  );
};
export default AdminDashboard;
