import React from "react";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useTranslation } from "react-i18next";

// import SendIcon from "@mui/icons-material/Send";
// import Collapse from "@mui/material/Collapse";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import StarBorder from "@mui/icons-material/StarBorder";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import SettingsIcon from "@mui/icons-material/Settings";
// import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
// import LaptopIcon from "@mui/icons-material/Laptop";

const MobileMenu = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  var lang = JSON.parse(localStorage.getItem("lang"));
  const handleClick = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();
  return (
    <>
      <div>
        <List>
          <ListItem
            className="ListItem_div"
            onClick={() => {
              history.push("/");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("home")}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          <ListItem
            className="ListItem_div"
            onClick={() => {
              history.push("/userLogin");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("loginregistration")}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          <ListItem
            className="ListItem_div"
            onClick={() => {
              history.push("/program");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("theprogram")}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          <ListItem
            className="ListItem_div"
            onClick={() => {
              history.push("/media");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("media")}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>

          <ListItem
            className="ListItem_div"
            onClick={() => {
              history.push("/contactus");
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("contactus")}
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default MobileMenu;

// onClick={GotoAboutUs}
