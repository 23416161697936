import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Avatar, Button, Container, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import Select from "react-select";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useHistory } from "react-router-dom";
import { VIEWONESTAFF } from "../../../../RouteS/ApiRoutes/Staff";
import { DASHBOARDCONTROLLER } from "../../../../RouteS/BrowserRoute/ControllerRoute/index";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import InputMask from "react-input-mask";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { EDITSTAFF } from "../../../../RouteS/ApiRoutes/Staff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./profile.css";

const token = JSON.parse(localStorage.getItem("token"));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100% !important",
      },
    },
  },
  ProfileBtn: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 30,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  ProfileBtn2: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 0,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  profileImageDiv: {
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&>span": {
      fontWeight: 600,
      marginTop: "0.5rem",
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      "&>.MuiFormControl-root": {
        width: "100% !important",
      },
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      "&>.MuiFormControl-root": {
        width: "100% !important",
      },
      width: "35%",
    },
  },
  FieldDiv2: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "23.3%",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "35%",
    },
    "&>.css-2b097c-container": {
      width: "100% !important",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  SaveBtn: {
    width: "15%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    marginBottom: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "35%",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 12,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  mainDiv_pass: {
    display: "flex",
    alignItems: "flex-start",
    // flexFlow: "wrap",
    flexDirection: "column",
    marginBottom: "1.5rem",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    marginBottom: 0,
    marginTop: "2rem",
  },
  DateTextField: {
    width: "223px",
  },
  passDiv: {
    display: "flex",
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
    },
  },
}));

const EngineerProfile = () => {
  const Id = JSON.parse(localStorage.getItem("EngineerId"));
  const classes = useStyles();
  const imageRef = useRef();
  const history = useHistory();

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [UserName, setUserName] = useState("");
  const [EIDno, setEIDno] = useState("");
  const [Email, setEmail] = useState("");
  const [ContactNo, setContactNo] = useState("");
  const [Emirate, setEmirate] = useState("");
  const [Gender, setGender] = useState("");
  const [DOB, setDOB] = useState("");
  const [PassportNo, setPassportNo] = useState("");
  const [JoiningDate, setJoiningDate] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Department, setDepartment] = useState("");
  const [Branch, setBranch] = useState("");
  const [MemberOfTeam, setMemberOfTeam] = useState("");
  const [Password, setPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowNewPassword = () => setNewShowPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setNewShowPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [Image, setImage] = useState("");
  const [Images, setImages] = useState("");
  const [file, setFile] = useState();
  const [emailerror, setEmailError] = useState("");
  const [profileImg, setProfileImage] = useState(true);

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  const onChangeFile = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    setProfileImage(true);
  };

  const onChangeUserName = (e) => {
    setUserName(e.target.value);
  };
  const onChangeEIDno = (e) => {
    setEIDno(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeEmirate = (e) => {
    setEmirate(e.value);
  };
  const onChangeGender = (e) => {
    setGender(e.value);
  };
  const onChangeDOB = (e) => {
    setDOB(e.target.value);
  };
  const onChangePassportNo = (e) => {
    setPassportNo(e.target.value);
  };
  const onChangeJoiningDate = (e) => {
    setJoiningDate(e.target.value);
  };
  const onChangeDesignation = (e) => {
    setDesignation(e.value);
  };
  const onChangeDepartment = (e) => {
    setDepartment(e.value);
  };
  const onChangeBranch = (e) => {
    setBranch(e.value);
  };
  // const onChangeMemberOfTeam = (e) => {
  //   setMemberOfTeam(e.value);
  // };

  const options1 = [
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Dubai", label: "Dubai" },
    { value: "Sharjah", label: "Sharjah" },
    { value: "Ajman", label: "Ajman" },
    { value: "Umm Al Quwain", label: "Umm Al Quwain" },
    { value: "Fujairah", label: "Fujairah" },
    { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  ];
  const options2 = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const options3 = [
    { value: "Technician", label: "Technician" },
    { value: "Engineer", label: "Engineer" },
    { value: "Admin", label: "Admin" },
    { value: "Sales", label: "Sales" },
    { value: "Marketing", label: "Marketing" },
    { value: "Technical Specialist", label: "Technical Specialist" },
    { value: "Fire Fighting Specialist", label: "Fire Fighting Specialist" },
    { value: "Fire Alarm Specialist", label: "Fire Alarm Specialist" },
  ];
  const options4 = [
    { value: "Service", label: "Service" },
    { value: "Projects", label: "Projects" },
  ];

  const Save = () => {
    if (NewPassword === ConfirmPassword) {
      if (emailerror == "") {
        const data = new FormData();
        data.append("Id", Id);
        data.append("FirstName", FirstName);
        data.append("LastName", LastName);
        data.append("UserName", UserName);
        data.append("EIDno", EIDno);
        data.append("Email", Email);
        data.append("ContactNo", ContactNo);
        data.append("Emirate", Emirate);
        data.append("Gender", Gender);
        data.append("DOB", DOB);
        data.append("JoiningDate", JoiningDate);
        data.append("PassportNo", PassportNo);
        data.append("Designation", Designation);
        data.append("Department", Department);
        data.append("Branch", Branch);
        // data.append("MemberOfTeam", MemberOfTeam);
        if (NewPassword !== "") {
          data.append("Password", NewPassword);
        } else {
          data.append("Password", Password);
        }
        data.append("ConfirmPassword", ConfirmPassword);
        data.append("file", file);
        fetch(EDITSTAFF, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.Status === true) {
              setStatus({ ...status, success: true });
              setMessage(res.Message);
            } else {
              setStatus({ ...status, error: true });
              setMessage(res.Message);
            }
          });
      }
    } else {
      alert("Password And ConfirmPassword Was Not Matched.");
    }
  };

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWONESTAFF + "Id=" + Id + "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setFirstName(responseJson.Data[0].FirstName);
        setLastName(responseJson.Data[0].LastName);
        setUserName(responseJson.Data[0].UserName);
        setEIDno(responseJson.Data[0].EIDno);
        setEmail(responseJson.Data[0].Email);
        setContactNo(responseJson.Data[0].ContactNo);
        setEmirate(responseJson.Data[0].Emirate);
        setGender(responseJson.Data[0].Gender);
        setDOB(responseJson.Data[0].DOB);
        setPassportNo(responseJson.Data[0].PassportNo);
        setJoiningDate(responseJson.Data[0].JoiningDate);
        setDesignation(responseJson.Data[0].Designation);
        setDepartment(responseJson.Data[0].Department);
        setBranch(responseJson.Data[0].Branch);
        setMemberOfTeam(responseJson.Data[0].MemberOfTeam);
        setPassword(responseJson.Data[0].Password);
        setImages(responseJson.Data[0].Image);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // width: "98%",
          alignItems: "center",
          marginLeft: "30px",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
            marginBottom: "20px",
          }}
        />
      </div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div style={{ textAlign: "left", marginTop: "-2rem" }}>
            <h1>Update Staff Member</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.profileImageDiv}>
                {profileImg ? (
                  <>
                    <IconButton
                      className={classes.ProfileBtn2}
                      onClick={() => imageRef.current.click()}
                    >
                      <Avatar
                        src={Image !== "" ? Image : Images}
                        style={{ height: 100, width: 100 }}
                      />
                    </IconButton>
                    <span>Profile Image</span>
                  </>
                ) : (
                  <>
                    {" "}
                    <IconButton
                      className={classes.ProfileBtn}
                      onClick={() => imageRef.current.click()}
                    >
                      <PhotoCameraIcon
                        style={{ color: "black", fontSize: 32 }}
                      />
                    </IconButton>
                    <span>Profile Image</span>
                  </>
                )}
                <input
                  type="file"
                  ref={imageRef}
                  style={{ display: "none" }}
                  onChange={onChangeFile}
                />
              </div>
              <div>
                <h3 className={classes.MainLabel}>Personal Information</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>First Name</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="FirstName"
                      value={FirstName}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setFirstName(e.target.value);
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Last Name</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="LastName"
                      value={LastName}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setLastName(e.target.value);
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>User Name</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="UserName"
                      value={UserName}
                      onChange={onChangeUserName}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>EID Number</label>
                    <InputMask
                      mask="999-9999-9999999-9"
                      disabled={false}
                      maskChar=" "
                      value={EIDno}
                      onChange={onChangeEIDno}
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          size="small"
                          name="EIDno"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      )}
                    </InputMask>
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Email Address</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Email"
                      value={Email}
                      // onChange={onChangeEmail}
                      onChange={(e) => {
                        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                        if (re.test(e.target.value)) {
                          setEmail(e.target.value);
                          setEmailError("");
                        } else {
                          setEmail(e.target.value);
                          setEmailError("Please Enter Valid Email");
                        }
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    {emailerror == "" ? (
                      ""
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {emailerror}
                      </div>
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Contact Number</label>
                    <ReactPhoneInput
                      country={"ae"}
                      value={ContactNo}
                      onChange={(e) => {
                        setContactNo(e);
                      }}
                    />
                    {/* <TextField
                      variant="outlined"
                      size="small"
                      name="ContactNo"
                      style={{ width: "222px" }}
                      inputProps={{ maxLength: 12 }}
                      value={ContactNo}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setContactNo(e.target.value);
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                  <div className={classes.FieldDiv2}>
                    <label className={classes.textLabel}>Emirate</label>
                    <Select
                      options={options1}
                      onChange={onChangeEmirate}
                      placeholder={Emirate}
                    />
                  </div>
                  <div className={classes.FieldDiv2}>
                    <label className={classes.textLabel}>Gender</label>
                    <Select
                      options={options2}
                      onChange={onChangeGender}
                      placeholder={Gender}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Date of Birth</label>
                    <TextField
                      type="date"
                      variant="outlined"
                      size="small"
                      name="DOB"
                      onChange={onChangeDOB}
                      value={DOB ? DOB.substr(0, 10) : ""}
                      className={classes.DateTextField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>

                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Passport Number</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="PassportNo"
                      value={PassportNo}
                      onChange={onChangePassportNo}
                      inputProps={{ maxLength: 9 }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                </div>
                <div>
                  <h3 className={classes.MainLabel}>Company Information</h3>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Joining Date</label>
                      <TextField
                        type="date"
                        className={classes.DateTextField}
                        variant="outlined"
                        size="small"
                        name="JoiningDate"
                        onChange={onChangeJoiningDate}
                        value={JoiningDate ? JoiningDate.substr(0, 10) : ""}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Designation</label>
                      <Select
                        options={options3}
                        onChange={onChangeDesignation}
                        placeholder={Designation}
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Departmaent</label>
                      <Select
                        options={options4}
                        onChange={onChangeDepartment}
                        placeholder={Department}
                      />
                    </div>

                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Branch</label>
                      <Select
                        options={options1}
                        onChange={onChangeBranch}
                        placeholder={Branch}
                      />
                    </div>
                    {/* <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>
                        Member Of Team
                      </label>
                      <Select onChange={onChangeMemberOfTeam} />
                    </div> */}
                  </div>
                </div>

                <div>
                  <h3 className={classes.MainLabel}>Security Information</h3>
                  <div className={classes.mainDiv_pass}>
                    <div>
                      <div className={classes.FieldDiv}>
                        <label className={classes.textLabel}>
                          Old Password
                        </label>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          size="small"
                          name="Password"
                          value={Password}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {Password.length >= 6 ? (
                          ""
                        ) : (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            At Least 6 Characters Password
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={classes.passDiv}>
                      <div className={classes.FieldDiv}>
                        <label className={classes.textLabel}>
                          New Password
                        </label>
                        <TextField
                          variant="outlined"
                          size="small"
                          name="NewPassword"
                          type={showNewPassword ? "text" : "password"}
                          value={NewPassword}
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  onMouseDown={handleMouseDownNewPassword}
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {NewPassword.length >= 6 ? (
                          ""
                        ) : (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            At Least 6 Characters Password
                          </span>
                        )}
                      </div>
                      <div className={classes.FieldDiv}>
                        <label className={classes.textLabel}>
                          Confirm Password
                        </label>
                        <TextField
                          type={showConfirmPassword ? "text" : "password"}
                          variant="outlined"
                          size="small"
                          name="ConfirmPassword"
                          value={ConfirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {ConfirmPassword.length >= 6 ? (
                          ""
                        ) : (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            At Least 6 Characters ConfirmPassword
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button className={classes.SaveBtn} onClick={Save}>
                  Update
                </Button>
                <Button
                  className={classes.SaveBtn}
                  onClick={() => history.push(DASHBOARDCONTROLLER)}
                >
                  Cancel
                </Button>
              </div>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(DASHBOARDCONTROLLER);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </>
  );
};
export default EngineerProfile;
