import { Button, IconButton, TextField } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Select from "react-select";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyModal from "../../../../Component/Modal/index";
import MyTable from "../../../../Component/Table/index";
import { VIEWORDER } from "../../../../RouteS/ApiRoutes/Order";
import useStyle from "./style";
import { GETTIME } from "../../../../RouteS/ApiRoutes/Time";
import { PRODUCT2 } from "../../../../RouteS/BrowserRoute/User";
import { Domain } from "../../../../RouteS/ApiDomain/index";
import { useTranslation } from "react-i18next";
import printJS from 'print-js'

const MyOrder1 = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const UserId = JSON.parse(localStorage.getItem("UserId"));
  const eidnos = JSON.parse(localStorage.getItem("EIDno"));

  const classes = useStyle();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [Data, setData] = useState([]);
  const [size1, setSize1] = useState(5);
  const [open2, setOpen2] = useState(true);
  const { t } = useTranslation();

  const StatusInquiry = (e, j) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("UserId", UserId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`${Domain}/statusinquiry`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status != false) {
          if (result.Data == "Success") {
            setTimeout(() => {
              view();
            }, 1000);
          } else if (result.Data == 9) {
            OpenPage("/premises" + "/" + j);
          } else if (result.Data) {
            setTimeout(() => {
              view();
            }, 1000);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  const Generatepdf = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("UserId", e.UserId);
    urlencoded.append("noc_num", e.noc_num);
    urlencoded.append("receiptno", e.ReceiptNo);
    urlencoded.append("refno", e.RefNo);
    urlencoded.append("plotno", e.PlotNumber);
    urlencoded.append("date", moment(e.PurchaseOn).format("DD/MM/YYYY"));
    urlencoded.append("fullname", e.FullName);
    urlencoded.append("premisesname", e.PremisesName);
    urlencoded.append("makanino", e.MakaniNo);
    urlencoded.append("pdfs", moment(e.PurchaseOn).format("DD-MM-YYYY"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`${Domain}/generatepdf`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == true) {
          window.open(`${Domain}/upload/NOC_${moment(e.PurchaseOn).format("DD-MM-YYYY")}_${e.RefNo}.pdf`, "_blank");
          view();
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  function printTest(e) {
    let rawHTML = `
    <html>
    <head>
      <style>
        body {
          background-color: white;
          width: 100%;
          font-family: "Manrope", sans-serif;
          font-family: "Noto Sans", sans-serif;
        }
        .root {
          padding-top: 15px;
          padding-left: 20px;
          padding-right: 40px;
        }
        .firstdiv {
          width: 100%;
        }
        .image1 {
          border-radius: 5px;
          text-align: left;
        }
        .image2 {
          border-radius: 5px;
          text-align: right;
        }
        .text_center {
          font-size: 16px;
          font-weight: 800;
          text-align: center;
          display:flex;
          justify-content:center;
          align-items:center;
        }
        .detaildiv {
          width: 100%;
          margin-top: 10px;
          border-radius: 20px;
          border: 1px solid #006cff;
        }
        .detail1 {
          padding: 30px 10px;
          border-right: 1px solid #006cff;
          width: 30%;
        }
        .detail2 {
          padding: 30px 10px;
          width: 45%;
        }
        .detail3 {
          padding: 30px 10px;
          border-left: 1px solid #006cff;
          width: 25%;
        }
        .line {
          border: 2px solid #006cff;
          height: 300px;
        }
        .size_div {
          font-size: 14px;
          font-weight: 800;
          margin-bottom: 10px;
          text-align: left;
        }
        .size_div2 {
          font-size: 14px;
          font-weight: 800;
          text-align: left;
        }
        .size_div3 {
          font-size: 14px;
          font-weight: 800;
          margin-bottom: 10px;
          text-align: right;
        }
        .size_div4 {
          font-size: 14px;
          font-weight: 800;
          text-align: right;
        }
        .detaildiv2 {
          margin-top: 20px;
          border-radius: 30px;
          border: 1px solid #006cff;
        }
        .detaildiv3 {
          margin-top: 20px;
          border-radius: 30px;
          border: 1px solid #006cff;
          height: 120px;
        }
        .detail_div21 {
          padding: 30px 10px;
          /* border-right: 1px solid #006cff; */
        }
        .detail_div22 {
          padding: 30px 10px;
          border-left: 1px solid #006cff;
        }
        .textdiv2 {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .textdiv3 {
          font-size: 14px;
          margin-bottom: 10px;
          font-weight: 800;
          text-decoration: underline;
        }
        .textdiv4 {
          font-size: 14px;
          margin-bottom: 10px;
          /* padding-right: 250px; */
        }
        .textdiv5 {
          font-size: 14px;
          margin-bottom: 10px;
          /* padding-right: 512px; */
        }
        .textdiv6 {
          font-size: 14px;
          font-weight: 800;
        }
        .textdiv7 {
          font-size: 14px;
          margin-bottom: 10px;
          text-align: right;
        }
        .textdiv8 {
          font-size: 14px;
          margin-bottom: 10px;
          font-weight: 800;
          text-decoration: underline;
          text-align: right;
        }
        .textdiv9 {
          font-size: 14px;
          margin-bottom: 20px;
          /* padding-left: 250px; */
          text-align: right;
        }
        .textdiv10 {
          font-size: 14px;
          margin-bottom: 20px;
          /* padding-left: 438px; */
          text-align: right;
        }
        .textdiv11 {
          font-size: 14px;
          text-align: right;
          font-weight: 800;
        }
        .main_center {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .div_center {
          font-size: 17px;
          font-weight: 800;
          text-align: center;
        }
        .div_left {
          font-size: 16.5px;
          font-weight: 800;
          text-align: left;
          width: 160px;
          border-radius: 10px;
          padding: 6px;
          border: 1px solid #006cff;
        }
        .div_right {
          font-size: 16.5px;
          font-weight: 800;
          text-align: right;
          width: 160px;
          border-radius: 10px;
          padding: 6px;
          border: 1px solid #006cff;
        }
        .styleimg1 {
          height: 100px;
          width: 80px;
        }
        .styleimg2 {
          height: 65px;
          width: 340px;
        }
      </style>
    </head>
    <body>
      <div class="root">
        <table class="firstdiv">
          <tr>
            <td class="image1">
              <img
                class="styleimg1"
                src="${Domain}/logo/Weqayah.png"
                style="height: 100px; width: 80px"
                height="100px"
                width="80px"
              />
            </td>
            <td class="image2">
              <img
                class="styleimg2"
                src="${Domain}/logo/SSSA.png"
                style="height: 65px; width: 340px"
                height="65px"
                width="340px"
              />
            </td>
          </tr>
        </table>
  
        <table class="firstdiv">
          <tr style="width: 100%">
            <td class="text_center">شهادة عدم ممانعة</td>
          </tr>
          <tr class="main_center">
            <td class="div_left">No:${e.noc_num}</td>
            <td class="div_center">No Objection Certificate</td>
            <td class="div_right">${e.noc_num}:ﺍﻟﺮﻗﻢ</td>
          </tr>
        </table>
  
        <table class="detaildiv">
          <tr>
            <td class="detail1">
              <div class="size_div">Date:</div>
              <div class="size_div">Owner Name:</div>
              <div class="size_div">Weqayah ID:</div>
              <div class="size_div">Building Name:</div>
              <div class="size_div">Receipt No:</div>
              <div class="size_div">Makani Number:</div>
              <div class="size_div2">Plot Number:</div>
            </td>
            <td class="detail2">
              <div class="size_div">${moment(e.PurchaseOn).format("DD/MM/YYYY")}</div>
              <div class="size_div">${e.FullName}</div>
              <div class="size_div">${eidnos}</div>
              <div class="size_div">${e.PremisesName}</div>
              <div class="size_div">${e.ReceiptNo}</div>
              <div class="size_div">${e.MakaniNo == "" ? "-" : e.MakaniNo}</div>
              <div class="size_div2">${e.PlotNumber == "" ? "-" : e.PlotNumber}</div>
            </td>
            <td class="detail3">
              <div class="size_div3">:التاريخ</div>
              <div class="size_div3">:اسم المالك</div>
              <div class="size_div3">:رقم وقاية</div>
              <div class="size_div3">:اسم المبنى</div>
              <div class="size_div3">:رقم الإيصال</div>
              <div class="size_div3">:رقم مكاني</div>
              <div class="size_div4">:رقم قطعة الأرض</div>
            </td>
          </tr>
        </table>
  
        <table class="detaildiv2">
          <tr>
            <td class="detail_div21">
              <div class="textdiv2">To Whome it May Concern</div>
              <div class="textdiv3">No Objection Certificate</div>
              <div class="textdiv4">
                This is to confirm that the customer has registered in Weqayah and
                cleared all his service charges.
              </div>
              <div class="textdiv5">
                Yours sincerely, Kevin Monaghan Project Director WEQAYAH
              </div>
              <div class="textdiv6">
                This NOC is valid for 1 year from the issuance date
              </div>
            </td>
            <td class="detail_div22">
              <div class="textdiv7">إلى من يهمه الأمر</div>
              <div class="textdiv8">شهادة عدم ممانعة</div>
              <div class="textdiv9">
                يرجى التفضل بالعلم بأن المذكور أعلاه قد قام بالتسجيل في نظام وقاية
                وقام بتسديد كل الرسوم المترتبة على العقار أعلاه
              </div>
              <div class="textdiv10">
                وتفضلوا بقبول فائق الاحترام والتقدير، كيفين موناغان 
           مدير مشروع وقاية     
  
              </div>
              <div class="textdiv11">
                هذه الرسالة صالحة لمدة سنة من تاريخ الإصدار
              </div>
            </td>
          </tr>
        </table>
  
        <div class="detaildiv3"></div>
      </div>
    </body>
  </html>  
  `;
    printJS({
      printable: rawHTML,
      type: "raw-html",
      // style: ".heavy {font-weight: 800;}"
    });
  }

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: `${t("bussines_name")}`,
      accessor: "BusinessName",
    },
    {
      Header: `${t("product")}`,
      accessor: "Product",
    },
    {
      Header: `${t("ates")}`,
      accessor: "ATEs",
    },
    {
      Header: `${t("payment_invoice")}`,
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.receiptUrl != "" ? (
              <a
                href={data.original.receiptUrl}
                style={{
                  color: "blue",
                }}
                rel="noreferrer"
                target="_blank"
              >
                {data.original.receiptUrl == "" ? "" : "View Invoice"}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: `${t("noc")}`,
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.payStatus == "Complete" ? (
              data.original.noc == "" ? (
                <Button style={{ backgroundColor: ThemeColor, color: "white" }} onClick={() => { printTest(data.original) }}>Generate Noc</Button>
              ) : (
                <a
                  href={data.original.noc}
                  style={{
                    color: "blue",
                  }}
                  rel="noreferrer"
                  target="_blank"
                >
                  {data.original.receiptUrl == "" ? "" : "View Noc"}
                </a>
              )
            ) : data.original.noc != "" ? (
              <a
                href={data.original.noc}
                style={{
                  color: "blue",
                }}
                rel="noreferrer"
                target="_blank"
              >
                {data.original.receiptUrl == "" ? "" : "View Noc"}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: `${t("payment_status")}`,
      id: "row",
      filterable: false,
      Cell: (data) => {
        return <div>{data.original.payStatus}</div>;
      },
    },
    {
      Header: `${t("repay_status")}`,
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            {data.original.payStatus == "Expired" ||
              data.original.payStatus == "Cancel" ||
              data.original.payStatus == "Failed" ? (
              <Button
                style={{
                  color: "white",
                  background: ThemeColor,
                  borderRadius: 10,
                }}
                onClick={() => {
                  OpenPage(`/premisesrepay/${data.original.OrderId}`);
                }}
              >
                {t("repay")}
              </Button>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      Header: `${t("purchased_on")}`,
      id: "PurchaseOn",
      Cell: (data) => {
        return moment(data.original.PurchaseOn).format("DD/MM/YYYY hh:mm:ss a");
      },
    },
    {
      Header: "PaymentDate",
      accessor: "PaymentDate",
    },
    {
      Header: `${t("status")}`,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Hold":
            return (
              <div
                style={{
                  background: "red",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
    {
      Header: "",
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => OpenPage(PRODUCT2 + "/" + data.original.Id)}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  const OpenPage = (url) => {
    history.push(url);
  };

  useEffect(() => {
    view();
    StatusInquiry();
  }, []);

  const view = () => {
    const Data = JSON.stringify({ UserId: UserId });
    fetch(VIEWORDER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
  };

  if (open2 == false) {
    setTimeout(function () {
      setOpen2(true);
    }, 30000);
  }

  if (open2) {
    setTimeout(function () {
      view();
      setOpen2(false);
    }, 30000);
  }

  return (
    <div className={classes.MainContainer}>
      <div className={classes.Titlediv}>
        <h1 className={classes.h1tag}>Premises</h1>
        <Button
          className={classes.AddPremises}
          onClick={() => history.push("/premises")}
        >
          {t("add_premises")}
        </Button>
      </div>
      <MyTable data={Data} columns={columns} defaultPageSize={size1} />
    </div>
  );
};
export default MyOrder1;
