import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import MyTable from "../../../../../Component/Table/index";
import { ADDTICKET, PRODUCT2 } from "../../../../../RouteS/BrowserRoute/User";
import { GETUSERATES } from "../../../../../RouteS/ApiRoutes/Customer";
import moment from "moment";

const ViewCustomerATEs = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const [Data, setData] = useState([]);

  const url_str = window.location.href;
  const d = url_str.split("/");
  const UserId = d[4].toString();

  useEffect(() => {
    print1();
  }, []);

  const print1 = () => {
    var raw = JSON.stringify({
      UserId: UserId,
    });
    fetch(GETUSERATES, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Serial Number",
      accessor: "SerialNo",
    },
    {
      Header: "Floor",
      accessor: "Floor",
    },
    {
      Header: "Install By",
      accessor: "InstallBy",
    },
    {
      Header: "Installation Date",
      id: "Date",
      Cell: (row) => {
        return moment(row.original.InstallationDate).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Renew Date",
      accessor: "DueDate",
    },
    {
      Header: "",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return (
          <div>
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => OpenPage("#")}
            >
              Send Reminder
            </Button>
          </div>
        );
      },
    },
  ];
  const OpenPage = (url) => {
    history.push(url);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
        }}
      ></div>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default ViewCustomerATEs;
