import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Avatar, Button, Container, IconButton } from "@material-ui/core";
import { VIEWPRICE } from "../../../../RouteS/BrowserRoute/Admin/index";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import { VIEWONEPRICE, EDITPRICE } from "../../../../RouteS/ApiRoutes/Price";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const token = JSON.parse(localStorage.getItem("token"));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "600px",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginLeft: "25px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "0rem",
      width: "100%",
    },
  },
  SaveBtn: {
    width: "100%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    marginBottom: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      width: "42%",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 12,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem",
    marginRight: "2rem",
    marginLeft: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "3rem",
      flexFlow: "wrap",
    },
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "2rem",
    marginBottom: 0,
    marginTop: "2rem",
  },
  DateTextField: {
    width: "223px",
  },
}));

const EditPrice = () => {
  const classes = useStyles();
  const history = useHistory();

  const [Description, setDescription] = useState("");
  const [ATEPrice, setATEPrice] = useState("");
  const [Tax, setTax] = useState("");
  const [DeliveryFee, setDeliveryFee] = useState("");
  const [InstallationFee, setInstallationFee] = useState("");
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  const onChangeATEPrice = (e) => {
    setATEPrice(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onChangeTax = (e) => {
    setTax(e.target.value);
  };

  const onChangeDeliveryFee = (e) => {
    setDeliveryFee(e.target.value);
  };

  const onChangeInstallationFee = (e) => {
    setInstallationFee(e.target.value);
  };

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const Save = () => {
    var Data = JSON.stringify({
      Id: Id,
      Description: Description,
      ATEPrice: ATEPrice,
      Tax: Tax,
      DeliveryFee: DeliveryFee,
      InstallationFee: InstallationFee,
    });
    console.log(Data);
    fetch(EDITPRICE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setStatus({ ...status, success: true });
          setMessage(res.Message);
        } else {
          setStatus({ ...status, error: true });
          setMessage(res.Message);
        }
      });
  };

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWONEPRICE + "Id=" + Id + "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setDescription(responseJson.Data[0].Description);
        setATEPrice(responseJson.Data[0].ATEPrice);
        setTax(responseJson.Data[0].Tax);
        setDeliveryFee(responseJson.Data[0].DeliveryFee);
        setInstallationFee(responseJson.Data[0].InstallationFee);
      });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
          marginLeft: "30px",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
            marginBottom: "20px",
          }}
        />
      </div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div style={{ textAlign: "left", marginTop: "-2rem" }}>
            <h1>Update Price</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                <h3 className={classes.MainLabel}>ATE Categories</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>ATE Price</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="ATEPrice"
                      value={ATEPrice}
                      onChange={onChangeATEPrice}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Tax</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="Tax"
                      value={Tax}
                      onChange={onChangeTax}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Delivery Fee</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="DeliveryFee"
                      value={DeliveryFee}
                      onChange={onChangeDeliveryFee}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Installation Fee
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="InstallationFee"
                      value={InstallationFee}
                      onChange={onChangeInstallationFee}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Description</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="Description"
                      value={Description}
                      onChange={onChangeDescription}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div> */}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button className={classes.SaveBtn} onClick={Save}>
                  Update
                </Button>
                <Button
                  className={classes.SaveBtn}
                  onClick={() => history.push(VIEWPRICE)}
                >
                  Cancel
                </Button>
              </div>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(VIEWPRICE);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </>
  );
};
export default EditPrice;
