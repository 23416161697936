import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MyTable from "../../../../../Component/Table/index";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import { ADDTICKET, PRODUCT2 } from "../../../../../RouteS/BrowserRoute/User";
import { GETONEORDERIDATES } from "../../../../../RouteS/ApiRoutes/Ate";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginTop: "2rem",
    marginRight: "15px",
  },
  TitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
}));

const ViewATEs = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [Data, setData] = useState([]);
  const [size1, setSize1] = useState(5);

  const url_str = window.location.href;
  const data = url_str.split("/");
  const OrderId = data[4].toString();

  useEffect(() => {
    GetOneOrder();
  }, []);
  const GetOneOrder = () => {
    const raw = JSON.stringify({ OrderId: OrderId });
    fetch(GETONEORDERIDATES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => setData(res.data));
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: `${t("serial_number")}`,
      accessor: "SerialNo",
    },
    {
      Header: `${t("floor")}`,
      accessor: "Floor",
    },
    {
      Header: `${t("install_by")}`,
      accessor: "InstallBy",
    },
    {
      Header: `${t("installation_date")}`,
      accessor: "InstallationDate",
    },
    {
      Header: `${t("due_date")}`,
      accessor: "DueDate",
    },
    {
      Header: "",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return (
          <div>
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => OpenPage(PRODUCT2 + "/" + 1)}
            >
              {t("renew_now")}
            </Button>
          </div>
        );
      },
    },
  ];

  const OpenPage = (url) => {
    history.push(url);
  };

  return (
    <div className={classes.MainContainer}>
      <div>
        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>ATE Detail</h1>
          <Button
            style={{ color: ThemeColor, fontSize: 16, fontWeight: 600 }}
            onClick={() => history.push(ADDTICKET)}
          >
            {t("request_new_ticket")}
          </Button>
        </div>
        <MyTable data={Data} columns={columns} defaultPageSize={size1} />
      </div>
    </div>
  );
};
export default ViewATEs;
