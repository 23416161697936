import { Button, Divider, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
import { useHistory, useParams } from "react-router";
import { GETONEUSER } from "../../../../../RouteS/ApiRoutes/Customer";
import { GETONESERVICE } from "../../../../../RouteS/ApiRoutes/Services";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "60px",
    marginRight: "50px",
  },
  root: {
    "& > .MuiPaper-elevation3": {
      width: "100%",
      height: "100%",
      borderRadius: 20,
      display: "flex",
      alignItems: "center",
      background: "white",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  ArrowDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
  ArrowIcon: {
    paddingLeft: "10px",
    width: "40px !important",
    height: "40px !important",
    fontSize: 15,
    backgroundColor: ThemeColor,
    color: "white",
  },
  TitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
    marginTop: "15px",
  },
  div1: {
    display: "flex",
    width: "100%",
    marginLeft: "2rem",
    paddingTop: "10px",
    paddingBottom: "20px",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  DividerVeritical: {
    height: 110,
    width: 2.8,
    background: ThemeColor,
    borderRadius: 10,
  },
}));

const ViewDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("token"));
  const userid = JSON.parse(localStorage.getItem("UserId"));

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    EIDno: "",
    Email: "",
    ContactNo: "",
    FirstName2: "",
    LastName2: "",
    ContactNo2: "",
    PurchasedATEs: "",
  });

  const [field1, setField1] = useState({
    CustomerName: "",
    BusinessName: "",
    TicketNo: "",
    SerialNo: "",
    Floor: "",
    Issue: "",
    Address: "",
    StartDate: "",
    Time: "",
    SheduleOn: "",
    AssignTo: "",
    Status: "",
  });

  useEffect(() => {
    print1();
    print2();
  }, []);

  const print1 = () => {
    var raw = JSON.stringify({
      Id: userid,
    });
    fetch(GETONEUSER, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
      });
  };

  const print2 = () => {
    var raw = JSON.stringify({
      Id: id,
    });
    fetch(GETONESERVICE, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField1(responseJson.Data[0]);
      });
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.root}>
        <div className={classes.ArrowDiv}>
          <ArrowBackIosIcon
            onClick={history.goBack}
            className={classes.ArrowIcon}
          />
        </div>
        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>Customer Details</h1>
        </div>
        <Paper elevation={3}>
          <div className={classes.div1}>
            <div>
              <span>Customer Name&nbsp;</span>
              <span>EID Number&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field.FirstName + " " + field.LastName}</span>
              <span>:&nbsp;&nbsp;{field.EIDno}</span>
            </div>
          </div>
          <div className={classes.div1}>
            <div>
              <span>Email&nbsp;</span>
              <span>Contact No&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field.Email}</span>
              <span>:&nbsp;&nbsp;{field.ContactNo}</span>
            </div>
          </div>
          {/* <div className={classes.div1}>
            <div>
              <span>Alternate&nbsp;</span>
              <span>Contact No&nbsp;</span>
            </div>
            <div>
              <span>
                :&nbsp;&nbsp;{field.FirstName2 + " " + field.LastName2}
              </span>
              <span>:&nbsp;&nbsp;{field.ContactNo2}</span>
            </div>
          </div> */}
        </Paper>

        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>Service Details</h1>
        </div>
        <Paper elevation={3}>
          <div className={classes.div1}>
            <div>
              <span>Customer Name&nbsp;</span>
              <span>Business Name&nbsp;</span>
              <span>Ticket No&nbsp;</span>
              <span>Assign To&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field1.CustomerName}</span>
              <span>:&nbsp;&nbsp;{field1.BusinessName}</span>
              <span>:&nbsp;&nbsp;{field1.TicketNo}</span>
              <span>:&nbsp;&nbsp;{field1.AssignTo}</span>
            </div>
          </div>

          <div className={classes.div1}>
            <div>
              <span>Serial No&nbsp;</span>
              <span>Floor&nbsp;</span>
              <span>Issue&nbsp;</span>
              <span>Status&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field1.SerialNo}</span>
              <span>:&nbsp;&nbsp;{field1.Floor}</span>
              <span>:&nbsp;&nbsp;{field1.Issue}</span>
              <span>:&nbsp;&nbsp;{field1.Status}</span>
            </div>
          </div>
          <div className={classes.div1}>
            <div>
              <span>Address&nbsp;</span>
              <span>Shedule On&nbsp;</span>
              <span>Time&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field1.Address}</span>
              <span>:&nbsp;&nbsp;{field1.SheduleOn}</span>
              <span>:&nbsp;&nbsp;{field1.Time}</span>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default ViewDetails;
