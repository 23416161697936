import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MyTable from "../../../../../Component/Table/index";
import { GETUSERSERVICES } from "../../../../../RouteS/ApiRoutes/Customer";
const token = JSON.parse(localStorage.getItem("token"));

const ViewPreviewService = () => {
  const history = useHistory();
  const [Data, setData] = useState([]);

  const url_str = window.location.href;
  const d = url_str.split("/");
  const UserId = d[4].toString();

  useEffect(() => {
    print1();
  }, []);

  const print1 = () => {
    var raw = JSON.stringify({
      UserId: UserId,
    });
    fetch(GETUSERSERVICES, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: raw,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Ticket Number",
      accessor: "TicketNo",
    },
    {
      Header: "ATE Number",
      accessor: "SerialNo",
    },
    {
      Header: "Floor",
      accessor: "Floor",
    },

    {
      Header: "Service Date",
      accessor: "SheduleOn",
    },
    {
      Header: "Issue",
      accessor: "Issue",
    },
    {
      Header: "Status",
      id: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Hold":
            return (
              <div
                style={{
                  background: "red",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
        }}
      ></div>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default ViewPreviewService;
