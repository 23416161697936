import {Domain} from '../ApiDomain/index'
export const MAIL=`${Domain}/signup`

//request otp for forgot password
export const REQUESTOTP=`${Domain}/requestOtp`

//Otp Verification
export const FORGET=`${Domain}/forgotPassword`

//resend Otp
export const RESENDOTP=`${Domain}/resendOtp`
