import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Link } from "react-router-dom";
import SucessModal from "../../../../Component/Modal/SucessModal";
import { GETONEUSER } from "../../../../RouteS/ApiRoutes/Profile";

const token = JSON.parse(localStorage.getItem("token"));
const UserId = JSON.parse(localStorage.getItem("UserId"));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      height: 670,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  RegisterBtn: {
    width: "35%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
  },
}));
const Profile = () => {
  const classes = useStyles();
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");
  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    UserName: "",
    FirstName2: "",
    LastName2: "",
    ContactNo: "",
    ContactNo2: "",
    Email: "",
    Password: "",
    EIDno: "",
  });

  useEffect(() => {
    view();
  }, []);

  const [a, setA] = useState(false);
  const view = () => {
    const Id = JSON.stringify({ Id: UserId });
    fetch(GETONEUSER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Id,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setField(responseJson.Data[0]);
      });
  };
  return (
    <Container maxWidth="xl" className={classes.MainContainer}>
      <div>
        <div style={{ textAlign: "left", marginTop: "-2rem" }}>
          <h1>My Profile</h1>
        </div>
        <div className={classes.root}>
          <Paper elevation={3}>
            <div>
              <h3 className={classes.MainLabel}>Personal Information</h3>
              <div className={classes.mainDiv}>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>First Name</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="FirstName"
                    value={field.FirstName}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Last Name</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="LastName"
                    value={field.LastName}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>User Name</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="UserName"
                    value={field.UserName}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>EID Number</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="EIDno"
                    value={field.EIDno}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Email Address</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="Email"
                    value={field.Email}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Contact Number</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="Contact"
                    value={field.ContactNo}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <h3 className={classes.MainLabel}>
                  Alternate Contact Information
                </h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>First Name</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="FirstName"
                      value={field.FirstName2}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Last Name</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="LastName"
                      value={field.LastName2}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Contact Number</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Contact"
                      value={field.ContactNo2}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className={classes.MainLabel}>Security Information</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Old Password</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="Password"
                      value={field.Password}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>New Password</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="ConfirmPassword"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Confirm Password
                    </label>
                    <TextField
                      type="password"
                      variant="outlined"
                      size="small"
                      name="ConfirmPassword"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button className={classes.RegisterBtn}>Save Changes</Button>
          </Paper>
        </div>
      </div>
      <SucessModal
        open={status.success}
        message={message}
        close={() => {
          setStatus({ ...status, success: false });
        }}
      />
    </Container>
  );
};
export default Profile;
