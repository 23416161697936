import {Domain} from '../ApiDomain/index'

//User Login
export const SIGNUP=`${Domain}/register`
export const LOGIN=`${Domain}/login`

//Engineer Login
export const ELOGIN=`${Domain}/loginEngineer`

//Admin Login
export const ALOGIN=`${Domain}/adminlogin`

