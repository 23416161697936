import { Divider, Paper, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import IntstallationTask from "../InstallationTask";
import ServiceTask from "../ServiceTask";
import { LISTENGINEERINSTALLATION } from "../../../../RouteS/ApiRoutes/Installation";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "15px",
    "&>.MuiPaper-elevation3": {
      width: "100%",
      height: "100%",
      margin: "0 15px",
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: 18,
      background: ThemeColor,
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
      "&>h2": {
        marginTop: 0,
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "97%",
        height: "100%",
        marginBottom: "1rem",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "93%",
      marginLeft: "15px",
      marginRight: "0px",
      flexDirection: "column",
    },
  },
}));

const EngineerDashboard = () => {
  const classes = useStyles();

  const token = JSON.parse(localStorage.getItem("token"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  var Total = JSON.parse(localStorage.getItem("Total"));
  var Pending = JSON.parse(localStorage.getItem("Pending"));
  var Complete = JSON.parse(localStorage.getItem("Complete"));
  var Hold = JSON.parse(localStorage.getItem("Hold"));
  var Hold = JSON.parse(localStorage.getItem("Hold"));

  const [Data, setData] = useState([]);

  useEffect(() => {
    getInstallation();
  }, []);

  const getInstallation = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(LISTENGINEERINSTALLATION, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.Data);
        localStorage.setItem("Total", JSON.stringify(res.Data.length));
      });
  };

  return (
    <div>
      <div className={classes.MainContainer}>
        <div className={classes.root}>
          <Paper elevation={3}>
            <h2 style={{ color: "white" }}>Total Tasks</h2>
            <h2 style={{ color: "white" }}>{Total}</h2>
          </Paper>
          <Paper elevation={3}>
            <h2 style={{ color: "white" }}>Pending Task</h2>
            <h2 style={{ color: "white" }}>{Pending}</h2>
          </Paper>
          <Paper elevation={3}>
            <h2 style={{ color: "white" }}>Completed Tasks</h2>
            <h2 style={{ color: "white" }}>{Complete}</h2>
          </Paper>
          <Paper elevation={3}>
            <h2 style={{ color: "white" }}>Hold Tasks</h2>
            <h2 style={{ color: "white" }}>{Hold}</h2>
          </Paper>
        </div>
        <IntstallationTask />
      </div>
      <div>
        <ServiceTask />
      </div>
    </div>
  );
};
export default EngineerDashboard;
