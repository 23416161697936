import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { GETSERVICESADMIN } from "../../RouteS/ApiRoutes/Ticket";
import useStyles from "../../Page/ProtectedPage/SuperAdmin/Customer/style";
import { makeStyles } from "@material-ui/core/styles";

const localizer = momentLocalizer(moment);

const Calendar1 = () => {
  const [task, setTask] = useState([]);
  const [Show, setShow] = useState(false);
  useEffect(() => {
    fetch(GETSERVICESADMIN, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          task.splice(0, task.length);
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].AssignTo != "") {
              task.push({
                start: res.data[i].AppointmentDate,
                end: res.data[i].AppointmentDate,
                title: res.data[i].AssignTo,
              });
            }
          }
        }
      });
  });

  return (
    <div style={{ marginRight: "2rem", marginLeft: "25px" }}>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={task}
        style={{ height: "90vh" }}
        showAllEvents={true}
      />
    </div>
  );
};

export default Calendar1;

//.substr(0,10)
