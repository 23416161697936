import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import "./index.css";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";
const ErrorModal = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        // onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="errorModal"
      >
        {/* <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle> */}
        <DialogContent>
          <div className="Sucess-error-Tic" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div>
              {/* <CancelIcon style={{fontSize:80,color:'Red'}}/> */}
              <div
                className="swal2-icon swal2-error swal2-animate-error-icon"
                style={{display:'flex'}}
              >
                <span className="swal2-x-mark">
                  <span className="swal2-x-mark-line-left"></span>
                  <span className="swal2-x-mark-line-right"></span>
                </span>
              </div>
            </div>
            <h2 style={{margin:0,fontSize:15,fontWeight:600}}>{props.message}</h2>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} className="theme-btn" style={{background:ThemeColor,color:'white'}}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorModal;
