import React, { useEffect, useState } from "react";
import MyTable from "../../../../Component/Table/index";
import { GETNOTIFICATION } from "../../../../RouteS/ApiRoutes/Notification";
import PuffLoader from "react-spinners/PuffLoader";
import { Avatar, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { DASHBOARDCONTROLLER } from "../../../../RouteS/BrowserRoute/ControllerRoute";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
  },
}));
const Notification = () => {
  const classes = useStyles();
  const Token = JSON.parse(localStorage.getItem("token"));
  const [Data, setData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () => {
    fetch(GETNOTIFICATION, {
      headers: {
        Authorization: "Bearer " + Token + "",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return setData(res.data);
      });
  };
  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      filterable: false,
      Cell: (data) => {
        return (
          <Avatar
            style={{
              backgroundColor: "grey",
            }}
          >
            {data.original.AdminMsg
              ? data.original.AdminMsg.substr(0, 1)
              : data.original.EngineerMsg
              ? data.original.EngineerMsg.substr(0, 1)
              : data.original.CustomerMsg
              ? data.original.CustomerMsg.substr(0, 1)
              : ""}
          </Avatar>
        );
      },
    },
    {
      Header: "Notification",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.AdminMsg !== "") {
          return data.original.AdminMsg;
        } else if (data.original.EngineerMsg) {
          return data.original.EngineerMsg;
        } else {
          return data.original.CustomerMsg;
        }
      },
    },
    {
      Header: "Time",
      accessor: "Time",
    },
    {
      Header: "Date",
      accessor: "AddOn",
    },
    // {
    //   Header: "Action",
    //   filterable: false,
    //   id: "icon",
    //   Cell: (data) => {
    //     return (
    //       <Button
    //         style={{
    //           color: "white",
    //           background: ThemeColor,
    //           borderRadius: 10,
    //         }}
    //         onClick={(e) => {
    //           history.push(DASHBOARDCONTROLLER);
    //         }}
    //       >
    //         View Details
    //       </Button>
    //     );
    //   },
    // },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left", marginTop: "3rem" }}>Notifications</h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default Notification;
