import { Domain } from "../ApiDomain/index";
export const ASSIGNINSTLLATION = `${Domain}/AssignInstallation`;
export const LISTENGINEERINSTALLATION = `${Domain}/getEngineerInstallation`;
export const GETINSTALLATIONADMIN = `${Domain}/getAllOrder`;
export const UPDATESTATUS = `${Domain}/UpdateStatus`;
export const ADDFIRESURVEY = `${Domain}/addfiresurvey`;
export const UPDATEFIRESURVEY = `${Domain}/updatefiresurvey`;
export const GETPENDING = `${Domain}/getpending`;
export const GETINPROGRESS = `${Domain}/getinprogress`;
export const GETCOMPLETE = `${Domain}/getcomplete`;
export const GETENGPENDING = `${Domain}/getengpending`;
export const GETENGCOMPLETE = `${Domain}/getengcomplete`;
export const GETENGHOLD = `${Domain}/getenghold`;
export const GETACTIVEENG = `${Domain}/getactiveeng`;
export const UPDATESTATUS1 = `${Domain}/UpdateStatus1`;
export const GETONEORDER = `${Domain}/getOneOrder`;
