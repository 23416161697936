import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import "./index.css";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const ErrorModal = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={props.open}
        // onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="errorModal"
      >
        {/* <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle> */}
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ExitToAppIcon style={{ fontSize: 80 }} />
            <h1 style={{ margin: 0, fontSize: 25, fontWeight: 600 }}>
              Are you sure to logout?
            </h1>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.ok}
            className="theme-btn"
            style={{
              background: ThemeColor,
              color: "white",
              marginRight: lang == "ar" ? "0px" : "10px",
              marginLeft: lang == "ar" ? "10px" : "0px",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={props.close}
            className="theme-btn"
            style={{
              background: ThemeColor,
              color: "white",
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorModal;
