import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "20px",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginLeft: "100px",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "90%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    fontSize: 20,
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "2rem",
    marginTop: "1rem",
    marginLeft: "15rem",
    marginBottom: "1rem",
    width: "30%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "30%",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  FieldDiv2: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "3rem",
    width: "30%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "92%",
      marginLeft: "3rem",
    },
    "&>.select-container .css-2b097c-container": {
      width: "80% !important",
    },
    "&>.select-container  .css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.select-container  .css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  SubmitBtn: {
    width: "25%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    marginRight: "0rem",
    color: "white",
    height: "7%",
    marginBottom: "2rem",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "35%",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 11,
    },
  },
  ModalField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&>div label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  ModalField2: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&>label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  AssignBtn: {
    background: ThemeColor,
    color: "white",
    width: "100%",
    fontWeight: 600,
    marginTop: "1rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
}));
export default useStyles;
