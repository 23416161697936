import { Button, Divider, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { STAFF, VIEWONESTAFF } from "../../../../RouteS/ApiRoutes/Staff";
import { VIEWSTAFF } from "../../../../RouteS/BrowserRoute/Admin";
import { useHistory } from "react-router";
import StaffTab from "../Component/Tab/StaffTab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const token = JSON.parse(localStorage.getItem("token"));
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "25px",
    marginRight: "15px",
    "& > .MuiPaper-elevation3": {
      width: "100%",
      height: "100%",
      borderRadius: 20,
      display: "flex",
      alignItems: "center",
      background: "white",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  ArrowDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
  ArrowIcon: {
    paddingLeft: "10px",
    width: "40px !important",
    height: "40px !important",
    fontSize: 15,
    backgroundColor: ThemeColor,
    color: "white",
  },
  TitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  div1: {
    display: "flex",
    width: "100%",
    marginLeft: "2rem",
    paddingTop: "10px",
    paddingBottom: "20px",
    "&>div:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
    "&>div:nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "&>span": {
        height: "100%",
        marginTop: "10px",
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  DividerVeritical: {
    height: 110,
    width: 2.8,
    background: ThemeColor,
    borderRadius: 10,
  },
}));
const DetailStaff = () => {
  const classes = useStyles();
  const history = useHistory();

  const url_str = window.location.href;
  const d = url_str.split("/");
  const Id = d[4].toString();

  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    UserName: "",
    EIDno: "",
    Email: "",
    ContactNo: "",
    Emirate: "",
    Gender: "",
    DOB: "",
    Nationality: "Static5",
    PassportNo: "",
    JoiningDate: "",
    Designation: "",
    Department: "",
    MemberOfTeam: "",
    Branch: "",
    Password: "",
    IsAssign: "hover",
    Status: "true",
    Image: "",
  });

  useEffect(() => {
    print();
  }, []);

  const print = () => {
    fetch(VIEWONESTAFF + "Id=" + Id + "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.Data[0]);
        setField(responseJson.Data[0]);
      });
  };

  console.log(field);
  return (
    <div className={classes.MainContainer}>
      <div className={classes.root}>
        <div className={classes.ArrowDiv}>
          <ArrowBackIosIcon
            onClick={history.goBack}
            className={classes.ArrowIcon}
          />
        </div>
        <div className={classes.TitleDiv}>
          <h1 style={{ textAlign: "left" }}>Staff Detail</h1>
        </div>
        <Paper elevation={3}>
          <div className={classes.div1}>
            <div>
              <span>Name&nbsp;</span>
              <span>EID Number&nbsp;</span>
              <span>User Name&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field.FirstName + " " + field.LastName}</span>
              <span>:&nbsp;&nbsp;{field.EIDno}</span>
              <span>:&nbsp;&nbsp;{field.UserName}</span>
            </div>
          </div>

          <div className={classes.div1}>
            <div>
              <span>Date Of Birth&nbsp;</span>
              <span>Passport Number&nbsp;</span>
              <span>Joined On&nbsp;</span>
            </div>
            <div>
              <span>
                :&nbsp;&nbsp;{field.DOB ? field.DOB.substr(0, 10) : ""}
              </span>
              <span>:&nbsp;&nbsp;{field.PassportNo}</span>
              <span>
                :&nbsp;&nbsp;
                {field.JoiningDate ? field.JoiningDate.substr(0, 10) : ""}
              </span>
            </div>
          </div>

          <div className={classes.div1}>
            <div>
              <span>Designation&nbsp;</span>
              <span>Department&nbsp;</span>
              <span>Status&nbsp;</span>
            </div>
            <div>
              <span>:&nbsp;&nbsp;{field.Designation}</span>
              <span>:&nbsp;&nbsp;{field.Department}</span>
              <span>:&nbsp;&nbsp;{field.Status}</span>
            </div>
          </div>
        </Paper>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <StaffTab />
      </div>
    </div>
  );
};
export default DetailStaff;
