import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./Assets/StyleSheets/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-table-6/react-table.css";
import "./i18n";
const lang = JSON.parse(localStorage.getItem("lang"));
const url_str = window.location.href;
const data = url_str.split("/");
const email = data[3].toString();
const auths = JSON.parse(localStorage.getItem("auth"));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div></div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
document
  .getElementsByTagName("html")[0]
  .setAttribute(
    "dir",
    auths == "rea" || email == "admin"
      ? "ltr"
      : auths == "ree" || email == "engineer"
      ? "ltr"
      : lang == "ar"
      ? "rtl"
      : lang == "en" && auths == "reu"
      ? "ltr"
      : "ltr"
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
