import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@material-ui/core/styles";
import MyTable from "../../../../Component/Table/index";
import { Button, Divider, IconButton } from "@material-ui/core";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { ADDPRICE, EDITPRICE2 } from "../../../../RouteS/BrowserRoute/Admin";
import { VIEWALLPRICE, DELETEPRICE } from "../../../../RouteS/ApiRoutes/Price";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  Titlediv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
}));

const MyPrice = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const classes = useStyles();

  const [Data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "ATE Price",
      accessor: "ATEPrice",
      id: "icon",
      Cell: (data) => {
        return "AED" + data.original.ATEPrice;
      },
      // Cell: (props) =>
      //   new Intl.NumberFormat("en-US", {
      //     style: "currency",
      //     currency: "USD",
      //   }).format(props.value),
    },
    // {
    //   Header: "Tax",
    //   accessor: "Tax",
    //   Cell: (props) =>
    //     new Intl.NumberFormat("en-US", {
    //       style: "currency",
    //       currency: "USD",
    //     }).format(props.value),
    // },
    // {
    //   Header: "Delivery Fee",
    //   accessor: "DeliveryFee",
    //   Cell: (props) =>
    //     new Intl.NumberFormat("en-US", {
    //       style: "currency",
    //       currency: "USD",
    //     }).format(props.value),
    // },
    // {
    //   Header: "Installation Fee",
    //   accessor: "InstallationFee",
    //   Cell: (props) =>
    //     new Intl.NumberFormat("en-US", {
    //       style: "currency",
    //       currency: "USD",
    //     }).format(props.value),
    // },
    // {
    //   Header: "Description",
    //   accessor: "Description",
    // },
    {
      Headers: "",
      id: "Action",
      filterable: false,
      Cell: (data) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => history.push(EDITPRICE2 + "/" + data.original.Id)}
            >
              <EditIcon style={{ fontSize: 20, color: "#000000" }} />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ background: "black", height: 15, width: 2 }}
            />
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                save(data.original.Id);
                view();
              }}
            >
              <DeleteIcon style={{ fontSize: 20, color: "#717171" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    view();
  }, []);

  const view = () => {
    fetch(VIEWALLPRICE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
  };

  const save = (Id) => {
    const c = { Id: Id };
    fetch(DELETEPRICE, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(c),
    })
      .then((res) => res.json())
      .then((responseJson) => {
        if (responseJson.Status === true) {
          setStatus({ ...status, success: true });
          setMessage(responseJson.Message);
        } else {
          setStatus({ ...status, error: true });
          setMessage(responseJson.Message);
        }
      });
    view();
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.Titlediv}>
        <h1 style={{ textAlign: "left" }}>Add Price</h1>
        {Data.length > 0 ? null : (
          <Button
            style={{ color: ThemeColor, fontSize: 16, fontWeight: 600 }}
            onClick={() => history.push(ADDPRICE)}
          >
            Add Price +
          </Button>
        )}
      </div>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default MyPrice;
