import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Container } from "@material-ui/core";
import { VIEWTIME } from "../../../../RouteS/BrowserRoute/Admin/index";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import { ADDTIME } from "../../../../RouteS/ApiRoutes/Time";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const token = JSON.parse(localStorage.getItem("token"));
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  main_div: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "left",
    marginTop: "2rem",
    width: "950px",
    alignItems: "baseline",
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
    },
  },
  MainContainer: {
    // display: "flex",
    // justifyContent: "center",
    // flexDirection: "row",
    // marginTop: "2.5rem",
    // alignItems: "center",
    // width: "100%",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    alignItems: "center",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
      marginLeft: "0rem",
    },
  },
  SaveBtn: {
    width: "15%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    marginBottom: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      width: "32%",
      marginRight: "10px !important",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  SaveBtn2: {
    width: "10%",
    background: ThemeColor,
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "50%",
    alignItems: "center",
    borderRadius: "10px",
    marginTop: "1.5rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 12,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    marginBottom: 0,
    marginTop: "2rem",
  },
  DateTextField: {
    width: "223px",
  },
}));

const AddPrice = () => {
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const theme = useTheme();

  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState([{ Time: "" }]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { Time: "" }]);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    var Data = JSON.stringify({
      data: formValues,
    });
    console.log("data is :" + Data);
    fetch(ADDTIME, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.Status === true) {
            setStatus({ ...status, success: true });
            setMessage(res.Message);
          } else {
            setStatus({ ...status, error: true });
            setMessage(res.Message);
          }
        }
      });
  };

  return (
    <>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div style={{ marginLeft: "2rem" }}>
          <div className={classes.main_div}>
            <h1>Add Time</h1>
            <Button
              className={classes.SaveBtn2}
              type="button"
              onClick={() => addFormFields()}
            >
              Add
            </Button>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                {formValues.map(
                  (element, index) =>
                    index < 3 && (
                      <div className={classes.FieldDiv} key={index}>
                        {/* <span className={classes.textLabel}>Enter TimeSlote</span> */}
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder="Enter Time Slots"
                          type="text"
                          style={{ width: "70%", marginTop: "1rem" }}
                          name="Time"
                          value={element.Time || ""}
                          onChange={(e) => handleChange(index, e)}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </div>
                    )
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button className={classes.SaveBtn} onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className={classes.SaveBtn}
                    onClick={() => history.push(VIEWTIME)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(VIEWTIME);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </>
  );
};

export default AddPrice;
