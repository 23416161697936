import React, { useState, useEffect } from "react";
import useStyle from "./style";
import { useHistory } from "react-router";
import { Button, IconButton } from "@material-ui/core";
import MyTable from "../../../../Component/Table/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { GETALLSURVEY } from "../../../../RouteS/ApiRoutes/Survey";
import { DETAILSURVEY } from "../../../../RouteS/BrowserRoute/Admin";

const AdminViewSurvey = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const classes = useStyle();
  const history = useHistory();
  const [Data, setData] = useState([]);

  useEffect(() => {
    view();
  }, []);

  const view = () => {
    fetch(GETALLSURVEY, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.Data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Id",
      accessor: "Id",
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "Ready To Install",
      accessor: "installed",
      Cell: (data) => {
        switch (data.original.installed) {
          case "No":
            return (
              <div
                style={{
                  color: "Red",
                }}
              >
                {data.original.installed}
              </div>
            );
          case "Yes":
            return (
              <div
                style={{
                  color: "green",
                }}
              >
                {data.original.installed}
              </div>
            );
        }
      },
    },
    {
      Header: "ATEs No",
      accessor: "atessr",
    },
    {
      Header: "Owner Name",
      accessor: "OwnerName",
    },
    {
      Header: "View",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={() => history.push(DETAILSURVEY + "/" + data.original.Id)}
          >
            View Survey
          </Button>
        );
      },
    },
  ];
  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Fire Survey Report</h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default AdminViewSurvey;
