import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import MyTable from "../../../../Component/Table/index";
import { PRODUCT2 } from "../../../../RouteS/BrowserRoute/User";
import { LISTPREMISES } from "../../../../RouteS/ApiRoutes/Premises";
import { VIEWORDER } from "../../../../RouteS/ApiRoutes/Order";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
  },
}));

const MyBuilding = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const Id = JSON.parse(localStorage.getItem("UserId"));

  const history = useHistory();
  const classes = useStyles();

  const [Data, setData] = useState([]);

  useEffect(() => {
    GetBulidinList();
  }, []);

  const GetBulidinList = () => {
    const raw = JSON.stringify({ UserId: Id });
    fetch(VIEWORDER, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((res) => setData(res.Data));
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "Product",
      accessor: "Product",
    },
    {
      Header: "ATEs",
      accessor: "ATEs",
    },
    {
      Header: "Address",
      accessor: "Address",
    },
    {
      Header: "Premise End Date",
      id: "PurchaseOn",
      Cell: (data) => {
        return moment(data.original.PurchaseOn).format("DD/MM/YYYY hh:mm:ss a");
      },
    },
    {
      Header: "",
      id: "row",
      filterable: false,
      Cell: (data) => {
        return (
          <div>
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => OpenPage(PRODUCT2 + "/" + data.original.Id)}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];
  const OpenPage = (url) => {
    history.push(url);
  };
  return (
    <div className={classes.MainContainer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ textAlign: "left" }}>Active Premises</h1>
        <Button
          style={{
            color: ThemeColor,
            fontSize: 16,
            fontWeight: 600,
            marginRight: "2rem",
          }}
          onClick={() => history.push("/premises")}
        >
          Add Premises +
        </Button>
      </div>

      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default MyBuilding;
