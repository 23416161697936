import * as React from "react";

function HomeSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.75 9.758a1.008 1.008 0 00-.079-1.41L10.733.27A1.087 1.087 0 009.28.287L.31 8.78a1.002 1.002 0 00-.043 1.408l.225.242c.373.4.976.448 1.346.106l.67-.62v9.077c0 .557.437 1.008.976 1.008H6.98c.538 0 .975-.45.975-1.008v-6.35h4.46v6.35c-.008.557.377 1.008.916 1.008h3.705c.539 0 .975-.451.975-1.008v-8.95l.414.376c.228.207.707.04 1.07-.371l.253-.289z"
        fill="white"
      />
    </svg>
  );
}

export default HomeSvg;
