import React, { useState } from "react";
import ReactTable from "react-table-6";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";

const useStyles = makeStyles((theme) => ({
  TableRoot: {
    width: "100%",
    borderRadius: 15,
    // marginBottom:'3rem',
    "&>.rt-table": {
      borderRadius: 15,
    },
    "&>.rt-table .-header": {
      height: 55,
      boxShadow: "none",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      background: ThemeColor,
      color: "white",
      minWidth: "1200px",
      "&>.rt-tr": {
        alignItems: "center",
        fontWeight: 600,
        fontSize: 16,
      },
    },
    "&>.rt-table .-filters": {
      padding: "10px 5px",
    },
    "&>.rt-table .rt-tbody .rt-td": {
      padding: "15px 5px",
    },
    "&>.rt-table .rt-tbody .rt-tr-group .-odd": {
      background: "#8080800a",
      alignItems: "center",
    },
    "&>.rt-table .rt-tbody .rt-tr-group .-even": {
      alignItems: "center",
    },
    "&>.pagination-bottom .-pagination": {
      borderRadius: "1px 1px 13px 13px",
    },
    "&>.pagination-bottom .-previous button": {
      borderRadius: "1px 1px 1px 13px",
      "&.-btn:not([disabled]):hover": {
        background: ThemeColor,
      },
    },
    "&>.pagination-bottom .-next button": {
      borderRadius: "1px 1px 13px 1px",
      "&.-btn:not([disabled]):hover": {
        background: ThemeColor,
      },
    },
  },
}));

const Table = ({ data, columns, defaultPageSize }) => {
  const classes = useStyles();
  return (
    <ReactTable
      data={data}
      columns={columns}
      defaultPageSize={defaultPageSize}
      resizable={true}
      showPagination={true}
      filterable={true}
      showPageJump={true}
      className={classes.TableRoot}
    />
  );
};
export default Table;
