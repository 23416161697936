import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import BlockIcon from "@material-ui/icons/Block";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React from "react";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";
import "./index.css";
const SucessModal = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        // onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="sucessModal"
      >
        {/* <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle> */}
        <DialogContent>
          <div className="Sucess-error-Tic">
            <div>
              <div
                className="swal2-icon swal2-success swal2-animate-success-icon"
                style={{ display: "flex" }}
              >
                <div
                  className="swal2-success-circular-line-left"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                ></div>
                <span className="swal2-success-line-tip"></span>
                <span className="swal2-success-line-long"></span>
                <div className="swal2-success-ring"></div>
                <div
                  className="swal2-success-fix"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                ></div>
                <div
                  className="swal2-success-circular-line-right"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                ></div>
              </div>
            </div>
            <h5 style={{ color: "black", fontSize: "15px",textAlign:'center',margin:0 }}>
              {props.message}
            </h5>
          </div>
        </DialogContent>
        <DialogActions style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <Button
            onClick={props.close}
            className="theme-btn"
            style={{ borderRadius: 10, background: ThemeColor, color: "white",justifyContent:"center",alignItems:"center" }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SucessModal;
