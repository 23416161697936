import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LoginImage from "../../../Assets/Images/Otp.png";
import { Button, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../Assets/StyleSheets/Theme";
import { Link, useLocation } from "@reach/router";
import { useParams, useHistory } from "react-router-dom";
import { FORGET } from "../../../RouteS/ApiRoutes/Mail";
import SucessModal from "../../../Component/Modal/SucessModal";
import ErrorModal from "../../../Component/Modal/ErrorModal";
import InputMask from "react-input-mask";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Header from "../../../Component/Header/index";
import MainImage from "../../../Assets/Images/MainLogo.jpg";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "600px",
      height: 410,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "2.5rem",
    // paddingTop: "9.5rem",
    paddingTop: "11rem",
    // position: "relative",
    // bottom: 0,
    position: "absolute",
    // marginBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "9.5rem",
    },
  },
  RootImage: {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "2rem",
  },
  SubmitBtn: {
    width: "85%",
    background: ThemeColor,
    marginTop: "1rem",
    color: "white",
    "&:hover": {
      background: ThemeColor,
    },
    [theme.breakpoints.down("xs")]: {
      width: "77%",
    },
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  loginImage: {
    [theme.breakpoints.down("sm")]: {
      width: 304,
      height: 207,
    },
    [theme.breakpoints.down("md")]: {
      width: 304,
      height: 207,
    },
  },
}));
const ForgotPass = () => {
  const classes = useStyles();
  const [Otp, setOtp] = useState("");
  const [Password, setPassword] = useState("");
  const [PassErr, setPassErr] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [ConfirmPassErr, setConfirmPassErr] = useState("");

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const { t } = useTranslation();

  const onChangeOtp = (e) => {
    let value = e.target.value;
    setOtp(value);
  };
  const onChangePassword = (e) => {
    let value = e.target.value;
    setPassword(value);
    if (value.length >= 6) {
      setPassErr("");
    } else {
      setPassErr("At Least 6 Characters Password");
    }
  };
  const onChangeConfirmPassword = (e) => {
    let value = e.target.value;
    setConfirmPassword(value);
    if (value.length >= 6) {
      setConfirmPassErr("");
    } else {
      setConfirmPassErr("At Least 6 Characters ConfirmPassword");
    }
  };
  var Name = JSON.parse(localStorage.getItem("Name"));
  let history = useHistory();
  const Save = () => {
    if (Password === ConfirmPassword) {
      var raw = JSON.stringify({
        Name: Name,
        Otp: Otp,
        Password: Password,
        ConfirmPassword: ConfirmPassword,
      });
      fetch(FORGET, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            if (res.Status === true) {
              localStorage.clear();
              setStatus({ ...status, success: true });
              setMessage(res.Message);
            } else {
              setStatus({ ...status, error: true });
              setMessage(res.Message);
            }
          }
        });
    } else {
      setStatus({ ...status, error: true });
      setMessage("Password and ConfirmPassword Not Matched");
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <div className={classes.RootImage}></div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div style={{ textAlign: "left" }}>
            <h1
              style={{ marginBottom: 0, color: "white ", textAlign: "center" }}
            >
              {t("otp_verify")}
            </h1>
            <br />
            <h1 style={{ marginBottom: 0, marginTop: 0 }}></h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel} name="Otp">
                  {t("enter_otp")}
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  // style={{ width: 300 }}
                  name="Otp"
                  placeholder="Enter Otp"
                  onChange={onChangeOtp}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel} name="Password">
                  {t("enter_new_pass")}
                </label>
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  size="small"
                  // style={{ width: 300 }}
                  name="Password"
                  placeholder="At Least 6 Characters Password"
                  onChange={onChangePassword}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <span
                  style={{ color: "red", fontSize: "15px", textAlign: "left" }}
                >
                  {PassErr}
                </span>
              </div>
              <div className={classes.FieldDiv}>
                <label className={classes.textLabel} name="ConfirmPassword">
                  {t("enter_new_confi_pass")}
                </label>
                <TextField
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  size="small"
                  // style={{ width: 300 }}
                  name="ConfirmPassword"
                  placeholder="At Least 6 Characters ConfirmPassword"
                  onChange={onChangeConfirmPassword}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />{" "}
                <span
                  style={{ color: "red", fontSize: "15px", textAlign: "left" }}
                >
                  {ConfirmPassErr}
                </span>
              </div>
              <br />
              <Button className={classes.SubmitBtn} onClick={Save}>
                {t("submit")}
              </Button>
            </Paper>
            {/* <img
              src={LoginImage}
              width="480"
              height="345"
              style={{ marginLeft: "8rem" }}
              className={classes.loginImage}
            /> */}
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push("/userLogin");
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </div>
  );
};
export default ForgotPass;
