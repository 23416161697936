import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "&>.MuiPaper-elevation3": {
      width: 250,
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: 18,
      background: ThemeColor,
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        height: 100,
        margin: "0 10px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "92%",
        height: "100%",
        marginBottom: "1rem",
      },

      "&>h2": {
        marginTop: 0,
        [theme.breakpoints.down("lg")]: {
          fontSize: 14,
        },
      },
    },
  },
}));
const CardComponent = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <h2 style={{ color: "white" }}>{title}</h2>
        <h2 style={{ color: "white" }}>{value}</h2>
      </Paper>
    </div>
  );
};
export default CardComponent;
