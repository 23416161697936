import React, { useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../Assets/StyleSheets/Theme";
import { Avatar, Button, Container } from "@material-ui/core";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-select";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ErrorModal from "../../../Component/Modal/ErrorModal";
import SucessModal from "../../../Component/Modal/SucessModal";
import { SIGNUP } from "../../../RouteS/ApiRoutes/Login-SignUp";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../ProtectedPage/Engineer/Profile/profile.css";
import Header from "../../../Component/Header/index";
import MainImage from "../../../Assets/Images/MainLogo.jpg";
import { useTranslation } from "react-i18next";
var lang = JSON.parse(localStorage.getItem("lang"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      height: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "100%",
      },
    },
  },
  RootImage: {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundImage: `url(${MainImage})`,
    backgroundSize: "cover",
    marginTop: "40px",
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "13rem",
    // paddingTop: "16.5rem",
    // position: "relative",
    // height: "100%",
    bottom: 0,
    // position: "fixed",
    position: "relative",
    marginBottom: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: "12.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "9.5rem",
      position: "relative",
    },
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "2.8rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      "&>.MuiFormControl-root": {
        width: "100% !important",
      },
      width: "36%  !important",
    },
    [theme.breakpoints.down("xs")]: {
      "&>.MuiFormControl-root": {
        width: "230px !important",
      },
      width: "36%  !important",
    },
  },
  // FieldDiv2: {
  //   display: "flex",
  //   flexDirection: "column",
  //   marginRight: "3rem",
  //   marginLeft: "3rem",
  //   marginTop: "1rem",
  //   width: "23.3%",
  //   [theme.breakpoints.down("sm", "md")]: {
  //     width: "36% !important",
  //   },
  //   "&>.css-2b097c-container": {
  //     width: "100% !important",
  //   },
  //   "&>.css-2b097c-container .css-yk16xz-control": {
  //     borderColor: ThemeColor,
  //   },
  //   "&>.css-2b097c-container .css-1pahdxg-control": {
  //     borderColor: ThemeColor,
  //     boxShadow: "none",
  //     "&:hover": {
  //       borderColor: ThemeColor,
  //     },
  //     "&:active": {
  //       borderColor: ThemeColor,
  //     },
  //   },
  // },
  RegisterBtn: {
    width: "35%",
    background: ThemeColor,
    marginTop: "1rem",
    color: "white",
    height: "7%",
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginBottom: "5px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      width: "230px !important",
    },
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  ProfileBtn: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 30,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  ProfileBtn2: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 0,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  profileImageDiv: {
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&>span": {
      fontWeight: 600,
      marginTop: "0.5rem",
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  MainLabel: {
    textAlign: lang == "ar" ? "right" : "left",
    marginLeft: lang == "ar" ? "0rem" : "3rem",
    marginRight: lang == "ar" ? "3rem" : "0rem",
  },
  Title: {
    textAlign: "left",
    marginTop: "-2rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const imageRef = useRef();
  let history = useHistory();
  const [file, setFile] = useState();
  const [Image, setImage] = useState();
  const [profileImg, setProfileImage] = useState(false);
  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    UserName: "",
    EIDno: "",
    ContactNo: "",
    // AltContactNo: "",
    // ContactNo2: "",
    Email: "",
    // FirstName2: "",
    // LastName2: "",
    ConfirmPassword: "",
    roleid: 1,
    Status: "Inactive",
    // Relation: "",
  });
  const [field1, setField1] = useState({
    Password: "",
  });
  const [field2, setField2] = useState({
    ConfirmPassword: "",
  });
  const [field3, setField3] = useState({
    Relation: "",
  });
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [emailerror, setEmailError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onChangeFile = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    setProfileImage(true);
  };
  const onChangePass = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField1({ ...field, [e.target.name]: value });
      setA("");
    } else {
      setA("At Least 6 Characters Password");
    }
  };
  const onChangeConfirm = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField2({ ...field, [e.target.name]: value });
      setB("");
    } else {
      setB("At Least 6 Characters ConfirmPassword");
    }
  };
  const onChangeField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };
  const onChangeRelation = (e) => {
    let value = e.value;
    setField3({ Relation: value });
  };

  const options = [
    { value: "Spounce", label: "Spounce" },
    { value: "PRO", label: "PRO" },
    { value: "Representative", label: "Representative" },
  ];

  const Register = () => {
    if (field.Email === "" && field.EIDno === "") {
      setStatus({ ...status, error: true });
      setMessage("Please fill all field");
    } else {
      if (field1.Password === field2.ConfirmPassword) {
        if (emailerror == "") {
          const dates = new Date().getMinutes();
          const data = new FormData();
          data.append("FirstName", field.FirstName);
          data.append("LastName", field.LastName);
          data.append("UserName", field.UserName);
          data.append("ContactNo", field.ContactNo);
          // data.append("AltContactNo", field.AltContactNo);
          data.append("Email", field.Email);
          data.append("EIDno", field.EIDno);
          // data.append("FirstName2", field.FirstName2);
          // data.append("LastName2", field.LastName2);
          // data.append("ContactNo2", field.ContactNo2);
          data.append("Password", field1.Password);
          // data.append("Relation", field.Relation);
          data.append("ConfirmPassword", field2.ConfirmPassword);
          data.append("roleid", "3");
          data.append("Time", dates);
          data.append("file", file);
          fetch(SIGNUP, {
            method: "POST",
            body: data,
          })
            .then((res) => res.json())
            .then((res) => {
              if (res) {
                if (res.Status === true) {
                  setStatus({ ...status, success: true });
                  setMessage(res.Message);
                  localStorage.setItem("signupval", JSON.stringify(res.Otp));
                } else {
                  setStatus({ ...status, error: true });
                  setMessage(res.Message);
                }
              }
            });
        }
      } else {
        setStatus({ ...status, error: true });
        setMessage("Password And ConfirmPassword Was Not Matched");
      }
    }
  };
  const [val2, setValue2] = useState("971");
  const [close, setClose] = useState(true);
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <div className={classes.RootImage}></div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div className={classes.Title}>
            <h1 style={{ color: "white ", textAlign: "center" }}>
              {t("user_registration")}
            </h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div className={classes.profileImageDiv}>
                {profileImg ? (
                  <>
                    <IconButton
                      className={classes.ProfileBtn2}
                      onClick={() => imageRef.current.click()}
                    >
                      <Avatar src={Image} style={{ height: 100, width: 100 }} />
                    </IconButton>
                    <span>{t("registration_image")}</span>
                  </>
                ) : (
                  <>
                    {" "}
                    <IconButton
                      className={classes.ProfileBtn}
                      onClick={() => imageRef.current.click()}
                    >
                      <PhotoCameraIcon
                        style={{ color: "black", fontSize: 32 }}
                      />
                    </IconButton>
                    <span>{t("registration_image")}</span>
                  </>
                )}
                <input
                  type="file"
                  ref={imageRef}
                  style={{ display: "none" }}
                  onChange={onChangeFile}
                />
              </div>
              <div>
                <h3 className={classes.MainLabel}>{t("personal_info")}</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("first_name")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      type="text"
                      variant="outlined"
                      value={field.FirstName}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setField({ ...field, FirstName: e.target.value });
                      }}
                      size="small"
                      name="FirstName"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      required
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("last_name")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="Text"
                      name="LastName"
                      value={field.LastName}
                      onChange={(e) => {
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setField({ ...field, LastName: e.target.value });
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      required
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("user_name")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="Text"
                      name="UserName"
                      value={field.UserName}
                      onChange={(e) => {
                        setField({ ...field, UserName: e.target.value });
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      required
                    />
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("eid_number")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <InputMask
                      mask="999-9999-9999999-9"
                      disabled={false}
                      maskChar=" "
                      onChange={onChangeField}
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          size="small"
                          name="EIDno"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      )}
                    </InputMask>
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("email_address")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="Email"
                      onChange={
                        // onChangeField
                        (e) => {
                          const re =
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                          if (re.test(e.target.value)) {
                            setField({ ...field, Email: e.target.value });
                            setEmailError("");
                          } else {
                            setEmailError("Please Enter Valid Email");
                          }
                        }
                      }
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      required
                    />
                    {emailerror == "" ? (
                      ""
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        {emailerror}
                      </div>
                    )}
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      {t("mobile_number")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <ReactPhoneInput
                      country={"ae"}
                      value={field.ContactNo}
                      onChange={(e) => {
                        setField({ ...field, ContactNo: e });
                      }}
                    />
                    {/* <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="ContactNo"
                      style={{ width: "225px" }}
                      inputProps={{ maxLength: 12 }}
                      value={field.ContactNo}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setField({ ...field, ContactNo: e.target.value });
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                      required
                    /> */}
                  </div>
                  {/* <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Alternative Contact Number
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="AltContactNo"
                      style={{ width: "225px" }}
                      inputProps={{ maxLength: 12 }}
                      value={field.AltContactNo}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setField({ ...field, AltContactNo: e.target.value });
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">+</InputAdornment>
                        ),
                      }}
                      required
                    />
                  </div> */}
                </div>
                {/* <div>
                  <h3 className={classes.MainLabel}>
                    Alternate Contact Information
                  </h3>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>First Name</label>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="text"
                        name="FirstName2"
                        value={field.FirstName2}
                        onChange={(e) => {
                          const re = /^[A-Za-z]+$/;
                          if (e.target.value === "" || re.test(e.target.value))
                            setField({ ...field, FirstName2: e.target.value });
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        required
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Last Name</label>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="text"
                        name="LastName2"
                        value={field.LastName2}
                        onChange={(e) => {
                          const re = /^[A-Za-z]+$/;
                          if (e.target.value === "" || re.test(e.target.value))
                            setField({ ...field, LastName2: e.target.value });
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        required
                      />
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>Contact Number</label>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="text"
                        name="ContactNo2"
                        style={{ width: "225px" }}
                        inputProps={{ maxLength: 12 }}
                        value={field.ContactNo2}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value))
                            setField({ ...field, ContactNo2: e.target.value });
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          startAdornment: (
                            <InputAdornment position="start">+</InputAdornment>
                          ),
                        }}
                        required
                      />
                    </div>
                    <div className={classes.FieldDiv2}>
                      <label className={classes.textLabel}>Relation</label>
                      <Select options={options} onChange={onChangeRelation} />
                    </div>
                  </div>
                </div> */}
                <div>
                  <h3 className={classes.MainLabel}>{t("security_info")}</h3>
                  <div className={classes.mainDiv}>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        {t("create_pass")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        size="small"
                        name="Password"
                        placeholder="At Least 6 Characters"
                        onChange={onChangePass}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                          textAlign: "left",
                        }}
                      >
                        {a}
                      </span>
                    </div>
                    <div className={classes.FieldDiv}>
                      <label className={classes.textLabel}>
                        {t("confirm_pass")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type={showConfirmPassword ? "text" : "password"}
                        variant="outlined"
                        size="small"
                        name="ConfirmPassword"
                        placeholder="At Least 6 Characters"
                        onChange={onChangeConfirm}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                          textAlign: "left",
                        }}
                      >
                        {b}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <Button className={classes.RegisterBtn} onClick={() => { if (close) { Register(); setClose(false) } }}>
                {t("register_now")}
              </Button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    textAlign: "right",
                    fontWeight: 500,
                    marginBottom: "0rem",
                  }}
                >
                  {t("alreadyaccount")}
                </p>
                <Link
                  to="/userLogin"
                  style={{ fontSize: 12, color: ThemeColor, marginLeft: "4px" }}
                >
                  {t("sign_in")}
                </Link>
              </div>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(`/email/${field.Email}`);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </div>
  );
};
export default SignUp;
