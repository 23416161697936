export const ADMINLOGIN = "/admin";

//Customer
export const MYCUSTOMER = "/mycustomer";
export const DEATAILCUSTOMER = "/detailcustomer/:id";
export const DEATAILCUSTOMER2 = "/detailcustomer";

//Staff
export const VIEWSTAFF = "/staff";
export const ADDSTAFF = "/addstaff";
export const EDITSTAFF = "/editstaff/:id";
export const EDITSTAFF2 = "/editstaff";
export const DETAILSTAFF = "/detailstaff";

//timeslote
export const VIEWTIME = "/timeslots";
export const ADDTIME = "/addtime";
export const EDITTIME = "/edittime/:id";
export const EDITTIME2 = "/edittime";

//Customer Request
export const CUSTOMERREQUEST = "/customerrequest";

//Installation Details
export const INSTALLATIONDETAILS = "/installationdetails/:id";

//Service Details
export const SERVICEDETAILS = "/servicedetails/:id";

//Time Slots
export const TIMESLOTS = "/timeslots";

//Notification
export const NOTIFICATION = "/notifications";

//Reshedule Request
export const RESHEDULEREQ = "/reshedulerequest";

//Damage Rerquest
export const DAMAGEREQUEST = "/damagerequest";
export const DAMAGEHISTORY = "/damagehistory";

export const VIEWPRICE = "/ViewPrice";
export const ADDPRICE = "/addPrice";
export const EDITPRICE = "/editPrice/:id";
export const EDITPRICE2 = "/editPrice";

export const ADMINVIEWSURVEY = "/AdminViewSurvey";
export const DETAILSURVEY = "/Detailsurvey";

export const ADMIN = "/newadmin";
export const ADDADMIN = "/addadmin";
export const EDITADMIN = "/editadmin/:id";
