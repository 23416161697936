import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../../Assets/StyleSheets/Theme";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  ModalField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&>label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },

  ModalField2: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&>label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },

  ReshedulBtn: {
    background: ThemeColor,
    color: "white",
    width: "100%",
    fontWeight: 600,
    "&:hover": {
      background: ThemeColor,
    },
  },
  AssignBtn: {
    background: ThemeColor,
    color: "white",
    width: "100%",
    fontWeight: 600,
    marginTop: "1rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
}));
export default useStyles;
