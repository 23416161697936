import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Avatar, Button, Container, IconButton } from "@material-ui/core";
import { VIEWSTAFF } from "../../../../RouteS/BrowserRoute/Admin/index";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Link } from "react-router-dom";
import Select from "react-select";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useHistory } from "react-router-dom";
import { STAFF } from "../../../../RouteS/ApiRoutes/Staff";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import InputMask from "react-input-mask";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../Engineer/Profile/profile.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 957,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  ProfileBtn: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 30,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  ProfileBtn2: {
    background: "rgba(196, 196, 196, 0.47)",
    padding: 0,
    "&:hover": {
      background: "rgba(196, 196, 196, 0.47)",
    },
  },
  profileImageDiv: {
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&>span": {
      fontWeight: 600,
      marginTop: "0.5rem",
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginLeft: "25px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      "&>.MuiFormControl-root": {
        width: "100% !important",
      },
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      "&>.MuiFormControl-root": {
        width: "100% !important",
      },
      width: "35%",
    },
  },
  FieldDiv2: {
    display: "flex",
    flexDirection: "column",
    marginRight: "3rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    width: "23.3%",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "35%",
    },
    "&>.css-2b097c-container": {
      width: "100% !important",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  SaveBtn: {
    width: "15%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    marginBottom: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      width: "35%",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 12,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    marginBottom: 0,
    marginTop: "2rem",
    fontWeight: 500,
    fontSize: "22px",
  },
  DateTextField: {
    width: "223px",
  },
}));

const AddStaff = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const classes = useStyles();
  const imageRef = useRef();
  const [Image, setImage] = useState();
  const [file, setFile] = useState();
  const [profileImg, setProfileImage] = useState(false);
  const history = useHistory();

  const onChangeFile = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    setProfileImage(true);
  };

  const [field1, setField1] = useState({
    Password: "",
  });
  const [field2, setField2] = useState({
    Password: "",
  });
  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const [message, setMessage] = useState("");
  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const OnChagePass = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField1({ ...field, [e.target.name]: value });
      setA("");
    } else {
      setA("At Least 6 Characters Password");
    }
  };

  const onChangeConfirm = (e) => {
    let value = e.target.value;
    if (value.length >= 6) {
      setField2({ ...field, [e.target.name]: value });
      setB("");
    } else {
      setB("At Least 6 Characters ConfirmPassword");
    }
  };

  const [field, setField] = useState({
    FirstName: "",
    LastName: "",
    IdNumber: "",
    UserName: "",
    EIDno: "",
    Email: "",
    ContactNo: "",
    Emirate: "",
    Gender: "",
    DOB: "",
    PassportNo: "",
    JoiningDate: "",
    Designation: "",
    MemberOfTeam: "",
    Department: "",
    Branch: "",
    IsAssign: "No",
    Status: "pending",
  });

  const onChangeField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };
  const onChangeEmirate = (e) => {
    let value = e.value;
    setField({ ...field, Emirate: value });
  };
  const onChangeGender = (e) => {
    let value = e.value;
    setField({ ...field, Gender: value });
  };
  const onChangeDesignation = (e) => {
    let value = e.value;
    setField({ ...field, Designation: value });
  };
  const onChangeDepartment = (e) => {
    let value = e.value;
    setField({ ...field, Department: value });
  };
  const onChangeBranch = (e) => {
    let value = e.value;
    setField({ ...field, Branch: value });
  };

  const options1 = [
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Dubai", label: "Dubai" },
    { value: "Sharjah", label: "Sharjah" },
    { value: "Ajman", label: "Ajman" },
    { value: "Umm Al Quwain", label: "Umm Al Quwain" },
    { value: "Fujairah", label: "Fujairah" },
    { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  ];

  const options2 = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const options3 = [
    { value: "Technician", label: "Technician" },
    { value: "Engineer", label: "Engineer" },
    { value: "Admin", label: "Admin" },
    { value: "Sales", label: "Sales" },
    { value: "Marketing", label: "Marketing" },
    { value: "Technical Specialist", label: "Technical Specialist" },
    { value: "Fire Fighting Specialist", label: "Fire Fighting Specialist" },
    { value: "Fire Alarm Specialist", label: "Fire Alarm Specialist" },
  ];

  const options4 = [
    { value: "Service", label: "Service" },
    { value: "Projects", label: "Projects" },
  ];

  const Save = () => {
    if (field1.Password === field2.ConfirmPassword) {
      if (emailerror == "") {
        const data = new FormData();
        data.append("RoleId", "3");
        data.append("FirstName", field.FirstName);
        data.append("LastName", field.LastName);
        data.append("IdNumber", field.IdNumber);
        data.append("UserName", field.UserName);
        data.append("EIDno", field.EIDno);
        data.append("Email", field.Email);
        data.append("ContactNo", field.ContactNo);
        data.append("Emirate", field.Emirate);
        data.append("Gender", field.Gender);
        data.append("DOB", field.DOB);
        data.append("MaritialStatus", field.MaritialStatus);
        data.append("JoiningDate", field.JoiningDate);
        data.append("PassportNo", field.PassportNo);
        data.append("Designation", field.Designation);
        data.append("Department", field.Department);
        data.append("Branch", field.Branch);
        data.append("MemberOfTeam", field.MemberOfTeam);
        data.append("Password", field1.Password);
        data.append("file", file);
        data.append("IsAssign", field.IsAssign);
        data.append("Status", "Active");
        fetch(STAFF, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.Status === true) {
              setStatus({ ...status, success: true });
              setMessage(res.Message);
            } else {
              setStatus({ ...status, error: true });
              setMessage(res.Message);
            }
          });
      }
    } else {
      alert("Password And ConfirmPassword Was Not Matched.");
    }
  };
  return (
    <Container maxWidth="xl" className={classes.MainContainer}>
      <div>
        <div style={{ textAlign: "left", marginTop: "-2rem" }}>
          <h1>Add Staff Member</h1>
        </div>
        <div className={classes.root}>
          <Paper elevation={3}>
            <div className={classes.profileImageDiv}>
              {profileImg ? (
                <>
                  <IconButton
                    className={classes.ProfileBtn2}
                    onClick={() => imageRef.current.click()}
                  >
                    <Avatar src={Image} style={{ height: 100, width: 100 }} />
                  </IconButton>
                  <span>Profile Image</span>
                </>
              ) : (
                <>
                  {" "}
                  <IconButton
                    className={classes.ProfileBtn}
                    onClick={() => imageRef.current.click()}
                  >
                    <PhotoCameraIcon style={{ color: "black", fontSize: 32 }} />
                  </IconButton>
                  <span>Profile Image</span>
                </>
              )}
              <input
                type="file"
                ref={imageRef}
                style={{ display: "none" }}
                onChange={onChangeFile}
              />
            </div>
            <div>
              <h3 className={classes.MainLabel}>Personal Information</h3>
              <div className={classes.mainDiv}>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>First Name</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="FirstName"
                    value={field.FirstName}
                    onChange={(e) => {
                      const re = /^[A-Za-z]+$/;
                      if (e.target.value === "" || re.test(e.target.value))
                        setField({ ...field, FirstName: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Last Name</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="LastName"
                    type="text"
                    value={field.LastName}
                    onChange={(e) => {
                      const re = /^[A-Za-z]+$/;
                      if (e.target.value === "" || re.test(e.target.value))
                        setField({ ...field, LastName: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>WEQAYA Id Number</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="IdNumber"
                    onChange={onChangeField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>User Name</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="UserName"
                    onChange={onChangeField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>EID Number</label>
                  <InputMask
                    mask="999-9999-9999999-9"
                    disabled={false}
                    maskChar=" "
                    onChange={onChangeField}
                  >
                    {() => (
                      <TextField
                        variant="outlined"
                        size="small"
                        name="EIDno"
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    )}
                  </InputMask>
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Email Address</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="Email"
                    onChange={(e) => {
                      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                      if (re.test(e.target.value)) {
                        setField({ ...field, Email: e.target.value });
                        setEmailError("");
                      } else {
                        setField({ ...field, Email: e.target.value });
                        setEmailError("Please Enter Valid Email");
                      }
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                  {emailerror == "" ? (
                    ""
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {emailerror}
                    </div>
                  )}
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Contact Number</label>
                  <ReactPhoneInput
                    country={"ae"}
                    value={field.ContactNo}
                    onChange={(e) => {
                      setField({ ...field, ContactNo: e });
                    }}
                  />
                  {/* <TextField
                    variant="outlined"
                    size="small"
                    type="Text"
                    name="ContactNo"
                    inputProps={{ maxLength: 12 }}
                    style={{ width: "222px" }}
                    value={field.ContactNo}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value))
                        setField({ ...field, ContactNo: e.target.value });
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      startAdornment: (
                        <InputAdornment position="start">+</InputAdornment>
                      ),
                    }}
                  /> */}
                </div>
                <div className={classes.FieldDiv2}>
                  <label className={classes.textLabel} onChange={onChangeField}>
                    Emirate
                  </label>
                  <Select options={options1} onChange={onChangeEmirate} />
                </div>
                <div className={classes.FieldDiv2}>
                  <label className={classes.textLabel} onChange={onChangeField}>
                    Gender
                  </label>
                  <Select options={options2} onChange={onChangeGender} />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel} onChange={onChangeField}>
                    Date of Birth
                  </label>
                  <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    name="DOB"
                    onChange={onChangeField}
                    className={classes.DateTextField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Passport Number</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="PassportNo"
                    onChange={onChangeField}
                    inputProps={{ maxLength: 9 }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <h3 className={classes.MainLabel}>Company Information</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Joining Date</label>
                    <TextField
                      type="date"
                      className={classes.DateTextField}
                      variant="outlined"
                      size="small"
                      name="JoiningDate"
                      onChange={onChangeField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.FieldDiv2}>
                    <label className={classes.textLabel}>Designation</label>
                    <Select options={options3} onChange={onChangeDesignation} />
                  </div>
                  <div className={classes.FieldDiv2}>
                    <label
                      className={classes.textLabel}
                      onChange={onChangeField}
                    >
                      Departmaent
                    </label>
                    <Select options={options4} onChange={onChangeDepartment} />
                  </div>

                  <div className={classes.FieldDiv2}>
                    <label
                      className={classes.textLabel}
                      onChange={onChangeField}
                    >
                      Branch
                    </label>
                    <Select options={options1} onChange={onChangeBranch} />
                  </div>
                  {/* <div className={classes.FieldDiv2}>
                    <label
                      className={classes.textLabel}
                      onChange={onChangeField}
                    >
                      Member Of Team
                    </label>
                    <Select />
                  </div> */}
                </div>
              </div>

              <div>
                <h3 className={classes.MainLabel}>Security Information</h3>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>Password</label>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      size="small"
                      name="Password"
                      placeholder="At Least 6 Characters"
                      onChange={OnChagePass}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <span style={{ color: "red", fontSize: "15px" }}>{a}</span>
                  </div>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>
                      Confirm Password
                    </label>
                    <TextField
                      type={showConfirmPassword ? "text" : "password"}
                      variant="outlined"
                      size="small"
                      name="ConfirmPassword"
                      onChange={onChangeConfirm}
                      placeholder="At Least 6 Characters"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />{" "}
                    <span style={{ color: "red", fontSize: "15px" }}>{b}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button className={classes.SaveBtn} onClick={Save}>
                Save
              </Button>
              <Button
                className={classes.SaveBtn}
                onClick={() => history.push(VIEWSTAFF)}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </div>
      </div>
      <SucessModal
        open={status.success}
        message={message}
        close={() => {
          return history.push(VIEWSTAFF);
        }}
      />
      <ErrorModal
        open={status.error}
        message={message}
        close={() => {
          setStatus({ ...status, error: false });
        }}
      />
    </Container>
  );
};
export default AddStaff;
