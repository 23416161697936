//Login
export const LOGIN = "/userLogin";

export const MAINPAGE = "/";

export const CONATCTUS = "/contactus";
export const PROGRAM = "/program";
export const REGISTRATIONFEES = "/registrationfees";
export const REQUIREMENTS = "/requirements";
export const MEDIA = "/media";
//Signup
export const SIGNUP = "/signup";
//Email
export const EMAIL = "/email";
export const EMAIL2 = "/email/:id";
//requestOtp
export const REQUEST = "/requestOtp";
export const FORGOTPASS = "/forgotPassword";

export const PREMISES = "/premises";
export const PREMISESRETURN = "/premises/:id";
export const PREMISESREPAY = "/premisesrepay/:id";

//Dashboard
export const DASHBOARD = "/dashboard";

export const VIEWPREMISES = "/viewpremises";

//Notification
export const CUSTOMERNOTIFICATION = "/customernotification";

//Ticket
export const VIEWTICKET = "/viewticket";
export const ADDTICKET = "/requestnewticket";

//Order
export const VIEWORDER = "/vieworder";

//Product
export const PRODUCT = "/productdetial/:id";
export const PRODUCT2 = "/productdetial";

//Profile
export const PROFILE = "/profile";

export const USERVIEWSURVEY = "/Userviewsurvey";
export const USERDETAILSURVEY = "/UserDetailSurvey";
