import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { ATEREPORTS } from "../../../../RouteS/BrowserRoute/Engineer/index";
import { LISTONEENGINEER } from "../../../../RouteS/ApiRoutes/Damage";
import Dropdown, {
  DropdownToggle,
  DropdownMenu,
  DropdownMenuWrapper,
  MenuItem,
  DropdownButton,
  Icon,
} from "@trendmicro/react-dropdown";
import useStyle from "./style";
import PuffLoader from "react-spinners/PuffLoader";

const DamageEngineer = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const Id = JSON.parse(localStorage.getItem("Id"));
  const classes = useStyle();
  const history = useHistory();

  const [Data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  let [color, setColor] = useState("#ffffff");
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, []);

  useEffect(() => {
    getDamageEngineer();
  }, []);

  const getDamageEngineer = () => {
    const BodyData = JSON.stringify({
      EngineerId: EngineerId,
    });
    fetch(LISTONEENGINEER, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        return setData(res.data);
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Issue Name",
      accessor: "IssueName",
    },

    {
      Header: "Description",
      accessor: "Description",
    },
    {
      Header: "Serial No",
      accessor: "SerialNo",
    },
    {
      Header: "Location",
      accessor: "Location",
    },
    {
      Header: "Reschedule",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.Reschedule === "") {
          return <p>Not Schedule</p>;
        } else {
          return <p>{data.original.Reschedule}</p>;
        }
      },
    },
    {
      Header: "Damage ATEs Report",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.Reschedule === "") {
          return <div>-</div>;
        } else if (data.original.Action !== "") {
          return "Done";
        } else {
          return (
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={() => {
                history.push("/AddDamageATEs");
                localStorage.setItem(
                  "OrderId",
                  JSON.stringify(data.original.OrderId)
                );
                localStorage.setItem(
                  "UserId",
                  JSON.stringify(data.original.UserId)
                );
                localStorage.setItem(
                  "dueDate",
                  JSON.stringify(data.original.DueDate)
                );
                localStorage.setItem(
                  "BusinessName",
                  JSON.stringify(data.original.BusinessName)
                );
                localStorage.setItem(
                  "DamageId",
                  JSON.stringify(data.original.DamageId)
                );
              }}
            >
              Submit
            </Button>
          );
        }
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Damage ATEs</h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default DamageEngineer;
