import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import MyModal from "../../../../Component/Modal/index";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import useStyle from "./style";
import { useHistory } from "react-router-dom";
import { LISTENGINEERSERVICES } from "../../../../RouteS/ApiRoutes/Services";
import { UPDATESTATUS1 } from "../../../../RouteS/ApiRoutes/Installation";
import AssignmentIcon from "@mui/icons-material/Assignment";

const ServiceTask = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const classes = useStyle();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [Data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [text, setText] = useState("");
  const [userId, setUserId] = useState("");
  const [uname, setUserName] = useState("");
  const [ticketno, setTicketNo] = useState("");
  const [ename, setEname] = useState("");
  const [obj, setObj] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [holdmessage, setHoldMessage] = useState("");

  useEffect(() => {
    getServices();
  }, []);

  const getServices = () => {
    const BodyData = JSON.stringify({
      Id: EngineerId,
    });
    fetch(LISTENGINEERSERVICES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        return setData(res.data);
      });
  };

  const updatestatus = (id) => {
    const BodyData = JSON.stringify({
      Id: id,
      Status: status,
      HoldMessage: text,
      UserId: userId,
      TicketNo: ticketno,
      Ename: ename,
      Uname: uname,
    });
    fetch(UPDATESTATUS1, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setObj(true);
        handleClickOpen();
        getServices();
      });
  };

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handlestatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value === "Hold") {
      setOpen(true);
    }
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Ticket No",
      accessor: "TicketNo",
    },
    {
      Header: "Name",
      accessor: "CustomerName",
    },

    {
      Header: "Business Name",
      accessor: "BusinessName",
    },
    {
      Header: "SerialNo",
      accessor: "SerialNo",
    },
    {
      Header: "Issue",
      accessor: "Issue",
    },

    {
      Header: "Address",
      accessor: "Address",
    },
    {
      Header: "Scheduled On",
      accessor: "SheduleOn",
    },
    {
      Header: "Status",
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Pending") {
          return row[filter.id] === "Pending";
        }
        if (filter.value === "In Progress") {
          return row[filter.id] === "In Progress";
        }
        if (filter.value === "Hold") {
          return row[filter.id] === "Hold";
        }
        if (filter.value === "Complete") {
          return row[filter.id] === "Complete";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all" selected>
            View All Status
          </option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Hold">Hold</option>
          <option value="Complete">Complete</option>
        </select>
      ),
      id: "Status",
      accessor: "Status",
      Cell: (data) => {
        switch (data.original.Status) {
          case "Pending":
            return (
              <div
                style={{
                  background: "grey",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "In Progress":
            return (
              <div
                style={{
                  background: "skyblue",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 3,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
          case "Hold":
            return (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      background: "red",
                      width: "100px",
                      height: "20px",
                      margin: "0px auto",
                      marginTop: "15px",
                      borderRadius: 13,
                      color: "white",
                      padding: 3,
                      marginLeft: "40px",
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    {data.original.Status}
                  </div>
                  <div>
                    <IconButton
                      onClick={() => {
                        setOpen2(true),
                          setHoldMessage(data.original.HoldMessage);
                      }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            );
          case "Complete":
            return (
              <div
                style={{
                  background: "green",
                  width: "70px",
                  margin: "0px auto",
                  borderRadius: 13,
                  color: "white",
                  padding: 2,
                  fontWeight: 600,
                  fontSize: 11,
                }}
              >
                {data.original.Status}
              </div>
            );
        }
      },
    },
    {
      Header: "To Do",
      accessor: "Status",
      filterable: false,
      Cell: (data) => {
        if (data.original.Status === "Hold") {
          return (
            <select disabled onChange={handlestatus}>
              <option value="In Progress">In Progress</option>
              <option selected value="Hold">
                Hold
              </option>
              <option value="Complete">Complete</option>
            </select>
          );
        } else if (data.original.Status === "Complete") {
          return (
            <select onChange={handlestatus}>
              <option value="In Progress">In Progress</option>
              <option value="Hold">Hold</option>
              <option selected value="Complete">
                Complete
              </option>
            </select>
          );
        } else if (data.original.Status === "In Progress") {
          return (
            <select onChange={handlestatus}>
              <option selected value="In Progress">
                In Progress
              </option>
              <option value="Hold">Hold</option>
              <option value="Complete">Complete</option>
            </select>
          );
        } else {
          return (
            <select onChange={handlestatus}>
              <option value="" selected disabled>
                Select Status
              </option>
              <option value="In Progress">In Progress</option>
              <option value="Hold">Hold</option>
              <option value="Complete">Complete</option>
            </select>
          );
        }
      },
    },
    {
      Header: "Update",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        if (data.original.Status === "Hold") {
          return (
            <Button
              disabled
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={(e) => {
                updatestatus(data.original.Id);
                setUserId(data.original.UserId);
                setTicketNo(data.original.TicketNo);
                setEname(data.original.AssignTo);
              }}
            >
              Update
            </Button>
          );
        } else {
          return (
            <Button
              style={{
                color: "white",
                background: ThemeColor,
                borderRadius: 10,
              }}
              onClick={(e) => {
                updatestatus(data.original.Id);
                setUserId(data.original.UserId);
                setTicketNo(data.original.TicketNo);
                setEname(data.original.AssignTo);
                setUserName(data.original.CustomerName);
              }}
            >
              Update
            </Button>
          );
        }
      },
    },
    // {
    //   Header: "Survey",
    //   filterable: false,
    //   id: "icon",
    //   Cell: (data) => {
    //     return (
    //       <Button
    //         style={{
    //           color: "white",
    //           background: ThemeColor,
    //           borderRadius: 10,
    //         }}
    //         onClick={() => {
    //           localStorage.setItem("ate", JSON.stringify(data.original.SerialNo));
    //           localStorage.setItem(
    //             "OrderId",
    //             JSON.stringify(data.original.Id)
    //           );
    //           localStorage.setItem(
    //             "UserId",
    //             JSON.stringify(data.original.UserId)
    //           );
    //           history.push("/firesurveyreport");
    //         }}
    //       >
    //         <AssignmentIcon />
    //       </Button>
    //     );
    //   },
    // },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Service Task</h1>
      <MyTable data={Data} columns={columns} />
      {open2 === true ? (
        <>
          <MyModal
            open={open2}
            close={() => setOpen2(false)}
            title="Reason For Hold Task"
            class="ReshedulModal"
            MyClose={() => setOpen2(false)}
          >
            <div className={classes.ModalField}>
              <div>
                <label>{holdmessage}</label>
              </div>
            </div>
            <Button
              className={classes.AssignBtn}
              onClick={() => {
                setOpen2(false);
              }}
            >
              Close
            </Button>
          </MyModal>
        </>
      ) : (
        <MyModal
          open={open}
          close={() => setOpen(false)}
          title="Reason For Hold Task"
          class="ReshedulModal"
          MyClose={() => setOpen(false)}
        >
          <div className={classes.ModalField}>
            <div>
              <label>Reason For Hold Task</label>
            </div>
            <TextField
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setText(e.target.value);
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              fullWidth
            />
          </div>
          <Button
            className={classes.AssignBtn}
            onClick={() => {
              setOpen(false);
            }}
          >
            Hold
          </Button>
        </MyModal>
      )}
    </div>
  );
};
export default ServiceTask;
