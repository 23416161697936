import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import MyTable from "../../../../Component/Table/index";
import useStyle from "./style";
import TextField from "@material-ui/core/TextField";
import MyModal from "../../../../Component/Modal/index";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { GETONEORDERATES, UPDATEATES } from "../../../../RouteS/ApiRoutes/Ate";
import Select from "react-select";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Id = JSON.parse(localStorage.getItem("Id"));
const token = JSON.parse(localStorage.getItem("token"));
const EngineerId = JSON.parse(localStorage.getItem("EngineerId"));

const ViewAtes = () => {
  const classes = useStyle();
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const [serialno, setSerialNo] = useState();
  const [floor, setFloor] = useState();
  const [status, setStatus] = useState();
  const [id, setId] = useState();

  const [display, setDisplay] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState("");

  const url_str = window.location.href;
  const data = url_str.split("/");
  const orderid = data[4].toString();

  const options = [{ value: "Installed", label: "Installed" }];

  useEffect(() => {
    getOneOrderAtes();
  }, []);

  const getOneOrderAtes = () => {
    const BodyData = JSON.stringify({
      OrderId: orderid,
      EngineerId: EngineerId,
    });
    fetch(GETONEORDERATES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
      });
  };

  const updateAtes = () => {
    const BodyData = JSON.stringify({
      Id: id,
      SerialNo: serialno,
      Floor: floor,
      Status: status,
      OrderId: orderid,
    });
    fetch(UPDATEATES, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token + "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: BodyData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          getOneOrderAtes();
          setDisplay({ ...display, success: true });
          setMessage(res.Message);
        } else {
          setDisplay({ ...display, error: true });
          setMessage(res.Message);
        }
      });
  };

  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "BusinessName",
      accessor: "BusinessName",
    },

    {
      Header: "SerialNo",
      accessor: "SerialNo",
    },
    {
      Header: "Floor",
      accessor: "Floor",
    },
    {
      Header: "InstallBy",
      accessor: "InstallBy",
    },
    {
      Header: "InstallationDate",
      accessor: "InstallationDate",
    },
    {
      Header: "Status",
      accessor: "Status",
      //id:Status,
      // Cell: (data) => {
      //   switch (data.original.Status) {
      //     case "Installed":
      //       return (
      //         <div
      //           style={{
      //             background: "green",
      //             width: "70px",
      //             margin: "0px auto",
      //             borderRadius: 13,
      //             color: "white",
      //             padding: 2,
      //             fontWeight: 600,
      //             fontSize: 11,
      //           }}
      //         >
      //           {data.original.Status}
      //         </div>
      //       );
      //     case "Defective ATE":
      //       return (
      //         <div
      //           style={{
      //             background: "red",
      //             width: "70px",
      //             margin: "0px auto",
      //             borderRadius: 13,
      //             color: "white",
      //             padding: 3,
      //             fontWeight: 600,
      //             fontSize: 11,
      //           }}
      //         >
      //           {data.original.Status}
      //         </div>
      //       );
      //   }
      // },
    },
    {
      Header: "Update",
      filterable: false,
      id: "icon",
      Cell: (data) => {
        return (
          <Button
            style={{
              color: "white",
              background: ThemeColor,
              borderRadius: 10,
            }}
            onClick={(e) => {
              setId(data.original.Id);
              setStatus(data.original.Status);
              setSerialNo(data.original.SerialNo);
              setFloor(data.original.Floor);
              setOpen(true);
            }}
          >
            Update
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <div className={classes.ArrowDiv}>
        <ArrowBackIosIcon
          className={classes.ArrowIcon}
          onClick={history.goBack}
        />
      </div>
      <div className={classes.Titlediv}>
        <h1 style={{ textAlign: "left" }}>ATEs Detail</h1>
      </div>
      <MyTable data={Data} columns={columns} />
      <MyModal
        open={open}
        close={() => setOpen(false)}
        title="Update ATE Detail"
        class="ReshedulModal"
        MyClose={() => setOpen(false)}
      >
        <div className={classes.ModalField}>
          <label>ATEs Serial Number</label>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            name="SerialNo"
            value={serialno}
            onChange={(e) => {
              setSerialNo(e.target.value);
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </div>
        <div className={classes.ModalField}>
          <label>ATE Location</label>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            name="Location"
            value={floor}
            onChange={(e) => {
              setFloor(e.target.value);
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </div>
        <div className={classes.ModalField2}>
          <label>Installation Status</label>
          <Select
            options={options}
            placeholder={status}
            onChange={(e) => {
              setStatus(e.value);
            }}
          />
        </div>
        <Button
          className={classes.ReasonBtn}
          onClick={() => {
            updateAtes(), setOpen(false);
          }}
        >
          Update ATE
        </Button>
      </MyModal>
      <SucessModal
        open={display.success}
        message={message}
        close={() => {
          setDisplay({ ...display, success: false });
        }}
      />
      <ErrorModal
        open={display.error}
        message={message}
        close={() => {
          setDisplay({ ...display, error: false });
        }}
      />
    </div>
  );
};
export default ViewAtes;
