import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import DialogActions from "@material-ui/core/DialogActions";
import "./index.css";
import { ThemeColor } from "../../Assets/StyleSheets/Theme";

const Modal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={props.open}
        onClose={props.close}
        aria-labelledby="responsive-dialog-title"
        className={props.class}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            color: "black",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "2rem",
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent>
          {  props.children }
        </DialogContent>
        </Dialog>
    </div>
  );
};
export default Modal;
