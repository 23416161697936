import React, { useEffect, useState } from "react";
import MyTable from "../../../../Component/Table/index";
import { LISTHISTORY } from "../../../../RouteS/ApiRoutes/Damage";
import PuffLoader from "react-spinners/PuffLoader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
}));
const DamageHistory = () => {
  const classes = useStyles();
  const [Data, setData] = useState([]);
  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    fetch(LISTHISTORY)
      .then((res) => res.json())
      .then((res) => {
        return setData(res.data);
      });
  };
  const columns = [
    {
      Header: "#",
      id: "row",
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Order Id",
      accessor: "OrderId",
    },
    {
      Header: "Damage Id",
      accessor: "DamageId",
    },
    {
      Header: "Old Assign",
      accessor: "OldAssign",
    },
    {
      Header: "New Assign",
      accessor: "NewAssign",
    },
    {
      Header: "Serial No",
      accessor: "SerialNo",
    },
  ];

  return (
    <div className={classes.MainContainer}>
      <h1 style={{ textAlign: "left" }}>Damage History</h1>
      <MyTable data={Data} columns={columns} />
    </div>
  );
};
export default DamageHistory;
