import useStyle from "./style";
import Select from "react-select";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { Button, Container, IconButton } from "@material-ui/core";
import { ADDATES } from "../../../../RouteS/ApiRoutes/Ate";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";

const AddDamageATEs = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const engineerId = JSON.parse(localStorage.getItem("EngineerId"));
  const orderid = JSON.parse(localStorage.getItem("OrderId"));
  const BusinessName = JSON.parse(localStorage.getItem("BusinessName"));
  const UserName = JSON.parse(localStorage.getItem("UserName"));
  const DueDate = JSON.parse(localStorage.getItem("dueDate"));
  const userId = JSON.parse(localStorage.getItem("UserId"));
  const DamageId = JSON.parse(localStorage.getItem("DamageId"));
  const classes = useStyle();
  const history = useHistory();

  const options = [
    { value: "Installed", label: "Installed" },
    { value: "Defective ATE", label: "Defective ATE" },
  ];

  const [field, setField] = useState({
    SerialNo: "",
    Floor: "",
  });

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const [message, setMessage] = useState("");

  const onChangeSerialNumber = (e, j) => {
    setField({ ...field, SerialNo: e.target.value });
  };
  const onChangeLocation = (e, j) => {
    setField({ ...field, Floor: e.target.value });
  };

  const Submit = () => {
    if (field.SerialNo !== "" && field.Floor !== "") {
      const BodyData = JSON.stringify({
        OrderId: orderid,
        DamageId: DamageId,
        BusinessName: BusinessName,
        InstallBy: UserName,
        DueDate: DueDate,
        ates: [
          {
            SerialNumber: field.SerialNo,
            Location: field.Floor,
            Status: "Installed",
          },
        ],
        EngineerId: engineerId,
        UserId: userId,
      });
      fetch(ADDATES, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token + "",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: BodyData,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status === true) {
            setField({ ...field, SerialNo: "", Floor: "" });
            setStatus({ ...status, success: true });
            setMessage(res.Message);
          }
        });
    } else {
      setStatus({ ...status, error: true });
      setMessage("Please Fill The All Details");
      setView1(false);
    }
  };

  return (
    <Container maxWidth="xl" className={classes.MainContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
            marginBottom: "20px",
          }}
        />
      </div>
      <div>
        <div style={{ textAlign: "left", marginTop: "-2rem" }}>
          <h1>Submit Damage ATEs</h1>
        </div>
        <div className={classes.root}>
          <Paper elevation={3}>
            <div>
              <h3 className={classes.MainLabel}>Damage ATEs Information</h3>
              <div className={classes.mainDiv}>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>ATE Serial Number</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="SerialNumber"
                    onChange={(e) => {
                      onChangeSerialNumber(e);
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className={classes.FieldDiv2}>
                  <label className={classes.textLabel}>ATE Location</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="Location"
                    onChange={(e) => {
                      onChangeLocation(e);
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button className={classes.SubmitBtn} onClick={Submit}>
                Submit
              </Button>
            </div>
          </Paper>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            history.push("/dashboardcontroller");
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </div>
    </Container>
  );
};
export default AddDamageATEs;
