import React from "react";
import MyTab from "../Component/Tab/index";

const CustomerReq = () => {
  return (
    <div>
      <div>
        <h1 style={{ textAlign: "left", marginLeft: "25px" }}>
          Customers Requests
        </h1>
        <MyTab />
      </div>
    </div>
  );
};
export default CustomerReq;
