import { makeStyles } from "@material-ui/core/styles";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: "100%",
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  ArrowDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
  ArrowIcon: {
    paddingLeft: "10px",
    width: "40px !important",
    height: "40px !important",
    fontSize: 15,
    backgroundColor: ThemeColor,
    color: "white",
  },
  Titlediv: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
    alignItems: "center",
  },
  ModalField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&>label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
  },
  ModalField3: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    width: "400px",
    "&>label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
  },
  ModalField2: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    "&>label": {
      fontSize: 15,
      marginBottom: "0.3rem",
      fontWeight: 600,
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },

  MainContainer: {
    marginLeft: "25px",
    marginRight: "15px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1rem",
    marginTop: "1rem",
    marginLeft: "1rem",
    marginBottom: "1rem",
    width: "23%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "30%",
    },
    "&>.css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  FieldDiv3: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "3rem",
    marginRight: "-50px",
    // marginTop: "1rem",
    width: "23%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "92%",
      marginLeft: "3rem",
    },
    "&>.select-container .css-2b097c-container": {
      width: "80% !important",
    },
    "&>.select-container  .css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.select-container  .css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  FieldDiv2: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
    // marginTop: "1rem",
    width: "23%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "92%",
      marginLeft: "3rem",
    },
    "&>.select-container .css-2b097c-container": {
      width: "80% !important",
    },
    "&>.select-container  .css-2b097c-container .css-yk16xz-control": {
      borderColor: ThemeColor,
    },
    "&>.select-container  .css-2b097c-container .css-1pahdxg-control": {
      borderColor: ThemeColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: ThemeColor,
      },
      "&:active": {
        borderColor: ThemeColor,
      },
    },
  },
  SubmitBtn: {
    width: "25%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    marginRight: "1rem",
    color: "white",
    height: "7%",
    marginBottom: "2rem",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "35%",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  SubmitBtn1: {
    width: "25%",
    background: ThemeColor,

    fontSize: 17,
    fontWeight: 600,
    marginLeft: "1rem",
    color: "white",
    height: "7%",

    "&:hover": {
      background: ThemeColor,
    },
  },
  SubmitBtn2: {
    width: "25%",
    background: "green",

    fontSize: 17,
    fontWeight: 600,
    marginLeft: "1rem",
    color: "white",
    height: "7%",

    "&:hover": {
      background: "green",
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 11,
    },
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap",
    marginBottom: "1.5rem",
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    fontSize: 20,
    marginTop: "30px",
  },
  CheckColor: {
    color: ThemeColor + "!important",
  },
  IconeClick: {
    border: "1px solid " + ThemeColor + "",
    borderRadius: 4,
    marginLeft: "5px",
    padding: 7,
    "&>span": {
      color: ThemeColor,
    },
  },
  EndTaskBtn: {
    background: ThemeColor,
    color: "white",
    width: 130,
    marginRight: "1rem",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "1.5rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
  ReasonBtn: {
    background: ThemeColor,
    color: "white",
    width: "100%",
    fontWeight: 600,
    marginTop: "1rem",
    "&:hover": {
      background: ThemeColor,
    },
  },
}));
export default useStyles;
