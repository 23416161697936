import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Avatar, Button, Container, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ThemeColor } from "../../../../Assets/StyleSheets/Theme";
import { useHistory } from "react-router-dom";
import SucessModal from "../../../../Component/Modal/SucessModal";
import ErrorModal from "../../../../Component/Modal/ErrorModal";
import { VIEWPRICE } from "../../../../RouteS/BrowserRoute/Admin/index";
import { PRICE } from "../../../../RouteS/ApiRoutes/Price";
import { InputAdornment } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "600px",
    "& > .MuiPaper-elevation3": {
      margin: theme.spacing(1),
      width: 990,
      borderRadius: 20,
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  },
  MainContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
    marginLeft: "25px",
  },
  FieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "0rem",
      width: "100%",
    },
  },
  SaveBtn: {
    width: "100%",
    background: ThemeColor,
    marginTop: "1rem",
    fontSize: 17,
    fontWeight: 600,
    color: "white",
    height: "7%",
    marginBottom: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      width: "42%",
    },
    "&:hover": {
      background: ThemeColor,
    },
  },
  textLabel: {
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 12,
  },
  notchedOutline: {
    borderColor: ThemeColor + " " + "!important",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem",
    marginRight: "2rem",
    marginLeft: "1rem",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "3rem",
      flexFlow: "wrap",
    },
  },
  MainLabel: {
    textAlign: "left",
    marginLeft: "3rem",
    marginBottom: 0,
    marginTop: "2rem",
  },
  DateTextField: {
    width: "223px",
  },
}));

const AddPrice1 = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const classes = useStyles();
  const history = useHistory();

  const [field, setField] = useState({
    Description: "",
    ATEPrice: "",
    Tax: "",
    DeliveryFee: "",
    InstallationFee: "",
  });

  const [status, setStatus] = useState({
    success: false,
    error: false,
  });

  const [message, setMessage] = useState("");

  const onChangeField = (e) => {
    let value = e.target.value;
    setField({ ...field, [e.target.name]: value });
  };
  const Save = () => {
    var Data = JSON.stringify({
      Description: field.Description,
      ATEPrice: field.ATEPrice,
      Tax: field.Tax,
      DeliveryFee: field.DeliveryFee,
      InstallationFee: field.InstallationFee,
    });
    fetch(PRICE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          setStatus({ ...status, success: true });
          setMessage(res.Message);
        } else {
          setStatus({ ...status, error: true });
          setMessage(res.Message);
        }
      });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
          marginLeft: "30px",
        }}
      >
        <ArrowBackIosIcon
          onClick={history.goBack}
          style={{
            paddingLeft: "10px",
            width: "40px",
            height: "40px",
            fontSize: 15,
            backgroundColor: ThemeColor,
            color: "white",
            marginBottom: "20px",
          }}
        />
      </div>
      <Container maxWidth="xl" className={classes.MainContainer}>
        <div>
          <div style={{ textAlign: "left", marginTop: "-2rem" }}>
            <h1>Add Price</h1>
          </div>
          <div className={classes.root}>
            <Paper elevation={3}>
              <div>
                <div className={classes.mainDiv}>
                  <div className={classes.FieldDiv}>
                    <label className={classes.textLabel}>ATE Price</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="ATEPrice"
                      value={field.ATEPrice}
                      onChange={onChangeField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <InputAdornment position="start">AED</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {/* <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Tax</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="Tax"
                    value={field.Tax}
                    onChange={onChangeField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Delivery Fee</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="DeliveryFee"
                    type="text"
                    value={field.DeliveryFee}
                    onChange={onChangeField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Installation Fee</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="InstallationFee"
                    value={field.InstallationFee}
                    onChange={onChangeField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={classes.FieldDiv}>
                  <label className={classes.textLabel}>Description</label>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    name="Description"
                    value={field.Description}
                    onChange={onChangeField}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div> */}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button className={classes.SaveBtn} onClick={Save}>
                  Save
                </Button>
                <Button
                  className={classes.SaveBtn}
                  onClick={() => history.push(VIEWPRICE)}
                >
                  Cancel
                </Button>
              </div>
            </Paper>
          </div>
        </div>
        <SucessModal
          open={status.success}
          message={message}
          close={() => {
            return history.push(VIEWPRICE);
          }}
        />
        <ErrorModal
          open={status.error}
          message={message}
          close={() => {
            setStatus({ ...status, error: false });
          }}
        />
      </Container>
    </>
  );
};
export default AddPrice1;
